import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';
import { Jetty } from '../models/jetty.model';

@Injectable()
export class JettyService extends BaseService {

    constructor(
        _http: HttpClient,
    ) {
        super(_http);
        this.actionUrl += 'mlm/jetty';
    }

    public getAll(): Observable<Jetty[]> {
        return this._http
            .get<Jetty[]>(this.actionUrl, this.getRequestOptions());
    }

    public get(id: string): Observable<Jetty> {
        return this._http
            .get<Jetty>(this.actionUrl + '/' + id, this.getRequestOptions());
    }

    public setCurrent(newCurrentJetty: Jetty): Observable<Jetty> {
        return this._http
            .post<Jetty>(this.actionUrl + '/current',
                JSON.stringify(newCurrentJetty),
                this.getRequestOptions());
    }

    public unsetCurrent(): Observable<HttpResponse<string>> {
        return this._http
            .post(this.actionUrl + '/current/unset',
                JSON.stringify({}),
                this.getFullRawRequestOptions());
    }

    public update(updatedJetty: Jetty): Observable<Jetty> {
        return this._http
            .put<Jetty>(this.actionUrl + '/' + updatedJetty.id,
                JSON.stringify(updatedJetty),
                this.getRequestOptions());
    }
}
