import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InstallationTypeEnum } from '../enums/installation-type.enum';

@Pipe({
    name: 'installationType'
})
export class InstallationTypePipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(installationType: InstallationTypeEnum): string {
        if (installationType == undefined) {
            return 'NONE';
        }

        switch (parseInt(installationType.toString(), 10)) {
            case InstallationTypeEnum.SHIP:
                return this._translate.instant('SSL_ESD.SHIP');
            case InstallationTypeEnum.SHORE:
                return this._translate.instant('SSL_ESD.SHORE');
        }
    }
}
