import { StatusTypeEnum } from '../enums/status-type.enum';
import { CommonAlarmTypeEnum } from '../../enums/system/common-alarm-type.enum';

export class CommonAlarm {

    public isTripped: boolean;
    public rawState: number;
    public activeAlarms: CommonAlarmTypeEnum[];

    constructor(data: any) {
        this.rawState = data.rawState;
        this.activeAlarms = data.activeAlarms || [];

        // Workaround because of boolean type (Silly JS)
        this.isTripped = false;
        if (data.isTripped != null) {
            this.isTripped = data.isTripped;
        }
    }
}
