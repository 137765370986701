import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToFromFilter'
})
export class DateToFromFilterPipe implements PipeTransform {

    public transform(array: any[],
                     timestampFrom: number,
                     timestampTo: number,
                     property: string): any {
        if (timestampFrom && timestampTo) {
            return array.filter((row) => (timestampFrom < row[property] &&
            timestampTo > row[property]));
        } else if (timestampTo) {
            return array.filter((row) => (timestampTo > row[property]));
        } else if (timestampFrom) {
            return array.filter((row) => (timestampFrom < row[property]));
        } else {
            return array;
        }
    }
}
