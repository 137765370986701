import { NgModule, ModuleWithProviders } from '@angular/core';

// Directives
import { DraggableDirective } from './draggable.directive';

@NgModule({
    declarations: [
        DraggableDirective,
    ],
    exports: [
        DraggableDirective,
    ]
})
export class SharedDirectivesModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedDirectivesModule
        };
    }
}
