import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UslSystemV0Log } from '../../../../../../models/usl-system/v0/log.model';
import { Paged } from '../../../../../../../shared/models/common/paged.model';

@Component({
    selector: 'usl-display-v0-log-modal',
    styleUrls: ['./log.component.css'],
    templateUrl: './log.component.html'
})
export class UslDisplayV0ModalLogComponent {

    @Input() public uslLogRows: Paged<UslSystemV0Log>;
    @Input() public isLoadingLogs: boolean;
    @Input() public currentTimeZone: string = 'UTC';
    @Output() public onSort: EventEmitter<any> = new EventEmitter();
    @Output() public onPage: EventEmitter<any> = new EventEmitter();
}
