import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UslSystemV0Alarm } from '../../../../../../models/usl-system/v0/alarm.model';
import { UslSystemV0DisplayReading } from '../../../../../../models/usl-system/v0/display-readings.model';

@Component({
    selector: 'usl-display-v0-reading-config-modal',
    styleUrls: ['./reading-config.component.css'],
    templateUrl: './reading-config.component.html'
})
export class UslDisplayV0ModalReadingConfigComponent {

    @Input() public selectedReading: UslSystemV0DisplayReading;
    @Input() public systemAlarms: UslSystemV0Alarm[];
    @Output() public onSelectAlarm: EventEmitter<UslSystemV0Alarm> =
        new EventEmitter<UslSystemV0Alarm>();
    @Output() public onRemoveAlarm: EventEmitter<UslSystemV0Alarm> =
        new EventEmitter<UslSystemV0Alarm>();

    public selectedItem: UslSystemV0Alarm = undefined;

    // Template Helpers
    public selectItem(selectedItem: UslSystemV0Alarm): void {
        this.selectedItem = selectedItem;
    }

    public addAlarm(): void {
        this.onSelectAlarm.next(new UslSystemV0Alarm({}));
    }

    public confirmEditAlarm(): void {
        this.onSelectAlarm.next(new UslSystemV0Alarm(this.selectedItem));
    }

    public confirmRemoveAlarm(): void {
        this.onRemoveAlarm.next(this.selectedItem);
    }
}
