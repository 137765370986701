import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ElectricLinePairConfigEnum } from '../enums/electric-line-pair-config.enum';

@Pipe({
    name: 'electricLinePair37Way'
})
export class ElectricLinePair37WayPipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(linePair: ElectricLinePairConfigEnum): string {
        switch (linePair) {
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_1_2:
                return '37W 01/02';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_3_4:
                return '37W 03/04';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_5_6:
                return '37W 05/06';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_7_8:
                return '37W 07/08';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_9_10:
                return '37W 09/10';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_11_12:
                return '37W 11/12';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_13_14:
                return '37W 13/14';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_15_16:
                return '37W 15/16';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_17_18:
                return '37W 17/18';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_19_20:
                return '37W 19/20';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_21_22:
                return '37W 21/22';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_23_24:
                return '37W 23/24';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_25_26:
                return '37W 25/26';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_27_28:
                return '37W 27/28';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_29_30:
                return '37W 29/30';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_31_32:
                return '37W 31/32';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_33_34:
                return '37W 33/34';
            case ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_35_36:
                return '37W 35/36';
            default:
            case ElectricLinePairConfigEnum.NOT_CONNECTED:
                return this._translate.instant('SSL.TERMINAL_CONN_NONE');
        }
    }
}
