import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';
import { Log } from '../models/log.model';
import { Paged } from '../../shared/models/common/paged.model';

@Injectable()
export class LogService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'ssl/log';
    }

    public getLogs(orderByField: string,
                   isDesc: boolean,
                   pageIndex: number = 1,
                   pageSize: number = 2): Observable<Paged<Log>> {
        if (isDesc) {
            orderByField += '_DESC';
        }

        return this._http
            .get<Paged<Log>>(this.actionUrl +
                '?orderByField=' + orderByField +
                '&pageIndex=' + pageIndex +
                '&pageSize=' + pageSize,
                this.getRequestOptions());
    }

    public getLogsBySystem(systemId: string,
                           orderByField: string,
                           isDesc: boolean,
                           pageIndex: number = 1,
                           pageSize: number = 2): Observable<Paged<Log>> {
        if (isDesc) {
            orderByField += '_DESC';
        }

        return this._http
            .get<Paged<Log>>(this.actionUrl + '/' + systemId +
                '?orderByField=' + orderByField +
                '&pageIndex=' + pageIndex +
                '&pageSize=' + pageSize,
                this.getRequestOptions());
    }

    public websocketGetLogs(): Observable<Log> {
        return this._ws.get('/wsapi/ssl/logs');
    }
}
