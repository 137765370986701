import { SafetyEventType } from '../../../enums/safety/safety-event-type.enum';

export abstract class BaseSafetyEvent {

    public id: string;
    public eventType: SafetyEventType;
    public replacesEventTypes: SafetyEventType[];

    constructor(data: any) {
        this.id = data.id;
        this.eventType = data.eventType;
        this.replacesEventTypes = data.replacesEventTypes;
    }
}
