import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { WebSocketService } from '../websocket/websocket.service';
import { WebsocketTrip } from '../../models/esd/websocket/trip.model';
import {
    WebsocketEsdSystemStatus
} from '../../models/esd/websocket/esd-system-status.model';
import { WebsocketTelecomm } from '../../models/esd/websocket/telecomm.model';
import { EsdSystem } from '../../models/esd/esd-system.model';
import { MlmSourceStateEnum } from '../../enums/mlm-source-state.enum';
import { BoardLookup } from '../../models/esd/board-lookup.model';
import {
    WebsocketEsdSystemStateChange
} from '../../models/esd/websocket/esd-system-state-change.model';
import { EslStateEnum } from '../../../mod_ssl/enums/esl-state.enum';
import { BaseSafetyEvent } from '../../models/safety/events/base-safety-event.model';
import { DiagnosticInformation } from '../../models/safety/diagnostic-information.model';
import { SystemLocationEnum } from '../../enums/system-location.enum';
import { BoardActivation } from '../../models/esd/board-activation.model';
import { FibreLoopbackResult } from '../../models/esd/fibre-loopback-result.model';
import { ElectricLoopbackResult } from '../../models/esd/electric-loopback-result.model';
import { MiscInformation } from '../../models/safety/misc-information.model';

@Injectable()
export class EsdService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'esd';
    }

    public websocketGetTripStatus(): Observable<WebsocketTrip> {
        return this._ws.get('/wsapi/esd/trip-status');
    }

    public websocketGetEsdSystemStatus(): Observable<WebsocketEsdSystemStatus> {
        return this._ws.get('/wsapi/esd/system-status');
    }

    public websocketGetTelecommStatus(): Observable<WebsocketTelecomm> {
        return this._ws.get('/wsapi/esd/telecomms-status');
    }

    public websocketGetEsdSystemStateChange(): Observable<WebsocketEsdSystemStateChange> {
        return this._ws.get('/wsapi/esd/system-state');
    }

    public websocketGetSafetyEvents(): Observable<BaseSafetyEvent> {
        return this._ws.get('/wsapi/esd/safety-events');
    }

    public websocketGetDiagnosticInformation(): Observable<DiagnosticInformation> {
        return this._ws.get('/wsapi/esd/diagnostics');
    }

    public websocketGetMiscInformation(): Observable<MiscInformation> {
        return this._ws.get('/wsapi/esd/misc');
    }

    public websocketGetFibreLoopbackResult(): Observable<FibreLoopbackResult> {
        return this._ws.get('/wsapi/esd/fibre-loopback-result');
    }

    public websocketGetElectricLoopbackResult(): Observable<ElectricLoopbackResult> {
        return this._ws.get('/wsapi/esd/electric-loopback-result');
    }

    public getEsdSystems(): Observable<EsdSystem[]> {
        return this._http
            .get<EsdSystem[]>(this.actionUrl, this.getRequestOptions());
    }

    public getDiagnosticInformation(): Observable<DiagnosticInformation> {
        return this._http
            .get<DiagnosticInformation>(this.actionUrl + '/diagnostics', this.getRequestOptions());
    }

    public getMiscInformation(): Observable<MiscInformation> {
        return this._http
            .get<MiscInformation>(this.actionUrl + '/misc', this.getRequestOptions());
    }

    public getEsdSystemById(systemId: string): Observable<EsdSystem> {
        return this._http
            .get<EsdSystem>(this.actionUrl + '/system-id/' + systemId, this.getRequestOptions());
    }

    public getAvailableBoards(): Observable<BoardLookup[]> {
        return this._http
            .get<BoardLookup[]>(this.actionUrl + '/available-boards', this.getRequestOptions());
    }

    public getBoardActivationRules(): Observable<BoardActivation[]> {
        return this._http
            .get<BoardActivation[]>(this.actionUrl + '/board-activation-rules', this.getRequestOptions());
    }

    public putReset(id: string): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/reset/' + id,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putInhibit(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/inhibit/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putContLinkOverride(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/override-continuity-link/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putInvertMlmPolarity(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/invert-mlm-polarity/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putContLink(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/continuity-link/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putEtu(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/etu/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putEtuPins(id: string, is3536: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/etu-pins/' + id + '/' + is3536,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putExchange(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/exchange/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putPrivateLine(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/private-line/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putFibreOnBankA(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/fibre-on-bank-a/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putFibreMlm(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/fibre-mlm/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putAutomaticReset(id: string, isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/automatic-reset/' + id + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putMlmBackplaneSwitch(id: string, newState: MlmSourceStateEnum): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/mlm-backplane-switch/' + id + '/' + newState,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putEslSwitch(id: string, newState: EslStateEnum): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/esl-switch/' + id + '/' + newState,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putFibreAnalogueGainSet(id: string,
                                   channelNumber: number,
                                   gain: number): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/fibre-analogue-gain/' + id + '/' + channelNumber + '/' + gain,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putFibreLoopbackTest(id: string,
                                isShore: boolean,
                                isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/fibre-loopback-test/' + id +
                '/' + isShore + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putElectricLoopbackTest(id: string,
                                   isMiyaki: boolean,
                                   isEnabled: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/electric-loopback-test/' + id +
                '/' + isMiyaki + '/' + isEnabled,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putModemSoundPoweredBankA(id: string,
                                     isSoundPoweredPhone: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/mk2/modem-spt-bank-a/' + id +
                '/' + isSoundPoweredPhone,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putModemSoundPoweredBankB(id: string,
                                     isSoundPoweredPhone: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/mk2/modem-spt-bank-b/' + id +
                '/' + isSoundPoweredPhone,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putModemOneConnected(id: string,
                                isConnected: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/mk2/modem-one-connected/' + id +
                '/' + isConnected,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putModemTwoConnected(id: string,
                                isConnected: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/mk2/modem-two-connected/' + id +
                '/' + isConnected,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putModemOneFibreElectric(id: string,
                                    isFibre: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/mk2/modem-one-fibre/' + id +
                '/' + isFibre,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putModemTwoFibreElectric(id: string,
                                    isFibre: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/mk2/modem-two-fibre/' + id +
                '/' + isFibre,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putMlmBridged(id: string,
                         isBridged: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/mk2/mlm-bridged/' + id +
                '/' + isBridged,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putMlmCrossed(id: string,
                         isCrossed: boolean): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/mk2/mlm-crossed/' + id +
                '/' + isCrossed,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putAcceptTrips(id: string): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/accept-trips/' + id,
                undefined,
                this.getFullRawRequestOptions());
    }

    public putSwitchSides(id: string, systemLocation: SystemLocationEnum): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/switch-sides/' + id + '/' + systemLocation,
                undefined,
                this.getFullRawRequestOptions());
    }
}
