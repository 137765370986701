import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';
import { ConnectionSystem } from '../models/connection-system.model';
import { DeactivateSystemDto } from '../models/dto/deactivate-system-dto.model';
import { ActivateSystemDto } from '../models/dto/activate-system-dto.model';

@Injectable()
export class SystemService extends BaseService {

    constructor(
        _http: HttpClient
    ) {
        super(_http);
        this.actionUrl += 'ssl/connectionsystem';
    }

    public getAll(): Observable<ConnectionSystem[]> {
        return this._http
            .get<ConnectionSystem[]>(this.actionUrl,
                this.getRequestOptions());
    }

    public getAllActive(): Observable<ConnectionSystem[]> {
        return this._http
            .get<ConnectionSystem[]>(this.actionUrl + '/active',
                this.getRequestOptions());
    }

    public getAdditional(): Observable<ConnectionSystem[]> {
        return this._http
            .get<ConnectionSystem[]>(this.actionUrl + '/additional',
                this.getRequestOptions());
    }

    public getMaxActivatableSystems(): Observable<number> {
        return this._http
            .get<number>(this.actionUrl + '/max-activatable',
                this.getRequestOptions());
    }

    public get(id: string): Observable<ConnectionSystem> {
        return this._http
            .get<ConnectionSystem>(this.actionUrl + '/' + id,
                this.getRequestOptions());
    }

    public getByTerminal(terminalId: string): Observable<ConnectionSystem[]> {
        return this._http
            .get<ConnectionSystem[]>(this.actionUrl + '/terminal/' + terminalId,
                this.getRequestOptions());
    }

    public activate(val: ActivateSystemDto): Observable<HttpResponse<string>> {
        return this._http
            .post(this.actionUrl + '/' + val.systemId + '/activate',
                JSON.stringify(val),
                this.getFullRawRequestOptions());
    }

    public deactivate(val: DeactivateSystemDto): Observable<HttpResponse<string>> {
        return this._http
            .post(this.actionUrl + '/' + val.systemId + '/deactivate',
                JSON.stringify(val),
                this.getFullRawRequestOptions());
    }

    public update(val: ConnectionSystem): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/' + val.id,
                JSON.stringify(val),
                this.getFullRawRequestOptions());
    }
}
