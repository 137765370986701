import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { StatusTypeEnum } from '../../enums/status-type.enum';

@Component({
    selector: 'esds-shared-status-icon',
    styleUrls: ['./status-icon.component.css'],
    templateUrl: './status-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsdsSharedStatusIconComponent implements OnInit {

    @Input() public statusType: StatusTypeEnum;
    @Input() public isFirstUp: boolean;
    @Input() public statusSize: number;

    public fontSize: number;
    public statusTypeEnum = StatusTypeEnum;

    public ngOnInit(): void {
        this.fontSize = this.statusSize * 0.7;
    }

}
