import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TelecommTypeEnum } from '../enums/telecomm-type.enum';

@Pipe({
    name: 'telecommType'
})
export class TelecommTypePipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(telecommType: TelecommTypeEnum): string {
        switch (telecommType) {
            case TelecommTypeEnum.ELECTRIC:
                return 'ELECTRIC';
            case TelecommTypeEnum.FIBRE_ANALOGUE:
                return 'FIBRE ANALOGUE';
            case TelecommTypeEnum.FIBRE_DIGITAL:
                return 'FIBRE DIGITAL';
            default:
                return this._translate.instant('SSL.TERMINAL_CONN_NONE');
        }
    }
}
