import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../../shared/services/base.service';
import { WebSocketService } from '../../../shared/services/websocket/websocket.service';
import { UslSystemV0Log } from '../../models/usl-system/v0/log.model';
import { UslSystemV0AlarmLog } from '../../models/usl-system/v0/alarm-log.model';
import { Paged } from '../../../shared/models/common/paged.model';

@Injectable()
export class UslLogV0Service extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'usl/log';
    }

    public getLogs(orderByField: string,
                   isDesc: boolean,
                   pageIndex: number = 1,
                   pageSize: number = 2): Observable<Paged<UslSystemV0Log>> {
        if (isDesc) {
            orderByField += '_DESC';
        }

        return this._http
            .get<Paged<UslSystemV0Log>>(this.actionUrl +
                '?orderByField=' + orderByField +
                '&pageIndex=' + pageIndex +
                '&pageSize=' + pageSize,
                this.getRequestOptions());
    }

    public getLocalAlarmLogs(orderByField: string,
                             isDesc: boolean,
                             pageIndex: number = 1,
                             pageSize: number = 2): Observable<Paged<UslSystemV0AlarmLog>> {
        if (isDesc) {
            orderByField += '_DESC';
        }

        return this._http
            .get<Paged<UslSystemV0AlarmLog>>(this.actionUrl + '/local/alarm' +
                '?orderByField=' + orderByField +
                '&pageIndex=' + pageIndex +
                '&pageSize=' + pageSize,
                this.getRequestOptions());
    }

    public getRemoteAlarmLogs(orderByField: string,
                              isDesc: boolean,
                              pageIndex: number = 1,
                              pageSize: number = 2): Observable<Paged<UslSystemV0AlarmLog>> {
        if (isDesc) {
            orderByField += '_DESC';
        }

        return this._http
            .get<Paged<UslSystemV0AlarmLog>>(this.actionUrl + '/remote/alarm' +
                '?orderByField=' + orderByField +
                '&pageIndex=' + pageIndex +
                '&pageSize=' + pageSize,
                this.getRequestOptions());
    }

    public websocketGetLogs(): Observable<UslSystemV0Log> {
        return this._ws.get('/wsapi/usl/logs');
    }

    public websocketGetAlarmLogs(): Observable<UslSystemV0AlarmLog> {
        return this._ws.get('/wsapi/usl/alarm-logs');
    }
}
