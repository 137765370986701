import { SslConfiguration } from '../ssl-configuration.model';

export class SslConfigurationDto {

    public settingsPin: string;
    public configuration: SslConfiguration;

    constructor(data: SslConfigurationDto) {
        this.settingsPin = data.settingsPin || '';
        this.configuration = data.configuration || undefined;
    }
}
