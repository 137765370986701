import { Component, Input, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { UslSystemV0Alarm } from '../../../../../models/usl-system/v0/alarm.model';
import { UslAlarmV0Service } from '../../../../../services/usl-system/v0-alarm.service';
import { UslSystemV0DisplayReading } from '../../../../../models/usl-system/v0/display-readings.model';

@Component({
    selector: 'usl-display-v0-alarms-modal',
    styleUrls: ['./alarms.component.css'],
    templateUrl: './alarms.component.html'
})
export class UslDisplayV0ModalAlarmsComponent {

    @Input() public isLocal: boolean;
    @Input() public activeAlarms: UslSystemV0Alarm[] = [];
    @Input() public systemReadings: { [id: number]: UslSystemV0DisplayReading; } = {};
    @ViewChild('alarmsModal') public alarmsModal: ModalDirective;

    public selectedItem: UslSystemV0Alarm = undefined;

    constructor(
        private _alarmService: UslAlarmV0Service
    ) {}

    public open(): void {
        this.selectedItem = undefined;
        this.alarmsModal.show();
    }

    // Template Helpers
    public selectItem(selectedItem: UslSystemV0Alarm): void {
        this.selectedItem = selectedItem;
    }

    public confirmAcknowledgeAlarm() {
        if (this.isLocal) {
            this.serviceAcknowledgeLocalAlarm(this.selectedItem).add(() => {
                this.selectedItem = undefined;
            });
        } else {
            this.serviceAcknowledgeRemoteAlarm(this.selectedItem).add(() => {
                this.selectedItem = undefined;
            });
        }
    }

    // Service Calls
    private serviceAcknowledgeLocalAlarm(alarm: UslSystemV0Alarm): Subscription {
        return this._alarmService
            .acknowledgeLocalAlarm(alarm)
            .subscribe();
    }

    private serviceAcknowledgeRemoteAlarm(alarm: UslSystemV0Alarm): Subscription {
        return this._alarmService
            .acknowledgeRemoteAlarm(alarm)
            .subscribe();
    }
}
