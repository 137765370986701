import { Component, ViewChild, OnDestroy, Input } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap';
import { EsdService } from '../../../services/esd/esd.service';
import { DiagnosticInformation } from '../../../models/safety/diagnostic-information.model';
import { PersistenceService } from '../../../services/system/persistence.service';
import { LocalboardConfiguration } from '../../../models/common/localboard-configuration.model';
import { DeviceCommsService } from '../../../services/system/device-comms.service';
import { CONFIGURATION } from '../../../../app.constants';
import { BaseState } from '../../../models/system/comms/states/base-state.model';
import { StateTypeEnum } from '../../../enums/comms/state-type.enum';

@Component({
    selector: 'diagnostics-info-modal',
    styleUrls: ['./diagnostics-info.component.css'],
    templateUrl: './diagnostics-info.component.html'
})
export class DiagnosticsInfoModalComponent implements OnDestroy {

    @Input() public isDebug: boolean;
    @ViewChild('diagnosticsInfoModal') public diagnosticsInfoModal: ModalDirective;

    public esdDiagnosticInfo: DiagnosticInformation;
    public localboardConfiguration: LocalboardConfiguration;
    public deviceCommsStates: BaseState[] = [];

    public deviceRxAnimationStates: { [id: string]: string; } = {};
    public deviceTxAnimationStates: { [id: string]: string; } = {};

    public deviceStateType = StateTypeEnum;

    private pollTimer: Subscription = undefined;
    private transientSubscriptions: Subscription[] = [];

    constructor(
        private _persistence: PersistenceService,
        private _esdService: EsdService,
        private _deviceCommsService: DeviceCommsService,
    ) {}

    public ngOnDestroy(): void {
        for (const val of this.transientSubscriptions) {
            if (val) {
                val.unsubscribe();
            }
        }

        this.stopPolling();
    }

    public open(): void {
        this.serviceGetEsdDiagnosticInfo();
        this.transientSubscriptions.push(this._persistence
            .isDebugToggled
            .subscribe(() => {
                this.isDebug = !this.isDebug;
            }));
        this.transientSubscriptions.push(this._persistence
            .localboardConfiguration
            .subscribe((data: LocalboardConfiguration) => {
                if (data) {
                    this.localboardConfiguration = data;
                }
            }));
        this.transientSubscriptions.push(this.websocketGetDiagnosticInformation());
        this.transientSubscriptions.push(this.websocketGetCommsRxStateReport());
        this.transientSubscriptions.push(this.websocketGetCommsTxStateReport());
        this.stopPolling();
        this.serviceGetDeviceStates().add(() => {
            this.startPolling();
        });

        this.diagnosticsInfoModal.show();
    }

    public close() {
        this.diagnosticsInfoModal.hide();

        for (const val of this.transientSubscriptions) {
            if (val) {
                val.unsubscribe();
            }
        }

        this.stopPolling();
    }

    public hasSafetyInfo(): boolean {
        return (this.esdDiagnosticInfo &&
            this.localboardConfiguration &&
            (this.localboardConfiguration.isPsuOneEnabled ||
                this.localboardConfiguration.isPsuTwoEnabled ||
                this.localboardConfiguration.isPsuThreeEnabled ||
                this.localboardConfiguration.isTemperatureOneEnabled ||
                this.localboardConfiguration.isTemperatureTwoEnabled));
    }

    // Internal Helpers
    private startPolling(): void {
        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                this.serviceGetDeviceStates().add(() => {
                    this.startPolling();
                });
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    // Service calls
    private serviceGetEsdDiagnosticInfo(): Subscription {
        return this._esdService
            .getDiagnosticInformation()
            .subscribe((data: DiagnosticInformation) => {
                this.esdDiagnosticInfo = data;
            });
    }

    private serviceGetDeviceStates(): Subscription {
        return this._deviceCommsService
            .getDeviceStates()
            .subscribe((data: BaseState[]) => {
                for (const deviceState of data) {
                    if (!this.deviceRxAnimationStates[deviceState.identifier]) {
                        this.deviceRxAnimationStates[deviceState.identifier] = 'inactive';
                    }

                    if (!this.deviceTxAnimationStates[deviceState.identifier]) {
                        this.deviceTxAnimationStates[deviceState.identifier] = 'inactive';
                    }
                }

                this.deviceCommsStates = data;
            });
    }

    private websocketGetDiagnosticInformation(): Subscription {
        return this._esdService
            .websocketGetDiagnosticInformation()
            .subscribe((data: DiagnosticInformation) => {
                if (data) {
                    this.esdDiagnosticInfo = data;
                }
            });
    }

    private websocketGetCommsRxStateReport(): Subscription {
        return this._deviceCommsService
            .websocketGetRxStateReport()
            .subscribe((identifier: any) => {
                this.deviceRxAnimationStates[identifier.raw] = 'active';
                setTimeout(() => {
                    this.deviceRxAnimationStates[identifier.raw] = 'inactive';
                }, 100);
            });
    }

    private websocketGetCommsTxStateReport(): Subscription {
        return this._deviceCommsService
            .websocketGetTxStateReport()
            .subscribe((identifier: any) => {
                this.deviceTxAnimationStates[identifier.raw] = 'active';
                setTimeout(() => {
                    this.deviceTxAnimationStates[identifier.raw] = 'inactive';
                }, 100);
            });
    }
}
