import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'keypad-dialog-modal',
    styleUrls: ['./keypad.component.css'],
    templateUrl: './keypad.component.html'
})
export class KeypadDialogModalComponent {

    @Input() public isPassword: boolean = true;
    @Input() public title: string;
    @Input() public isClosable: boolean;

    @Output() public enter: EventEmitter<string> = new EventEmitter<string>();
    @Output() public cancel: EventEmitter<undefined> = new EventEmitter<undefined>();

    public visibleValue: string = '';
    public currentValue: string = '';

    @ViewChild('keypadModal') public keypadModal: ModalDirective;

    public pressButton(character: string): void {
        if (this.isPassword) {
            this.visibleValue = '';
            for (let char of this.currentValue) {
                this.visibleValue += '*';
            }
        }

        this.visibleValue += character;
        this.currentValue += character;
    }

    public removeChar(): void {
        if (this.currentValue.length > 0) {
            this.currentValue = this.currentValue.slice(0, -1);
            this.visibleValue = this.visibleValue.slice(0, -1);
        }
    }

    public clear(): void {
        this.currentValue = '';
        this.visibleValue = '';
    }

    public open(): void {
        this.clear();
        this.keypadModal.show();
    }

    public enterPressed(): void {
        this.keypadModal.hide();
        this.enter.emit(this.currentValue);
    }

    public cancelPressed(): void {
        this.keypadModal.hide();
        this.cancel.emit(undefined);
    }
}
