import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SslConfiguration } from '../models/ssl-configuration.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';

@Component({
    selector: 'ssl-systems',
    styleUrls: ['./systems.component.css'],
    templateUrl: './systems.component.html'
})
export class SslSystemsComponent implements OnInit, OnDestroy {

    private routerSub: Subscription;
    private persistenceSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _persistence: PersistenceService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.persistenceSub = this._persistence
                    .sslConfiguration
                    .subscribe((data: SslConfiguration) => {
                        if (data) {
                            if (!data.currentTerminal) {
                                this._router.navigate(['/ssl', 'terminals']);
                            }
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.persistenceSub.unsubscribe();
        }
    }
}
