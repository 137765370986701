import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';

@Injectable()
export class ScreenToolsService extends BaseService {

    constructor(
        _http: HttpClient,
    ) {
        super(_http);
        this.screenActionUrl += 'screen/tools';
    }

    public getScreenCalibration(): Observable<any> {
        return this._http
            .get(this.screenActionUrl + '/calibrate',
                this.getRequestOptions());
    }
}
