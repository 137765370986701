import { SystemConfiguration } from '../configuration.model';

export class SystemConfigurationDto {

    public settingsPin: string;
    public configuration: SystemConfiguration;

    constructor(data: SystemConfigurationDto) {
        this.settingsPin = data.settingsPin || '';
        this.configuration = data.configuration || undefined;
    }
}
