import { Component, ViewChild, Input, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap';

import { CONFIGURATION } from '../../../app.constants';

import { EsdSystem } from '../../../shared/models/esd/esd-system.model';
import {
    WebsocketEsdSystemStatus
} from '../../../shared/models/esd/websocket/esd-system-status.model';
import { ConnectionTypeEnum } from '../../../shared/enums/connection-type.enum';
import { EsdService } from '../../../shared/services/esd/esd.service';
import { SystemLocationEnum } from '../../../shared/enums/system-location.enum';
import { SslConfiguration } from '../../../mod_ssl/models/ssl-configuration.model';
import { PersistenceService } from '../../../shared/services/system/persistence.service';

@Component({
    selector: 'usl-change-settings-modal',
    styleUrls: ['./change-settings.component.css'],
    templateUrl: './change-settings.component.html'
})
export class UslChangeSettingsModalComponent implements OnDestroy {

    @ViewChild('changeSettingsModal') public changeSettingsModal: ModalDirective;

    @Input() public systemId: string;
    @Input() public systemName: string;

    public availableLocations: SystemLocationEnum[];
    public connectionTypeEnum = ConnectionTypeEnum;
    public esdSystem: EsdSystem;
    public isSwitchingLocations: boolean = false;

    private pollTimer: Subscription = undefined;
    private wsEsdSystemSub: Subscription;
    private persistenceSub: Subscription;

    constructor(
        private _esdService: EsdService,
        private _persistence: PersistenceService
    ) {}

    public ngOnDestroy(): void {
        if (this.wsEsdSystemSub) {
            this.wsEsdSystemSub.unsubscribe();
        }

        this.wsEsdSystemSub = undefined;
        this.stopPolling();
    }

    public open(): void {
        this.changeSettingsModal.show();
        this.wsEsdSystemSub = this.websocketGetEsdSystemStatus();
        this.persistenceSub = this._persistence
            .sslConfiguration
            .subscribe((data: SslConfiguration) => {
                if (data) {
                    this.availableLocations = data.availableSystemLocations;
                }
            });
        this.stopPolling();
        this.serviceGetEsdSystem(this.systemId).add(() => {
            this.startPolling();
        });
    }

    public close(): void {
        this.changeSettingsModal.hide();
        this.persistenceSub.unsubscribe();
        this.wsEsdSystemSub.unsubscribe();
        this.wsEsdSystemSub = undefined;
        this.stopPolling();
    }

    // Template Helpers
    public switchLocation(newLocation: SystemLocationEnum): void {
        this.isSwitchingLocations = true;
        this.serviceSwitchSides(newLocation).add(() => {
            this.isSwitchingLocations = false;
        });
    }

    // Helpers
    private startPolling(): void {
        if (this.wsEsdSystemSub === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                if (this.systemId) {
                    this.serviceGetEsdSystem(this.systemId).add(() => {
                        this.startPolling();
                    });
                } else {
                    this.startPolling();
                }
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    // Service Calls
    private serviceGetEsdSystem(systemId: string): Subscription {
        return this._esdService
            .getEsdSystems()
            .subscribe((data: EsdSystem[]) => {
                for (const system of data) {
                    if (system.id == systemId) {
                        this.esdSystem = new EsdSystem(system);
                        break;
                    }
                }
            });
    }

    private websocketGetEsdSystemStatus(): Subscription {
        return this._esdService
            .websocketGetEsdSystemStatus()
            .subscribe((data: WebsocketEsdSystemStatus) => {
                if (data.id == this.esdSystem.id) {
                    this.esdSystem.updateWebsocketEsdSystemState(data);
                }
            });
    }

    private serviceSwitchSides(newLocation: SystemLocationEnum): Subscription {
        return this._esdService.putSwitchSides(this.systemId, newLocation).subscribe();
    }
}
