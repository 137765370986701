import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { WebSocketService } from '../websocket/websocket.service';
import { LocalboardConfiguration } from '../../models/common/localboard-configuration.model';

@Injectable()
export class CommonConfigurationService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'common/configuration';
    }

    public websocketGetLocalboardConfiguration(): Observable<LocalboardConfiguration> {
        return this._ws.get('/wsapi/system/localboard-config');
    }

    public getLocalboardConfiguration(): Observable<LocalboardConfiguration> {
        return this._http
            .get<LocalboardConfiguration>(this.actionUrl + '/localboard',
                this.getRequestOptions());
    }
}
