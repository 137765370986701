import {
    Directive, Input, EventEmitter, SimpleChange, OnChanges, DoCheck, IterableDiffers,
    IterableDiffer, Output
} from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface SortEvent {
    sortBy: string|string[];
    sortOrder: SortOrder|SortOrder[];
}

export interface PageEvent {
    activePage: number;
    rowsOnPage: number;
    dataLength: number;
}

export type SortOrder = 'asc' | 'desc';

@Directive({
    selector: 'table[mfData]',
    exportAs: 'mfDataTable'
})
export class Angular2DataTableDirective implements OnChanges, DoCheck {

    @Input('mfData') public inputData: any[] = [];

    @Input('mfSortBy') public sortBy: string|string[] = '';
    @Input('mfSortOrder') public sortOrder: SortOrder|SortOrder[] = 'asc';
    @Output('mfSortByChange') public sortByChange = new EventEmitter<string|string[]>();
    @Output('mfSortOrderChange') public sortOrderChange = new EventEmitter<SortOrder|SortOrder[]>();

    @Input('mfRowsOnPage') public rowsOnPage = 100;
    @Input('mfActivePage') public activePage = 1;

    public data: any[];

    public onSortChange = new ReplaySubject<SortEvent>(1);
    public onPageChange = new EventEmitter<PageEvent>();

    private mustRecalculateData = false;
    private diff: IterableDiffer<any>;

    public constructor(private differs: IterableDiffers) {
        this.diff = differs.find([]).create(null);
    }

    public getSort(): SortEvent {
        return {sortBy: this.sortBy, sortOrder: this.sortOrder};
    }

    public setSort(sortBy: string|string[], sortOrder: SortOrder|SortOrder[]): void {
        if (this.sortBy !== sortBy || this.sortOrder !== sortOrder) {
            this.sortBy = sortBy;
            if (Array.isArray(sortOrder)) {
                if (!sortOrder.every((elem) => ['asc', 'desc'].indexOf(elem) > -1)) {
                    this.sortOrder = 'asc';
                }
            } else {
                this.sortOrder = ['asc', 'desc'].indexOf(sortOrder) >= 0 ? sortOrder : 'asc';
            }

            this.mustRecalculateData = true;
            this.onSortChange.next({sortBy: this.sortBy, sortOrder: this.sortOrder});
            this.sortByChange.emit(this.sortBy);
            this.sortOrderChange.emit(this.sortOrder);
        }
    }

    public getPage(): PageEvent {
        return {
            activePage: this.activePage,
            rowsOnPage: this.rowsOnPage,
            dataLength: this.inputData.length
        };
    }

    public setPage(activePage: number, rowsOnPage: number): void {
        if (this.rowsOnPage !== rowsOnPage || this.activePage !== activePage) {
            this.activePage = this.activePage !== activePage ?
                activePage : this.calculateNewActivePage(this.rowsOnPage, rowsOnPage);
            this.rowsOnPage = rowsOnPage;
            this.mustRecalculateData = true;
            this.onPageChange.emit({
                activePage: this.activePage,
                rowsOnPage: this.rowsOnPage,
                dataLength: this.inputData ? this.inputData.length : 0
            });
        }
    }

    public ngOnChanges(changes: {[key: string]: SimpleChange}): any {
        if (changes['rowsOnPage']) {
            this.rowsOnPage = changes['rowsOnPage'].previousValue;
            this.setPage(this.activePage, changes['rowsOnPage'].currentValue);
            this.mustRecalculateData = true;
        }

        if (changes['sortBy'] || changes['sortOrder']) {
            if (Array.isArray(this.sortOrder)) {
                if (!this.sortOrder.every((elem) => ['asc', 'desc'].indexOf(elem) > -1)) {
                    console.warn('angular2-datatable: value for input mfSortOrder must be one of ["asc", "desc"], but is:', this.sortOrder);
                    this.sortOrder = 'asc';
                }
            } else {
                if (['asc', 'desc'].indexOf(this.sortOrder) < 0) {
                    console.warn('angular2-datatable: value for input mfSortOrder must be one of ["asc", "desc"], but is:', this.sortOrder);
                    this.sortOrder = 'asc';
                }
            }

            if (this.sortBy) {
                this.onSortChange.next({sortBy: this.sortBy, sortOrder: this.sortOrder});
            }

            this.mustRecalculateData = true;
        }

        if (changes['inputData']) {
            this.inputData = changes['inputData'].currentValue || [];
            this.recalculatePage();
            this.mustRecalculateData = true;
        }
    }

    public ngDoCheck(): any {
        const changes = this.diff.diff(this.inputData);
        if (changes) {
            this.recalculatePage();
            this.mustRecalculateData = true;
        }

        if (this.mustRecalculateData) {
            this.fillData();
            this.mustRecalculateData = false;
        }
    }

    private fillData(): void {
        const offset = (this.activePage - 1) * this.rowsOnPage;
        let data = this.inputData;
        data = [...data].sort(this.sorter(this.sortBy, this.sortOrder));
        data = data.slice(offset, offset + this.rowsOnPage);
        this.data = data;
    }

    private caseInsensitiveIteratee(sortBy: string) {
        const sortBySplit = sortBy.split('.');
        return (row: any): any => {
            let value = row;
            for (const sortByProperty of sortBySplit) {
                if (value) {
                    value = value[sortByProperty];
                }
            }

            if (value && typeof value === 'string' || value instanceof String) {
                return value.toLowerCase();
            }

            return value;
        };
    }

    private compare(a: any, b: any): number {
        let numA = parseFloat(a);
        let numB = parseFloat(b);

        if (a === null || typeof a === 'undefined') { return 0; }
        if (b === null || typeof b === 'undefined') { return 0; }

        if ((a === true || a === false) && (b === true || b === false)) {
            // Its a boolean
            if (numA == numB) {
                return 0;
            } else {
                numA = a ? 1 : -1;
                numB = b ? 1 : -1;
            }
        }

        if (((isNaN(numA) || !isFinite(numA)) || (isNaN(numB) || !isFinite(numB)))) {
            // Isn't a number so lowercase the string to properly compare
            if (a.toLowerCase() < b.toLowerCase()) { return -1; }
            if (a.toLowerCase() > b.toLowerCase()) { return 1; }
        } else {
            // Parse strings as numbers to compare properly
            if (numA < numB) { return -1; }
            if (numA > numB) { return 1; }
        }

        return 0; // equal each other
    }

    private sorter<T>(sortBy: string | string[],
                      sortOrder: SortOrder | SortOrder[]): (left: T, right: T) => number {

        if (typeof sortBy === 'string' || sortBy instanceof String) {
            let order = 0;
            if (Array.isArray(sortOrder) && sortOrder.length > 0) {
                order = sortOrder[0] === 'desc' ? -1 : 1;
            } else {
                order = sortOrder === 'desc' ? -1 : 1;
            }

            const iteratee = this.caseInsensitiveIteratee(sortBy as string);
            return (left, right) => {
                return this.compare(iteratee(left), iteratee(right)) * order;
            };
        } else {
            let orders: number[] = new Array(sortBy.length);
            if (Array.isArray(sortOrder) && sortOrder.length > 0) {
                // Set initial values
                orders = sortOrder[0] === 'desc' ? orders.fill(-1) : orders.fill(1);

                for (let i = 0; i < sortOrder.length; ++i) {
                    orders[i] = sortOrder[i] === 'desc' ? -1 : 1;
                }
            } else {
                orders = sortOrder === 'desc' ? orders.fill(-1) : orders.fill(1);
            }

            const iteratees = sortBy.map((entry) => this.caseInsensitiveIteratee(entry));
            return (left, right) => {
                for (let i = 0; i < sortBy.length; ++i) {
                    const comparison =
                        this.compare(iteratees[i](left), iteratees[i](right)) * orders[i];
                    if (comparison !== 0) {
                        return comparison;
                    }
                }

                return 0;
            };
        }
    }

    private calculateNewActivePage(previousRowsOnPage: number, currentRowsOnPage: number): number {
        const firstRowOnPage = (this.activePage - 1) * previousRowsOnPage + 1;
        return Math.ceil(firstRowOnPage / currentRowsOnPage);
    }

    private recalculatePage() {
        const lastPage = Math.ceil(this.inputData.length / this.rowsOnPage);
        this.activePage = lastPage < this.activePage ? lastPage : this.activePage;
        this.activePage = this.activePage || 1;

        this.onPageChange.emit({
            activePage: this.activePage,
            rowsOnPage: this.rowsOnPage,
            dataLength: this.inputData.length
        });
    }
}
