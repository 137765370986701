import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Terminal } from '../../../../models/terminal.model';
import { ConnectionTypeEnum } from '../../../../../shared/enums/connection-type.enum';
import { TerminalConfiguration } from '../../../../models/terminal-configuration.model';
import { BoardTypeEnum } from '../../../../../shared/enums/board-type.enum';
import { SslConfiguration } from '../../../../models/ssl-configuration.model';

@Component({
    selector: 'ssl-terminals-config-step-additional-configuration',
    styleUrls: ['./step-additional-configuration.component.css',
        '../step-common/step-common.component.css'],
    templateUrl: './step-additional-configuration.component.html'
})
export class SslTerminalsConfigStepAdditionalConfigurationComponent implements OnInit {

    @Input() public currentStep: number;
    @Input() public sslConfiguration: SslConfiguration;
    @Input() public availableConnectionTypes: ConnectionTypeEnum[];
    @Input() public currentTerminal: Terminal;
    @Input() public isLoading: boolean;
    @Output() public loadingState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public validatedState: EventEmitter<boolean> = new EventEmitter<boolean>();

    public boardTypeEnum = BoardTypeEnum;

    public ngOnInit(): void {
        if (!this.currentTerminal.terminalConfiguration) {
            this.currentTerminal.terminalConfiguration = new TerminalConfiguration({
                maxActivatableSystems: undefined,
                carrierDetectThreshold: undefined,
                noiseThreshold: undefined,
            });
        }

        if (this.hasBoardType(BoardTypeEnum.FIBRE_ANALOGUE) &&
            this.currentTerminal.terminalConfiguration.carrierDetectThreshold == undefined) {
            this.currentTerminal.terminalConfiguration.carrierDetectThreshold = 4096;
        }

        this.loadingState.emit(false);
        this.doValidation();
    }

    // Helper methods
    public hasBoardType(boardType: BoardTypeEnum): boolean {
        for (let system of this.currentTerminal.connectionSystems) {
            if ((system.esdConfiguration != undefined &&
                    system.esdConfiguration.boardType == boardType) ||
                (system.telecommConfiguration != undefined &&
                    system.telecommConfiguration.boardType == boardType)) {
                return true;
            }
        }

        return false;
    }

    public reduceCarrierDetect(): void {
        this.currentTerminal.terminalConfiguration.carrierDetectThreshold -= 163.84;
        if (this.currentTerminal.terminalConfiguration.carrierDetectThreshold < 0) {
            this.currentTerminal.terminalConfiguration.carrierDetectThreshold = 0;
        }
    }

    public increaseCarrierDetect(): void {
        this.currentTerminal.terminalConfiguration.carrierDetectThreshold += 163.84;
        if (this.currentTerminal.terminalConfiguration.carrierDetectThreshold > 32768) {
            this.currentTerminal.terminalConfiguration.carrierDetectThreshold = 32768;
        }
    }

    public calculateCarrierDetectThreshold(threshold: number): string {
        return ((threshold / 32768) * 100).toFixed(1) + 'Δ';
    }

    public reduceNoiseThreshold(): void {
        this.currentTerminal.terminalConfiguration.noiseThreshold -= 1;
        if (this.currentTerminal.terminalConfiguration.noiseThreshold < 0) {
            this.currentTerminal.terminalConfiguration.noiseThreshold = 0;
        }
    }

    public increaseNoiseThreshold(): void {
        this.currentTerminal.terminalConfiguration.noiseThreshold += 1;
        if (this.currentTerminal.terminalConfiguration.noiseThreshold > 15) {
            this.currentTerminal.terminalConfiguration.noiseThreshold = 15;
        }
    }

    public calculateNoiseThreshold(threshold: number): string {
        return ((threshold / 15) * 100).toFixed(1) + '%';
    }

    public reduceMaxActivatableSystems(): void {
        this.currentTerminal.terminalConfiguration.maxActivatableSystems--;
        if (this.currentTerminal.terminalConfiguration.maxActivatableSystems < 0) {
            this.currentTerminal.terminalConfiguration.maxActivatableSystems = undefined;
        }
    }

    public increaseMaxActivatableSystems(): void {
        if (this.currentTerminal.terminalConfiguration.maxActivatableSystems == undefined) {
            this.currentTerminal.terminalConfiguration.maxActivatableSystems = 0;
        } else {
            this.currentTerminal.terminalConfiguration.maxActivatableSystems++;
        }

        if (this.currentTerminal.terminalConfiguration.maxActivatableSystems >
            this.sslConfiguration.maxActivateableSystems) {
            this.currentTerminal.terminalConfiguration.maxActivatableSystems =
                this.sslConfiguration.maxActivateableSystems;
        }
    }

    public getMaxActivatableSystems(): string {
        if (this.currentTerminal.terminalConfiguration.maxActivatableSystems != undefined) {
            return this.currentTerminal.terminalConfiguration.maxActivatableSystems.toString(10);
        }

        return 'MAX';
    }

    // Internal Helpers
    private doValidation(): void {
        this.validatedState.emit(true);
    }
}
