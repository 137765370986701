import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UslSystemV0AlarmLog } from '../../../../../../models/usl-system/v0/alarm-log.model';
import { Paged } from '../../../../../../../shared/models/common/paged.model';

@Component({
    selector: 'usl-display-v0-alarm-log-modal',
    styleUrls: ['./alarm-log.component.css'],
    templateUrl: './alarm-log.component.html'
})
export class UslDisplayV0ModalAlarmLogComponent {

    @Input() public uslAlarmLogRows: Paged<UslSystemV0AlarmLog>;
    @Input() public isLoadingAlarmLogs: boolean;
    @Input() public currentTimeZone: string = 'UTC';
    @Output() public onSort: EventEmitter<any> = new EventEmitter();
    @Output() public onPage: EventEmitter<any> = new EventEmitter();

}
