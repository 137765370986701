import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../../shared/services/base.service';
import { UslSystemV0DisplayReading } from '../../models/usl-system/v0/display-readings.model';
import { UslReadingLite } from '../../models/usl-system/lite/reading-lite.model';
import { UslSystemV0SystemConfiguration } from '../../models/usl-system/v0/system-configuration.model';
import { WebSocketService } from '../../../shared/services/websocket/websocket.service';

@Injectable()
export class UslSystemV0Service extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'usl/system';
    }

    public getLocalConfiguration(): Observable<UslSystemV0SystemConfiguration> {
        return this._http
            .get<UslSystemV0SystemConfiguration>(this.actionUrl + '/local',
                this.getRequestOptions());
    }

    public getLocalReadings(): Observable<UslSystemV0DisplayReading[]> {
        return this._http
            .get<UslSystemV0DisplayReading[]>(this.actionUrl + '/local/readings',
                this.getRequestOptions());
    }

    public getRemoteConfiguration(): Observable<UslSystemV0SystemConfiguration> {
        return this._http
            .get<UslSystemV0SystemConfiguration>(this.actionUrl + '/remote',
                this.getRequestOptions());
    }

    public getRemoteReadings(): Observable<UslSystemV0DisplayReading[]> {
        return this._http
            .get<UslSystemV0DisplayReading[]>(this.actionUrl + '/remote/readings',
                this.getRequestOptions());
    }

    public websocketGetReadings(): Observable<UslReadingLite> {
        return this._ws.get('/wsapi/usl/reading');
    }
}
