import { GraphicalTypeEnum } from '../enums/graphical-type.enum';
import { Country } from '../../shared/models/system/country.model';
import { ConnectionSystem } from './connection-system.model';
import { InstallationTypeEnum } from '../../shared/enums/installation-type.enum';
import { TerminalConfiguration } from './terminal-configuration.model';

export class Terminal {

    public id: string;
    public name: string;
    public countryId: string;
    public loCode: string;
    public unLoCode: any;
    public country: Country;
    public terminalType: InstallationTypeEnum;
    public graphicalType: GraphicalTypeEnum;
    public userCreated: boolean;
    public isFavourite: boolean;
    public isHidden: boolean;
    public connectionSystems: ConnectionSystem[];
    public terminalConfiguration: TerminalConfiguration;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name || '';
        this.countryId = data.countryId || undefined;
        this.country = data.country || undefined;
        this.loCode = data.loCode || undefined;
        this.unLoCode = data.unLoCode || undefined;
        this.terminalType = data.terminalType;
        this.graphicalType = data.graphicalType;
        this.connectionSystems = data.connectionSystems || [];
        this.terminalConfiguration = data.terminalConfiguration;

        // Workaround because of boolean type (Silly JS)
        this.userCreated = false;
        if (data.userCreated != null) {
            this.userCreated = data.userCreated;
        }

        this.isFavourite = false;
        if (data.isFavourite != null) {
            this.isFavourite = data.isFavourite;
        }

        this.isHidden = false;
        if (data.isHidden != null) {
            this.isHidden = data.isHidden;
        }
    }
}
