import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { Log } from '../models/log.model';
import { LogService } from '../services/log.service';
import { SafetyLogService } from '../../shared/services/esd/log.service';
import { CommonAlarmLog } from '../../shared/models/safety/common-alarm-log.model';
import { Paged } from '../../shared/models/common/paged.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';

@Component({
    selector: 'ssl-logs',
    styleUrls: ['./logs.component.css'],
    templateUrl: './logs.component.html'
})
export class SslLogsComponent implements OnInit, OnDestroy {

    public pageSize: number = 13;
    public isLoading: boolean = false;
    public currentTimeZone: string = 'UTC';

    public currentLogPage: number = 1;
    public logTableSortBy: string = 'timestamp';
    public logTableSortByDesc: boolean = true;
    public currentLogs: Paged<Log>;

    public currentCommonAlarmPage: number = 1;
    public commonAlarmLogTableSortBy: string = 'timestamp';
    public commonAlarmLogTableSortByDesc: boolean = true;
    public currentCommonAlarmLogs: Paged<CommonAlarmLog>;

    private routerSub: Subscription;
    private logSubscription: Subscription;
    private commonAlarmLogSubscription: Subscription;
    private persistenceCurrentTimeZoneSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _persistence: PersistenceService,
        private _configService: PageConfigService,
        private _logService: LogService,
        private _safetyLogService: SafetyLogService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.serviceGetLogs(this.logTableSortBy,
                    this.logTableSortByDesc,
                    this.currentLogPage);
                this.serviceGetCommonAlarmLogs(this.commonAlarmLogTableSortBy,
                    this.commonAlarmLogTableSortByDesc,
                    this.currentCommonAlarmPage);
                this.logSubscription = this.websocketGetLogs();
                this.commonAlarmLogSubscription = this.websocketGetCommonAlarmLogs();
                this.persistenceCurrentTimeZoneSub = this._persistence
                    .currentTimeZoneIANA
                    .subscribe((data: string) => {
                        this.currentTimeZone = data;
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();

            this.logSubscription.unsubscribe();
            this.commonAlarmLogSubscription.unsubscribe();
            this.persistenceCurrentTimeZoneSub.unsubscribe();
        }
    }

    // Template Helpers
    public goToLogPage(pageInfo: any): void {
        this.serviceGetLogs(this.logTableSortBy,
            this.logTableSortByDesc,
            pageInfo.offset + 1);
    }

    public sortLogPage(sortInfo: any): void {
        this.logTableSortBy = sortInfo.column.prop;
        this.logTableSortByDesc = sortInfo.newValue == 'desc';
        this.serviceGetLogs(this.logTableSortBy,
            this.logTableSortByDesc,
            this.currentLogs.pageIndex);
    }

    public goToCommonAlarmLogPage(pageInfo: any): void {
        this.serviceGetCommonAlarmLogs(this.commonAlarmLogTableSortBy,
            this.commonAlarmLogTableSortByDesc,
            pageInfo.offset + 1);
    }

    public sortCommonAlarmLogPage(sortInfo: any): void {
        this.commonAlarmLogTableSortBy = sortInfo.column.prop;
        this.commonAlarmLogTableSortByDesc = sortInfo.newValue == 'desc';
        this.serviceGetLogs(this.logTableSortBy,
            this.logTableSortByDesc,
            this.currentLogs.pageIndex);
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'SSL',
            path: '/ssl'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'LOGS',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service Calls
    private websocketGetLogs(): Subscription {
        return this._logService
            .websocketGetLogs()
            .subscribe((data: Log) => {
                if (data && this.currentLogs && this.currentLogPage == 1) {
                    this.serviceGetLogs(this.logTableSortBy,
                        this.logTableSortByDesc,
                        this.currentLogPage);
                }
            });
    }

    private websocketGetCommonAlarmLogs(): Subscription {
        return this._safetyLogService
            .websocketGetCommonAlarmLogs()
            .subscribe((data: CommonAlarmLog) => {
                if (data && this.currentCommonAlarmLogs && this.currentCommonAlarmPage == 1) {
                    this.serviceGetCommonAlarmLogs(this.commonAlarmLogTableSortBy,
                        this.commonAlarmLogTableSortByDesc,
                        this.currentCommonAlarmPage);
                }
            });
    }

    private serviceGetLogs(orderByField: string,
                           isDesc: boolean,
                           pageIndex: number): Subscription {
        this.currentLogPage = pageIndex;
        this.isLoading = true;
        return this._logService
            .getLogs(orderByField, isDesc, pageIndex, this.pageSize)
            .subscribe((data: Paged<Log>) => {
                this.currentLogs = data;
                this.isLoading = false;
            });
    }

    private serviceGetCommonAlarmLogs(orderByField: string,
                                      isDesc: boolean,
                                      pageIndex: number): Subscription {
        this.currentCommonAlarmPage = pageIndex;
        this.isLoading = true;
        return this._safetyLogService
            .getCommonAlarmLogs(orderByField, isDesc, pageIndex, this.pageSize)
            .subscribe((data: Paged<CommonAlarmLog>) => {
                this.currentCommonAlarmLogs = data;
                this.isLoading = false;
            });
    }
}
