import { Component, Input } from '@angular/core';
import { EsdSystem } from '../../../../shared/models/esd/esd-system.model';
import { TripTypeEnum } from '../../../../shared/enums/trip-type.enum';
import { DirectionTypeEnum } from '../../../../shared/enums/direction-type.enum';
import { EsdConfiguration } from '../../../../shared/models/system/esd-configuration.model';
import {
    LocalboardConfiguration
} from '../../../../shared/models/common/localboard-configuration.model';

@Component({
    selector: 'ssl-shared-vertical-cable-column',
    styleUrls: ['vertical-cable-column.component.css'],
    templateUrl: 'vertical-cable-column.component.html'
})
export class SslOperationsSharedVerticalCableColumnComponent {

    @Input() public esdSystem: EsdSystem;
    @Input() public localIndex: number;
    @Input() public remoteIndex: number;
    @Input() public isLeft: boolean;

    public tripType = TripTypeEnum;
    public directionType = DirectionTypeEnum;
}
