import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatusTypeEnum } from '../enums/status-type.enum';
import { Observable } from 'rxjs';

@Pipe({
    name: 'statusType'
})
export class StatusTypePipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(statusType: StatusTypeEnum): Observable<string> {
        switch (statusType) {
            case StatusTypeEnum.OK:
                return this._translate.get('ESDS.STATUS_OK');
            case StatusTypeEnum.ERROR:
                return this._translate.get('ESDS.STATUS_ERROR');
            default:
            case StatusTypeEnum.UNKNOWN:
                return this._translate.get('ESDS.STATUS_UNKNOWN');
        }
    }
}
