import { AlarmStateEnum } from '../enums/alarm-state.enum';

export class DasData {

    public channelId: string;
    public channelValue: number;
    public name: string;
    public unit: string;
    public displayFormat: string;
    public lastDataUpdate: number;
    public isStale: boolean;
    public alarmState: AlarmStateEnum;
    public isAlarmAcknowledged: boolean;

    constructor(data: DasData) {
        this.channelId = data.channelId || undefined;
        this.channelValue = data.channelValue;
        this.name = data.name || undefined;
        this.unit = data.unit || undefined;
        this.displayFormat = data.displayFormat || undefined;
        this.lastDataUpdate = data.lastDataUpdate;
        this.alarmState = data.alarmState;

        this.isStale = false;
        if (data.isStale != null) {
            this.isStale = data.isStale;
        }

        this.isAlarmAcknowledged = false;
        if (data.isAlarmAcknowledged != null) {
            this.isAlarmAcknowledged = data.isAlarmAcknowledged;
        }
    }

    public updateState(newState: DasData): void {
        this.channelValue = newState.channelValue;
        this.lastDataUpdate = newState.lastDataUpdate;
        this.isStale = newState.isStale;
        this.alarmState = newState.alarmState;
        this.isAlarmAcknowledged = newState.isAlarmAcknowledged;
    }
}
