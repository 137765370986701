import { Terminal } from './terminal.model';
import { SystemLocationEnum } from '../../shared/enums/system-location.enum';
import { ConnectionTypeEnum } from '../../shared/enums/connection-type.enum';
import { EsdConfiguration } from '../../shared/models/system/esd-configuration.model';

export class SslConfiguration extends EsdConfiguration {

    public currentTerminalId: string;
    public currentTerminal: Terminal;

    public isRemoteSwitchingAvailable: boolean;
    public isGraphicsPageEnabled: boolean;
    public isBootToGraphical: boolean;
    public isAudioRecordingsEnabled: boolean;
    public isCCTVRecordingsEnabled: boolean;
    public isMk2ButtonPushesEnabled: boolean;
    public availableSystemLocations: SystemLocationEnum[];
    public availableConnectionTypes: ConnectionTypeEnum[];
    public maxActivateableSystems: number;

    public firstUpIndexesAvailable: number[];
    public inhibitIndexesAvailable: number[];
    public resetIndexesAvailable: number[];
    public localInputIndexesAvailable: number[];
    public localOutputIndexesAvailable: number[];
    public continuityLinkIndexesAvailable: number[];

    constructor(data: any) {
        super(data);
        this.currentTerminalId = data.currentTerminalId;
        this.currentTerminal = data.currentTerminal || undefined;
        this.availableSystemLocations = data.availableSystemLocations || [];
        this.availableConnectionTypes = data.availableConnectionTypes || [];

        this.firstUpIndexesAvailable = data.firstUpIndexesAvailable || [];
        this.inhibitIndexesAvailable = data.inhibitIndexesAvailable || [];
        this.resetIndexesAvailable = data.resetIndexesAvailable || [];
        this.localInputIndexesAvailable = data.localInputIndexesAvailable || [];
        this.localOutputIndexesAvailable = data.localOutputIndexesAvailable || [];
        this.continuityLinkIndexesAvailable = data.continuityLinkIndexesAvailable || [];
        this.maxActivateableSystems = data.maxActivateableSystems;

        // Workaround because of boolean type (Silly JS)
        this.isRemoteSwitchingAvailable = false;
        if (data.isRemoteSwitchingAvailable != null) {
            this.isRemoteSwitchingAvailable = data.isRemoteSwitchingAvailable;
        }

        this.isGraphicsPageEnabled = false;
        if (data.isGraphicsPageEnabled != null) {
            this.isGraphicsPageEnabled = data.isGraphicsPageEnabled;
        }

        this.isBootToGraphical = false;
        if (data.isBootToGraphical != null) {
            this.isBootToGraphical = data.isBootToGraphical;
        }

        this.isAudioRecordingsEnabled = false;
        if (data.isAudioRecordingsEnabled != null) {
            this.isAudioRecordingsEnabled = data.isAudioRecordingsEnabled;
        }

        this.isCCTVRecordingsEnabled = false;
        if (data.isCCTVRecordingsEnabled != null) {
            this.isCCTVRecordingsEnabled = data.isCCTVRecordingsEnabled;
        }

        this.isMk2ButtonPushesEnabled = false;
        if (data.isMk2ButtonPushesEnabled != null) {
            this.isMk2ButtonPushesEnabled = data.isMk2ButtonPushesEnabled;
        }
    }
}
