import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionTypeEnum } from '../enums/connection-type.enum';

@Pipe({
    name: 'connectionType'
})
export class ConnectionTypePipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(connectionType: ConnectionTypeEnum): string {
        switch (connectionType) {
            case ConnectionTypeEnum.ESL:
                return 'ESL';
            case ConnectionTypeEnum.ITT_CANNON:
                return 'ITT CANNON';
            case ConnectionTypeEnum.MIYAKI:
                return 'MIYAKI';
            case ConnectionTypeEnum.THIRTYSEVENWAY:
                return '37W ELECTRIC';
            case ConnectionTypeEnum.FIBRE_ANALOGUE:
                return '6W FIBRE ANALOGUE';
            case ConnectionTypeEnum.FIBRE_DIGITAL:
                return 'FIBRE DIGITAL';
            case ConnectionTypeEnum.PNEUMATIC:
                return 'PNEUMATIC';
            default:
            case ConnectionTypeEnum.NONE:
                return this._translate.instant('SSL.TERMINAL_CONN_NONE');
        }
    }
}
