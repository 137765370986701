import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { ConnectionStateEnum } from '../../shared/enums/connection-state.enum';

@Component({
    selector: 'connection-status',
    styleUrls: ['./connection-status.component.css'],
    templateUrl: './connection-status.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectionStatusComponent {

    @Input() public connectionState: ConnectionStateEnum;

    public connectionStateEnum = ConnectionStateEnum;

}
