import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { CONFIGURATION } from './app.constants';

// Third party
import {
    TranslateLoader,
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    AlertModule,
    BsDropdownModule,
    ButtonsModule, CollapseModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    TabsModule
} from 'ngx-bootstrap';

// Platform and Environment providers/directives/pipes
import { environment } from 'environments/environment';
import { ROUTES } from './app.routes';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';

// Root Components
import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './navbar/breadcrumb/breadcrumb.component';
import { NavBarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { NoContentComponent } from './no-content/no-content.component';
import { SettingsComponent } from './settings/settings.component';
import { ConnectionStatusComponent } from './navbar/connection-status/connection-status.component';
import { SystemErrorComponent } from './system-error/system-error.component';
import { AboutDialogModalComponent } from './shared/components/dialog/about/about.component';

// Root Modules
import { SharedDirectivesModule } from './shared/directives/shared-directives.module';
import { SharedPipesModule } from './shared/pipes/shared-pipes.module';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { EsdsModule } from './mod_esds/mod_esds.module';
import { SslModule } from './mod_ssl/mod_ssl.module';
import { UslModule } from './mod_usl/mod_usl.module';
import { MlmModule } from './mod_mlm/mod_mlm.module';
import { RemoteModule } from './mod_remote/mod_remote.module';

// Root Module Guards
import { EsdsModuleGuard } from './mod_esds/guards/module.guard';
import { SslModuleGuard } from './mod_ssl/guards/module.guard';
import { UslModuleGuard } from './mod_usl/guards/module.guard';
import { MlmModuleGuard } from './mod_mlm/guards/module.guard';

// Root Services
import { AppState } from './app.service';
import { MockDbService } from './shared/services/system/mock-db.service';
import { PageConfigService } from './shared/services/system/page-config.service';
import { SystemConfigurationService } from './shared/services/system/configuration.service';
import { PersistenceService } from './shared/services/system/persistence.service';
import { WebSocketService } from './shared/services/websocket/websocket.service';
import { LanguageService } from './shared/services/system/language.service';
import { RemoteScreenService } from './shared/services/system/remote-screen.service';
import { MetaService } from './shared/services/system/meta.service';
import { ScreenOptionService } from './shared/services/system/screen-option.service';
import { DeviceCommsService } from './shared/services/system/device-comms.service';
import {
    DateTimeConfigModalComponent
} from './shared/components/dialog/datetime-config/datetime-config.component';
import { DateTimeService } from './shared/services/platform/datetime.service';
import { CommonConfigurationService } from './shared/services/common/common-configuration.service';
import { ScreenService } from './shared/services/system/screen.service';
import { ScreenToolsService } from './shared/services/screen/screen-tools.service';
import { TimezoneConfigModalComponent } from './shared/components/dialog/timezone-config/timezone-config.component';
import { ReactiveFormsModule } from '@angular/forms';

// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AppState
];

@NgModule({
    bootstrap: [ AppComponent ],
    declarations: [
        AppComponent,
        BreadcrumbComponent,
        ConnectionStatusComponent,
        NavBarComponent,
        HomeComponent,
        SettingsComponent,
        NoContentComponent,
        SystemErrorComponent,
        AboutDialogModalComponent,
        DateTimeConfigModalComponent,
        TimezoneConfigModalComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES, {preloadingStrategy: PreloadAllModules}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),

        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        TabsModule.forRoot(),
        CollapseModule.forRoot(),

        SharedDirectivesModule.forRoot(),
        SharedPipesModule.forRoot(),
        SharedComponentsModule.forRoot(),

        // Child Modules
        EsdsModule.forRoot(),
        SslModule.forRoot(),
        UslModule.forRoot(),
        MlmModule.forRoot(),
        RemoteModule.forRoot(),

        /**
         * This section will import the `DevModuleModule` only in certain build types.
         * When the module is not imported it will get tree shaked.
         * This is a simple example, a big app should probably implement some logic
         */
        ...environment.showDevModule ? [] : [],
        ReactiveFormsModule,
    ],
    providers: [
        environment.ENV_PROVIDERS,
        APP_PROVIDERS,
        PersistenceService,
        SystemConfigurationService,
        CommonConfigurationService,
        MetaService,
        LanguageService,
        RemoteScreenService,
        PageConfigService,
        WebSocketService,
        ScreenOptionService,
        DeviceCommsService,
        DateTimeService,
        ScreenService,
        ScreenToolsService,

        {
            provide: APP_INITIALIZER,
            useFactory: (createConfigLoader),
            deps: [SystemConfigurationService, HttpClient, TranslateService, PersistenceService],
            multi: true
        },

        // Module Guards
        EsdsModuleGuard,
        SslModuleGuard,
        UslModuleGuard,
        MlmModuleGuard,

        // Workaround for demo purposes
        MockDbService
    ]
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
    let langUrl = '';
    const suffix = '';
    let httpPrefix = '';
    if (CONFIGURATION.isProduction) {
        let host = '';
        if (CONFIGURATION.prodConfig.server && CONFIGURATION.prodConfig.port) {
            host = CONFIGURATION.prodConfig.server + ':' + CONFIGURATION.prodConfig.port;
        } else {
            host = window.location.hostname +
                (window.location.port ? ':' + window.location.port : '');
        }

        httpPrefix = CONFIGURATION.prodConfig.useHttps ? 'https://' : 'http://';
        langUrl = httpPrefix + host +
            CONFIGURATION.prodConfig.apiUrl + 'system/language/';
    } else {
        let host = '';
        if (CONFIGURATION.devConfig.server && CONFIGURATION.devConfig.port) {
            host = CONFIGURATION.devConfig.server + ':' + CONFIGURATION.devConfig.port;
        } else {
            host = window.location.hostname +
                (window.location.port ? ':' + window.location.port : '');
        }

        httpPrefix = CONFIGURATION.devConfig.useHttps ? 'https://' : 'http://';
        langUrl = httpPrefix + host +
            CONFIGURATION.devConfig.apiUrl + 'system/language/';
    }

    return new TranslateHttpLoader(http, langUrl, suffix);
}

export function createConfigLoader(config: SystemConfigurationService,
                                   http: HttpClient,
                                   translate: TranslateService,
                                   persistence: PersistenceService) {
    return () => config.load();
}
