import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageConfig } from '../shared/models/system/page-config.model';
import { PageConfigService } from '../shared/services/system/page-config.service';
import { SystemConfiguration } from '../shared/models/system/configuration.model';
import { SystemConfigurationService } from '../shared/services/system/configuration.service';
import { PersistenceService } from '../shared/services/system/persistence.service';

import { CONFIGURATION } from '../app.constants';
import { ModuleTypeEnum } from '../shared/enums/module-type.enum';
import { SystemConfigurationDto } from '../shared/models/system/dto/system-configuration-dto.model';
import { BrainMetadataDto } from '../shared/models/system/dto/brain-metadata-dto.model';
import { MetaService } from '../shared/services/system/meta.service';
import { ScreenOption } from '../shared/models/system/screen-option.model';
import { ScreenOptionService } from '../shared/services/system/screen-option.service';
import { Version } from '../shared/models/system/version.model';

@Component({
    selector: 'settings',
    styleUrls: ['./settings.component.css'],
    templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit, OnDestroy {

    public screenConfiguration: SystemConfiguration;
    public isSettingsChanged: boolean = false;
    public isError: boolean = false;
    public constants = CONFIGURATION;
    public moduleTypeEnum = ModuleTypeEnum;
    public versions: any;
    public currentMeta: BrainMetadataDto;
    public mirrors: BrainMetadataDto[] = [];
    public serverTimeSeconds: number = 0;
    public screenVersion: string = undefined;
    public isDebugMode: boolean = false;
    public hasNtp: boolean = false;
    public currentTimezone: string = 'UTC';

    private screenOptions: ScreenOption = new ScreenOption({});
    private routerSub: Subscription;
    private webSockets: Subscription[] = [];

    constructor(
        private _route: ActivatedRoute,
        private _configService: PageConfigService,
        private _persistence: PersistenceService,
        private _sysConfigService: SystemConfigurationService,
        private _metaService: MetaService,
        private _screenOptionService: ScreenOptionService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.serviceGetVersions();
                this._configService.setCurrentContext(ModuleTypeEnum.NONE);
                this.serviceGetCurrentMeta();
                this.webSockets.push(this._persistence
                    .systemConfiguration
                    .subscribe((data: SystemConfiguration) => {
                        if (data) {
                            this.screenConfiguration = new SystemConfiguration(data);
                            if (data.systemConfiguration) {
                                this.hasNtp = (data.systemConfiguration.ntpServers != undefined &&
                                    data.systemConfiguration.ntpServers.length > 0 &&
                                    !data.systemConfiguration.canSetNtpServerDatetime);
                            }
                        }
                    }));

                this.webSockets.push(this._persistence
                    .brainMirrors
                    .subscribe((data: BrainMetadataDto[]) => {
                        this.mirrors = data;
                    }));

                this.webSockets.push(this._persistence
                    .screenOptions
                    .subscribe((data: ScreenOption) => {
                        if (data) {
                            this.screenOptions = data;
                        }
                    }));

                this.webSockets.push(this._persistence
                    .currentTimeZoneIANA
                    .subscribe((data: string) => {
                        this.currentTimezone = data;
                    }));

                this.webSockets.push(this._persistence
                    .serverTimeSeconds
                    .subscribe((data: number) => {
                        this.serverTimeSeconds = data;
                    }));

                this.webSockets.push(this._persistence
                    .screenVersion
                    .subscribe((data: string) => {
                        this.screenVersion = data;
                    }));

                this.webSockets.push(this._persistence
                    .isDebugToggled
                    .subscribe(() => {
                        this.isDebugMode = !this.isDebugMode;
                    }));

                this.webSockets.push(this._sysConfigService
                    .webSocketGetVersions()
                    .subscribe((version: Version) => {
                        this.serviceGetVersions();
                    }));
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }

        for (let val of this.webSockets) {
            if (val) {
                val.unsubscribe();
            }
        }
    }

    // Template Helpers
    public markChange(): void {
        this.isError = false;
        this.isSettingsChanged = true;
    }

    public onChangeFakeBrightness(newValue: number) {
        this.screenOptions.fakeBrightness = newValue;
        this._screenOptionService.updateScreenOption(this.screenOptions);
    }

    public resetChanges(): void {
        this.screenConfiguration = new SystemConfiguration(
            this._persistence.systemConfiguration.getValue()
        );
        this.isError = false;
        this.isSettingsChanged = false;
    }

    public saveChanges(pin: string): void {
        this.isError = false;
        this.serviceUpdateConfiguration(new SystemConfigurationDto({
            settingsPin: pin,
            configuration: this.screenConfiguration
        })).add(() => {
            if (!this.isError) {
                this.isSettingsChanged = false;
            }
        });
    }

    public calculateUpTime(startTime: number): string {
        let upTime = this.serverTimeSeconds - startTime;

        let hours: number = Math.floor(upTime / 3600);
        let minutes: number = Math.floor((upTime - (hours * 3600)) / 60);
        let seconds: number = upTime - (hours * 3600) - (minutes * 60);

        let hoursText = hours.toString();
        let minutesText = minutes.toString();
        let secondsText = seconds.toString();
        if (hours < 10) {
            hoursText = '0' + hoursText;
        }

        if (minutes < 10) {
            minutesText = '0' + minutesText;
        }

        if (seconds < 10) {
            secondsText = '0' + secondsText;
        }

        return hoursText + ':' + minutesText + ':' + secondsText;
    }

    // Helper methods
    private updateNavigation(): void {
        this._configService.setConfig(new PageConfig({
            title: 'SETTINGS',
            breadcrumbs: []
        }));
    }

    // Service Calls
    private serviceUpdateConfiguration(updatedConfig: SystemConfigurationDto): Subscription {
        return this._sysConfigService
            .putUpdateConfiguration(updatedConfig)
            .subscribe(undefined, () => {
                this.isError = true;
            });
    }

    private serviceDefaultConfiguration(): Subscription {
        return this._sysConfigService
            .postDefaultSettings()
            .subscribe(undefined, () => {
                this.isError = true;
            });
    }

    private serviceGetVersions(): Subscription {
        return this._sysConfigService
            .getVersions()
            .subscribe((data: any) => {
                this.versions = data;
            });
    }

    private serviceGetCurrentMeta(): Subscription {
        return this._metaService
            .getCurrent()
            .subscribe((data: BrainMetadataDto) => {
                this.currentMeta = data;
            });
    }
}
