import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription, interval } from 'rxjs';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { Effect } from '../models/effect.model';
import { EffectService } from '../services/effect.service';
import { CauseService } from '../services/cause.service';
import { Cause } from '../models/cause.model';
import { StatusTypeEnum } from '../enums/status-type.enum';
import { Debounce } from '../../shared/helpers/debounce.helper';
import { WebsocketEffect } from '../models/websocket/effect.model';
import { WebsocketCause } from '../models/websocket/cause.model';

const ESDS_POLL_INTERVAL: number = 500;

@Component({
    selector: 'esds-cause-and-effect',
    styleUrls: ['./cause-and-effect.component.css'],
    templateUrl: './cause-and-effect.component.html'
})
export class EsdsCauseAndEffectComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('statusesList') public statusesListRef: ElementRef;
    public selectedCauses: Cause[] = [];
    public selectedEffects: Effect[] = [];
    public causeMargin: number = 0;
    public effectMargin: number = 0;

    private scrollItemOffsetX: number = 0;
    private scrollItemOffsetY: number = 0;
    private scrollMaxItemsX: number = 0;
    private scrollMaxItemsY: number = 0;
    private syncScrollTicking: boolean = false;
    private resizeEventListener: any;
    private pollTimer: Subscription = undefined;
    private routerSub: Subscription;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _configService: PageConfigService,
        private _causeService: CauseService,
        private _effectService: EffectService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.serviceGetCauses().add(() => {
                    this.serviceGetEffects().add(() => {
                        this.startPolling();
                    });
                });
                //this.websocketGetCauses();
                //this.websocketGetEffects();
            });
    }

    public ngOnDestroy(): void {
        window.removeEventListener('resize', this.resizeEventListener);
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.routerSub = undefined;
            this.stopPolling();
        }
    }

    public ngAfterViewInit(): void {
        this.syncScroll();

        this.scrollMaxItemsY = Math.floor(this.statusesListRef.nativeElement.offsetHeight / 80);
        this.scrollMaxItemsX = Math.floor(this.statusesListRef.nativeElement.offsetWidth / 80);

        this.resizeEventListener = Debounce.debounce(() => {
            this.scrollMaxItemsY = Math.floor(this.statusesListRef.nativeElement.offsetHeight / 80);
            this.scrollMaxItemsX = Math.floor(this.statusesListRef.nativeElement.offsetWidth / 80);
        }, 250);

        window.addEventListener('resize', this.resizeEventListener);
    }

    // Template Helpers
    public goToCause(cause: Cause): void {
        this._router.navigate(['/esds', 'causes', cause.id]);
    }

    public isInsideY(index: number): boolean {
        return (index >= this.scrollItemOffsetY &&
        index <= (this.scrollMaxItemsY + this.scrollItemOffsetY + 1));
    }

    public isInsideX(index: number): boolean {
        return (index >= this.scrollItemOffsetX &&
        index <= (this.scrollMaxItemsX + this.scrollItemOffsetX + 1));
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'ESDS',
            path: '/esds'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'CAUSE AND EFFECT',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    private syncScroll(): void {
        const thisReference = this;
        this.statusesListRef.nativeElement.addEventListener('scroll', () => {
            if (!thisReference.syncScrollTicking) {
                window.requestAnimationFrame(() => {
                    const scrollTop = thisReference.statusesListRef.nativeElement.scrollTop;
                    const scrollLeft = thisReference.statusesListRef.nativeElement.scrollLeft;
                    thisReference.scrollItemOffsetX = Math.floor(scrollLeft / 80);
                    thisReference.scrollItemOffsetY = Math.floor(scrollTop / 80);
                    thisReference.causeMargin = scrollTop * -1;
                    thisReference.effectMargin = scrollLeft * -1;

                    thisReference.syncScrollTicking = false;
                });

                thisReference.syncScrollTicking = true;
            }
        });
    }

    private startPolling(): void {
        if (this.routerSub === undefined) {
            return;
        }

        this.pollTimer = interval(ESDS_POLL_INTERVAL)
            .subscribe(() => {
                this.stopPolling();
                this.serviceGetCauses().add(() => {
                    this.sortCauses();
                    this.serviceGetEffects().add(() => {
                        this.startPolling();
                    });
                });
        });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    private sortCauses(): void {
        this.selectedCauses.sort((causeA: Cause, causeB: Cause) => {
            let sortResult: number = 0;

            if (causeA.isFirstUp === true && causeB.isFirstUp === false) {
                sortResult = -1;
            } else if (causeA.isFirstUp === false && causeB.isFirstUp === true) {
                sortResult = 1;
            } else if (causeA.status == StatusTypeEnum.OK &&
                causeB.status == StatusTypeEnum.ERROR) {
                sortResult = 1;
            } else if (causeA.status == StatusTypeEnum.ERROR &&
                causeB.status == StatusTypeEnum.OK) {
                sortResult = -1;
            } else {
                sortResult = (causeA.id > causeB.id) ? 1 : -1;
                sortResult = (causeA.id == causeB.id) ? 0 : sortResult;
            }

            return sortResult;
        });
    }

    // Service Calls
    private serviceGetCauses(): Subscription {
        return this._causeService
            .getAllCauses()
            .subscribe((data: Cause[]) => {
                if (!this.selectedCauses || this.selectedCauses.length <= 0) {
                    this.selectedCauses = data;
                }

                for (const newState of data) {
                    for (const oldState of this.selectedCauses) {
                        if (newState.id == oldState.id) {
                            oldState.status = newState.status;
                            oldState.isFirstUp = newState.isFirstUp;
                            break;
                        }
                    }
                }
            }, () => {
                for (const val of this.selectedCauses) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }

    private serviceGetEffects(): Subscription {
        return this._effectService
            .getAllEffects()
            .subscribe((data: Effect[]) => {
                if (!this.selectedEffects || this.selectedEffects.length <= 0) {
                    this.selectedEffects = data;
                }

                for (const newState of data) {
                    for (const oldState of this.selectedEffects) {
                        if (newState.id == oldState.id) {
                            oldState.status = newState.status;
                            break;
                        }
                    }
                }
            }, () => {
                for (const val of this.selectedEffects) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }

    private websocketGetCauses(): Subscription {
        return this._causeService
            .websocketGetCauses()
            .subscribe((data: WebsocketCause) => {
                for (const oldState of this.selectedCauses) {
                    if (data.id == oldState.id) {
                        oldState.status = data.status;
                        oldState.isFirstUp = data.isFirstUp;
                        break;
                    }
                }
            }, () => {
                for (const val of this.selectedCauses) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }

    private websocketGetEffects(): Subscription {
        return this._effectService
            .websocketGetEffects()
            .subscribe((data: WebsocketEffect) => {
                for (const oldState of this.selectedEffects) {
                    if (data.id == oldState.id) {
                        oldState.status = data.status;
                        break;
                    }
                }
            }, () => {
                for (const val of this.selectedEffects) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }
}
