import { Routes } from '@angular/router';

// Guard
import { EsdsModuleGuard } from './guards/module.guard';

// Pages
import { EsdsComponent } from './mod_esds.component';
import { EsdsOverviewComponent } from './overview/overview.component';
import { EsdsCausesComponent } from './causes/causes.component';
import { EsdsHistoryComponent } from './history/history.component';
import { EsdsCauseAndEffectComponent } from './cause-and-effects/cause-and-effect.component';
import { EsdsCauseComponentComponent } from './causes/component/component.component';

export const ESDS_ROUTES: Routes = [
    {
        path: 'esds',
        component: EsdsComponent,
        children: [
            { path: '', redirectTo: 'overview', pathMatch: 'full' },
            { path: 'overview', component: EsdsOverviewComponent },
            { path: 'history', component: EsdsHistoryComponent },
            { path: 'cause-and-effect', component: EsdsCauseAndEffectComponent },
            { path: 'causes', component: EsdsCausesComponent },
            { path: 'causes/:id', component: EsdsCausesComponent },
            { path: 'causes/:id/component', component: EsdsCauseComponentComponent },
        ],
        canActivate: [EsdsModuleGuard]
    },
];
