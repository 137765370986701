import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Log } from '../models/log.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { LogService } from '../services/log.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { PageConfig } from '../../shared/models/system/page-config.model';
import { Paged } from '../../shared/models/common/paged.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';

@Component({
    selector: 'mlm-logs',
    styleUrls: ['./logs.component.css'],
    templateUrl: './logs.component.html'
})
export class MlmLogsComponent implements OnInit, OnDestroy {

    public pageSize: number = 13;
    public isLoading: boolean = false;
    public currentTimeZone: string = 'UTC';

    public currentLogPage: number = 1;
    public logTableSortBy: string = 'timestamp';
    public logTableSortByDesc: boolean = true;
    public currentLogs: Paged<Log>;

    private routerSub: Subscription;
    private webSockets: Subscription[] = [];

    constructor(
        private _route: ActivatedRoute,
        private _persistence: PersistenceService,
        private _configService: PageConfigService,
        private _logService: LogService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.serviceGetLogs(this.logTableSortBy,
                    this.logTableSortByDesc,
                    this.currentLogPage);
                this.webSockets.push(this.websocketGetLogs());

                this.webSockets.push(this._persistence
                    .currentTimeZoneIANA
                    .subscribe((data: string) => {
                        this.currentTimeZone = data;
                    }));
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            for (const val of this.webSockets) {
                if (val) {
                    val.unsubscribe();
                }
            }
        }
    }

    // Template Helpers
    public goToLogPage(pageInfo: any): void {
        this.serviceGetLogs(this.logTableSortBy,
            this.logTableSortByDesc,
            pageInfo.offset + 1);
    }

    public sortLogPage(sortInfo: any): void {
        this.logTableSortBy = sortInfo.column.prop;
        this.logTableSortByDesc = sortInfo.newValue == 'desc';
        this.serviceGetLogs(this.logTableSortBy,
            this.logTableSortByDesc,
            this.currentLogs.pageIndex);
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'MLM',
            path: '/mlm'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'LOGS',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service Calls
    private websocketGetLogs(): Subscription {
        return this._logService
            .websocketGetLogs()
            .subscribe((data: Log) => {
                if (data && this.currentLogs && this.currentLogPage == 1) {
                    this.serviceGetLogs(this.logTableSortBy,
                        this.logTableSortByDesc,
                        this.currentLogPage);
                }
            });
    }

    private serviceGetLogs(orderByField: string,
                           isDesc: boolean,
                           pageIndex: number): Subscription {
        this.currentLogPage = pageIndex;
        this.isLoading = true;
        return this._logService
            .getLogs(orderByField, isDesc, pageIndex, this.pageSize)
            .subscribe((data: Paged<Log>) => {
                this.currentLogs = data;
                this.isLoading = false;
            });
    }
}
