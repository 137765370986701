import { Component, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap';

import { CONFIGURATION } from '../../../../app.constants';
import { MetaService } from '../../../services/system/meta.service';
import { BrainMetadataDto } from '../../../models/system/dto/brain-metadata-dto.model';
import { MacAddressPipe } from '../../../pipes/mac-address.pipe';
import { ScreenToolsService } from '../../../services/screen/screen-tools.service';
import { PersistenceService } from '../../../services/system/persistence.service';

declare global {
    interface Window { networkAddresses: string[]; }
}

@Component({
    selector: 'about-dialog-modal',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css']
})
export class AboutDialogModalComponent {

    @ViewChild('aboutModal') public aboutModal: ModalDirective;

    public appConstants = CONFIGURATION;
    public brainMetadata: BrainMetadataDto;
    public screenIpAddresses: string;
    public ipAddresses: string;
    public macAddresses: string;
    public isScreenCalibrationAvailable: boolean = true;
    public isTouchTestAvailable: boolean = true;
    public touchTestCountdown: number = 0;
    public touchEvents: TouchEvent[] = [];
    public currentTimeZone: string = 'UTC';

    private persistenceCurrentTimeZone: Subscription;

    constructor(
        private _metaService: MetaService,
        private _persistence: PersistenceService,
        private _screenToolsService: ScreenToolsService,
    ) {}

    // Template helpers
    public open(): void {
        this.isScreenCalibrationAvailable = true;
        this.isTouchTestAvailable = true;
        this.brainMetadata = undefined;
        this.ipAddresses = 'UNKNOWN';
        this.macAddresses = 'UNKNOWN';
        if (window.networkAddresses) {
            this.screenIpAddresses = window.networkAddresses.join(', ');
        }

        this.serviceGetMetadata()
            .add(() => {
                this.ipAddresses = this.brainMetadata.ipAddresses.join(', ');

                const macAddressPipe = new MacAddressPipe();
                this.macAddresses = '';
                for (const macAddress of this.brainMetadata.macAddresses) {
                    this.macAddresses += macAddressPipe.transform(macAddress) + ', ';
                }
            });

        this.persistenceCurrentTimeZone = this._persistence
            .currentTimeZoneIANA
            .subscribe((data: string) => {
                this.currentTimeZone = data;
            });
        this.aboutModal.show();
    }

    public close(): void {
        this.persistenceCurrentTimeZone.unsubscribe();
        this.aboutModal.hide();
    }

    public getConfig(): any {
        if (this.appConstants.isProduction) {
            return this.appConstants.prodConfig;
        } else {
            return this.appConstants.devConfig;
        }
    }

    public calibrateScreen(): void {
        this.isScreenCalibrationAvailable = false;
        this._screenToolsService
            .getScreenCalibration()
            .subscribe(() => {
                this.isScreenCalibrationAvailable = true;
            }, () => {
                this.isScreenCalibrationAvailable = true;
            });
    }

    public startTouchTest(touchTestTimeSeconds: number): void {
        if (!this.isTouchTestAvailable) {
            return;
        }

        this.touchEvents = [];
        this.isTouchTestAvailable = false;
        this.touchTestCountdown = touchTestTimeSeconds;
        const countdownTimer = setInterval(() => {
            this.touchTestCountdown -= 1;

            if (this.touchTestCountdown < 0) {
                clearInterval(countdownTimer);
                this.touchTestCountdown = 0;
            }
        }, 1000);

        setTimeout(() => {
            this.isTouchTestAvailable = true;
        }, touchTestTimeSeconds * 1000);
    }

    public handleTouchEvent(event: TouchEvent): void {
        event.preventDefault();
        this.touchEvents.push(event);
    }

    // Service calls
    private serviceGetMetadata(): Subscription {
        return this._metaService
            .getCurrent()
            .subscribe((data: BrainMetadataDto) => {
                this.brainMetadata = data;
            });
    }
}
