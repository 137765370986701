export enum SystemLocationEnum {
    PORT = 0,
    STARBOARD = 1,
    BOW = 2,
    STERN = 4,
    PORT_FORWARD = 8,
    PORT_REAR = 16,
    STARBOARD_FORWARD = 32,
    STARBOARD_REAR = 64
}
