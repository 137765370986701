import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { PageConfig } from '../../../shared/models/system/page-config.model';
import { PageConfigService } from '../../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../../shared/models/system/page-breadcrumb.model';
import { TerminalService } from '../../services/terminal.service';
import { Terminal } from '../../models/terminal.model';
import { PersistenceService } from '../../../shared/services/system/persistence.service';
import { SslConfiguration } from '../../models/ssl-configuration.model';
import { ScreenOptionService } from '../../../shared/services/system/screen-option.service';
import { ScreenOption } from '../../../shared/models/system/screen-option.model';
import { SystemConfiguration } from '../../../shared/models/system/configuration.model';
import { InstallationTypeEnum } from '../../../shared/enums/installation-type.enum';
import { BaseSafetyEvent } from '../../../shared/models/safety/events/base-safety-event.model';
import { SafetyEventType } from '../../../shared/enums/safety/safety-event-type.enum';
import {
    TerminalActivatingSafetyEvent
} from '../../../shared/models/safety/events/terminal-activating-safety-event.model';
import {
    TerminalDeactivatingSafetyEvent
} from '../../../shared/models/safety/events/terminal-deactivating-safety-event.model';
import { ConfigService } from '../../services/config.service';
import { YesNoDialogModalComponent } from '../../../shared/components/dialog/yesno/yesno.component';

@Component({
    selector: 'ssl-terminals-list',
    styleUrls: ['./list.component.css'],
    templateUrl: './list.component.html'
})
export class SslTerminalsListComponent implements OnInit, OnDestroy {

    @ViewChild('editTerminalModal') public editTerminalModal: YesNoDialogModalComponent;
    @ViewChild('deleteTerminalModal') public deleteTerminalModal: YesNoDialogModalComponent;
    public terminalRows: Terminal[] = [];
    public filterCharacter: string = '';
    public possibleFilters: string[] = [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
        'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
        'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ];
    public isShowFavouriteOnly: boolean = false;
    public isPinError: boolean = false;
    public selectedTerminal: Terminal;
    public currentTerminal: Terminal;
    public terminalChangingToId: string = undefined;
    public currentInstallationType: InstallationTypeEnum;
    public installationTypeEnum = InstallationTypeEnum;
    public sslConfiguration: SslConfiguration;

    private persistenceSslConfigSub: Subscription;
    private persistenceSysConfigSub: Subscription;
    private persistenceEventsSub: Subscription;
    private routerSub: Subscription;
    private screenOptions: ScreenOption = new ScreenOption({});

    constructor(private _route: ActivatedRoute,
                private _router: Router,
                private _screenOpts: ScreenOptionService,
                private _persistence: PersistenceService,
                private _terminalService: TerminalService,
                private _configurationService: ConfigService,
                private _configService: PageConfigService) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                const screenOpts = this._screenOpts.getScreenOption();
                if (screenOpts) {
                    this.screenOptions = screenOpts;
                    this.isShowFavouriteOnly = this.screenOptions.isTerminalFavourite;
                }

                this.terminalRows = [];
                this.persistenceSysConfigSub = this._persistence
                    .systemConfiguration
                    .subscribe((data: SystemConfiguration) => {
                        if (data) {
                            this.currentInstallationType =
                                data.systemConfiguration.installationType;
                            this.serviceGetTerminals();
                        }
                    });

                this.persistenceSslConfigSub = this._persistence
                    .sslConfiguration
                    .subscribe((data: SslConfiguration) => {
                        if (data) {
                            this.sslConfiguration = data;
                            this.currentTerminal = data.currentTerminal;
                        }
                    });

                this.persistenceEventsSub = this._persistence
                    .lastSafetyEvent
                    .subscribe((data: BaseSafetyEvent) => {
                        if (data) {
                            switch (data.eventType) {
                                case SafetyEventType.TerminalActivating:
                                    const terminalActivatingEvent =
                                        new TerminalActivatingSafetyEvent(data);
                                    this.terminalChangingToId = terminalActivatingEvent.terminalId;
                                    break;
                                case SafetyEventType.TerminalActivated:
                                case SafetyEventType.TerminalActivatingFailed:
                                    this.terminalChangingToId = undefined;
                                    break;
                                case SafetyEventType.TerminalDeactivating:
                                    const terminalDeactivatingEvent =
                                        new TerminalDeactivatingSafetyEvent(data);
                                    this.terminalChangingToId =
                                        terminalDeactivatingEvent.terminalId;
                                    break;
                                case SafetyEventType.TerminalDeactivated:
                                case SafetyEventType.TerminalDeactivatingFailed:
                                    this.terminalChangingToId = undefined;
                                    break;
                            }
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.persistenceSslConfigSub.unsubscribe();
            this.persistenceEventsSub.unsubscribe();
            this.persistenceSysConfigSub.unsubscribe();
        }
    }

    // Template Helpers
    public selectTerminal(selectedTerminal: Terminal): void {
        this.selectedTerminal = selectedTerminal;
    }

    public newTerminal(): void {
        this._router.navigate(['/ssl', 'terminals', 'new']);
    }

    public confirmSetCurrentTerminal(): void {
        this.serviceSetCurrentTerminal(this.selectedTerminal);
    }

    public confirmEditTerminal(): void {
        this._router.navigate(['/ssl', 'terminals', 'edit', this.selectedTerminal.id]);
    }

    public confirmDeleteTerminal(): void {
        this.serviceDeleteTerminal(this.selectedTerminal).add(() => {
            this.serviceGetTerminals();
        });
        this.selectedTerminal = undefined;
    }

    public confirmUnsetCurrentTerminal(): void {
        this.serviceUnsetCurrentTerminal();
    }

    public confirmUnsetFavouriteTerminal(): void {
        this.selectedTerminal.isFavourite = false;
        this.serviceUpdateTerminal(this.selectedTerminal).add(() => {
            this.serviceGetTerminals();
        });
    }

    public confirmSetFavouriteTerminal(): void {
        this.selectedTerminal.isFavourite = true;
        this.serviceUpdateTerminal(this.selectedTerminal).add(() => {
            this.serviceGetTerminals();
        });
    }

    public confirmUnhideTerminal(): void {
        this.selectedTerminal.isHidden = false;
        this.serviceUpdateTerminal(this.selectedTerminal).add(() => {
            this.serviceGetTerminals();
        });
    }

    public confirmHideTerminal(): void {
        this.selectedTerminal.isHidden = true;
        this.serviceUpdateTerminal(this.selectedTerminal).add(() => {
            this.serviceGetTerminals();
        });
    }

    public setFilter(character: string): void {
        this.filterCharacter = character;
    }

    public clearFilter(): void {
        this.filterCharacter = '';
    }

    public toggleFavouriteFilter(): void {
        this.isShowFavouriteOnly = !this.isShowFavouriteOnly;
        this.screenOptions.isTerminalFavourite = this.isShowFavouriteOnly;
        this._screenOpts.updateScreenOption(this.screenOptions);
    }

    public checkPin(pin: string, type: string): void {
        this.isPinError = false;
        this._configurationService
            .checkPin(pin)
            .subscribe(() => {
                switch (type) {
                    case 'add':
                        this.newTerminal();
                        break;
                    case 'edit':
                        this.editTerminalModal.open();
                        break;
                    case 'delete':
                        this.deleteTerminalModal.open();
                        break;
                }
            }, () => {
                this.isPinError = true;
            });
    }

    public terminalsToShowCount(): number {
        let terminalsToShow: number = 13;
        if (this.currentTerminal) {
            terminalsToShow--;
        }

        if (this.isPinError) {
            terminalsToShow -= 2;
        }

        return terminalsToShow;
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'SSL',
            path: '/ssl'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'TERMINALS',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service calls
    private serviceGetTerminals(): Subscription {
        switch (this.currentInstallationType) {
            case InstallationTypeEnum.SHORE:
                return this.serviceGetShoreTerminals();
            default:
            case InstallationTypeEnum.SHIP:
                return this.serviceGetShipTerminals();
        }
    }

    private serviceGetShipTerminals(): Subscription {
        return this._terminalService
            .getAllShipTerminals()
            .subscribe((data: Terminal[]) => {
                this.terminalRows = data;
            });
    }

    private serviceGetShoreTerminals(): Subscription {
        return this._terminalService
            .getAllShoreTerminals()
            .subscribe((data: Terminal[]) => {
                this.terminalRows = data;
            });
    }

    private serviceSetCurrentTerminal(newTerminal: Terminal): Subscription {
        return this._terminalService
            .setCurrentTerminal(newTerminal)
            .subscribe((data: Terminal) => {
                this.currentTerminal = data;
            });
    }

    private serviceUnsetCurrentTerminal(): Subscription {
        return this._terminalService
            .unsetCurrentTerminal()
            .subscribe((data: HttpResponse<string>) => {
                this.currentTerminal = undefined;
            });
    }

    private serviceDeleteTerminal(terminal: Terminal): Subscription {
        return this._terminalService.delete(terminal).subscribe();
    }

    private serviceUpdateTerminal(terminal: Terminal): Subscription {
        return this._terminalService.update(terminal).subscribe();
    }
}
