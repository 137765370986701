import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';
import { SslConfiguration } from '../models/ssl-configuration.model';
import { SystemConfiguration } from '../../shared/models/system/configuration.model';
import { InstallationTypeEnum } from '../../shared/enums/installation-type.enum';

@Component({
    selector: 'ssl-home',
    styleUrls: ['./home.component.css'],
    templateUrl: './home.component.html'
})
export class SslHomeComponent implements OnInit, OnDestroy {

    public isLoading: boolean = true;
    private persistenceSysConfigSub: Subscription;
    private persistenceSslSub: Subscription;
    private routerSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _configService: PageConfigService,
        private _persistence: PersistenceService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.isLoading = true;
                this.updateNavigation();
                this.persistenceSysConfigSub = this._persistence
                    .systemConfiguration
                    .subscribe((systemData: SystemConfiguration) => {
                        this.persistenceSslSub = this._persistence
                            .sslConfiguration
                            .subscribe((sslData: SslConfiguration) => {
                                if (sslData) {
                                    if (sslData.currentTerminal) {
                                        if (sslData.isBootToGraphical &&
                                            sslData.isGraphicsPageEnabled) {
                                            this._router.navigate(
                                                ['/ssl', 'operations', 'graphical']
                                            );
                                        } else {
                                            this._router.navigate(
                                                ['/ssl', 'operations', 'detailed']
                                            );
                                        }
                                    } else if (systemData.systemConfiguration.installationType ==
                                        InstallationTypeEnum.SHIP) {
                                        this._router.navigate(['/ssl', 'terminals']);
                                    } else if (systemData.systemConfiguration.installationType ==
                                        InstallationTypeEnum.SHORE) {
                                        this._router.navigate(['/ssl', 'settings']);
                                    }
                                }
                                setTimeout(() => {
                                    this.isLoading = false;
                                }, 2000);
                            });
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.persistenceSysConfigSub.unsubscribe();
            this.persistenceSslSub.unsubscribe();
        }
    }

    // Helper methods
    private updateNavigation(): void {
        let pageBreadcrumbs: PageBreadcrumb[] = [];
        this._configService.setConfig(new PageConfig({
            title: 'SSL',
            breadcrumbs: pageBreadcrumbs
        }));
    }
}
