import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { Recording } from '../models/recording.model';
import { RecordingService } from '../services/recording.service';
import { Paged } from '../../shared/models/common/paged.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';

@Component({
    selector: 'ssl-recordings',
    styleUrls: ['./recordings.component.css'],
    templateUrl: './recordings.component.html'
})
export class SslRecordingsComponent implements OnInit, OnDestroy {

    public pageSize: number = 13;
    public currentTimeZone: string = 'UTC';

    public currentRecordingPage: number = 1;
    public recordingTableSortBy: string = 'captureStartTimestamp';
    public recordingTableSortByDesc: boolean = true;
    public recordingRows: Paged<Recording>;

    public selectedRecording: Recording;
    public timestampFilterFrom: number = undefined;
    public timestampFilterTo: number = undefined;
    public isLoading: boolean = false;

    private routerSub: Subscription;
    private recordingSub: Subscription;
    private persistenceCurrentTimeZoneSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _persistence: PersistenceService,
        private _configService: PageConfigService,
        private _recordingService: RecordingService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.serviceGetRecordings(this.recordingTableSortBy,
                    this.recordingTableSortByDesc,
                    this.currentRecordingPage);
                this.recordingSub = this.websocketGetRecordings();

                this.persistenceCurrentTimeZoneSub = this._persistence
                    .currentTimeZoneIANA
                    .subscribe((data: string) => {
                        this.currentTimeZone = data;
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.recordingSub.unsubscribe();
            this.persistenceCurrentTimeZoneSub.unsubscribe();
        }
    }

    // Template Helper
    public goToRecordingPage(pageInfo: any): void {
        this.serviceGetRecordings(this.recordingTableSortBy,
            this.recordingTableSortByDesc,
            pageInfo.offset + 1);
    }

    public sortRecordingPage(sortInfo: any): void {
        this.recordingTableSortBy = sortInfo.column.prop;
        this.recordingTableSortByDesc = sortInfo.newValue == 'desc';
        this.serviceGetRecordings(this.recordingTableSortBy,
            this.recordingTableSortByDesc,
            this.recordingRows.pageIndex);
    }

    public filterRecordingPage(): void {
        this.serviceGetRecordings(this.recordingTableSortBy,
            this.recordingTableSortByDesc,
            1);
    }

    public selectRecording(selectedRecordings: any): void {
        this.selectedRecording = selectedRecordings.selected[0];
    }

    public clearDateFormat(): void {
        this.timestampFilterFrom = undefined;
        this.timestampFilterTo = undefined;
        this.filterRecordingPage();
    }

    public confirmFilterFromDate(newFilter: number): void {
        this.timestampFilterFrom = newFilter;
        this.filterRecordingPage();
    }

    public confirmFilterToDate(newFilter: number): void {
        this.timestampFilterTo = newFilter;
        this.filterRecordingPage();
    }

    public confirmDeleteRecording(): void {
        this.serviceDeleteRecording(this.selectedRecording.id).add(() => {
            this.serviceGetRecordings(this.recordingTableSortBy,
                this.recordingTableSortByDesc,
                this.recordingRows.pageIndex);
        });
        this.selectedRecording = undefined;
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'SSL',
            path: '/ssl'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'RECORDINGS',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service calls
    private websocketGetRecordings(): Subscription {
        return this._recordingService
            .websocketGetRecordings()
            .subscribe((data: Recording) => {
                if (data && this.recordingRows && this.currentRecordingPage == 1) {
                    this.serviceGetRecordings(this.recordingTableSortBy,
                        this.recordingTableSortByDesc,
                        this.currentRecordingPage);
                }
            });
    }

    private serviceGetRecordings(orderByField: string,
                                 isDesc: boolean,
                                 pageIndex: number): Subscription {
        this.isLoading = true;
        this.currentRecordingPage = pageIndex;
        return this._recordingService
            .getRecordings(orderByField,
                isDesc,
                this.timestampFilterFrom,
                this.timestampFilterTo,
                pageIndex,
                this.pageSize)
            .subscribe((data: Paged<Recording>) => {
                this.recordingRows = data;
                this.isLoading = false;
            });
    }

    private serviceDeleteRecording(recordingId: string): Subscription {
        return this._recordingService
            .deleteRecording(recordingId)
            .subscribe();
    }
}
