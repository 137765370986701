import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { REMOTE_ROUTES } from './mod_remote.routes';

// Thirdparty
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';

// Services

// Pipes

// Components
import { RemoteComponent } from './mod_remote.component';
import { RemoteHomeComponent } from './home/home.component';
import { RemoteViewComponent } from './view/view.component';

@NgModule({
    declarations: [
        RemoteComponent,
        RemoteHomeComponent,
        RemoteViewComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(REMOTE_ROUTES),
        TranslateModule,

        // Pipes
        SharedPipesModule,

        // Directives
        SharedDirectivesModule,
    ],
    exports: [
        RouterModule
    ],
    providers: [
    ]
})
export class RemoteModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: RemoteModule
        };
    }
}
