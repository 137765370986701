import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ConnectionSystem } from '../../../../../models/connection-system.model';
import { ConnectionTypeEnum } from '../../../../../../shared/enums/connection-type.enum';
import { ConnectionConfiguration } from '../../../../../models/connection-configuration.model';

import { BoardTypeEnum } from '../../../../../../shared/enums/board-type.enum';
import { ConnectionTelecommChannelElectric } from '../../../../../models/connection-telecomm-channel-electric.model';
import { SslConfiguration } from '../../../../../models/ssl-configuration.model';
import { KeyboardDialogModalComponent } from '../../../../../../shared/components/dialog/keyboard/keyboard.component';
import { ConnectionTelecommChannel } from '../../../../../models/connection-telecomm-channel.model';
import { ConnectionTelecommChannelFibreAnalogue } from '../../../../../models/connection-telecomm-channel-fibre-analogue.model';
import { SystemLocationEnum } from '../../../../../../shared/enums/system-location.enum';
import { ElectricLinePairConfigEnum } from '../../../../../../shared/enums/electric-line-pair-config.enum';
import { ConnectionTelecommChannelFibreDigital } from '../../../../../models/connection-telecomm-channel-fibre-digital.model';
import { ConnectionEsd } from '../../../../../models/connection-esd.model';

@Component({
    selector: 'ssl-terminals-config-telecomm-config',
    styleUrls: ['./telecomm-config.component.css', '../../step-common/step-common.component.css'],
    templateUrl: './telecomm-config.component.html'
})
export class SslTerminalsConfigTelecommConfigComponent implements OnInit {

    @Input() public sslConfiguration: SslConfiguration;
    @Input() public allConnectionSystems: ConnectionSystem[];
    @Input() public availableConnectionTypes: ConnectionTypeEnum[];
    @Input() public currentConnectionSystem: ConnectionSystem;
    @Output() public onSave: EventEmitter<ConnectionConfiguration> =
        new EventEmitter<ConnectionConfiguration>();
    @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('channelNameChange') public channelNameChangeModal: KeyboardDialogModalComponent;

    public selectedConfiguration: ConnectionConfiguration;
    public boardTypeEnum = BoardTypeEnum;
    public connectionTypeEnum = ConnectionTypeEnum;
    public electricLinePairConfigEnum = ElectricLinePairConfigEnum;
    public telecommConnectionTypes: ConnectionTypeEnum[] = [];
    public systemLocationEnum = SystemLocationEnum;

    private selectedChannel: ConnectionTelecommChannel;

    public ngOnInit(): void {
        const possibleTelecommConnectionTypes: ConnectionTypeEnum[] = [];
        possibleTelecommConnectionTypes.push(
            ConnectionTypeEnum.FIBRE_ANALOGUE,
            ConnectionTypeEnum.FIBRE_DIGITAL,
            ConnectionTypeEnum.MIYAKI,
            ConnectionTypeEnum.THIRTYSEVENWAY,
            ConnectionTypeEnum.ITT_CANNON);

        this.telecommConnectionTypes = possibleTelecommConnectionTypes
            .filter((value) => -1 !== this.availableConnectionTypes.indexOf(value));
        this.telecommConnectionTypes.push(ConnectionTypeEnum.NONE);

        this.selectedConfiguration =
            new ConnectionConfiguration(this.currentConnectionSystem.telecommConfiguration);
    }

    // Template Helpers
    public changeChannelName(channel: ConnectionTelecommChannel): void {
        this.selectedChannel = channel;
        this.channelNameChangeModal.open(channel.channelName);
    }

    public saveChannelName(channelName: string): void {
        this.selectedChannel.channelName = channelName;
    }

    public changedPortStarboardTelecommsBanks(channels: ConnectionTelecommChannel[],
                                              isStarboard: boolean,
                                              isBankB: boolean): void {
        if (channels) {
            for (const channel of channels) {
                if (isStarboard) {
                    channel.isStarboardBankB = isBankB;
                } else {
                    channel.isPortBankB = isBankB;
                }
            }
        }
    }

    public isUsingPseudoBoard(): boolean {
        return (this.selectedConfiguration.usePseudoBoard) ||
            (this.currentConnectionSystem.esdConfiguration != undefined &&
                this.currentConnectionSystem.esdConfiguration.usePseudoBoard);
    }

    public changedConnectionSystem(connectionType: ConnectionTypeEnum): void {
        if (connectionType == ConnectionTypeEnum.NONE) {
            this.selectedConfiguration.isEnabled = false;
            return;
        }

        this.selectedConfiguration.isEnabled = true;
        this.selectedConfiguration.availableTelecommChannels = [];

        switch (this.selectedConfiguration.connectionType) {
            case ConnectionTypeEnum.FIBRE_ANALOGUE:
                this.selectedConfiguration.boardType = BoardTypeEnum.FIBRE_ANALOGUE;
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelFibreAnalogue({
                    channelNumber: 1,
                    channelName: 'Channel 1',
                    isEnabled: false,
                    isShore: false,
                    isSlic: false,
                    isBankB: false,
                    gain: 64,
                }));
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelFibreAnalogue({
                    channelNumber: 2,
                    channelName: 'Channel 2',
                    isEnabled: false,
                    isShore: false,
                    isSlic: false,
                    isBankB: false,
                    gain: 64,
                }));
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelFibreAnalogue({
                    channelNumber: 3,
                    channelName: 'Channel 3',
                    isEnabled: false,
                    isShore: false,
                    isSlic: false,
                    isBankB: false,
                    gain: 5,
                }));
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelFibreAnalogue({
                    channelNumber: 4,
                    channelName: 'Channel 4',
                    isEnabled: false,
                    isShore: false,
                    isSlic: false,
                    isBankB: false,
                    gain: 5,
                }));
                break;
            case ConnectionTypeEnum.FIBRE_DIGITAL:
                this.selectedConfiguration.boardType = BoardTypeEnum.FIBRE_DIGITAL;
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelFibreDigital({
                    isEnabled: false,
                    isShore: false,
                    isBankB: false,
                    channelNumber: 1,
                    channelName: 'Channel 1',
                }));
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelFibreDigital({
                    isEnabled: false,
                    isShore: false,
                    isBankB: false,
                    channelNumber: 2,
                    channelName: 'Channel 2',
                }));
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelFibreDigital({
                    isEnabled: false,
                    isShore: false,
                    isBankB: false,
                    channelNumber: 3,
                    channelName: 'Channel 3',
                }));
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelFibreDigital({
                    isEnabled: false,
                    isShore: false,
                    isBankB: false,
                    channelNumber: 4,
                    channelName: 'Channel 4',
                }));
                break;
            case ConnectionTypeEnum.ITT_CANNON:
            case ConnectionTypeEnum.THIRTYSEVENWAY:
            case ConnectionTypeEnum.MIYAKI:
                this.selectedConfiguration.boardType = BoardTypeEnum.ELECTRIC;
                break;
            default:
                // No ESDs created if no type exists.
                break;
        }

        switch (this.selectedConfiguration.boardType) {
            case BoardTypeEnum.ELECTRIC:
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelElectric({
                    channelNumber: 1,
                    channelName: 'Channel 1',
                    data: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    defaultData: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    isBankB: false,
                }));
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelElectric({
                    channelNumber: 2,
                    channelName: 'Channel 2',
                    data: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    defaultData: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    isBankB: false,
                }));
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelElectric({
                    channelNumber: 3,
                    channelName: 'Channel 3',
                    data: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    defaultData: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    isBankB: false,
                }));
                this.selectedConfiguration.availableTelecommChannels.push(new ConnectionTelecommChannelElectric({
                    channelNumber: 4,
                    channelName: 'Channel 4',
                    data: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    defaultData: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    isBankB: false,
                }));
                break;
            default:
                // No ESDs created if no type exists.
                break;
        }
    }

    public isElectricPairDuplicated(channel: ConnectionTelecommChannelElectric,
                                    newPair: number): boolean {
        let isDuplicated: boolean = false;
        const availableChannels: any[] = this.selectedConfiguration.availableTelecommChannels;

        for (const availableChannel of availableChannels) {
            if (availableChannel != channel) {
                isDuplicated = (availableChannel.data == newPair);
                if (isDuplicated) {
                    break;
                }
            }
        }

        return isDuplicated;
    }

    public canSave(): boolean {
        if (!this.selectedConfiguration) {
            return false;
        }

        if (this.selectedConfiguration.connectionType == undefined) {
            return false;
        }

        return true;
    }

    public saveTelecommConfig(): void {
        if (this.currentConnectionSystem.esdConfiguration &&
            this.currentConnectionSystem.esdConfiguration.id &&
            this.currentConnectionSystem.esdConfiguration.id != '') {
            for (const connectionSystem of this.allConnectionSystems) {
                if (connectionSystem != this.currentConnectionSystem &&
                    connectionSystem.telecommConfiguration &&
                    connectionSystem.esdConfiguration &&
                    connectionSystem.esdConfiguration.masterConnectionConfigurationId &&
                    connectionSystem.esdConfiguration.masterConnectionConfigurationId ==
                    this.currentConnectionSystem.esdConfiguration.id) {
                    connectionSystem.telecommConfiguration.availableTelecommChannels = [];
                    for (const esd of this.selectedConfiguration.availableTelecommChannels) {
                        const copiedChannel: ConnectionTelecommChannel = JSON.parse(JSON.stringify(esd)); // Deep Copy
                        copiedChannel.id = undefined;
                        copiedChannel.connectionConfigurationId = undefined;
                        connectionSystem.telecommConfiguration.availableTelecommChannels.push(copiedChannel);
                    }
                }
            }
        }

        this.onSave.emit(this.selectedConfiguration);
    }

    public cancelTelecommConfig(): void {
        this.onCancel.emit(undefined);
    }

    public reduceGain(telecomm: ConnectionTelecommChannelFibreAnalogue): void {
        telecomm.gain -= 5;
        if (telecomm.gain < 0) {
            telecomm.gain = 0;
        }
    }

    public increaseGain(telecomm: ConnectionTelecommChannelFibreAnalogue): void {
        telecomm.gain += 5;
        if (telecomm.gain > 255) {
            telecomm.gain = 255;
        }
    }

    public reduceDemodulation(): void {
        this.selectedConfiguration.demodulationType -= 1;
        if (this.selectedConfiguration.demodulationType < 0) {
            this.selectedConfiguration.demodulationType = 0;
        }
    }

    public increaseDemodulation(): void {
        this.selectedConfiguration.demodulationType += 1;
        if (this.selectedConfiguration.demodulationType > 15) {
            this.selectedConfiguration.demodulationType = 15;
        }
    }

    public calculateGain(gain: number): string {
        return ((gain / 255) * 100).toFixed(0) + '%';
    }
}
