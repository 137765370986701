import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'keyboard-dialog-modal',
    styleUrls: ['./keyboard.component.css'],
    templateUrl: './keyboard.component.html'
})
export class KeyboardDialogModalComponent {

    @Input() public title: string;
    @Input() public isClosable: boolean;
    @Input() public isRequired: boolean;
    @Input() public maxCharacters: number = 0;

    @Output() public change: EventEmitter<string> = new EventEmitter<string>();
    @Output() public enter: EventEmitter<string> = new EventEmitter<string>();
    @Output() public cancel: EventEmitter<undefined> = new EventEmitter<undefined>();

    public keyboardRows: string[][] = [
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['Z', 'X', 'C', 'V', 'B', 'N', 'M']
    ];
    public currentValue: string = '';

    @ViewChild('keyboardModal') public keyboardModal: ModalDirective;

    public pressButton(character: string): void {
        this.currentValue += character;
        this.change.emit(this.currentValue);
    }

    public removeChar(): void {
        if (this.currentValue.length > 0) {
            this.currentValue = this.currentValue.slice(0, -1);
            this.change.emit(this.currentValue);
        }
    }

    public clear(): void {
        this.currentValue = '';
        this.change.emit(this.currentValue);
    }

    public open(setValue: string): void {
        if (setValue) {
            this.currentValue = setValue;
        } else {
            this.currentValue = '';
        }
        this.keyboardModal.show();
    }

    public enterPressed(): void {
        this.keyboardModal.hide();
        this.enter.emit(this.currentValue);
    }

    public cancelPressed(): void {
        this.keyboardModal.hide();
        this.cancel.emit(undefined);
    }

    public isCharacterLimit(): boolean {
        return (this.maxCharacters > 0 && this.currentValue.length >= this.maxCharacters);
    }
}
