import { Component, OnInit } from '@angular/core';
import { PageConfigService } from '../shared/services/system/page-config.service';
import { ModuleTypeEnum } from '../shared/enums/module-type.enum';

@Component({
    selector: 'remote',
    templateUrl: './mod_remote.component.html',
    styleUrls: ['./mod_remote.component.css']
})
export class RemoteComponent implements OnInit {

    constructor(
        private _configService: PageConfigService,
    ) {}

    public ngOnInit(): void {
        this._configService.setCurrentContext(ModuleTypeEnum.NONE);
    }
}
