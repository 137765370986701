import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConnectionSystem } from '../../../../../models/connection-system.model';
import { ConnectionTypeEnum } from '../../../../../../shared/enums/connection-type.enum';
import { ConnectionConfiguration } from '../../../../../models/connection-configuration.model';
import { BoardTypeEnum } from '../../../../../../shared/enums/board-type.enum';
import { ConnectionEsdEsl } from '../../../../../models/connection-esd-esl.model';
import { ConnectionEsdElectric } from '../../../../../models/connection-esd-electric.model';
import {
    ConnectionEsdFibreAnalogue
} from '../../../../../models/connection-esd-fibre-analogue.model';
import { TripTypeEnum } from '../../../../../../shared/enums/trip-type.enum';
import { EslStateEnum } from '../../../../../enums/esl-state.enum';
import { LanguageService } from '../../../../../../shared/services/system/language.service';
import { SslConfiguration } from '../../../../../models/ssl-configuration.model';
import {
    ElectricLinePairConfigEnum
} from '../../../../../../shared/enums/electric-line-pair-config.enum';
import { ConnectionEsdPneumatic } from '../../../../../models/connection-esd-pneumatic.model';
import {
    ConnectionEsdFibreDigital
} from '../../../../../models/connection-esd-fibre-digital.model';
import { ConnectionEsd } from '../../../../../models/connection-esd.model';

@Component({
    selector: 'ssl-terminals-config-esd-config',
    styleUrls: ['./esd-config.component.css', '../../step-common/step-common.component.css'],
    templateUrl: './esd-config.component.html'
})
export class SslTerminalsConfigEsdConfigComponent implements OnInit {

    @Input() public sslConfiguration: SslConfiguration;
    @Input() public allConnectionSystems: ConnectionSystem[];
    @Input() public currentConnectionSystem: ConnectionSystem;
    @Input() public availableConnectionTypes: ConnectionTypeEnum[];
    @Output() public onSave: EventEmitter<ConnectionConfiguration> =
        new EventEmitter<ConnectionConfiguration>();
    @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();

    public selectedConfiguration: ConnectionConfiguration;
    public connectionTypeEnum = ConnectionTypeEnum;
    public eslStateEnum = EslStateEnum;
    public electricLinePairConfigEnum = ElectricLinePairConfigEnum;
    public boardTypeEnum = BoardTypeEnum;
    public tripTypeEnum = TripTypeEnum;
    public esdConnectionTypes: ConnectionTypeEnum[] = [];
    public esdLangLines: string[] = [];
    public esdSubTextLangLines: string[] = [];
    public esdsSubTextLangLines: string[] = [];
    public esdTextLocalBlacklist: Set<string> = new Set([
        'SSL_ESD.REMOTE',
        'SSL_ESD.SHIP_REMOTE',
        'SSL_ESD.SHORE_REMOTE',
        'SSL_ESD.REMOTE_LNG',
        'SSL_ESD.REMOTE_CNG',
        'SSL_ESD.REMOTE_SYSTEM_NAME'
    ]);
    public esdTextRemoteBlacklist: Set<string> = new Set([
        'SSL_ESD.LOCAL',
        'SSL_ESD.SHIP_LOCAL',
        'SSL_ESD.SHORE_LOCAL',
        'SSL_ESD.LOCAL_LNG',
        'SSL_ESD.LOCAL_CNG',
        'SSL_ESD.LOCAL_SYSTEM_NAME'
    ]);
    public esdSubTextLocalBlacklist: Set<string> = new Set([
        'SSL_ESD_SUBTEXT.SHIP_SHIP_REMOTE_SUBTEXT'
    ]);
    public esdSubTextRemoteBlacklist: Set<string> = new Set([
        'SSL_ESD_SUBTEXT.SHIP_SHIP_LOCAL_SUBTEXT'
    ]);

    private additionalTripPairs: Set<number> = new Set([
        ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_21_22,
        ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_25_26,
        ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_27_28
    ]);

    constructor(
        private _languageService: LanguageService
    ) {}

    public ngOnInit(): void {
        this.serviceGetEsdLangLines();
        this.serviceGetEsdSubTextLangLines();
        this.serviceGetEsdsSubTextLangLines();

        let possibleEsdConnectionTypes: ConnectionTypeEnum[] = [];
        possibleEsdConnectionTypes.push(
            ConnectionTypeEnum.ESL,
            ConnectionTypeEnum.FIBRE_ANALOGUE,
            ConnectionTypeEnum.FIBRE_DIGITAL,
            ConnectionTypeEnum.MIYAKI,
            ConnectionTypeEnum.THIRTYSEVENWAY,
            ConnectionTypeEnum.PNEUMATIC);

        this.esdConnectionTypes = possibleEsdConnectionTypes
            .filter((value) => -1 !== this.availableConnectionTypes.indexOf(value));
        this.esdConnectionTypes.push(ConnectionTypeEnum.NONE);

        this.selectedConfiguration =
            new ConnectionConfiguration(this.currentConnectionSystem.esdConfiguration);
    }

    public getTextLangKeys(tripType: TripTypeEnum): string[] {
        let filteredList: string[] = [];
        switch (tripType) {
            case TripTypeEnum.LOCAL:
                for (let esdLangLine of this.esdLangLines) {
                    if (!this.esdTextLocalBlacklist.has(esdLangLine)) {
                        filteredList.push(esdLangLine);
                    }
                }

                return filteredList;
            case TripTypeEnum.REMOTE:
                for (let esdLangLine of this.esdLangLines) {
                    if (!this.esdTextRemoteBlacklist.has(esdLangLine)) {
                        filteredList.push(esdLangLine);
                    }
                }

                return filteredList;
            default:
                return this.esdLangLines;
        }
    }

    public getSubTextLangKeys(tripType: TripTypeEnum): string[] {
        let filteredList: string[] = [];
        switch (tripType) {
            case TripTypeEnum.LOCAL:
                for (let esdLangLine of this.esdSubTextLangLines) {
                    if (!this.esdSubTextLocalBlacklist.has(esdLangLine)) {
                        filteredList.push(esdLangLine);
                    }
                }

                return filteredList;
            case TripTypeEnum.REMOTE:
                for (let esdLangLine of this.esdSubTextLangLines) {
                    if (!this.esdSubTextRemoteBlacklist.has(esdLangLine)) {
                        filteredList.push(esdLangLine);
                    }
                }

                return filteredList;
            default:
                return this.esdSubTextLangLines;
        }
    }

    // Template Helpers
    public changedConnectionSystem(connectionType: ConnectionTypeEnum): void {
        if (connectionType == ConnectionTypeEnum.NONE) {
            this.selectedConfiguration.isEnabled = false;
            return;
        }

        this.selectedConfiguration.isEnabled = true;
        this.selectedConfiguration.availableEsds = [];

        switch (this.selectedConfiguration.connectionType) {
            case ConnectionTypeEnum.ESL:
                this.selectedConfiguration.boardType = BoardTypeEnum.Esl;
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdEsl({
                    esdLangKey: 'SSL_ESD.LOCAL',
                    esdType: TripTypeEnum.LOCAL,
                }));
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdEsl({
                    esdLangKey: 'SSL_ESD.REMOTE',
                    esdType: TripTypeEnum.REMOTE,
                }));
                break;
            case ConnectionTypeEnum.PNEUMATIC:
                this.selectedConfiguration.boardType = BoardTypeEnum.Pneumatic;
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdPneumatic({
                    esdLangKey: 'SSL_ESD.LOCAL',
                    esdType: TripTypeEnum.LOCAL,
                }));
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdPneumatic({
                    esdLangKey: 'SSL_ESD.REMOTE',
                    esdType: TripTypeEnum.REMOTE,
                }));
                break;
            case ConnectionTypeEnum.FIBRE_ANALOGUE:
                this.selectedConfiguration.boardType = BoardTypeEnum.FIBRE_ANALOGUE;
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdFibreAnalogue({
                    esdLangKey: 'SSL_ESD.LOCAL',
                    esdType: TripTypeEnum.LOCAL,
                }));
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdFibreAnalogue({
                    esdLangKey: 'SSL_ESD.REMOTE',
                    esdType: TripTypeEnum.REMOTE,
                }));
                break;
            case ConnectionTypeEnum.FIBRE_DIGITAL:
                this.selectedConfiguration.boardType = BoardTypeEnum.FIBRE_DIGITAL;
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdFibreDigital({
                    esdLangKey: 'SSL_ESD.LOCAL',
                    esdType: TripTypeEnum.LOCAL,
                }));
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdFibreDigital({
                    esdLangKey: 'SSL_ESD.REMOTE',
                    esdType: TripTypeEnum.REMOTE,
                }));
                break;
            case ConnectionTypeEnum.MIYAKI:
                this.selectedConfiguration.boardType = BoardTypeEnum.ELECTRIC;
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdElectric({
                    esdLangKey: 'SSL_ESD.LOCAL',
                    esdType: TripTypeEnum.LOCAL,
                    data: this.modElectricMiyakiData(0x00,
                        ElectricLinePairConfigEnum.MIYAKI_1,
                        ElectricLinePairConfigEnum.MIYAKI_2),
                    isInput: false
                }));
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdElectric({
                    esdLangKey: 'SSL_ESD.REMOTE',
                    esdType: TripTypeEnum.REMOTE,
                    data: this.modElectricMiyakiData(0x00,
                        ElectricLinePairConfigEnum.MIYAKI_3,
                        ElectricLinePairConfigEnum.MIYAKI_4),
                    isInput: false
                }));
                break;
            case ConnectionTypeEnum.THIRTYSEVENWAY:
                this.selectedConfiguration.boardType = BoardTypeEnum.ELECTRIC;
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdElectric({
                    esdLangKey: 'SSL_ESD.LOCAL',
                    esdType: TripTypeEnum.LOCAL,
                    isInput: false,
                    data: ElectricLinePairConfigEnum.NOT_CONNECTED,
                }));
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdElectric({
                    esdLangKey: 'SSL_ESD.REMOTE',
                    esdType: TripTypeEnum.REMOTE,
                    isInput: false,
                    data: ElectricLinePairConfigEnum.NOT_CONNECTED,
                }));
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdElectric({
                    esdLangKey: 'SSL_ESD.TANK_HIGH',
                    esdType: TripTypeEnum.TANK_HIGH,
                    isInput: false,
                    data: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    defaultData: ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_21_22
                }));
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdElectric({
                    esdLangKey: 'SSL_ESD.FIRST_STAGE_LOADING_ARM',
                    esdType: TripTypeEnum.FIRST_STAGE,
                    isInput: false,
                    data: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    defaultData: ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_25_26
                }));
                this.selectedConfiguration.availableEsds.push(new ConnectionEsdElectric({
                    esdLangKey: 'SSL_ESD.SECOND_STAGE_LOADING_ARM',
                    esdType: TripTypeEnum.SECOND_STAGE,
                    isInput: false,
                    data: ElectricLinePairConfigEnum.NOT_CONNECTED,
                    defaultData: ElectricLinePairConfigEnum.THIRTY_SEVEN_WAY_27_28
                }));
                break;
            default:
                // No ESDs created if no type exists.
                break;
        }
    }

    public getSystemsWithConnectionType(connectionType: ConnectionTypeEnum): ConnectionSystem[] {
        let foundConnectionSystems: ConnectionSystem[] = [];

        for (let connectionSystem of this.allConnectionSystems) {
            if (connectionSystem != this.currentConnectionSystem &&
                connectionSystem.esdConfiguration &&
                connectionSystem.esdConfiguration.connectionType & connectionType &&
                connectionSystem.esdConfiguration.id &&
                connectionSystem.esdConfiguration.id != '' &&
                connectionSystem.esdConfiguration.usePseudoBoard == false) {
                foundConnectionSystems.push(connectionSystem);
            }
        }

        return foundConnectionSystems;
    }

    public masterConnectionConfigurationChanged(newId: string): void {
        for (let connectionSystem of this.allConnectionSystems) {
            if (newId == connectionSystem.id) {
                this.selectedConfiguration.availableEsds = [];
                for (let esd of connectionSystem.esdConfiguration.availableEsds) {
                    let copiedEsd: ConnectionEsd = JSON.parse(JSON.stringify(esd)); // Deep Copy
                    copiedEsd.id = undefined;
                    copiedEsd.connectionConfigurationId = undefined;
                    this.selectedConfiguration.availableEsds.push(copiedEsd);
                }

                break;
            }
        }
    }

    public usePseudoBoardChanged(newState: boolean): void {
        if (!newState) {
            this.selectedConfiguration.masterConnectionConfigurationId = undefined;
        }
    }

    public canSave(): boolean {
        if (!this.selectedConfiguration) {
            return true;
        }

        if (this.selectedConfiguration.connectionType == undefined) {
            return false;
        }

        if (this.selectedConfiguration.usePseudoBoard &&
            (!this.selectedConfiguration.masterConnectionConfigurationId ||
                this.selectedConfiguration.masterConnectionConfigurationId == '')) {
            return false;
        }

        return true;
    }

    public saveEsdConfig(): void {
        if (this.selectedConfiguration.id && this.selectedConfiguration.id != '') {
            for (let connectionSystem of this.allConnectionSystems) {
                if (connectionSystem != this.currentConnectionSystem &&
                    connectionSystem.esdConfiguration &&
                    connectionSystem.esdConfiguration.masterConnectionConfigurationId &&
                    connectionSystem.esdConfiguration.masterConnectionConfigurationId ==
                    this.selectedConfiguration.id) {
                    connectionSystem.esdConfiguration.availableEsds = [];
                    for (let esd of this.selectedConfiguration.availableEsds) {
                        let copiedEsd: ConnectionEsd = JSON.parse(JSON.stringify(esd)); // Deep Copy
                        copiedEsd.id = undefined;
                        copiedEsd.connectionConfigurationId = undefined;
                        connectionSystem.esdConfiguration.availableEsds.push(copiedEsd);
                    }
                }
            }
        }

        this.onSave.emit(this.selectedConfiguration);
    }

    public cancelEsdConfig(): void {
        this.onCancel.emit(undefined);
    }

    public is37WPairAvailable(esd: ConnectionEsdElectric, pinData: number): boolean {
        switch (esd.esdType) {
            case TripTypeEnum.REMOTE:
            case TripTypeEnum.LOCAL:
                return (!this.isElectricPairDuplicated(esd, pinData));
            case TripTypeEnum.CONTINUITY_LINK:
            case TripTypeEnum.ESDS:
                return false;
            case TripTypeEnum.TANK_HIGH:
                if (esd.defaultData != 0 && esd.defaultData != undefined) {
                    return (!this.selectedConfiguration.useSecondaryTrips &&
                        (esd.defaultData == pinData) &&
                        (!this.isElectricPairDuplicated(esd, pinData)));
                } else {
                    return (!this.isElectricPairDuplicated(esd, pinData));
                }

            case TripTypeEnum.FIRST_STAGE:
            case TripTypeEnum.SECOND_STAGE:
                if (esd.defaultData != 0 && esd.defaultData != undefined) {
                    return (((this.selectedConfiguration.useSecondaryTrips &&
                        this.additionalTripPairs.has(pinData)) ||
                        esd.defaultData == pinData) &&
                        (!this.isElectricPairDuplicated(esd, pinData)));
                } else {
                    return (!this.isElectricPairDuplicated(esd, pinData));
                }

            default:
                if (esd.defaultData != 0 && esd.defaultData != undefined) {
                    return ((esd.defaultData == pinData) &&
                    (!this.isElectricPairDuplicated(esd, pinData)));
                } else {
                    return (!this.isElectricPairDuplicated(esd, pinData));
                }
        }
    }

    public is37WPairDisabled(esd: ConnectionEsdElectric): boolean {
        switch (esd.esdType) {
            case TripTypeEnum.TANK_HIGH:
                return this.selectedConfiguration.useSecondaryTrips;
            default:
                return false;
        }
    }

    public useSecondaryTripsChanged(change: boolean): void {
        if (change) {
            for (let esd of this.selectedConfiguration.availableEsds) {
                let electricEsd = <ConnectionEsdElectric> esd;
                if (electricEsd.esdType == TripTypeEnum.TANK_HIGH) {
                    electricEsd.data = 0;
                }
            }
        } else {
            this.selectedConfiguration.usePseudoBoard = false;
            for (let esd of this.selectedConfiguration.availableEsds) {
                let electricEsd = <ConnectionEsdElectric> esd;
                if (electricEsd.esdType == TripTypeEnum.FIRST_STAGE ||
                    electricEsd.esdType == TripTypeEnum.SECOND_STAGE) {
                    electricEsd.data = 0;
                }
            }
        }
    }

    public getMiyakiLeftPinData(esd: ConnectionEsdElectric): number {
        let buffer = new ArrayBuffer(4);
        let dataView = new DataView(buffer);
        dataView.setUint32(0, esd.data, false);

        return dataView.getUint16(0, false);
    }

    public getMiyakiRightPinData(esd: ConnectionEsdElectric): number {
        let buffer = new ArrayBuffer(4);
        let dataView = new DataView(buffer);
        dataView.setUint32(0, esd.data, false);

        return dataView.getUint16(2, false);
    }

    public updateMiyakiPin(esd: ConnectionEsdElectric,
                           leftPinData: number,
                           rightPinData: number): void {
        let buffer = new ArrayBuffer(4);
        let dataView = new DataView(buffer);
        dataView.setUint32(0, esd.data, false);

        let leftPin: number = leftPinData || 0x00;
        let rightPin: number = rightPinData || 0x00;

        // Line Pair Data
        dataView.setUint16(0, leftPin, false);
        dataView.setUint16(2, rightPin, false);

        esd.data = dataView.getUint32(0, false);
    }

    // Helpers
    private modElectricMiyakiData(data: number,
                                  pinDataOne: ElectricLinePairConfigEnum,
                                  pinDataTwo: ElectricLinePairConfigEnum): number {
        let buffer = new ArrayBuffer(4);
        let dataView = new DataView(buffer);
        dataView.setUint32(0, data, false);

        // Line Pair Data
        dataView.setUint16(0, pinDataOne, false);
        dataView.setUint16(2, pinDataTwo, false);

        return dataView.getUint32(0, false);
    }

    private getEsdLangLines(prefix: string, data: any): string[] {
        let objArr = data[prefix];
        let finishedArr: string[] = [];
        Object.keys(objArr).forEach((value: string) => {
            finishedArr.push(prefix + '.' + value);
        });
        return finishedArr;
    }

    private isElectricPairDuplicated(esd: ConnectionEsdElectric, newPin: number): boolean {
        let isDuplicated: boolean = false;
        let availableEsds: any[] = this.selectedConfiguration.availableEsds;

        for (let availableEsd of availableEsds) {
            if (availableEsd != esd) {
                isDuplicated = (availableEsd.data == newPin);
                if (isDuplicated) {
                    break;
                }
            }
        }

        return isDuplicated;
    }

    // Service Calls
    private serviceGetEsdLangLines(): Subscription {
        return this._languageService
            .getLanguageLinesByGroup('SSL_ESD')
            .subscribe((data: any) => {
                this.esdLangLines = this.getEsdLangLines('SSL_ESD', data);
            });
    }

    private serviceGetEsdSubTextLangLines(): Subscription {
        return this._languageService
            .getLanguageLinesByGroup('SSL_ESD_SUBTEXT')
            .subscribe((data: any) => {
                this.esdSubTextLangLines = this.getEsdLangLines('SSL_ESD_SUBTEXT', data);
            });
    }

    private serviceGetEsdsSubTextLangLines(): Subscription {
        return this._languageService
            .getLanguageLinesByGroup('SSL_ESDS_SUBTEXT')
            .subscribe((data: any) => {
                this.esdsSubTextLangLines = this.getEsdLangLines('SSL_ESDS_SUBTEXT', data);
            });
    }
}
