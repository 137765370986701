import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';

import { HistoryItem } from '../models/history-item.model';

@Injectable()
export class HistoryService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl = '/assets/mock/mod_esds/history-items.json';
    }

    public getHistoryItems(): Observable<HistoryItem[]> {
        return this._http
            .get<HistoryItem[]>(this.actionUrl,
                this.getRequestOptions());
    }

    public websocketGetNewHistoryItem(): Observable<HistoryItem> {
        return this._ws.get('/wsapi/esds/history-item');
    }

    public deleteClearHistory(): Observable<HttpResponse<string>> {
        return this._http.delete(this.actionUrl,
            this.getFullRawRequestOptions());
    }
}
