import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Pattern } from '../../../models/pattern.model';
import { Subscription } from 'rxjs';
import { PatternService } from '../../../services/pattern.service';

@Component({
    selector: 'mlm-operations-load-pattern-modal',
    styleUrls: ['./load-pattern.component.css'],
    templateUrl: './load-pattern.component.html'
})
export class MlmOperationsLoadPatternModalComponent {

    @ViewChild('loadPatternModal') public loadPatternModal: ModalDirective;

    @Output() public onSelect: EventEmitter<Pattern> = new EventEmitter<Pattern>();

    public rows: Pattern[] = [];
    public selectedPattern: Pattern;

    constructor(
        private _patternService: PatternService
    ) {}

    public open(): void {
        this.selectedPattern = undefined;
        this.loadPatternModal.show();
        this.serviceGetPatterns();
    }

    public loadPattern(): void {
        this.loadPatternModal.hide();
        this.onSelect.emit(this.selectedPattern);
    }

    // Template Helpers
    public selectPattern(selectedPattern: Pattern): void {
        this.selectedPattern = selectedPattern;
    }

    // Service Calls
    public serviceGetPatterns(): Subscription {
        return this._patternService
            .getAll()
            .subscribe((data: Pattern[]) => {
                this.rows = data;
            });
    }
}
