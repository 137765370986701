import { ElectricLoopbackTest } from './electric-loopback-test.model';

export class ElectricLoopbackResult {

    public systemId: string;
    public availableTests: ElectricLoopbackTest[];
    public isMiyaki: boolean;
    public isRun: boolean;
    public isFinished: boolean;

    constructor(data: any) {
        this.systemId = data.systemId || undefined;
        this.availableTests = data.availableTests || [];

        // Workaround because of boolean type (Silly JS)
        this.isMiyaki = false;
        if (data.isMiyaki != null) {
            this.isMiyaki = data.isMiyaki;
        }

        this.isRun = false;
        if (data.isRun != null) {
            this.isRun = data.isRun;
        }

        this.isFinished = false;
        if (data.isFinished != null) {
            this.isFinished = data.isFinished;
        }
    }
}
