import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';

import { CommonAlarm } from '../models/common-alarm.model';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';

@Injectable()
export class CommonAlarmService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl = '/assets/mock/mod_esds/common-alarm.json';
    }

    public getCommonAlarm(): Observable<CommonAlarm> {
        return this._http
            .get<CommonAlarm>(this.actionUrl,
                this.getRequestOptions());
    }

    public websocketGetCommonAlarm(): Observable<CommonAlarm> {
        return this._ws.get('/wsapi/esds/common-alarm');
    }
}
