import { Routes } from '@angular/router';

// Root Components
import { HomeComponent } from './home/home.component';
import { SettingsComponent } from './settings/settings.component';
import { NoContentComponent } from './no-content/no-content.component';
import { SystemErrorComponent } from './system-error/system-error.component';

export const ROUTES: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'settings', component: SettingsComponent },
    { path: 'system-error', component: SystemErrorComponent },
    { path: 'system-error/:id', component: SystemErrorComponent },
    { path: 'esds', loadChildren: './mod_esds/mod_esds.module#EsdsModule'},
    { path: 'mlm', loadChildren: './mod_mlm/mod_mlm.module#MlmModule'},
    { path: 'usl', loadChildren: './mod_usl/mod_usl.module#UslModule'},
    { path: 'ssl', loadChildren: './mod_ssl/mod_ssl.module#SslModule'},
    { path: 'remote', loadChildren: './mod_remote/mod_remote.module#RemoteModule'},
    { path: '404', component: NoContentComponent },
    { path: '**', component: NoContentComponent }
];
