export enum SafetyEventType {
    SystemActivating = 0x00,
    SystemActivated = 0x01,
    SystemActivatingFailed = 0x02,
    SystemDeactivating = 0x10,
    SystemDeactivated = 0x11,
    SystemDeactivatingFailed = 0x12,
    TerminalActivating = 0x20,
    TerminalActivated = 0x21,
    TerminalActivatingFailed = 0x22,
    JettyActivating = 0x23,
    JettyActivated = 0x24,
    JettyActivatingFailed = 0x25,
    TerminalDeactivating = 0x30,
    TerminalDeactivated = 0x31,
    TerminalDeactivatingFailed = 0x32,
    JettyDeactivating = 0x33,
    JettyDeactivated = 0x34,
    JettyDeactivatingFailed = 0x35,
    BoardsFound = 0xF0,
    BoardsLost = 0xF1,
}
