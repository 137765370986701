import { PatternJoin } from './pattern-join.model';
export class Pattern {

    public id: string;
    public jettyId: string;
    public name: string;
    public patternJoins: PatternJoin[];

    constructor(data: any) {
        this.id = data.id || undefined;
        this.jettyId = data.jettyId || undefined;
        this.name = data.name || undefined;
        this.patternJoins = data.patternJoins || [];
    }
}
