import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'favouriteOnly'
})
export class FavouriteOnlyPipe implements PipeTransform {

    public transform(array: any[], isFavourite: boolean, property: string): any {
        if (isFavourite) {
            return array.filter((row) => row[property] == true);
        }

        return array;
    }
}
