import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { ScreenNotification } from '../../models/system/screen-notification.model';
import { WebSocketService } from '../websocket/websocket.service';
import { Paged } from '../../models/common/paged.model';

@Injectable()
export class ScreenService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'system/screen';
    }

    public getUnreadNotifications(orderByField: string,
                                  isDesc: boolean,
                                  pageIndex: number = 1,
                                  pageSize: number = 12): Observable<Paged<ScreenNotification>> {
        if (isDesc) {
            orderByField += '_DESC';
        }

        return this._http
            .get<Paged<ScreenNotification>>(this.actionUrl + '/notifications/unread' +
                '?orderByField=' + orderByField +
                '&pageIndex=' + pageIndex +
                '&pageSize=' + pageSize,
                this.getRequestOptions());
    }

    public getReadNotifications(orderByField: string,
                                isDesc: boolean,
                                pageIndex: number = 1,
                                pageSize: number = 12): Observable<Paged<ScreenNotification>> {
        if (isDesc) {
            orderByField += '_DESC';
        }

        return this._http
            .get<Paged<ScreenNotification>>(this.actionUrl + '/notifications/read' +
                '?orderByField=' + orderByField +
                '&pageIndex=' + pageIndex +
                '&pageSize=' + pageSize,
                this.getRequestOptions());
    }

    public getMarkNotificationRead(notificationId: string): Observable<HttpResponse<string>> {
        return this._http
            .get(this.actionUrl + '/notifications/read/' + notificationId,
                this.getFullRawRequestOptions());
    }

    public getMarkAllNotificationRead(): Observable<HttpResponse<string>> {
        return this._http
            .get(this.actionUrl + '/notifications/read/all',
                this.getFullRawRequestOptions());
    }

    public webSocketGetNotifications(): Observable<ScreenNotification> {
        return this._ws.get('/wsapi/system/notifications');
    }
}
