import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';
import { Terminal } from '../models/terminal.model';
import { TerminalDefault } from '../models/terminal-default.model';

@Injectable()
export class TerminalService extends BaseService {

    constructor(
        _http: HttpClient
    ) {
        super(_http);
        this.actionUrl += 'ssl/terminal';
    }

    public getAllShipTerminals(): Observable<Terminal[]> {
        return this._http
            .get<Terminal[]>(this.actionUrl + '/ship', this.getRequestOptions());
    }

    public getAllShoreTerminals(): Observable<Terminal[]> {
        return this._http
            .get<Terminal[]>(this.actionUrl + '/shore', this.getRequestOptions());
    }

    public getTerminalDefaults(): Observable<TerminalDefault> {
        return this._http
            .get<TerminalDefault>(this.actionUrl + '/defaults', this.getRequestOptions());
    }

    public getTerminal(id: string): Observable<Terminal> {
        return this._http
            .get<Terminal>(this.actionUrl + '/' + id, this.getRequestOptions());
    }

    public setCurrentTerminal(newCurrentTerminal: Terminal): Observable<Terminal> {
        return this._http
            .post<Terminal>(this.actionUrl + '/current',
                JSON.stringify(newCurrentTerminal),
                this.getRequestOptions());
    }

    public unsetCurrentTerminal(): Observable<HttpResponse<string>> {
        return this._http
            .post(this.actionUrl + '/current/unset',
                JSON.stringify({}),
                this.getFullRawRequestOptions());
    }

    public add(terminal: Terminal): Observable<Terminal> {
        return this._http
            .post<Terminal>(this.actionUrl,
                JSON.stringify(terminal),
                this.getRequestOptions());
    }

    public update(updatedTerminal: Terminal): Observable<Terminal> {
        return this._http
            .put<Terminal>(this.actionUrl + '/' + updatedTerminal.id,
                JSON.stringify(updatedTerminal),
                this.getRequestOptions());
    }

    public delete(terminalToDelete: Terminal): Observable<HttpResponse<string>> {
        return this._http
            .delete(this.actionUrl + '/' + terminalToDelete.id,
                this.getFullRawRequestOptions());
    }
}
