import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { RemoteScreen } from '../../models/system/remote-screen.model';

@Injectable()
export class RemoteScreenService extends BaseService {

    constructor(
        _http: HttpClient,
    ) {
        super(_http);
        this.actionUrl += 'system/remotescreen';
    }

    public getRemoteScreens(): Observable<RemoteScreen[]> {
        return this._http
            .get<RemoteScreen[]>(this.actionUrl,
                this.getRequestOptions());
    }

    public getRemoteScreen(screenId: string): Observable<RemoteScreen> {
        return this._http
            .get<RemoteScreen>(this.actionUrl + '/' + screenId,
                this.getRequestOptions());
    }
}
