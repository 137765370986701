import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';
import { share, retry, finalize } from 'rxjs/operators';
import { WebSocketSubjectConfig, webSocket } from 'rxjs/webSocket';

import { BaseService } from '../base.service';

@Injectable()
export class WebSocketService extends BaseService {

    private connectedSocketSubjects: { [uri: string]: Observable<any> } = {};

    constructor(_http: HttpClient) {
        super(_http);
    }

    public get<T>(uri: string): Observable<T> {
        return this.getWebsocketObservable<T>(this.webSocketUrl + uri, false, 0);
    }

    public getFullPath<T>(uri: string): Observable<T> {
        return this.getWebsocketObservable<T>(uri, false, 0);
    }

    public getWithRetry<T>(uri: string, retryCount: number): Observable<T> {
        return this.getWebsocketObservable<T>(this.webSocketUrl + uri, true, retryCount);
    }

    public getFullPathWithRetry<T>(uri: string, retryCount: number): Observable<T> {
        return this.getWebsocketObservable<T>(uri, true, retryCount);
    }

    private getWebsocketObservable<T>(uri: string,
                                      hasRetry: boolean,
                                      retryCount: number): Observable<T> {
        if (this.connectedSocketSubjects[uri]) {
            return this.connectedSocketSubjects[uri];
        } else {
            const config: WebSocketSubjectConfig<T> = {
                url: uri,
                WebSocketCtor: WebSocket,
            };

            let sharedSocket = webSocket<T>(config).pipe(
                finalize(() => {
                    this.connectedSocketSubjects[uri] = undefined;
                }),
                share()
            );
            if (hasRetry && retryCount > 0) {
                sharedSocket = sharedSocket.pipe(retry(retryCount));
            } else if (hasRetry) {
                sharedSocket = sharedSocket.pipe(retry());
            }

            this.connectedSocketSubjects[uri] = sharedSocket;
            return sharedSocket;
        }
    }
}
