import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';

import { Effect } from '../models/effect.model';
import { MockDbService } from '../../shared/services/system/mock-db.service';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';
import { WebsocketEffect } from '../models/websocket/effect.model';

@Injectable()
export class EffectService extends BaseService {

    constructor(
        _http: HttpClient,
        private _db: MockDbService,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl = '/assets/mock/mod_esds/effects.json';
    }

    public getAllEffects(): Observable<Effect[]> {
        return new Observable<Effect[]>((subscriber: Subscriber<Effect[]>) => {
            this._http
                .get<Effect[]>(this.actionUrl,
                    this.getRequestOptions())
                .subscribe((data: Effect[]) => {
                    this._db.effects = data;
                    subscriber.next(data);
                    subscriber.complete();
                });
        });
    }

    public getEffectsByIds(ids: number[]): Observable<Effect[]> {
        return new Observable<Effect[]>((subscriber: Subscriber<Effect[]>) => {
            this.getAllEffects()
                .subscribe((data: Effect[]) => {
                    const found: Effect[] = [];

                    for (const val of data) {
                        if (ids.indexOf(val.id) !== -1) {
                            found.push(val);
                        }
                    }

                    if (found) {
                        subscriber.next(found);
                    } else {
                        subscriber.error('Effects Not Found');
                    }
                    subscriber.complete();
                });
        });
    }

    public websocketGetEffects(): Observable<WebsocketEffect> {
        return this._ws.get('/wsapi/esds/effects');
    }
}
