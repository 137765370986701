import { InstallationTypeEnum } from '../../enums/installation-type.enum';

export class BrainConfiguration {

    public id: string;
    public installationType: InstallationTypeEnum;
    public installationName: string;
    public shipImageB64: string;
    public shipImageWidth: number;
    public shipImageHeight: number;
    public serialNumber: string;
    public ntpServers: string;
    public canSetNtpServerDatetime: boolean;

    constructor(data: BrainConfiguration) {
        this.id = data.id || undefined;
        this.installationType = data.installationType;
        this.installationName = data.installationName || '';
        this.shipImageB64 = data.shipImageB64 || '';
        this.shipImageWidth = data.shipImageWidth;
        this.shipImageHeight = data.shipImageHeight;
        this.serialNumber = data.serialNumber;
        this.ntpServers = data.ntpServers;

        // Workaround because of boolean type (Silly JS)
        this.canSetNtpServerDatetime = false;
        if (data.canSetNtpServerDatetime != null) {
            this.canSetNtpServerDatetime = data.canSetNtpServerDatetime;
        }
    }
}
