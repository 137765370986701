import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { SslConfiguration } from '../../../models/ssl-configuration.model';
import { EsdSystem } from '../../../../shared/models/esd/esd-system.model';
import { ConnectionTypeEnum } from '../../../../shared/enums/connection-type.enum';
import { EsdService } from '../../../../shared/services/esd/esd.service';
import {
    LocalboardConfiguration
} from '../../../../shared/models/common/localboard-configuration.model';

@Component({
    selector: 'ssl-shared-control-area',
    styleUrls: ['control-area.component.css'],
    templateUrl: 'control-area.component.html'
})
export class SslOperationsSharedControlAreaComponent {

    @Input() public esdSystem: EsdSystem;
    @Input() public sslConfig: SslConfiguration;
    @Input() public localboardConfig: LocalboardConfiguration;

    public connectionTypeEnum = ConnectionTypeEnum;

    constructor(
        private _esdService: EsdService
    ) {}

    public confirmDoInhibit(id: string): void {
        this.serviceDoInhibit(id);
    }

    public confirmDoCancelInhibit(id: string): void {
        this.serviceDoCancelInhibit(id);
    }

    public confirmReset(id: string): void {
        this.serviceDoReset(id);
    }

    public confirmAcceptTrips(id: string): void {
        this.serviceDoAcceptTrips(id);
    }

    public doBeginFibreLoopbackTest(id: string, isShore: boolean): void {
        this.serviceDoBeginFibreLoopbackTest(id, isShore);
    }

    public doEndFibreLoopbackTest(id: string, isShore: boolean): void {
        this.serviceDoEndFibreLoopbackTest(id, isShore);
    }

    public doBeginElectricLoopbackTest(id: string, isMiyaki: boolean): void {
        this.serviceDoBeginElectricLoopbackTest(id, isMiyaki);
    }

    public doEndElectricLoopbackTest(id: string, isMiyaki: boolean): void {
        this.serviceDoEndElectricLoopbackTest(id, isMiyaki);
    }

    // Service Calls
    private serviceDoInhibit(systemId: string): Subscription {
        return this._esdService.putInhibit(systemId, true).subscribe();
    }

    private serviceDoCancelInhibit(systemId: string): Subscription {
        return this._esdService.putInhibit(systemId, false).subscribe();
    }

    private serviceDoReset(systemId: string): Subscription {
        return this._esdService.putReset(systemId).subscribe();
    }

    private serviceDoAcceptTrips(systemId: string): Subscription {
        return this._esdService.putAcceptTrips(systemId).subscribe();
    }

    private serviceDoBeginFibreLoopbackTest(systemId: string, isShore: boolean): Subscription {
        return this._esdService.putFibreLoopbackTest(systemId, isShore, true).subscribe();
    }

    private serviceDoEndFibreLoopbackTest(systemId: string, isShore: boolean): Subscription {
        return this._esdService.putFibreLoopbackTest(systemId, isShore, false).subscribe();
    }

    private serviceDoBeginElectricLoopbackTest(systemId: string, isMiyaki: boolean): Subscription {
        return this._esdService.putElectricLoopbackTest(systemId, isMiyaki, true).subscribe();
    }

    private serviceDoEndElectricLoopbackTest(systemId: string, isMiyaki: boolean): Subscription {
        return this._esdService.putElectricLoopbackTest(systemId, isMiyaki, false).subscribe();
    }
}
