import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';
import { Pattern } from '../models/pattern.model';

@Injectable()
export class PatternService extends BaseService {

    constructor(
        _http: HttpClient
    ) {
        super(_http);
        this.actionUrl += 'mlm/pattern';
    }

    public getAll(): Observable<Pattern[]> {
        return this._http
            .get<Pattern[]>(this.actionUrl, this.getRequestOptions());
    }

    public get(id: string): Observable<Pattern> {
        return this._http
            .get<Pattern>(this.actionUrl + '/' + id,
                this.getRequestOptions());
    }

    public getCurrent(): Observable<Pattern> {
        return this._http
            .get<Pattern>(this.actionUrl + '/current',
                this.getRequestOptions());
    }

    public setCurrent(newCurrent: Pattern): Observable<Pattern> {
        return this._http
            .post<Pattern>(this.actionUrl + '/current', JSON.stringify(newCurrent),
                this.getRequestOptions());
    }

    public add(pattern: Pattern): Observable<Pattern> {
        return this._http
            .post<Pattern>(this.actionUrl,
                JSON.stringify(pattern),
                this.getRequestOptions());
    }

    public update(updatedVal: Pattern): Observable<Pattern> {
        return this._http
            .put<Pattern>(this.actionUrl,
                JSON.stringify(updatedVal),
                this.getRequestOptions());
    }

    public delete(patternToDelete: Pattern): Observable<HttpResponse<string>> {
        return this._http
            .delete(this.actionUrl + '/' + patternToDelete.id,
                this.getFullRawRequestOptions());
    }
}
