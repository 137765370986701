import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, interval } from 'rxjs';

import { PageConfigService } from '../shared/services/system/page-config.service';
import { ModuleTypeEnum } from '../shared/enums/module-type.enum';
import { ConfigService } from './services/config.service';
import { PersistenceService } from '../shared/services/system/persistence.service';
import { EsdsConfiguration } from './models/esds-configuration.model';
import { CommonAlarm } from './models/common-alarm.model';
import { CommonAlarmService } from './services/common-alarm.service';
import { StatusTypeEnum } from './enums/status-type.enum';
import { CONFIGURATION } from '../app.constants';

@Component({
    selector: 'esds',
    templateUrl: './mod_esds.component.html',
    styleUrls: ['./mod_esds.component.css']
})
export class EsdsComponent implements OnInit, OnDestroy {

    public commonAlarm: CommonAlarm = undefined;
    public statusTypeEnum = StatusTypeEnum;

    private webSockets: Subscription[] = [];
    private pollTimer: Subscription = undefined;

    constructor(
        private _configService: PageConfigService,
        private _esdsService: ConfigService,
        private _persistence: PersistenceService,
        private _commonAlarmService: CommonAlarmService
    ) {}

    public ngOnInit(): void {
        this._esdsService
            .getConfig()
            .subscribe((data: EsdsConfiguration) => {
                this._configService.setCurrentContext(ModuleTypeEnum.ESDS);
                this._persistence.esdsConfiguration.next(data);
            });

        this.webSockets = [];
        this.webSockets.push(this._esdsService
            .websocketGetConfig()
            .subscribe((data: EsdsConfiguration) => {
                this._persistence.esdsConfiguration.next(data);
            }));
        this.webSockets.push(this.websocketGetCommonAlarm());
        this.serviceGetCommonAlarm().add(() => {
            this.startPolling();
        });
    }

    public ngOnDestroy(): void {
        for (const val of this.webSockets) {
            if (val) {
                val.unsubscribe();
            }
        }

        this.webSockets = undefined;
        this.stopPolling();
    }

    // Helper methods
    private startPolling(): void {
        if (this.webSockets === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                this.serviceGetCommonAlarm().add(() => {
                    this.startPolling();
                });
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    // Service calls
    private websocketGetCommonAlarm(): Subscription {
        return this._commonAlarmService
            .websocketGetCommonAlarm()
            .subscribe((data: CommonAlarm) => {
                this.commonAlarm = data;
            }, () => {
                if (this.commonAlarm) {
                    this.commonAlarm.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }

    private serviceGetCommonAlarm(): Subscription {
        return this._commonAlarmService
            .getCommonAlarm()
            .subscribe((data: CommonAlarm) => {
                this.commonAlarm = data;
            }, () => {
                if (this.commonAlarm) {
                    this.commonAlarm.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }
}
