import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';
import { EnvironmentalOption } from '../models/environmental-option.model';
import { LoadOption } from '../models/load-option.model';
import { Shape } from '../models/shape.model';
import { Hook } from '../models/hook.model';
import { Dolphin } from '../models/dolphin.model';
import { EnvironmentalData } from '../models/environmental-data.model';
import { DasOption } from '../models/das-option.model';
import { DasData } from '../models/das-data.model';
import { PollStatus } from '../models/poll-status.model';
import { HandshakeState } from '../models/handshake-state.model';

@Injectable()
export class OperationService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'mlm/operation';
    }

    public getHooks(): Observable<Hook[]> {
        return this._http
            .get<Hook[]>(this.actionUrl + '/hook', this.getRequestOptions());
    }

    public getDolphins(): Observable<Dolphin[]> {
        return this._http
            .get<Dolphin[]>(this.actionUrl + '/dolphin', this.getRequestOptions());
    }

    public getLoadOptions(): Observable<LoadOption> {
        return this._http
            .get<LoadOption>(this.actionUrl + '/load-option', this.getRequestOptions());
    }

    public getEnvironmentalOptions(): Observable<EnvironmentalOption> {
        return this._http
            .get<EnvironmentalOption>(this.actionUrl + '/environmental-option',
                this.getRequestOptions());
    }

    public getEnvironmentalData(): Observable<EnvironmentalData[]> {
        return this._http
            .get<EnvironmentalData[]>(this.actionUrl + '/environmental-data',
                this.getRequestOptions());
    }

    public getDasOptions(): Observable<DasOption> {
        return this._http
            .get<DasOption>(this.actionUrl + '/das-option', this.getRequestOptions());
    }

    public getDasData(): Observable<DasData[]> {
        return this._http
            .get<DasData[]>(this.actionUrl + '/das-data', this.getRequestOptions());
    }

    public getShapes(): Observable<Shape[]> {
        return this._http
            .get<Shape[]>(this.actionUrl + '/shape', this.getRequestOptions());
    }

    public getPollStatus(): Observable<PollStatus> {
        return this._http
            .get<PollStatus>(this.actionUrl + '/poll-status', this.getRequestOptions());
    }

    public getHandshakeState(): Observable<HandshakeState> {
        return this._http
            .get<HandshakeState>(this.actionUrl + '/handshake-state', this.getRequestOptions());
    }

    public putAlarmAcknowledge(): Observable<HttpResponse<string>> {
        return this._http
            .put<HttpResponse<string>>(this.actionUrl + '/alarm-acknowledge', undefined,
                this.getRequestOptions());
    }

    public putPollNow(): Observable<HttpResponse<string>> {
        return this._http
            .put<HttpResponse<string>>(this.actionUrl + '/poll-now', undefined,
                this.getRequestOptions());
    }

    public websocketGetHooks(): Observable<Hook> {
        return this._ws.get('/wsapi/mlm/hook');
    }

    public websocketGetEnvironmentalData(): Observable<EnvironmentalData> {
        return this._ws.get('/wsapi/mlm/environmental');
    }

    public websocketGetDasData(): Observable<DasData> {
        return this._ws.get('/wsapi/mlm/das');
    }

    public websocketGetPollStatus(): Observable<PollStatus> {
        return this._ws.get('/wsapi/mlm/poll-status');
    }

    public websocketGetHandshakeState(): Observable<HandshakeState> {
        return this._ws.get('/wsapi/mlm/handshake-state');
    }
}
