import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    public transform(value: string, inLimit: number, inTrail: string): any {
        let limit: number = (inLimit == undefined || inLimit == null) ? 10 : inLimit;
        let trail: string =  (inTrail == undefined || inTrail == null) ? '…' : inTrail;

        return value.length > limit ? value.substring(0, limit).trim() + trail : value;
    }
}
