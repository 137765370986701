export class Debounce {

    public static debounce(func: any, wait: number, immediate?: any): any {
        let timeout: any;
        return function () {
            const context = this;
            const args = arguments;
            const later = () => {
                timeout = undefined;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    }

}
