import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

const START_YEAR: number = 1970;

@Component({
    selector: 'date-picker-dialog-modal',
    styleUrls: ['./date-picker.component.css'],
    templateUrl: './date-picker.component.html'
})
export class DatePickerDialogModalComponent {

    @Input() public title: string;
    @Input() public currentDate: number;

    @Output() public dateSelected: EventEmitter<number> = new EventEmitter<number>();

    public dayNumber: number = 0;
    public monthNumber: number = 0;
    public yearNumber: number = START_YEAR;
    public daysInMonth: number[] = [];
    public monthsInYear: number[] = [];
    public yearsCount: number[] = [];

    @ViewChild('datePickerModal') public datePickerModal: ModalDirective;

    constructor() {
        this.monthsInYear = Array(12).fill(12).map((x, i) => i);
    }

    public open(): void {
        this.initialiseTimes();
        this.datePickerModal.show();
    }

    // Template helpers
    public selectDatePressed(): void {
        const timestamp = new Date(this.yearNumber, this.monthNumber, this.dayNumber).getTime();
        this.datePickerModal.hide();
        this.dateSelected.emit(timestamp);
    }

    public updateDaysInMonth(): void {
        const dayCount = new Date(this.yearNumber, this.monthNumber + 1, 0)
            .getUTCDate();
        this.daysInMonth = new Array(dayCount).fill(dayCount).map((x, i) => i + 1);

        if (this.dayNumber > dayCount) {
            this.dayNumber = dayCount;
        }
    }

    public modifyDay(sign: string): void {
        const index = this.daysInMonth.indexOf(this.dayNumber);

        if (sign == '+') {
            if (index + 1 > (this.daysInMonth.length - 1)) {
                this.modifyMonth(sign);
                this.dayNumber = this.daysInMonth[0];
            } else {
                this.dayNumber = this.daysInMonth[index + 1];
            }
        } else {
            if (index - 1 < 0) {
                this.modifyMonth(sign);
                this.dayNumber = this.daysInMonth[this.daysInMonth.length - 1];
            } else {
                this.dayNumber = this.daysInMonth[index - 1];
            }
        }
    }

    public modifyMonth(sign: string): void {
        const index = this.monthsInYear.indexOf(this.monthNumber);

        if (sign == '+') {
            if (index + 1 > (this.monthsInYear.length - 1)) {
                this.modifyYear(sign);
                this.monthNumber = this.monthsInYear[0];
            } else {
                this.monthNumber = this.monthsInYear[index + 1];
            }
        } else {
            if (index - 1 < 0) {
                this.modifyYear(sign);
                this.monthNumber = this.monthsInYear[this.monthsInYear.length - 1];
            } else {
                this.monthNumber = this.monthsInYear[index - 1];
            }
        }

        this.updateDaysInMonth();
    }

    public modifyYear(sign: string): void {
        const index = this.yearsCount.indexOf(this.yearNumber);

        if (sign == '+') {
            this.yearNumber = (index + 1 > (this.yearsCount.length - 1)) ?
                this.yearsCount[0] : this.yearsCount[index + 1];
        } else {
            this.yearNumber = (index - 1 < 0) ?
                this.yearsCount[this.yearsCount.length - 1] : this.yearsCount[index - 1];
        }

        this.updateDaysInMonth();
    }

    // Helpers
    private initialiseTimes(): void {
        const dateNow = new Date();
        const date = (this.currentDate) ? new Date(this.currentDate) : dateNow;
        this.yearNumber = date.getUTCFullYear();
        this.monthNumber = date.getUTCMonth();
        this.dayNumber = date.getUTCDate();
        const yearCount = dateNow.getUTCFullYear() - START_YEAR + 1;
        this.yearsCount = new Array(yearCount).fill(yearCount).map((x, i) => (i + START_YEAR));
        this.updateDaysInMonth();
    }
}
