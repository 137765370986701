import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageConfigService } from '../shared/services/system/page-config.service';
import { PageConfig } from '../shared/models/system/page-config.model';
import { ModuleTypeEnum } from '../shared/enums/module-type.enum';
import { PersistenceService } from '../shared/services/system/persistence.service';
import { SystemConfiguration } from '../shared/models/system/configuration.model';
import { SystemConfigurationService } from '../shared/services/system/configuration.service';
import { Response } from '@angular/http';

@Component({
    selector: 'home',
    styleUrls: ['./home.component.css'],
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {

    public isLoading: boolean = true;
    public moduleTypeEnum = ModuleTypeEnum;

    private currentConfiguration: SystemConfiguration;
    private routerSub: Subscription;
    private persistenceSub: Subscription;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _configService: PageConfigService,
        private _persistence: PersistenceService,
        private _systemService: SystemConfigurationService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.isLoading = true;
                this._configService.setCurrentContext(ModuleTypeEnum.NONE);
                this.updateNavigation();

                this.persistenceSub = this._persistence
                    .systemConfiguration
                    .subscribe((data: SystemConfiguration) => {
                        this.currentConfiguration = data;
                        if (data) {
                            this.goToModule(data.bootupModule);
                        }
                        this.isLoading = false;
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.persistenceSub.unsubscribe();
        }
    }

    // Template Helpers
    public setBootupModule(module: ModuleTypeEnum): void {
        this.currentConfiguration.bootupModule = module;
        this.serviceUpdateConfiguration(this.currentConfiguration).add(() => {
            this.goToModule(module);
        });
    }

    public isModuleEnabled(moduleType: ModuleTypeEnum): boolean {
        return (this.currentConfiguration &&
        (this.currentConfiguration.enabledModules.indexOf(moduleType) !== -1));
    }

    // Helper methods
    private updateNavigation(): void {
        this._configService.setConfig(new PageConfig({
            title: 'WELCOME',
            breadcrumbs: []
        }));
    }

    private goToModule(moduleType: ModuleTypeEnum): void {
        switch (moduleType) {
            case ModuleTypeEnum.ESDS:
                this._router.navigate(['/esds']);
                break;
            case ModuleTypeEnum.SSL:
                this._router.navigate(['/ssl']);
                break;
            case ModuleTypeEnum.USL:
                this._router.navigate(['/usl']);
                break;
            case ModuleTypeEnum.MLM:
                this._router.navigate(['/mlm']);
                break;
            default:
                this._router.navigate(['/']);
                break;
        }
    }

    private serviceUpdateConfiguration(updatedConfig: SystemConfiguration): Subscription {
        return this._systemService
            .setBootupModule(updatedConfig)
            .subscribe();
    }
}
