import { NgModule, ModuleWithProviders } from '@angular/core';

// Pipes
import { DataFilterPipe } from './data-filter.pipe';
import { TruncatePipe } from './truncate.pipe';
import { OrderByPipe } from './order-by.pipe';
import { DateToFromFilterPipe } from './date-to-from-filter.pipe';
import { ConnectionTypePipe } from './connection-type.pipe';
import { SystemLocationPipe } from './system-location.pipe';
import { TelecommTypePipe } from './telecomm-type.pipe';
import { SystemLocationShortPipe } from './system-location-short.pipe';
import { FavouriteOnlyPipe } from './favourite-only.pipe';
import { InstallationTypePipe } from './installation-type.pipe';
import { SafePipe } from './safe.pipe';
import { BoardTypePipe } from './board-type.pipe';
import { ElectricLinePair37WayPipe } from './electric-line-pair-37way.pipe';
import { ElectricLinePairIttCannonPipe } from './electric-line-pair-itt-cannon.pipe';
import { ElectricLinePairMiyakiTelPipe } from './electric-line-pair-miyaki-tel.pipe';
import { ElectricLinePairMiyakiEsdPipe } from './electric-line-pair-miyaki-esd.pipe';
import { MacAddressPipe } from './mac-address.pipe';
import { ZeroPadPipe } from './zero-pad.pipe';
import { EslTypePipe } from './esl-type.pipe';
import { ShowHiddenPipe } from './show-hidden.pipe';

@NgModule({
    declarations: [
        DataFilterPipe,
        TruncatePipe,
        OrderByPipe,
        DateToFromFilterPipe,
        ConnectionTypePipe,
        SystemLocationPipe,
        SystemLocationShortPipe,
        TelecommTypePipe,
        FavouriteOnlyPipe,
        InstallationTypePipe,
        SafePipe,
        BoardTypePipe,
        ElectricLinePair37WayPipe,
        ElectricLinePairIttCannonPipe,
        ElectricLinePairMiyakiTelPipe,
        ElectricLinePairMiyakiEsdPipe,
        MacAddressPipe,
        ZeroPadPipe,
        EslTypePipe,
        ShowHiddenPipe,
    ],
    exports: [
        DataFilterPipe,
        TruncatePipe,
        OrderByPipe,
        DateToFromFilterPipe,
        ConnectionTypePipe,
        SystemLocationPipe,
        SystemLocationShortPipe,
        TelecommTypePipe,
        FavouriteOnlyPipe,
        InstallationTypePipe,
        SafePipe,
        BoardTypePipe,
        ElectricLinePair37WayPipe,
        ElectricLinePairIttCannonPipe,
        ElectricLinePairMiyakiTelPipe,
        ElectricLinePairMiyakiEsdPipe,
        MacAddressPipe,
        ZeroPadPipe,
        EslTypePipe,
        ShowHiddenPipe,
    ]
})
export class SharedPipesModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedPipesModule
        };
    }
}
