import { Hook } from './hook.model';

export class MooringLine {

    public patternJoinId: string;
    public hookId: string;
    public hook: Hook;
    public tetherId: string;
    public left: number;
    public top: number;
    public transform: string;
    public width: number;
    public textFlipY: boolean;

    constructor(data: MooringLine) {
        this.patternJoinId = data.patternJoinId || undefined;
        this.hookId = data.hookId || undefined;
        this.hook = data.hook;
        this.tetherId = data.tetherId || undefined;
        this.left = data.left;
        this.top = data.top;
        this.transform = data.transform || undefined;
        this.width = data.width;

        this.textFlipY = false;
        if (data.textFlipY != null) {
            this.textFlipY = data.textFlipY;
        }
    }
}
