import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'showHidden'
})
export class ShowHiddenPipe implements PipeTransform {

    public transform(array: any[], showHidden: boolean, property: string): any {
        return array
            .filter((row) =>
                (row[property] == showHidden) || (row[property] == false));
    }
}
