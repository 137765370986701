import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { Dolphin } from '../../models/dolphin.model';
import { Hook } from '../../models/hook.model';
import { Pattern } from '../../models/pattern.model';
import { LoadOption } from '../../models/load-option.model';
import { AlarmStateEnum } from '../../enums/alarm-state.enum';
import { SystemLocationEnum } from '../../../shared/enums/system-location.enum';

@Component({
    selector: 'mlm-operations-hooks-graph',
    styleUrls: ['./hooks-graph.component.css'],
    templateUrl: './hooks-graph.component.html'
})
export class MlmOperationsHooksGraphComponent implements OnChanges {

    @Input() public shipSide: SystemLocationEnum;
    @Input() public selectedDolphin: Dolphin;
    @Input() public selectedPattern: Pattern;
    @Input() public currentDolphins: Dolphin[];
    @Input() public loadOption: LoadOption;
    @Input() public tonnageDisplayFormat: string;
    @Input() public tonnageUnit: string;
    @Output() public onDolphinSelect: EventEmitter<Dolphin> = new EventEmitter<Dolphin>();

    public tonnageBars: number[] = [];
    public tonnageBarHeight: number = 0;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['loadOption'] && this.loadOption) {
            const barGap: number = this.loadOption.maxTonnage / this.loadOption.tonnageLines;
            this.tonnageBarHeight = 100 / this.loadOption.tonnageLines;

            this.tonnageBars = [];
            for (let i = 1; i <= this.loadOption.tonnageLines; i++) {
                this.tonnageBars.push(+((i * barGap).toFixed(1)));
            }

            this.tonnageBars.sort((a: number, b: number) => {
                return b - a;
            });
        }
    }

    // Template Helpers
    public getStatusClass(hook: Hook): string {
        let finalClass: string = 'stale';
        if (!hook.isStale) {
            switch (hook.alarmState) {
                case AlarmStateEnum.High:
                    finalClass = 'high';
                    break;
                case AlarmStateEnum.Low:
                    finalClass = 'low';
                    break;
                case AlarmStateEnum.Normal:
                    finalClass = 'normal';
                    break;
            }

            if (!hook.isAlarmAcknowledged) {
                finalClass += ' flash sync-animation';
            }
        }

        return finalClass;
    }

    public getDisplayValueRaw(displayFormat: string, value: number, unit: string): string {
        if (displayFormat && displayFormat.includes('##0.0')) {
            return `${displayFormat.replace('##0.0', value.toFixed(1))} ${unit}`;
        } else if (displayFormat && displayFormat.includes('##0')) {
            return `${displayFormat.replace('##0', value.toFixed(0))} ${unit}`;
        } else {
            return `${value.toFixed(0)} ${unit}`;
        }
    }

    public getDisplayValue(hook: Hook): string {
        if (hook.displayFormat && hook.displayFormat.includes('##0.0')) {
            return `${hook.displayFormat.replace('##0.0', hook.pullTonnage.toFixed(1))} ${hook.unit}`;
        } else if (hook.displayFormat && hook.displayFormat.includes('##0')) {
            return `${hook.displayFormat.replace('##0', hook.pullTonnage.toFixed(0))} ${hook.unit}`;
        } else {
            return `${hook.pullTonnage.toFixed(0)} ${hook.unit}`;
        }
    }

    public selectDolphin(selectedDolphin: Dolphin): void {
        if (this.selectedDolphin == selectedDolphin) {
            this.onDolphinSelect.emit(undefined);
        } else {
            this.onDolphinSelect.emit(selectedDolphin);
        }
    }

    public isHookConnected(hookId: string): boolean {
        let isConnected: boolean = false;

        if (this.selectedPattern && this.selectedPattern.patternJoins) {
            for (const val of this.selectedPattern.patternJoins) {
                if (val.shipSide == this.shipSide && val.hookId == hookId) {
                    isConnected = true;
                    break;
                }
            }
        }

        return isConnected;
    }
}
