import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective, TabsetComponent } from 'ngx-bootstrap';
import { ScreenNotification } from '../../../models/system/screen-notification.model';
import { Paged } from '../../../models/common/paged.model';
import { Subscription } from 'rxjs';
import { MetaService } from '../../../services/system/meta.service';
import { PersistenceService } from '../../../services/system/persistence.service';
import { ScreenToolsService } from '../../../services/screen/screen-tools.service';

@Component({
    selector: 'screen-notification-dialog-modal',
    templateUrl: './screen-notification.component.html',
    styleUrls: ['./screen-notification.component.css']
})
export class ScreenNotificationDialogModalComponent {

    @ViewChild('screenNotificationModal') public screenNotificationModal: ModalDirective;
    @ViewChild('notificationTabs') public screenNotificationTabs: TabsetComponent;
    @Input() public unreadNotifications: Paged<ScreenNotification>;
    @Input() public readNotifications: Paged<ScreenNotification>;
    @Input() public isLoadingNotifications: boolean;
    @Output() public onUnreadSort: EventEmitter<any> = new EventEmitter();
    @Output() public onUnreadPage: EventEmitter<any> = new EventEmitter();
    @Output() public onReadSort: EventEmitter<any> = new EventEmitter();
    @Output() public onReadPage: EventEmitter<any> = new EventEmitter();
    @Output() public onMarkNotificationAsRead: EventEmitter<string> = new EventEmitter<string>();
    @Output() public onMarkAllNotificationsAsRead: EventEmitter<undefined> =
        new EventEmitter<undefined>();

    public isLoading: boolean = false;
    public selectedNotification: ScreenNotification = undefined;
    public currentTimeZone: string = 'UTC';

    private persistenceCurrentTimeZone: Subscription;

    constructor(
        private _persistence: PersistenceService,
    ) {}

    // Template helpers
    public open(): void {
        this.persistenceCurrentTimeZone = this._persistence
            .currentTimeZoneIANA
            .subscribe((data: string) => {
                this.currentTimeZone = data;
            });

        this.screenNotificationTabs.tabs[0].active = true;
        this.screenNotificationModal.show();
        this.onTabChange();
    }

    public close(): void {
        this.persistenceCurrentTimeZone.unsubscribe();
        this.onUnreadPage.emit({
            offset: 0,
        });
        this.onReadPage.emit({
            offset: 0,
        });
        this.onUnreadSort.emit({
            column: {
                prop: 'timestamp'
            },
            newValue: 'desc'
        });
        this.onReadSort.emit({
            column: {
                prop: 'timestamp'
            },
            newValue: 'desc'
        });
        this.screenNotificationModal.hide();
    }

    public onTabChange(): void {
        this.isLoading = true;
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            this.isLoading = false;
        }, 250);
    }

    public selectNotification(notification: any): void {
        this.selectedNotification = notification.selected[0];
    }

    public goMarkNotificationAsRead(notification: ScreenNotification): void {
        this.onMarkNotificationAsRead.next(notification.id);
    }

    public goMarkAllNotifications(): void {
        this.onMarkAllNotificationsAsRead.next(undefined);
    }
}
