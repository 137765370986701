export class LocalboardConfiguration {

    public id: string;
    public fibreAnalogueEsdSignalMin: number;
    public fibreAnalogueEsdSignalWarn: number;
    public fibreAnalogueEsdSignalMax: number;
    public fibreAnalogueTelSignalMin: number;
    public fibreAnalogueTelSignalWarn: number;
    public fibreAnalogueTelSignalMax: number;
    public fibreDigitalBitErrorMin: number;
    public fibreDigitalBitErrorWarn: number;
    public fibreDigitalBitErrorMax: number;
    public telecommsVoltageMultiplier: number;
    public upperTelecommsVoltageMultiplier: number;
    public isPsuOneEnabled: boolean;
    public isPsuTwoEnabled: boolean;
    public isPsuThreeEnabled: boolean;
    public isTemperatureOneEnabled: boolean;
    public isTemperatureTwoEnabled: boolean;

    constructor(data: any) {
        this.id = data.id;
        this.fibreAnalogueEsdSignalMin = data.fibreAnalogueEsdSignalMin;
        this.fibreAnalogueEsdSignalWarn = data.fibreAnalogueEsdSignalWarn;
        this.fibreAnalogueEsdSignalMax = data.fibreAnalogueEsdSignalMax;
        this.fibreAnalogueTelSignalMin = data.fibreAnalogueTelSignalMin;
        this.fibreAnalogueTelSignalWarn = data.fibreAnalogueTelSignalWarn;
        this.fibreAnalogueTelSignalMax = data.fibreAnalogueTelSignalMax;
        this.fibreDigitalBitErrorMin = data.fibreDigitalBitErrorMin;
        this.fibreDigitalBitErrorWarn = data.fibreDigitalBitErrorWarn;
        this.fibreDigitalBitErrorMax = data.fibreDigitalBitErrorMax;
        this.telecommsVoltageMultiplier = data.telecommsVoltageMultiplier;
        this.upperTelecommsVoltageMultiplier = data.upperTelecommsVoltageMultiplier;
        this.isPsuOneEnabled = data.isPsuOneEnabled;
        this.isPsuTwoEnabled = data.isPsuTwoEnabled;
        this.isPsuThreeEnabled = data.isPsuThreeEnabled;
        this.isTemperatureOneEnabled = data.isTemperatureOneEnabled;
        this.isTemperatureTwoEnabled = data.isTemperatureTwoEnabled;
    }
}
