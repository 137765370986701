import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'zeroPad'
})
export class ZeroPadPipe implements PipeTransform {

    public transform(value: string,
                     padCount: number,
                     padCharacter: string = '0'): string {
        let prePadded = value + '';
        let padded = new Array(1 + padCount).join(padCharacter);
        return (padded + prePadded).slice(-padded.length);
    }
}
