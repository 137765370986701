import { ElectricLinePairConfigEnum } from '../../shared/enums/electric-line-pair-config.enum';

export class TerminalDefault {

    public id: string;
    public isEtuEnabled: boolean;
    public etuPins: ElectricLinePairConfigEnum;
    public isMlmTxRxInvert: boolean;
    public isContLinkInput: boolean;
    public isExchangeEnabled: boolean;
    public isPrivateLine: boolean;
    public inhibitIndex: number;
    public resetIndex: number;
    public firstUpIndex: number;
    public localInputIndex: number;
    public localOutputIndex: number;
    public continuityLinkIndex: number;

    constructor(data: any) {
        this.id = data.id;
        this.inhibitIndex = data.inhibitIndex;
        this.resetIndex = data.resetIndex;
        this.firstUpIndex = data.firstUpIndex;
        this.localInputIndex = data.localInputIndex;
        this.localOutputIndex = data.localOutputIndex;
        this.continuityLinkIndex = data.continuityLinkIndex;
        this.etuPins = data.etuPins;

        // Workaround because of boolean type (Silly JS)
        this.isEtuEnabled = false;
        if (data.isEtuEnabled != null) {
            this.isEtuEnabled = data.isEtuEnabled;
        }

        this.isMlmTxRxInvert = false;
        if (data.isMlmTxRxInvert != null) {
            this.isMlmTxRxInvert = data.isMlmTxRxInvert;
        }

        this.isContLinkInput = false;
        if (data.isContLinkInput != null) {
            this.isContLinkInput = data.isContLinkInput;
        }

        this.isExchangeEnabled = false;
        if (data.isExchangeEnabled != null) {
            this.isExchangeEnabled = data.isExchangeEnabled;
        }

        this.isPrivateLine = false;
        if (data.isPrivateLine != null) {
            this.isPrivateLine = data.isPrivateLine;
        }
    }
}
