import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ModuleTypeEnum } from '../shared/enums/module-type.enum';
import { PageConfigService } from '../shared/services/system/page-config.service';
import { PersistenceService } from '../shared/services/system/persistence.service';
import { SslConfiguration } from './models/ssl-configuration.model';
import { ConfigService } from './services/config.service';
import { SystemConfiguration } from '../shared/models/system/configuration.model';
import { InstallationTypeEnum } from '../shared/enums/installation-type.enum';
import { Terminal } from './models/terminal.model';
import { TerminalService } from './services/terminal.service';

@Component({
    selector: 'ssl',
    templateUrl: './mod_ssl.component.html',
    styleUrls: ['./mod_ssl.component.css']
})
export class SslComponent implements OnInit, OnDestroy {

    public sslConfig: SslConfiguration;
    public currentInstallationType: InstallationTypeEnum;
    public installationTypeEnum = InstallationTypeEnum;
    public showTerminalsPage: boolean = false;

    private webSockets: Subscription[] = [];

    constructor(
        private _configService: PageConfigService,
        private _terminalService: TerminalService,
        private _sslConfigService: ConfigService,
        private _persistence: PersistenceService
    ) {}

    public ngOnInit(): void {
        this._sslConfigService
            .getConfig()
            .subscribe((data: SslConfiguration) => {
                this._configService.setCurrentContext(ModuleTypeEnum.SSL);
                this._persistence.sslConfiguration.next(data);
            });

        this.webSockets.push(this._sslConfigService
            .websocketGetConfig()
            .subscribe((data: SslConfiguration) => {
                this._persistence.sslConfiguration.next(data);
            }));

        this.webSockets.push(this._persistence
            .sslConfiguration
            .subscribe((data: SslConfiguration) => {
                if (data) {
                    this.sslConfig = data;
                }
            }));

        this.webSockets.push(this._persistence
            .systemConfiguration
            .subscribe((data: SystemConfiguration) => {
                if (data) {
                    this.currentInstallationType =
                        data.systemConfiguration.installationType;

                    switch (this.currentInstallationType) {
                        case InstallationTypeEnum.SHORE:
                            return this.serviceGetShoreTerminals();
                        default:
                        case InstallationTypeEnum.SHIP:
                            return this.showTerminalsPage = true;
                    }
                }
            }));

        this.webSockets.push(this._persistence
            .isDebugToggled
            .subscribe((data) => {
                this._sslConfigService.toggleDebugMode().subscribe();
            }));
    }

    public ngOnDestroy(): void {
        for (let val of this.webSockets) {
            if (val) {
                val.unsubscribe();
            }
        }
    }

    private serviceGetShoreTerminals(): Subscription {
        return this._terminalService
            .getAllShoreTerminals()
            .subscribe((data: Terminal[]) => {
                if (data) {
                    this.showTerminalsPage = data.length > 1;
                } else {
                    this.showTerminalsPage = false;
                }
            });
    }
}
