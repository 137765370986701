export enum ElectricLinePairConfigEnum {
    NOT_CONNECTED = 0x00,
    MIYAKI_1 = 0x01,
    MIYAKI_2 = 0x02,
    MIYAKI_3 = 0x03,
    MIYAKI_4 = 0x04,
    MIYAKI_5 = 0x05,
    MIYAKI_6 = 0x06,
    MIYAKI_B2_1_2 = 0x01,
    MIYAKI_B2_3_4 = 0x02,
    MIYAKI_B2_5_6 = 0x03,
    MIYAKI_B3_1_2 = 0x04,
    MIYAKI_B3_3_4 = 0x05,
    MIYAKI_B3_5_6 = 0x06,
    THIRTY_SEVEN_WAY_13_14 = 0x01,
    THIRTY_SEVEN_WAY_15_16 = 0x02,
    THIRTY_SEVEN_WAY_21_22 = 0x03,
    THIRTY_SEVEN_WAY_23_24 = 0x04,
    THIRTY_SEVEN_WAY_25_26 = 0x05,
    THIRTY_SEVEN_WAY_27_28 = 0x06,
    THIRTY_SEVEN_WAY_29_30 = 0x07,
    THIRTY_SEVEN_WAY_31_32 = 0x08,
    THIRTY_SEVEN_WAY_33_34 = 0x09,
    THIRTY_SEVEN_WAY_35_36 = 0x0A,
    THIRTY_SEVEN_WAY_1_2 = 0x0B,
    THIRTY_SEVEN_WAY_3_4 = 0x0C,
    THIRTY_SEVEN_WAY_5_6 = 0x0D,
    THIRTY_SEVEN_WAY_7_8 = 0x0E,
    THIRTY_SEVEN_WAY_9_10 = 0x0F,
    THIRTY_SEVEN_WAY_11_12 = 0x10,
    THIRTY_SEVEN_WAY_17_18 = 0x11,
    THIRTY_SEVEN_WAY_19_20 = 0x12,
    ITT_CANNON_A_B = 0x01,
    ITT_CANNON_D_E = 0x02,
    ITT_CANNON_F_G = 0x03,
    ITT_CANNON_H_J = 0x04,
    ITT_CANNON_K_L = 0x05,
}
