import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { DateTimeService } from '../../../services/platform/datetime.service';
import { PersistenceService } from '../../../services/system/persistence.service';
import { TimezoneDto } from '../../../models/system/dto/timezone-dto.model';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'timezone-config-modal',
    styleUrls: ['./timezone-config.component.css'],
    templateUrl: './timezone-config.component.html'
})
export class TimezoneConfigModalComponent {

    @Input() public title: string;
    @Output() public timeZoneSaved: EventEmitter<string> = new EventEmitter<string>();

    public isSaving: boolean = false;
    public availableTimezones: string[] = [];
    public timezoneForm: FormGroup = undefined;

    @ViewChild('timeZoneConfigModal') public timeZoneConfigModal: ModalDirective;

    constructor(
        private _fb: FormBuilder,
        private _persistence: PersistenceService,
        private _dateTimeService: DateTimeService
    ) {}

    public open(): void {
        const selectedTimezone = this._persistence.currentTimeZoneIANA.getValue();
        this.timezoneForm = this._fb.group({
            timezone: [selectedTimezone, [Validators.required]],
        });
        this.serviceGetAvailableTimeZones().add(() => {
            this.timeZoneConfigModal.show();
        });
    }

    // Template helpers
    public saveTimezone(): void {
        this.isSaving = true;
        const selectedTimezone = this.timezoneForm.get('timezone').value;

        this._dateTimeService
            .setTimezone(new TimezoneDto({
                timeZoneIANA: selectedTimezone
            }))
            .subscribe(() => {
                this.timeZoneConfigModal.hide();
                this.timeZoneSaved.emit(selectedTimezone);
                this.isSaving = false;
            }, () => {
                this.timeZoneConfigModal.hide();
                this.isSaving = false;
            });
    }

    // Services
    private serviceGetAvailableTimeZones(): Subscription {
        return this._dateTimeService
            .getAvailableTimezones()
            .subscribe((data: TimezoneDto[]) => {
                if (data) {
                    this.availableTimezones = [];
                    for (const ianaDto of data) {
                        this.availableTimezones.push(ianaDto.timeZoneIANA);
                    }
                }
            });
    }
}
