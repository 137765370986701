export class ScreenOption {

    public isTerminalFavourite: boolean;
    public isJettyFavourite: boolean;
    public fakeBrightness: number;

    constructor(data: any) {
        this.fakeBrightness = 100;
        if (data.fakeBrightness != null || data.fakeBrightness != undefined) {
            this.fakeBrightness = data.fakeBrightness;
        }

        // Workaround because of boolean type (Silly JS)
        this.isTerminalFavourite = false;
        if (data.isTerminalFavourite != null || data.fakeBrightness != undefined) {
            this.isTerminalFavourite = data.isTerminalFavourite;
        }

        this.isJettyFavourite = false;
        if (data.isJettyFavourite != null || data.fakeBrightness != undefined) {
            this.isJettyFavourite = data.isJettyFavourite;
        }
    }
}
