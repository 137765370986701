import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { WebSocketService } from '../websocket/websocket.service';
import { CommonAlarmLog } from '../../models/safety/common-alarm-log.model';
import { Paged } from '../../models/common/paged.model';

@Injectable()
export class SafetyLogService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'safety/log';
    }

    public getCommonAlarmLogs(orderByField: string,
                              isDesc: boolean,
                              pageIndex: number = 1,
                              pageSize: number = 2): Observable<Paged<CommonAlarmLog>> {
        if (isDesc) {
            orderByField += '_DESC';
        }

        return this._http
            .get<Paged<CommonAlarmLog>>(this.actionUrl + '/common-alarms' +
                '?orderByField=' + orderByField +
                '&pageIndex=' + pageIndex +
                '&pageSize=' + pageSize,
                this.getRequestOptions());
    }

    public websocketGetCommonAlarmLogs(): Observable<CommonAlarmLog> {
        return this._ws.get('/wsapi/system/common-alarm/logs');
    }
}
