import { ConnectionTelecommChannel } from './connection-telecomm-channel.model';

export class ConnectionTelecommChannelFibreAnalogue extends ConnectionTelecommChannel {

    public isEnabled: boolean;
    public isShore: boolean;
    public isSlic: boolean;
    public gain: number;

    constructor(data: any) {
        super(data, 'Trelleborg.SSL10K.Brain.Database.Models.Ssl.EntityConnectionTelecommChannelFibreAnalogue, Trelleborg.SSL10K.Brain.Database');
        this.isEnabled = data.isEnabled;
        this.isShore = data.isShore;
        this.isSlic = data.isSlic;
        this.gain = data.gain;
    }
}
