import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription ,  Observable } from 'rxjs';

import { PageConfig } from '../../../shared/models/system/page-config.model';
import { PageConfigService } from '../../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../../shared/models/system/page-breadcrumb.model';
import { TerminalService } from '../../services/terminal.service';
import { Terminal } from '../../models/terminal.model';
import { PersistenceService } from '../../../shared/services/system/persistence.service';
import { SslConfiguration } from '../../models/ssl-configuration.model';
import { TerminalWizardStep } from '../../enums/terminal-wizard-step.enum';
import { TerminalDefault } from '../../models/terminal-default.model';
import { InstallationTypeEnum } from '../../../shared/enums/installation-type.enum';

@Component({
    selector: 'ssl-terminals-config',
    styleUrls: ['./config.component.css'],
    templateUrl: './config.component.html'
})
export class SslTerminalsConfigComponent implements OnInit, OnDestroy {

    public sslConfiguration: SslConfiguration;
    public currentTerminal: Terminal;
    public terminalDefaults: TerminalDefault;
    public currentWizardStep: TerminalWizardStep;
    public wizardStep: number;
    public wizardMaxStep: number;
    public isEdit: boolean;
    public isValid: boolean;
    public isLoading: boolean;
    public isSaving: boolean;
    public terminalWizardSteps = TerminalWizardStep;

    private sslConfigSub: Subscription;
    private routerSub: Subscription;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _terminalService: TerminalService,
        private _configService: PageConfigService,
        private _changeDetector: ChangeDetectorRef,
        private _persistence: PersistenceService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.currentWizardStep = TerminalWizardStep.GENERAL_INFO;
                this.wizardStep = 1;
                this.wizardMaxStep = 5;
                this.isValid = false;
                this.isLoading = true;
                this.isSaving = false;
                this.serviceGetTerminalDefaults();
                if (params['id']) {
                    this.isEdit = true;
                    let terminalId: string = params['id'];
                    this.serviceGetTerminalById(terminalId)
                        .subscribe((data: Terminal) => {
                            this.currentTerminal = data;
                            this.updateNavigation(this.currentTerminal.name);
                        });
                } else {
                    this.isEdit = false;
                    this.updateNavigation('NEW TERMINAL');
                    this.currentTerminal = new Terminal({});
                    this.currentTerminal.terminalType = InstallationTypeEnum.SHIP;
                }

                this.sslConfigSub =  this._persistence
                    .sslConfiguration
                    .subscribe((data: SslConfiguration) => {
                        if (data) {
                            this.sslConfiguration = data;

                            if (this.sslConfiguration.isRemoteSwitchingAvailable) {
                                this.wizardMaxStep += 1;
                            }
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }

        if (this.sslConfigSub) {
            this.sslConfigSub.unsubscribe();
        }
    }

    // Template Helpers
    public goCancel(): void {
        this._router.navigate(['/ssl', 'terminals']);
    }

    public goBack(): void {
        this.isLoading = true;
        this.wizardStep -= 1;
        switch (this.currentWizardStep) {
            case TerminalWizardStep.SYSTEM_PROFILES:
                this.currentWizardStep = TerminalWizardStep.GENERAL_INFO;
                break;
            case TerminalWizardStep.ESD_CONFIG:
                this.currentWizardStep = TerminalWizardStep.SYSTEM_PROFILES;
                break;
            case TerminalWizardStep.TELCOMMS_CONFIG:
                this.currentWizardStep = TerminalWizardStep.ESD_CONFIG;
                break;
            case TerminalWizardStep.ADDITIONAL_CONFIG:
                this.currentWizardStep = TerminalWizardStep.TELCOMMS_CONFIG;
                break;
            case TerminalWizardStep.REMOTE_SWITCH_CONFIG:
                this.currentWizardStep = TerminalWizardStep.ADDITIONAL_CONFIG;
                break;
        }
    }

    public goForward(): void {
        this.isLoading = true;
        this.isValid = false;
        this.wizardStep += 1;
        switch (this.currentWizardStep) {
            case TerminalWizardStep.GENERAL_INFO:
                this.currentWizardStep = TerminalWizardStep.SYSTEM_PROFILES;
                break;
            case TerminalWizardStep.SYSTEM_PROFILES:
                if (this.isEdit) {
                    this.serviceUpdateTerminal(this.currentTerminal)
                        .subscribe((data: Terminal) => {
                            this.serviceGetTerminalById(data.id)
                                .subscribe((updatedTerminal: Terminal) => {
                                    this.currentTerminal = updatedTerminal;
                                    this.isLoading = false;
                                    this.isEdit = true;
                                    this.currentWizardStep = TerminalWizardStep.ESD_CONFIG;
                                }, (error) => {
                                    this.isLoading = false;
                                });
                        }, (error) => {
                            this.isLoading = false;
                        });
                } else {
                    this.serviceAddTerminal(this.currentTerminal)
                        .subscribe((data: Terminal) => {
                            this.serviceGetTerminalById(data.id)
                                .subscribe((updatedTerminal: Terminal) => {
                                    this.currentTerminal = updatedTerminal;
                                    this.isLoading = false;
                                    this.isEdit = true;
                                    this.currentWizardStep = TerminalWizardStep.ESD_CONFIG;
                                }, (error) => {
                                    this.isLoading = false;
                                });
                        }, (error) => {
                            this.isLoading = false;
                        });
                }
                break;
            case TerminalWizardStep.ESD_CONFIG:
                this.currentWizardStep = TerminalWizardStep.TELCOMMS_CONFIG;
                break;
            case TerminalWizardStep.TELCOMMS_CONFIG:
                this.currentWizardStep = TerminalWizardStep.ADDITIONAL_CONFIG;
                break;
            case TerminalWizardStep.ADDITIONAL_CONFIG:
                if (this.sslConfiguration.isRemoteSwitchingAvailable) {
                    this.currentWizardStep = TerminalWizardStep.REMOTE_SWITCH_CONFIG;
                }
                break;
        }
    }

    public doSave(withRedirect: boolean): Subscription {
        this.isLoading = true;

        if (this.isEdit) {
            return this.serviceUpdateTerminal(this.currentTerminal)
                .subscribe((data: Terminal) => {
                    this.isLoading = false;
                    if (withRedirect) {
                        this._router.navigate(['/ssl', 'terminals']);
                    }
                }, (error) => {
                    this.isLoading = false;
                });
        } else {
            return this.serviceAddTerminal(this.currentTerminal)
                .subscribe((data: Terminal) => {
                    this.isLoading = false;
                    if (withRedirect) {
                        this._router.navigate(['/ssl', 'terminals']);
                    }
                }, (error) => {
                    this.isLoading = false;
                });
        }
    }

    public setLoadingState(loadingState: boolean): void {
        this.isLoading = loadingState;
        this._changeDetector.detectChanges();
    }

    public setValidatedState(validState: boolean): void {
        this.isValid = validState;
    }

    // Helper methods
    private updateNavigation(terminalName: string): void {
        let pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'SSL',
            path: '/ssl'
        }));
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'TERMINALS',
            path: '/ssl/terminals'
        }));
        this._configService.setConfig(new PageConfig({
            title: terminalName,
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service calls
    private serviceGetTerminalById(terminalId: string): Observable<Terminal> {
        return this._terminalService.getTerminal(terminalId);
    }

    private serviceAddTerminal(terminal: Terminal): Observable<Terminal> {
        return this._terminalService.add(terminal);
    }

    private serviceUpdateTerminal(terminal: Terminal): Observable<Terminal> {
        return this._terminalService.update(terminal);
    }

    private serviceGetTerminalDefaults(): Subscription {
        return this._terminalService
            .getTerminalDefaults()
            .subscribe((data: TerminalDefault) => {
                this.terminalDefaults = data;
            });
    }
}
