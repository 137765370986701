import { Component, Input, EventEmitter, Output } from '@angular/core';

import { EsdSystem } from '../../models/esd/esd-system.model';
import { GraphicalComponentTypeEnum } from '../../enums/graphical-component-type.enum';
import { ConnectionTypeEnum } from '../../enums/connection-type.enum';
import { TripTypeEnum } from '../../enums/trip-type.enum';
import { EsdConfiguration } from '../../models/system/esd-configuration.model';
import { Trip } from '../../models/esd/trip.model';
import { LocalboardConfiguration } from '../../models/common/localboard-configuration.model';

@Component({
    selector: 'esd-system',
    styleUrls: ['./esd-system.component.css'],
    templateUrl: './esd-system.component.html'
})
export class EsdSystemComponent {

    @Input() public esdConfiguration: EsdConfiguration;
    @Input() public localboardConfiguration: LocalboardConfiguration;
    @Input() public esdSystem: EsdSystem;
    @Input() public embedTelecomms: boolean = false;
    @Input() public isMaximized: boolean = false;
    @Input() public isAutomaticReset: boolean = false;
    @Input() public isInhibitEnabled: boolean = false;
    @Input() public isTripAcceptAvailable: boolean = false;
    @Input() public isMiscButtonAvailable: boolean = false;
    @Input() public isSmallMode: boolean = false;
    @Input() public totalSystemCount: number = 1;
    @Output() public inhibit: EventEmitter<string> = new EventEmitter<string>();
    @Output() public cancelInhibit: EventEmitter<string> = new EventEmitter<string>();
    @Output() public reset: EventEmitter<string> = new EventEmitter<string>();
    @Output() public acceptTrips: EventEmitter<string> = new EventEmitter<string>();
    @Output() public beginFibreLoopbackTest: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public endFibreLoopbackTest: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public beginElectricLoopbackTest: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public endElectricLoopbackTest: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public miscButtonPushed: EventEmitter<string> = new EventEmitter<string>();
    @Output() public maximize: EventEmitter<undefined> = new EventEmitter<undefined>();
    @Output() public minimize: EventEmitter<undefined> = new EventEmitter<undefined>();

    public connectionType = ConnectionTypeEnum;
    public tripType = TripTypeEnum;
    public graphicalComponentType = GraphicalComponentTypeEnum;

    public getTrips(): Trip[] {
        if (this.isSmallMode) {
            return this.esdSystem.trips.filter((filterTrip) => filterTrip.graphicalComponentType == GraphicalComponentTypeEnum.ESD);
        } else {
            return this.esdSystem.trips;
        }
    }

    public confirmDoInhibit(): void {
        this.inhibit.emit(this.esdSystem.id);
    }

    public confirmDoCancelInhibit(): void {
        this.cancelInhibit.emit(this.esdSystem.id);
    }

    public confirmReset(): void {
        this.reset.emit(this.esdSystem.id);
    }

    public confirmAcceptTrips(): void {
        this.acceptTrips.emit(this.esdSystem.id);
    }

    public doBeginFibreLoopbackTest(isShore: boolean): void {
        this.beginFibreLoopbackTest.emit(isShore);
    }

    public doEndFibreLoopbackTest(isShore: boolean): void {
        this.endFibreLoopbackTest.emit(isShore);
    }

    public doBeginElectricLoopbackTest(isMiyaki: boolean): void {
        this.beginElectricLoopbackTest.emit(isMiyaki);
    }

    public doEndElectricLoopbackTest(isMiyaki: boolean): void {
        this.endElectricLoopbackTest.emit(isMiyaki);
    }

    public doMaximize(): void {
        this.maximize.emit();
    }

    public doMinimize(): void {
        this.minimize.emit();
    }

    public isOutOfSync(): boolean {
        if (this.esdSystem) {
            return this.esdSystem.isStale;
        }

        return true;
    }

    public isProgressBarHealthy(trip: Trip): boolean {
        switch (trip.tripType) {
            case TripTypeEnum.FIBRE_SIGNAL_STRENGTH:
                return (trip.isHealthyValue >
                this.localboardConfiguration.fibreAnalogueEsdSignalWarn);
            case TripTypeEnum.FIBRE_BIT_ERROR_RATE:
                return (trip.isHealthyValue >
                this.localboardConfiguration.fibreDigitalBitErrorWarn);
            default:
                return trip.isHealthy;
        }
    }

    public isProgressBarWarning(trip: Trip): boolean {
        switch (trip.tripType) {
            case TripTypeEnum.FIBRE_SIGNAL_STRENGTH:
                return (trip.isHealthyValue <=
                this.localboardConfiguration.fibreAnalogueEsdSignalWarn);
            case TripTypeEnum.FIBRE_BIT_ERROR_RATE:
                return (trip.isHealthyValue <=
                this.localboardConfiguration.fibreDigitalBitErrorWarn);
            default:
                return trip.isWarning;
        }
    }

    public isProgressBarDanger(trip: Trip): boolean {
        switch (trip.tripType) {
            case TripTypeEnum.FIBRE_SIGNAL_STRENGTH:
            case TripTypeEnum.FIBRE_BIT_ERROR_RATE:
                return false;
            default:
                return !trip.isHealthy && !trip.isWarning;
        }
    }

    public getPercentageForTrip(trip: Trip): number {
        switch (trip.tripType) {
            case TripTypeEnum.FIBRE_SIGNAL_STRENGTH:
                let fibreAMin = this.localboardConfiguration.fibreAnalogueEsdSignalMin;
                let fibreAMax = this.localboardConfiguration.fibreAnalogueEsdSignalMax;
                return ((trip.isHealthyValue - fibreAMin) * 100) / (fibreAMax - fibreAMin);
            case TripTypeEnum.FIBRE_BIT_ERROR_RATE:
                let fibreDMin = this.localboardConfiguration.fibreDigitalBitErrorMin;
                let fibreDMax = this.localboardConfiguration.fibreDigitalBitErrorMax;
                return ((trip.isHealthyValue - fibreDMin) * 100) / (fibreDMax - fibreDMin);
            default:
                return trip.isHealthyValue;
        }
    }
}
