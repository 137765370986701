import { ConnectionTelecommChannel } from './connection-telecomm-channel.model';

export class ConnectionTelecommChannelElectric extends ConnectionTelecommChannel {

    public defaultData: number;
    public data: number;

    constructor(data: any) {
        super(data, 'Trelleborg.SSL10K.Brain.Database.Models.Ssl.EntityConnectionTelecommChannelElectric, Trelleborg.SSL10K.Brain.Database');
        this.defaultData = data.defaultData;
        this.data = data.data;
    }
}
