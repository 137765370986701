import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { interval, Subscription } from 'rxjs';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { DeviceCommsService } from '../../shared/services/system/device-comms.service';
import { CanMessageLog } from '../../shared/models/system/can-message-log.model';
import { BaseState } from '../../shared/models/system/comms/states/base-state.model';
import { StateTypeEnum } from '../../shared/enums/comms/state-type.enum';
import { CONFIGURATION } from '../../app.constants';
import { PersistenceService } from '../../shared/services/system/persistence.service';

@Component({
    selector: 'ssl-debug',
    styleUrls: ['./debug.component.css'],
    templateUrl: './debug.component.html'
})
export class SslDebugComponent implements OnInit, OnDestroy {

    public selectedCanMessageLogItem: CanMessageLog = undefined;
    public receivedCanMessageLogs: CanMessageLog[] = [];
    public sentCanMessageLogs: CanMessageLog[] = [];
    public canReceiveEnabled: boolean = false;
    public canDiagnosticEnabled: boolean = false;
    public canLoggingEnabled: boolean = false;
    public isLoading: boolean = false;
    public currentTimeZone: string = 'UTC';
    private routerSub: Subscription;
    private canMessageLogSubscription: Subscription;
    private pollTimer: Subscription = undefined;
    private persistenceCurrentTimeZoneSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _persistence: PersistenceService,
        private _configService: PageConfigService,
        private _deviceCommsService: DeviceCommsService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.serviceGetCanMessageLogs();
                this.canReceiveEnabled = false;
                this.receivedCanMessageLogs = [];
                this.canMessageLogSubscription = this.websocketGetCanMessageLogs();

                this.persistenceCurrentTimeZoneSub = this._persistence
                    .currentTimeZoneIANA
                    .subscribe((data: string) => {
                        this.currentTimeZone = data;
                    });

                this.stopPolling();
                this.serviceGetDeviceStates().add(() => {
                    this.startPolling();
                });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.routerSub = undefined;
            this.stopPolling();
            this.persistenceCurrentTimeZoneSub.unsubscribe();

            if (this.canMessageLogSubscription) {
                this.canMessageLogSubscription.unsubscribe();
            }

            this.receivedCanMessageLogs = [];
        }
    }

    // Template Helpers
    public toPaddedHexString(hexNumber: number, length: number): string {
        const fixedParse = parseInt(hexNumber.toString(), 10);
        const result = fixedParse.toString(16);
        return '0'.repeat(length - result.length) + result;
    }

    public toggleCanReceive(): void {
        this.canReceiveEnabled = !this.canReceiveEnabled;
    }

    public toggleCanDiagnosticMode(): void {
        this.serviceGetToggleCanDiagnosticMode().add(() => {
            this.serviceGetDeviceStates();
        });
    }

    public toggleCanLoggingMode(): void {
        this.serviceGetToggleCanLoggingMode().add(() => {
            this.serviceGetDeviceStates();
        });
    }

    public clearCanReceived(): void {
        this.receivedCanMessageLogs =  [];
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'SSL',
            path: '/ssl'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'DEBUG',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    private startPolling(): void {
        if (this.routerSub === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                this.serviceGetDeviceStates().add(() => {
                    this.startPolling();
                });
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    private organizeCanMessageLog(message: CanMessageLog): void {
        if (message.isSend) {
            let isFound: boolean = false;
            for (const existingMessage of this.sentCanMessageLogs) {
                if (this.isMatchingMessage(existingMessage, message)) {
                    if (existingMessage.internalData != message.internalData) {
                        existingMessage.data = message.data;
                        existingMessage.timestamp = message.timestamp;
                        existingMessage.internalData = message.internalData;
                    }

                    isFound = true;
                    break;
                }
            }

            if (!isFound) {
                this.sentCanMessageLogs.push(message);
            }

            this.sentCanMessageLogs = [...this.sentCanMessageLogs];
        } else {
            let isFound: boolean = false;
            for (const existingMessage of this.receivedCanMessageLogs) {
                if (this.isMatchingMessage(existingMessage, message)) {
                    if (existingMessage.internalData != message.internalData) {
                        existingMessage.data = message.data;
                        existingMessage.timestamp = message.timestamp;
                        existingMessage.internalData = message.internalData;
                    }

                    isFound = true;
                    break;
                }
            }

            if (!isFound) {
                this.receivedCanMessageLogs.push(message);
            }

            this.receivedCanMessageLogs = [...this.receivedCanMessageLogs];
        }
    }

    private isMatchingMessage(existingMessage: CanMessageLog, newMessage: CanMessageLog): boolean {
        let isFound: boolean = false;
        if (existingMessage.messageId == newMessage.messageId) {
            if (existingMessage.messageId == 0x079) {
                isFound = existingMessage.internalData.split(':')[1] == newMessage.internalData.split(':')[1];
            } else if (existingMessage.messageId == 0x07f) {
                isFound = existingMessage.internalData.split(':')[1] == newMessage.internalData.split(':')[1];
            } else if (existingMessage.messageId == 0x00a) {
                isFound = existingMessage.internalData.split(':')[2] == newMessage.internalData.split(':')[2];
            } else if (existingMessage.messageId == 0x080) {
                isFound = existingMessage.internalData.split(':')[1] == newMessage.internalData.split(':')[1];
            } else if (existingMessage.messageId == 0x082) {
                isFound = existingMessage.internalData.split(':')[1] == newMessage.internalData.split(':')[1];
            } else if (existingMessage.messageId == 0x083) {
                isFound = existingMessage.internalData.split(':')[1] == newMessage.internalData.split(':')[1];
            } else if (existingMessage.messageId == 0x084) {
                isFound = existingMessage.internalData.split(':')[1] == newMessage.internalData.split(':')[1];
            } else if (existingMessage.messageId == 0x08d) {
                isFound = existingMessage.internalData.split(':')[1] == newMessage.internalData.split(':')[1];
            } else {
                isFound = true;
            }
        }

        return isFound;
    }

    // Service Calls
    private websocketGetCanMessageLogs(): Subscription {
        return this._deviceCommsService
            .websocketGetCanMessageLogs()
            .subscribe((data: CanMessageLog) => {
                if (data && (data.isSend || (!data.isSend && this.canReceiveEnabled))) {
                    this.organizeCanMessageLog(data);
                }
            });
    }

    private serviceGetDeviceStates(): Subscription {
        return this._deviceCommsService
            .getDeviceStates()
            .subscribe((data: BaseState[]) => {
                for (const state of data) {
                    if (state.stateType == StateTypeEnum.Can) {
                        this.canDiagnosticEnabled = state.isDiagnosticMode;
                        this.canLoggingEnabled = state.isLoggingMode;
                        break;
                    }
                }
            });
    }

    private serviceGetToggleCanDiagnosticMode(): Subscription {
        return this._deviceCommsService
            .getCanToggleDiagnosticMode()
            .subscribe();
    }

    private serviceGetToggleCanLoggingMode(): Subscription {
        return this._deviceCommsService
            .getCanToggleLoggingMode()
            .subscribe();
    }

    private serviceGetCanMessageLogs(): Subscription {
        this.isLoading = true;
        return this._deviceCommsService
            .getCanMessageLogs()
            .subscribe((data: CanMessageLog[]) => {
                for (const message of data) {
                    this.organizeCanMessageLog(message);
                }

                this.isLoading = false;
            });
    }
}
