import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription, interval } from 'rxjs';
import { CONFIGURATION } from '../../app.constants';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';
import { EsdsConfiguration } from '../models/esds-configuration.model';
import { CauseService } from '../services/cause.service';
import { RegionService } from '../services/region.service';
import { Region } from '../models/region.model';
import { Cause } from '../models/cause.model';
import { OverviewTypeEnum } from '../enums/overview-type.enum';
import { Category } from '../models/category.model';
import { CategoryService } from '../services/category.service';
import { Debounce } from '../../shared/helpers/debounce.helper';
import { WebsocketCause } from '../models/websocket/cause.model';
import { WebsocketCategory } from '../models/websocket/category.model';
import { StatusTypeEnum } from '../enums/status-type.enum';

@Component({
    selector: 'esds-overview',
    styleUrls: ['./overview.component.css'],
    templateUrl: './overview.component.html'
})
export class EsdsOverviewComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('overviewShipRegions') public overviewShipRegionsRef: ElementRef;
    @ViewChild('overviewCauseHeader') public overviewCauseHeaderRef: ElementRef;

    public regions: Region[] = [];
    public causes: Cause[] = [];
    public categories: Category[] = [];
    public selectedRegion: Region = undefined;
    public selectedCategory: Category = undefined;
    public overviewType: OverviewTypeEnum = OverviewTypeEnum.CATEGORY;
    public overviewTypeEnum = OverviewTypeEnum;
    public esdsConfiguration: EsdsConfiguration;
    public causeAreaHeight: number = 0;
    public shipHeight: number = 0;

    private webSockets: Subscription[] = [];
    private resizeEventListener: any;
    private pollTimer: Subscription;
    private routerSub: Subscription;
    private persistenceSub: Subscription;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _configService: PageConfigService,
        private _persistence: PersistenceService,
        private _causeService: CauseService,
        private _regionService: RegionService,
        private _categoryService: CategoryService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.persistenceSub = this._persistence
                    .esdsConfiguration
                    .subscribe((data: EsdsConfiguration) => {
                        if (data) {
                            this.esdsConfiguration = data;
                            this.updateShipImageSize();
                        }
                    });

                this.serviceGetRegions();
                this.serviceGetCategories();
                this.serviceGetCauses();
                this.webSockets.push(this.websocketGetCategories());
                this.webSockets.push(this.websocketGetCauses());
                this.startPolling();
            });
    }

    public ngOnDestroy(): void {
        window.removeEventListener('resize', this.resizeEventListener);
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.routerSub = undefined;
            this.stopPolling();
            this.persistenceSub.unsubscribe();
            for (const val of this.webSockets) {
                if (val) {
                    val.unsubscribe();
                }
            }
        }
    }

    public ngAfterViewInit(): void {
        this.updateShipImageSize();

        this.resizeOverviewCauseArea();
        setTimeout(() => { this.resizeOverviewCauseArea(); }, 2000);
        this.resizeEventListener = Debounce.debounce(() => {
            this.resizeOverviewCauseArea();
            this.updateShipImageSize();
        }, 250);
        window.addEventListener('resize', this.resizeEventListener);
    }

    // Template Helpers
    public convertHex(argbHex: string): string {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(argbHex);
        const rgba = result ? {
                a: parseInt(result[1], 16) / 255, // Convert 0-255 value to 0-1
                r: parseInt(result[2], 16),
                g: parseInt(result[3], 16),
                b: parseInt(result[4], 16)
            } : null;

        if (rgba) {
            return 'rgba(' + rgba.r + ', ' + rgba.g + ', ' + rgba.b + ', ' + rgba.a + ')';
        }

        return 'rgba(0,0,0,.5)';
    }

    public hasCause(causeId: number): boolean {
        if (this.selectedRegion) {
            return (this.selectedRegion.causeIds.indexOf(causeId) !== -1);
        } else if (this.selectedCategory) {
            return (this.selectedCategory.causeIds.indexOf(causeId) !== -1);
        } else {
            return false;
        }
    }

    public isCauseShown(cause: Cause): boolean {
        switch (this.overviewType) {
            case OverviewTypeEnum.REGION:
                return (this.selectedRegion &&
                this.selectedRegion.causeIds.indexOf(cause.id) !== -1);
            case OverviewTypeEnum.CATEGORY_VIEW:
                return (this.selectedCategory &&
                this.selectedCategory.causeIds.indexOf(cause.id) !== -1);
            case OverviewTypeEnum.ALL:
            default:
                return true;
        }
    }

    public selectCause(cause: Cause): void {
        this._router.navigate(['/esds', 'causes', cause.id]);
    }

    public selectCategory(category: Category): void {
        this.overviewType = OverviewTypeEnum.CATEGORY_VIEW;
        this.selectedRegion = undefined;
        this.selectedCategory = category;
    }

    public selectRegion(region: Region): void {
        this.overviewType = OverviewTypeEnum.REGION;
        this.selectedRegion = region;
        this.selectedCategory = undefined;
    }

    public showAll(): void {
        this.overviewType = OverviewTypeEnum.ALL;
        this.selectedRegion = undefined;
        this.selectedCategory = undefined;
    }

    public showCategories(): void {
        this.overviewType = OverviewTypeEnum.CATEGORY;
        this.selectedRegion = undefined;
        this.selectedCategory = undefined;
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'ESDS',
            path: '/esds'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'OVERVIEW',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    private resizeOverviewCauseArea(): void {
        const shipRegions: number = this.overviewShipRegionsRef.nativeElement.offsetHeight;
        const causeHeader: number = this.overviewCauseHeaderRef.nativeElement.offsetHeight;
        const esdsNavbarHeight: number = document
            .querySelector('.esds-navbar')
            .getBoundingClientRect()
            .height;
        const navHeight: number = document
            .querySelector('#navbar')
            .getBoundingClientRect()
            .height;
        this.causeAreaHeight = window.innerHeight -
            (navHeight + shipRegions + causeHeader + esdsNavbarHeight);
    }

    private updateShipImageSize(): void {
        if (!this.esdsConfiguration) {
            return;
        }

        this.shipHeight = (this.esdsConfiguration.shipImageHeight /
        this.esdsConfiguration.shipImageWidth * 100);
    }

    private startPolling(): void {
        if (this.routerSub === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                this.serviceGetCauses().add(() => {
                    this.serviceGetCategories().add(() => {
                        this.startPolling();
                    });
                });
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    private sortCauses(): void {
        this.causes.sort((causeA: Cause, causeB: Cause) => {
            let sortResult: number = 0;

            if (causeA.isFirstUp === true && causeB.isFirstUp === false) {
                sortResult = -1;
            } else if (causeA.isFirstUp === false && causeB.isFirstUp === true) {
                sortResult = 1;
            } else if (causeA.status == StatusTypeEnum.OK &&
                causeB.status == StatusTypeEnum.ERROR) {
                sortResult = 1;
            } else if (causeA.status == StatusTypeEnum.ERROR &&
                causeB.status == StatusTypeEnum.OK) {
                sortResult = -1;
            } else {
                sortResult = (causeA.id > causeB.id) ? 1 : -1;
                sortResult = (causeA.id == causeB.id) ? 0 : sortResult;
            }

            return sortResult;
        });
    }

    // Service Calls
    private serviceGetRegions(): Subscription {
        return this._regionService
            .getRegions()
            .subscribe((data: Region[]) => {
                this.regions = data;
            });
    }

    private websocketGetCauses(): Subscription {
        return this._causeService
            .websocketGetCauses()
            .subscribe((data: WebsocketCause) => {
                for (const oldState of this.causes) {
                    if (data.id == oldState.id) {
                        oldState.status = data.status;
                        oldState.isFirstUp = data.isFirstUp;
                        break;
                    }
                }
                this.sortCauses();
            }, () => {
                for (const val of this.causes) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }

    private websocketGetCategories(): Subscription {
        return this._categoryService
            .websocketGetCategories()
            .subscribe((data: WebsocketCategory) => {
                for (const oldState of this.categories) {
                    if (data.id == oldState.id) {
                        oldState.status = data.status;
                        oldState.hasFirstUp = data.hasFirstUp;
                        break;
                    }
                }
            }, () => {
                for (const val of this.categories) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }

    private serviceGetCauses(): Subscription {
        return this._causeService
            .getAllCauses()
            .subscribe((data: Cause[]) => {
                if (!this.causes || this.causes.length <= 0) {
                    this.causes = data;
                }

                for (const newState of data) {
                    for (const oldState of this.causes) {
                        if (newState.id == oldState.id) {
                            oldState.status = newState.status;
                            oldState.isFirstUp = newState.isFirstUp;
                            break;
                        }
                    }
                }

                this.sortCauses();
            }, () => {
                for (const val of this.causes) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }

    private serviceGetCategories(): Subscription {
        return this._categoryService
            .getCategories()
            .subscribe((data: Category[]) => {
                if (!this.categories || this.categories.length <= 0) {
                    this.categories = data;
                }

                for (const newState of data) {
                    for (const oldState of this.categories) {
                        if (newState.id == oldState.id) {
                            oldState.status = newState.status;
                            oldState.hasFirstUp = newState.hasFirstUp;
                            break;
                        }
                    }
                }
            }, () => {
                for (const val of this.categories) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }
}
