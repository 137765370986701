import { RecordingTypeEnum } from '../enums/recording-type.enum';

export class Recording {

    public id: string;
    public captureStartTimestamp: number;
    public captureEndTimestamp: number;
    public sourceName: string;
    public type: RecordingTypeEnum;

    constructor(data: any) {
        this.id = data.id || undefined;
        this.captureStartTimestamp = data.captureStartTimestamp;
        this.captureEndTimestamp = data.captureEndTimestamp;
        this.sourceName = data.sourceName || '';
        this.type = data.type;
    }
}
