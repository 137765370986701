import { Pipe, PipeTransform } from '@angular/core';
import { SystemLocationEnum } from '../enums/system-location.enum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'systemLocation'
})
export class SystemLocationPipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(nauticalDirectionType: SystemLocationEnum): string {
        switch (parseInt(nauticalDirectionType.toString(), 10)) {
            case SystemLocationEnum.PORT:
                return this._translate.instant('GENERAL.NAUT_PORT');
            case SystemLocationEnum.STARBOARD:
                return this._translate.instant('GENERAL.NAUT_STARB');
            case SystemLocationEnum.BOW:
                return this._translate.instant('GENERAL.NAUT_BOW');
            case SystemLocationEnum.STERN:
                return this._translate.instant('GENERAL.NAUT_STERN');
            case SystemLocationEnum.PORT_FORWARD:
                return this._translate.instant('GENERAL.NAUT_PORT_FWD');
            case SystemLocationEnum.PORT_REAR:
                return this._translate.instant('GENERAL.NAUT_PORT_REAR');
            case SystemLocationEnum.STARBOARD_FORWARD:
                return this._translate.instant('GENERAL.NAUT_STARB_FWD');
            case SystemLocationEnum.STARBOARD_REAR:
                return this._translate.instant('GENERAL.NAUT_STARB_REAR');
            default:
                return '';
        }
    }
}
