import { Component, Input } from '@angular/core';

import { EsdSystem } from '../../../../../../shared/models/esd/esd-system.model';
import { ConnectionTypeEnum } from '../../../../../../shared/enums/connection-type.enum';

@Component({
    selector: 'ssl-operations-graphical-multi-view-local-column',
    styleUrls: ['local-column.component.css'],
    templateUrl: 'local-column.component.html'
})
export class SslOperationsGraphicalMultiViewLocalColumnComponent {

    @Input() public esdSystems: EsdSystem[];
    @Input() public localIndexes: number[];
    @Input() public remoteIndexes: number[];
    @Input() public esdsIndexes: number[];
    @Input() public portForwardIndex: number = -1;
    @Input() public starboardForwardIndex: number = -1;
    @Input() public portRearIndex: number = -1;
    @Input() public starboardRearIndex: number = -1;

    public connectionTypeEnum = ConnectionTypeEnum;

    public getStatusClass(systemIndex: number): string {
        let incomingClass: string = '';
        let outgoingClass: string = '';
        let esdsClass: string = '';
        let esdSystem = this.esdSystems[systemIndex];

        if (!esdSystem) {
            return 'btn-remote-unknown btn-local-unknown btn-esds-unknown';
        }

        let outgoingTrip = esdSystem.trips[this.localIndexes[systemIndex]];
        let incomingTrip = esdSystem.trips[this.remoteIndexes[systemIndex]];
        let esdsTrip = esdSystem.trips[this.esdsIndexes[systemIndex]];

        if (!esdsTrip) {
            return 'btn-remote-unknown btn-local-unknown btn-esds-unknown';
        }

        if (incomingTrip && incomingTrip.isHealthy && !esdsTrip.isInhibited) {
            incomingClass = 'btn-remote-healthy';
        } else if (incomingTrip &&  !incomingTrip.isHealthy && !esdsTrip.isInhibited) {
            incomingClass = 'btn-remote-error btn-status-error-static';

            if (!incomingTrip.isTripAccepted) {
                incomingClass += ' btn-status-error';
            }
        } else if (esdsTrip.isInhibited) {
            incomingClass = 'btn-remote-warning';
        } else {
            incomingClass = 'btn-remote-unknown';
        }

        if (outgoingTrip && outgoingTrip.isHealthy) {
            outgoingClass = 'btn-local-healthy';
        } else if (outgoingTrip && !outgoingTrip.isHealthy) {
            outgoingClass = 'btn-local-error btn-status-error-static';

            if (outgoingTrip && !outgoingTrip.isTripAccepted) {
                outgoingClass += ' btn-status-error';
            }
        } else {
            outgoingClass = 'btn-local-unknown';
        }

        if (esdsTrip.isHealthy) {
            esdsClass = 'btn-esds-healthy';
        } else if (!esdsTrip.isHealthy) {
            esdsClass = 'btn-esds-error btn-status-error-static';

            if (!esdsTrip.isTripAccepted) {
                esdsClass += ' btn-status-error';
            }
        }

        return incomingClass + ' ' + outgoingClass + ' ' + esdsClass;
    }

    public hasTwoSystems(isForward: boolean): boolean {
        if (isForward) {
            return this.portForwardIndex !== -1 && this.starboardForwardIndex !== -1;
        } else {
            return this.portRearIndex !== -1 && this.starboardRearIndex !== -1;
        }
    }
}
