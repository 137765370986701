import { UslAlarmLite } from '../lite/alarm-lite.model';

export class UslSystemV0Alarm {

    public id: string;
    public readingId: number;
    public threshold: number;
    public hysteresis: number;
    public high: boolean;
    public description: string;
    public isTripped: boolean;
    public isAcknowledged: boolean;

    constructor(data: any) {
        this.id = data.id || undefined;
        this.readingId = data.readingId;
        this.threshold = data.threshold;
        this.hysteresis = data.hysteresis;
        this.description = data.description || '';

        // Workaround because of boolean type (Silly JS)
        this.high = false;
        if (data.high != null) {
            this.high = data.high;
        }

        this.isTripped = false;
        if (data.isTripped != null) {
            this.isTripped = data.isTripped;
        }

        this.isAcknowledged = false;
        if (data.isAcknowledged != null) {
            this.isAcknowledged = data.isAcknowledged;
        }
    }

    public updateWebsocketAlarmState(newState: UslAlarmLite): void {
        this.readingId = newState.readingId;
        this.threshold = newState.threshold;
        this.hysteresis = newState.hysteresis;
        this.description = newState.description;
        this.high = newState.high;
        this.isTripped = newState.isTripped;
        this.isAcknowledged = newState.isAcknowledged;
    }

    public updateAlarmState(newState: UslSystemV0Alarm): void {
        this.readingId = newState.readingId;
        this.threshold = newState.threshold;
        this.hysteresis = newState.hysteresis;
        this.description = newState.description;
        this.high = newState.high;
        this.isTripped = newState.isTripped;
        this.isAcknowledged = newState.isAcknowledged;
    }
}
