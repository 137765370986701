import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription, Observable, interval } from 'rxjs';

import { CONFIGURATION } from '../../app.constants';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';
import { UslConfiguration } from '../models/usl-configuration.model';
import { EsdSystem } from '../../shared/models/esd/esd-system.model';
import { WebsocketTrip } from '../../shared/models/esd/websocket/trip.model';
import {
    WebsocketEsdSystemStatus
} from '../../shared/models/esd/websocket/esd-system-status.model';
import { WebsocketTelecomm } from '../../shared/models/esd/websocket/telecomm.model';
import { ConfigService } from '../services/config.service';
import { UslState } from '../models/usl-system/state.model';
import { OperationStateEnum } from '../enum/operation-state.enum';
import { UslRemoteTransfer } from '../models/usl-system/usl-remote-transfer.model';
import { EsdService } from '../../shared/services/esd/esd.service';
import { LocalboardConfiguration } from '../../shared/models/common/localboard-configuration.model';

@Component({
    selector: 'usl-home',
    styleUrls: ['./home.component.css'],
    templateUrl: './home.component.html'
})
export class UslHomeComponent implements OnInit, OnDestroy {

    public uslConfig: UslConfiguration;
    public uslState: UslState;
    public currentEsdSystem: EsdSystem;
    public operationStateEnum = OperationStateEnum;
    public remoteTransfer: UslRemoteTransfer;
    public localboardConfiguration: LocalboardConfiguration;
    public isLoading: boolean = true;

    private webSockets: Subscription[] = [];
    private pollTimer: Subscription = undefined;
    private routerSub: Subscription;
    private getRemoteTransfer: boolean = false;

    constructor(
        private _route: ActivatedRoute,
        private _esdService: EsdService,
        private _uslConfigService: ConfigService,
        private _configService: PageConfigService,
        private _persistence: PersistenceService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.isLoading = true;
                this.updateNavigation();

                this.webSockets.push(this._persistence
                    .uslConfiguration
                    .subscribe((data: UslConfiguration) => {
                        if (data) {
                            this.uslConfig = data;
                            if (this.isLoading && this.uslConfig &&
                                this.uslConfig.activeConnectionSystemId) {
                                this.stopPolling();
                                this.serviceGetState().add(() => {
                                    this.serviceGetEsdSystem(this.uslConfig.activeConnectionSystemId)
                                        .add(() => {
                                            this.isLoading = false;
                                            this.startPolling();
                                        });
                                });
                            } else if (this.uslConfig &&
                                this.uslConfig.activeConnectionSystemId) {
                                this.stopPolling();
                                this.serviceGetState().add(() => {
                                    this.serviceGetEsdSystem(this.uslConfig.activeConnectionSystemId)
                                        .add(() => {
                                            this.startPolling();
                                        });
                                });
                            } else if (this.uslConfig &&
                                !this.uslConfig.activeConnectionSystemId) {
                                this.stopPolling();
                                this.serviceGetState().add(() => {
                                    this.currentEsdSystem = undefined;
                                    this.isLoading = false;
                                    this.startPolling();
                                });
                            }
                        }
                    }));

                this.webSockets.push(this.websocketGetEsdSystemStatus());
                this.webSockets.push(this.websocketGetTelecommStatus());
                this.webSockets.push(this.websocketGetTripStatus());
                this.webSockets.push(this.websocketGetState());
                this.webSockets.push(this._persistence
                    .localboardConfiguration
                    .subscribe((data: LocalboardConfiguration) => {
                        if (data) {
                            this.localboardConfiguration = data;
                        }
                    }));
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.routerSub = undefined;
            this.stopPolling();
            for (const val of this.webSockets) {
                if (val) {
                    val.unsubscribe();
                }
            }
        }
    }

    // Template Helpers
    public doInhibit(id: string): void {
        this.serviceDoInhibit(id);
    }

    public doReset(id: string): void {
        this.serviceDoReset(id);
    }

    public doCancelInhibit(id: string): void {
        this.serviceDoCancelInhibit(id);
    }

    public doAcceptTrips(id: string): void {
        this.serviceDoAcceptTrips(id);
    }

    public getRemoteTransferProgress(): number {
        const maxBlocks = this.remoteTransfer.assetsBlockCount + this.remoteTransfer.systemBlockCount;
        const currentBlocks = this.remoteTransfer.assetBlocksReceived + this.remoteTransfer.systemBlocksReceived;
        return (currentBlocks / maxBlocks) * 100;
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        this._configService.setConfig(new PageConfig({
            title: 'USL',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    private startPolling(): void {
        if (this.routerSub === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                if (this.uslConfig && this.uslConfig.activeConnectionSystemId) {
                    this.serviceGetEsdSystem(this.uslConfig.activeConnectionSystemId).add(() => {
                        this.isLoading = false;
                        if (this.getRemoteTransfer) {
                            this.serviceGetRemoteTransfer().add(() => {
                                this.startPolling();
                            });
                        } else {
                            this.startPolling();
                        }
                    });
                } else if (this.getRemoteTransfer) {
                    this.isLoading = false;
                    this.serviceGetRemoteTransfer().add(() => {
                        this.startPolling();
                    });
                } else {
                    this.isLoading = false;
                    this.startPolling();
                }
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    private updateState(newState: UslState): void {
        this.uslState = newState;
        this.getRemoteTransfer = (newState.state == OperationStateEnum.WaitingForData);
        if (!this.getRemoteTransfer && this.remoteTransfer) {
            this.remoteTransfer = undefined;
        }
    }

    // Service Calls
    private websocketGetTripStatus(): Subscription {
        return this._esdService
            .websocketGetTripStatus()
            .subscribe((data: WebsocketTrip) => {
                if (this.currentEsdSystem && data.systemId === this.currentEsdSystem.id) {
                    this.currentEsdSystem.updateWebsocketEsdSystemTrip(data);
                }
            });
    }

    private websocketGetEsdSystemStatus(): Subscription {
        return this._esdService
            .websocketGetEsdSystemStatus()
            .subscribe((data: WebsocketEsdSystemStatus) => {
                if (this.currentEsdSystem && data.id === this.currentEsdSystem.id) {
                    this.currentEsdSystem.updateWebsocketEsdSystemState(data);
                }
            });
    }

    private websocketGetTelecommStatus(): Subscription {
        return this._esdService
            .websocketGetTelecommStatus()
            .subscribe((data: WebsocketTelecomm) => {
                if (this.currentEsdSystem && data.systemId === this.currentEsdSystem.id) {
                    this.currentEsdSystem.updateWebsocketEsdSystemTelecomm(data);
                }
            });
    }

    private websocketGetState(): Subscription {
        return this._uslConfigService
            .websocketGetState()
            .subscribe((data: UslState) => {
                this.updateState(data);
            });
    }

    private serviceGetEsdSystem(systemId: string): Subscription {
        return this._esdService
            .getEsdSystemById(systemId)
            .subscribe((newState: EsdSystem) => {
                if (!this.currentEsdSystem) {
                    this.currentEsdSystem = new EsdSystem(newState);
                }

                if (newState.id === this.currentEsdSystem.id) {
                    if (newState.stateIdentifier != this.currentEsdSystem.stateIdentifier) {
                        this.currentEsdSystem = undefined;
                    } else {
                        this.currentEsdSystem.updateEsdSystemState(newState);
                    }
                }
            });
    }

    private serviceGetState(): Subscription {
        return this._uslConfigService
            .getState()
            .subscribe((data: UslState) => {
                this.updateState(data);
            });
    }

    private serviceGetRemoteTransfer(): Subscription {
        return this._uslConfigService
            .getRemoteTransfer()
            .subscribe((data: UslRemoteTransfer) => {
                if (data && data.isSystemComplete) {
                    this.remoteTransfer = data;
                }
            });
    }

    private serviceDoInhibit(systemId: string): Subscription {
        return this._esdService.putInhibit(systemId, true).subscribe();
    }

    private serviceDoCancelInhibit(systemId: string): Subscription {
        return this._esdService.putInhibit(systemId, false).subscribe();
    }

    private serviceDoReset(systemId: string): Subscription {
        return this._esdService.putReset(systemId).subscribe();
    }

    private serviceDoAcceptTrips(systemId: string): Subscription {
        return this._esdService.putAcceptTrips(systemId).subscribe();
    }
}
