import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Terminal } from '../../../../models/terminal.model';
import { ConnectionSystem } from '../../../../models/connection-system.model';
import { SystemLocationEnum } from '../../../../../shared/enums/system-location.enum';
import { ConnectionConfiguration } from '../../../../models/connection-configuration.model';
import { ConnectionTypeEnum } from '../../../../../shared/enums/connection-type.enum';
import { GraphicalTypeEnum } from '../../../../enums/graphical-type.enum';
import { SslConfiguration } from '../../../../models/ssl-configuration.model';
import { TerminalDefault } from '../../../../models/terminal-default.model';
import { EslStateEnum } from '../../../../enums/esl-state.enum';

@Component({
    selector: 'ssl-terminals-config-step-2',
    styleUrls: ['./step-2.component.css', '../step-common/step-common.component.css'],
    templateUrl: './step-2.component.html'
})
export class SslTerminalsConfigStep2Component implements OnInit {

    @Input() public terminalDefaults: TerminalDefault;
    @Input() public currentStep: number;
    @Input() public sslConfiguration: SslConfiguration;
    @Input() public availableConnectionTypes: ConnectionTypeEnum[];
    @Input() public availableSystemLocations: SystemLocationEnum[];
    @Input() public currentTerminal: Terminal;
    @Input() public isLoading: boolean;
    @Output() public loadingState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public validatedState: EventEmitter<boolean> = new EventEmitter<boolean>();

    public graphicalTypeEnum = GraphicalTypeEnum;
    public primariesUsed: SystemLocationEnum[] = [];
    public selectedSystem: ConnectionSystem;

    public ngOnInit(): void {
        this.loadingState.emit(false);
        this.updatePrimary();
        this.doValidation();
    }

    // Helper methods
    public getMaxPrimaries(): number {
        if (this.currentTerminal.terminalConfiguration &&
            this.currentTerminal.terminalConfiguration.maxActivatableSystems) {
            return this.currentTerminal.terminalConfiguration.maxActivatableSystems;
        } else {
            return this.sslConfiguration.maxActivateableSystems;
        }
    }

    public toggleSelectSystem(system: ConnectionSystem): void {
        this.selectedSystem = system;
    }

    public canTogglePrimary(system: ConnectionSystem): boolean {
        return (this.primariesUsed.length < this.sslConfiguration.maxActivateableSystems)
            || system.isPrimary;
    }

    public togglePrimary(system: ConnectionSystem): void {
        system.isPrimary = !system.isPrimary;
        if (system.isPrimary) {
            system.primaryOrder = this.getNextAvailablePrimaryOrder();
        } else {
            for (let otherSystem of this.currentTerminal.connectionSystems) {
                if (otherSystem != system && otherSystem.isPrimary && otherSystem.primaryOrder > system.primaryOrder) {
                    otherSystem.primaryOrder--;
                }
            }
            system.primaryOrder = 0;
        }

        this.updatePrimary();
        this.doValidation();
    }

    public updatePrimary(): void {
        let primariesUsed: SystemLocationEnum[] = [];
        for (let system of this.currentTerminal.connectionSystems) {
            if (system.isPrimary) {
                primariesUsed.push(system.systemLocation);
            }
        }
        this.primariesUsed = primariesUsed;
    }

    public getNextAvailablePrimaryOrder(): number {
        return Math.max.apply(Math, this.currentTerminal
            .connectionSystems
            .map((o) => { return o.primaryOrder; })) + 1;
    }

    public getAvailableConnectionSystems(): ConnectionSystem[] {
        return this.currentTerminal.connectionSystems
            .filter((system: ConnectionSystem) => this.isSystemUsable(system));
    }

    public addSystem(baseSystem: ConnectionSystem): void {
        let system = new ConnectionSystem({});
        system.id = undefined;
        system.name = baseSystem.name;
        system.systemLocation = baseSystem.systemLocation;
        system.isActive = false;
        system.isPrimary = false;
        system.primaryOrder = 0;
        system.remoteType = baseSystem.remoteType;
        system.remoteName = baseSystem.remoteName;
        system.isEtuEnabled = this.terminalDefaults.isEtuEnabled;
        system.etuPins = this.terminalDefaults.etuPins;
        system.esdConfiguration = new ConnectionConfiguration({
            connectionType: ConnectionTypeEnum.NONE,
            firstUpIndex: this.terminalDefaults.firstUpIndex,
            resetIndex: this.terminalDefaults.resetIndex,
            inhibitIndex: this.terminalDefaults.inhibitIndex,
            localInputIndex: this.terminalDefaults.localInputIndex,
            localOutputIndex: this.terminalDefaults.localOutputIndex,
            eslState: EslStateEnum.MASTER,
        });
        system.telecommConfiguration = new ConnectionConfiguration({
            connectionType: ConnectionTypeEnum.NONE,
            continuityLinkIndex: this.terminalDefaults.continuityLinkIndex,
            isPrivateLine: this.terminalDefaults.isPrivateLine,
            isExchangeEnabled: this.terminalDefaults.isExchangeEnabled,
            isContLinkInput: this.terminalDefaults.isContLinkInput,
            isMlmTxRxInvert: this.terminalDefaults.isMlmTxRxInvert,
            carrierDetectThreshold: 4096,
            demodulationType: 2,
        });
        this.currentTerminal.connectionSystems.push(system);
        this.doValidation();
    }

    public updateSystem(updatedSystem: ConnectionSystem): void {
        let index = this.currentTerminal.connectionSystems.indexOf(this.selectedSystem);
        if (index === -1) {
            return;
        }

        this.currentTerminal.connectionSystems[index].name = updatedSystem.name;
        this.currentTerminal.connectionSystems[index].systemLocation = updatedSystem.systemLocation;
        this.currentTerminal.connectionSystems[index].remoteType = updatedSystem.remoteType;
        this.currentTerminal.connectionSystems[index].remoteName = updatedSystem.remoteName;
    }

    public removeSystem(): void {
        if (this.selectedSystem.isPrimary) {
            let primariesIndex = this.primariesUsed.indexOf(this.selectedSystem.systemLocation);
            if (primariesIndex !== -1) {
                this.primariesUsed.splice(primariesIndex, 1);
            }
        }

        let itemIndex = this.currentTerminal.connectionSystems.indexOf(this.selectedSystem);
        if (itemIndex !== -1) {
            this.currentTerminal.connectionSystems.splice(itemIndex, 1);
        }

        this.selectedSystem = undefined;
        this.doValidation();
    }

    // Internal Helpers
    private doValidation(): void {
        this.validatedState.emit((this.currentTerminal.connectionSystems.length > 0 &&
        this.primariesUsed.length > 0));
    }

    private isSystemUsable(system: ConnectionSystem): boolean {
        if (system.esdConfiguration &&
            system.esdConfiguration.isEnabled &&
            this.availableConnectionTypes.indexOf(system.esdConfiguration.connectionType) === -1) {
            return false;
        }

        if (system.telecommConfiguration &&
            system.telecommConfiguration.isEnabled &&
            this.availableConnectionTypes
                .indexOf(system.telecommConfiguration.connectionType) === -1) {
            return false;
        }

        return true;
    }
}
