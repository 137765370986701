export class ConnectionTelecommChannel {

    public $type: string;
    public id: string;
    public connectionConfigurationId: string;
    public channelName: string;
    public channelNumber: number;
    public isPortBankB: boolean;
    public isStarboardBankB: boolean;

    constructor(data: any, type: string) {
        this.$type = type || 'Trelleborg.SSL10K.Brain.Database.Models.Ssl.EntityConnectionTelecommChannel, Trelleborg.SSL10K.Brain.Database';
        this.id = data.id || undefined;
        this.connectionConfigurationId = data.connectionConfigurationId || undefined;
        this.channelName = data.channelName || undefined;
        this.channelNumber = data.channelNumber;
        this.isPortBankB = data.isPortBankB;
        this.isStarboardBankB = data.isStarboardBankB;
    }
}
