import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { UslSystemV0Alarm } from '../../../../../../models/usl-system/v0/alarm.model';
import { UslSystemV0DisplayReading } from '../../../../../../models/usl-system/v0/display-readings.model';

@Component({
    selector: 'usl-display-v0-alarm-config-modal',
    styleUrls: ['./alarm-config.component.css'],
    templateUrl: './alarm-config.component.html'
})
export class UslDisplayV0ModalAlarmConfigComponent implements OnInit, OnDestroy {

    @Input() public selectedReading: UslSystemV0DisplayReading;
    @Input() public selectedAlarm: UslSystemV0Alarm;
    @Output() public onAdd: EventEmitter<UslSystemV0Alarm> = new EventEmitter<UslSystemV0Alarm>();
    @Output() public onSave: EventEmitter<UslSystemV0Alarm> = new EventEmitter<UslSystemV0Alarm>();
    @Output() public onCancel: EventEmitter<undefined> = new EventEmitter<undefined>();

    public itemToConfigure: UslSystemV0Alarm = undefined;
    public alarmForm: FormGroup;
    public isEditable: boolean = false;
    public selectedEdit: string;
    public isKeypadNegative: boolean = false;
    public keypadInput: string = '';

    private formSubscription: Subscription;

    constructor(
        private _fb: FormBuilder
    ) {}

    public ngOnInit(): void {
        this.clearInput();
        this.isKeypadNegative = false;
        this.itemToConfigure = new UslSystemV0Alarm(this.selectedAlarm);
        this.alarmForm = this._fb.group({
            threshold: [this.selectedAlarm.threshold, [Validators.required]],
            hysteresis: [this.selectedAlarm.hysteresis, [Validators.required]],
            high: [this.selectedAlarm.high, [Validators.required]]
        });

        this.formSubscription = this.alarmForm
            .valueChanges
            .subscribe(() => {
                this.updateValues();
            });
    }

    public ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    }

    public doEditField(field: string): void {
        this.clearInput();
        this.isEditable = true;
        let currentValue = this.alarmForm.get(field).value;
        if (currentValue && currentValue != 0 && currentValue != '0') {
            if (currentValue < 0) {
                this.isKeypadNegative = true;
                currentValue = -currentValue;
            }
            this.keypadInput = '' + currentValue;
        }

        this.selectedEdit = field;
    }

    public doSave(): void {
        if (!this.itemToConfigure.id || this.itemToConfigure.id == '') {
            this.onAdd.next(this.itemToConfigure);
        } else {
            this.onSave.next(this.itemToConfigure);
        }
    }

    public doCancel(): void {
        this.onCancel.next(undefined);
    }

    public toggleHigh(): void {
        this.clearInput();
        this.selectedEdit = 'high';
        this.itemToConfigure.high = !this.itemToConfigure.high;
    }

    public getHighKey(): string {
        if (this.itemToConfigure.high) {
            return 'USL.ALARM_HIGHER';
        } else {
            return 'USL.ALARM_LOWER';
        }
    }

    public getSign(): string {
        if (this.isKeypadNegative) {
            return '-';
        }

        return '';
    }

    public toggleSign(): void {
        this.isKeypadNegative = !this.isKeypadNegative;
    }

    public addInput(char: string): void {
        this.keypadInput += char;
    }

    public removeInput(): void {
        if (this.keypadInput.length > 0) {
            this.keypadInput = this.keypadInput.slice(0, -1);
        }
    }

    public saveInput(): void {
        let value = parseFloat(this.keypadInput);
        if (this.isKeypadNegative) {
            value = -value;
        }

        this.alarmForm.patchValue({ [this.selectedEdit]: value });
        this.clearInput();
        this.isKeypadNegative = false;
    }

    public getInput(): string {
        if (this.keypadInput.length == 0) {
            return '0';
        }

        if (this.keypadInput.length > 0 && this.keypadInput.substr(0, 1) == '.') {
            return '0' + this.keypadInput;
        }

        return this.keypadInput;
    }

    public isFull(): boolean {
        return this.keypadInput.length > 14;
    }

    // Internal Helpers
    private clearInput(): void {
        this.isEditable = false;
        this.selectedEdit = '';
        this.keypadInput = '';
    }

    private updateValues(): void {
        this.itemToConfigure.readingId = this.selectedReading.readingId;
        this.itemToConfigure.description = this.selectedReading.description;
        this.itemToConfigure.hysteresis = this.alarmForm.get('hysteresis').value;
        this.itemToConfigure.threshold = this.alarmForm.get('threshold').value;
        this.itemToConfigure.high = this.alarmForm.get('high').value;
    }
}
