import { ConnectionTypeEnum } from '../../shared/enums/connection-type.enum';
import { ConnectionSystem } from './connection-system.model';

export class ConnectionSystemRelatedSystem {

    public id: string;
    public connectionSystemOriginId: string;
    public connectionSystemOrigin: ConnectionSystem;
    public connectionSystemRelationId: string;
    public connectionSystemRelation: ConnectionSystem;
    public originConnectionType: ConnectionTypeEnum;
    public relationConnectionType: ConnectionTypeEnum;

    constructor(data: any) {
        this.id = data.id;
        this.connectionSystemOriginId = data.connectionSystemOriginId || undefined;
        this.connectionSystemOrigin = data.connectionSystemOrigin || undefined;
        this.connectionSystemRelationId = data.connectionSystemRelationId || undefined;
        this.connectionSystemRelation = data.connectionSystemRelation || undefined;
        this.originConnectionType = data.originConnectionType;
        this.relationConnectionType = data.relationConnectionType;
    }
}
