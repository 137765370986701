import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';

import { UslConfiguration } from '../models/usl-configuration.model';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';
import { UslState } from '../models/usl-system/state.model';
import { UslRemoteTransfer } from '../models/usl-system/usl-remote-transfer.model';
import { SystemLocationEnum } from '../../shared/enums/system-location.enum';

@Injectable()
export class ConfigService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'usl/configuration';
    }

    public getConfig(): Observable<UslConfiguration> {
        return this._http
            .get<UslConfiguration>(this.actionUrl,
                this.getRequestOptions());
    }

    public getState(): Observable<UslState> {
        return this._http
            .get<UslState>(this.actionUrl + '/state',
                this.getRequestOptions());
    }

    public getRemoteTransfer(): Observable<UslRemoteTransfer> {
        return this._http
            .get<UslRemoteTransfer>(this.actionUrl + '/remote/transfer',
                this.getRequestOptions());
    }

    public updateConfig(updatedConfig: UslConfiguration): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl,
                JSON.stringify(updatedConfig),
                this.getFullRawRequestOptions());
    }

    public toggleDebugMode(): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/debug',
                undefined,
                this.getFullRawRequestOptions());
    }

    public switchSides(newLocation: SystemLocationEnum): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/switch-sides/' + newLocation,
                undefined,
                this.getFullRawRequestOptions());
    }

    public websocketGetConfig(): Observable<UslConfiguration> {
        return this._ws.get('/wsapi/usl/config');
    }

    public websocketGetState(): Observable<UslState> {
        return this._ws.get('/wsapi/usl/state');
    }
}
