import { Telecomm } from '../telecomm.model';
import { WebsocketTelecomm } from '../websocket/telecomm.model';
import { TelecommTypeEnum } from '../../../enums/telecomm-type.enum';

export class FibreAnaloguePhone extends Telecomm {

    public localCarrier: boolean;
    public remoteCarrier: boolean;
    public signalStrength: number;
    public isShore: boolean;
    public isSlic: boolean;
    public gain: number;

    private signalStrengthView = new DataView(new ArrayBuffer(4));

    constructor(data: any) {
        super(data);
        this.telecommType = TelecommTypeEnum.FIBRE_ANALOGUE;
        this.signalStrength = data.signalStrength;
        this.gain = data.gain;

        // Workaround because of boolean type (Silly JS)
        if (data.localCarrier != null) {
            this.localCarrier = data.localCarrier;
        }

        if (data.remoteCarrier != null) {
            this.remoteCarrier = data.remoteCarrier;
        }

        if (data.isShore != null) {
            this.isShore = data.isShore;
        }

        if (data.isSlic != null) {
            this.isSlic = data.isSlic;
        }
    }

    public updateTelecommState(newState: FibreAnaloguePhone): void {
        super.updateTelecommState(newState);
        this.localCarrier = newState.localCarrier;
        this.remoteCarrier = newState.remoteCarrier;
        this.signalStrength = newState.signalStrength;
        this.isShore = newState.isShore;
        this.isSlic = newState.isSlic;
        this.gain = newState.gain;
    }

    public updateWebsocketTelecommState(newState: WebsocketTelecomm): void {
        super.updateWebsocketTelecommState(newState);
        if (newState.data) {
            this.localCarrier = (newState.data[0] & 1) != 0;
            this.remoteCarrier = (newState.data[0] & (1 << 1)) != 0;
            this.signalStrengthView.setUint8(3, newState.data[1]);
            this.signalStrengthView.setUint8(2, newState.data[2]);
            this.signalStrengthView.setUint8(1, newState.data[3]);
            this.signalStrengthView.setUint8(0, newState.data[4]);
            this.signalStrength = this.signalStrengthView.getUint32(0);
        }
    }
}
