import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { PollStateEnum } from '../enums/poll-state.enum';

@Pipe({
    name: 'pollState'
})
export class PollStatePipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(pollStateEnum: PollStateEnum): Observable<string> {
        switch (parseInt(pollStateEnum.toString(), 10)) {
            case PollStateEnum.WaitForNextPoll:
                return this._translate.get('MLM.POLL_STATE_WAIT_FOR_NEXT_POLL');
            case PollStateEnum.RequestToSendHandshake:
                return this._translate.get('MLM.POLL_STATE_REQUEST_TO_SEND');
            case PollStateEnum.WaitForClearToSend:
                return this._translate.get('MLM.POLL_STATE_WAIT_FOR_CLEAR_TO_SEND');
            case PollStateEnum.PollForData:
                return this._translate.get('MLM.POLL_STATE_POLL_FOR_DATA');
            case PollStateEnum.ClearRequestToSend:
                return this._translate.get('MLM.POLL_STATE_CLEAR_REQUEST_TO_SEND');
            default:
            case PollStateEnum.Disabled:
                return this._translate.get('MLM.POLL_STATE_DISABLED');
        }
    }
}
