import { EsdConfiguration } from '../../shared/models/system/esd-configuration.model';
import { ConnectionSystem } from '../../mod_ssl/models/connection-system.model';

export class UslConfiguration extends EsdConfiguration {

    public installationLocation: string;
    public localApiVersion: number;
    public remoteApiVersion: number;
    public connectionSystemId: string;
    public connectionSystem: ConnectionSystem;
    public activeConnectionSystemId: string;

    constructor(data: any) {
        super(data);
        this.installationLocation = data.installationLocation;
        this.localApiVersion = data.localApiVersion;
        this.remoteApiVersion = data.remoteApiVersion;
        this.connectionSystemId = data.connectionSystemId || undefined;
        this.connectionSystem = data.connectionSystem || undefined;
        this.activeConnectionSystemId = data.activeConnectionSystemId || undefined;
    }
}
