import { Component, Input, OnInit } from '@angular/core';

import { Telecomm } from '../../models/esd/telecomm.model';
import { ElectricPhone } from '../../models/esd/telecomm/electric-phone.model';
import { FibreAnaloguePhone } from '../../models/esd/telecomm/fibre-analogue.model';
import { FibreDigitalPhone } from '../../models/esd/telecomm/fibre-digital.model';
import { TelecommTypeEnum } from '../../enums/telecomm-type.enum';
import { ConnectionTypeEnum } from '../../enums/connection-type.enum';
import { EsdConfiguration } from '../../models/system/esd-configuration.model';
import { LocalboardConfiguration } from '../../models/common/localboard-configuration.model';

@Component({
    selector: 'esd-telecomms',
    styleUrls: ['./telecomms.component.css'],
    templateUrl: './telecomms.component.html'
})
export class EsdTelecommsComponent implements OnInit {

    @Input() public localboardConfiguration: LocalboardConfiguration;
    @Input() public esdConfiguration: EsdConfiguration;
    @Input() public telecommSystems: Telecomm[];
    @Input() public isEmbedded: boolean;

    public connectionTypeEnum = ConnectionTypeEnum;
    public electricPhones: ElectricPhone[] = [];
    public fibreDigitalPhones: FibreDigitalPhone[] = [];
    public fibreAnaloguePhones: FibreAnaloguePhone[] = [];
    public telecommTypeEnum = TelecommTypeEnum;

    public ngOnInit(): void {
        if (!this.telecommSystems) {
            return;
        }

        for (const val of this.telecommSystems) {
            const phone: any = val;
            switch (val.telecommType) {
                case TelecommTypeEnum.ELECTRIC:
                    this.electricPhones.push(phone);
                    break;
                case TelecommTypeEnum.FIBRE_DIGITAL:
                    this.fibreDigitalPhones.push(phone);
                    break;
                case TelecommTypeEnum.FIBRE_ANALOGUE:
                    this.fibreAnaloguePhones.push(phone);
                    break;
            }
        }
    }

    // Template Helpers
    public isUniqueType(): boolean {
        const electricSize = this.electricPhones.length;
        const fbSize = this.fibreDigitalPhones.length;
        const faSize = this.fibreAnaloguePhones.length;

        // TODO: See if there is a better way to do this.
        return (electricSize > 0 && fbSize <= 0 && faSize <= 0) ||
            (electricSize <= 0 && fbSize > 0 && faSize <= 0) ||
            (electricSize <= 0 && fbSize <= 0 && faSize > 0);
    }

    public getPercentageForFibreAnalogue(signalStrength: number): number {
        const min = this.localboardConfiguration.fibreAnalogueTelSignalMin;
        const max = this.localboardConfiguration.fibreAnalogueTelSignalMax;
        return ((signalStrength - min) * 100) / (max - min);
    }

    public getPercentageForFibreDigital(bitErrorRate: number): number {
        const min = this.localboardConfiguration.fibreDigitalBitErrorMin;
        const max = this.localboardConfiguration.fibreDigitalBitErrorMax;
        return ((bitErrorRate - min) * 100) / (max - min);
    }
}
