import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';
import { Recording } from '../models/recording.model';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';
import { Paged } from '../../shared/models/common/paged.model';

@Injectable()
export class RecordingService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'ssl/recording';
    }

    public getRecordings(orderByField: string,
                         isDesc: boolean,
                         filterFrom?: number,
                         filterTo?: number,
                         pageIndex: number = 1,
                         pageSize: number = 2): Observable<Paged<Recording>> {

        if (isDesc) {
            orderByField += '_DESC';
        }

        let url = this.actionUrl +
            '?orderByField=' + orderByField +
            '&pageIndex=' + pageIndex +
            '&pageSize=' + pageSize;

        if (filterFrom || filterFrom == 0) {
            url += '&captureStart=' + filterFrom;
        }

        if (filterTo || filterTo == 0) {
            url += '&captureEnd=' + filterTo;
        }

        return this._http
            .get<Paged<Recording>>(url, this.getRequestOptions());
    }

    public deleteRecording(recordingId: string): Observable<HttpResponse<string>> {
        return this._http
            .delete(this.actionUrl + '/' + recordingId,
                this.getFullRawRequestOptions());
    }

    public getRecordingDataUrl(id: string): string {
        return this.actionUrl + '/' + id + '/data';
    }

    public websocketGetRecordings(): Observable<Recording> {
        return this._ws.get('/wsapi/ssl/recordings');
    }
}
