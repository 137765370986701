import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription, Observable, zip } from 'rxjs';

import { PageConfig } from '../../../shared/models/system/page-config.model';
import { PageConfigService } from '../../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../../shared/models/system/page-breadcrumb.model';
import { ConnectionSystem } from '../../models/connection-system.model';
import { PersistenceService } from '../../../shared/services/system/persistence.service';
import { SslConfiguration } from '../../models/ssl-configuration.model';
import { SystemService } from '../../services/system.service';
import { SystemLocationEnum } from '../../../shared/enums/system-location.enum';
import { Terminal } from '../../models/terminal.model';
import { ActivateSystemDto } from '../../models/dto/activate-system-dto.model';
import { DeactivateSystemDto } from '../../models/dto/deactivate-system-dto.model';
import { BoardLookup } from '../../../shared/models/esd/board-lookup.model';
import { EsdService } from '../../../shared/services/esd/esd.service';
import {
    WebsocketEsdSystemStateChange
} from '../../../shared/models/esd/websocket/esd-system-state-change.model';
import { BaseSafetyEvent } from '../../../shared/models/safety/events/base-safety-event.model';
import { SafetyEventType } from '../../../shared/enums/safety/safety-event-type.enum';
import {
    SystemActivatingSafetyEvent
} from '../../../shared/models/safety/events/system-activating-safety-event.model';
import {
    SystemDeactivatingSafetyEvent
} from '../../../shared/models/safety/events/system-deactivating-safety-event.model';
import { InstallationTypeEnum } from '../../../shared/enums/installation-type.enum';
import { SystemConfiguration } from '../../../shared/models/system/configuration.model';
import { BoardActivation } from '../../../shared/models/esd/board-activation.model';
import { ConnectionTypeEnum } from '../../../shared/enums/connection-type.enum';

@Component({
    selector: 'ssl-systems-list',
    styleUrls: ['./list.component.css'],
    templateUrl: './list.component.html'
})
export class SslSystemsListComponent implements OnInit, OnDestroy {

    public systemRows: ConnectionSystem[] = [];
    public allSystems: ConnectionSystem[] = [];
    public selectedSystem: ConnectionSystem;
    public availableSystemLocations: SystemLocationEnum[] = [];
    public availableConnectionTypes: ConnectionTypeEnum[] = [];
    public availableBoardLookups: BoardLookup[] = [];
    public boardActivationRules: BoardActivation[] = [];
    public systemChangingToId: string = undefined;
    public currentTerminal: Terminal;
    public maxActivateableSystems: number = 0;
    public activatedSystems: number = 0;
    public currentInstallationType: InstallationTypeEnum;
    public isLoading: boolean = false;

    private routerSub: Subscription;
    private persistenceSub: Subscription;
    private persistenceEventsSub: Subscription;
    private persistenceSysConfigSub: Subscription;
    private onSystemChangeSub: Subscription;
    private boardLostEventId: string;
    private boardFoundEventId: string;

    constructor(private _route: ActivatedRoute,
                private _router: Router,
                private _persistence: PersistenceService,
                private _esdService: EsdService,
                private _systemService: SystemService,
                private _configService: PageConfigService) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.onSystemChangeSub = this.websocketGetEsdSystemStateChange();
                this.persistenceEventsSub = this._persistence
                    .lastSafetyEvent
                    .subscribe((data: BaseSafetyEvent) => {
                        if (data) {
                            switch (data.eventType) {
                                case SafetyEventType.SystemActivating:
                                    const systemActivatingEvent =
                                        new SystemActivatingSafetyEvent(data);
                                    this.systemChangingToId = systemActivatingEvent.systemId;
                                    break;
                                case SafetyEventType.SystemActivatingFailed:
                                case SafetyEventType.SystemActivated:
                                    this.systemChangingToId = undefined;
                                    break;
                                case SafetyEventType.SystemDeactivating:
                                    const systemDeactivatingEvent =
                                        new SystemDeactivatingSafetyEvent(data);
                                    this.systemChangingToId = systemDeactivatingEvent.systemId;
                                    break;
                                case SafetyEventType.SystemDeactivated:
                                case SafetyEventType.SystemDeactivatingFailed:
                                    this.systemChangingToId = undefined;
                                    break;
                                case SafetyEventType.BoardsFound:
                                    if (data.id != this.boardFoundEventId) {
                                        this.boardFoundEventId = data.id;
                                        this.serviceGetAvailableBoards();
                                    }

                                    break;
                                case SafetyEventType.BoardsLost:
                                    if (data.id != this.boardLostEventId) {
                                        this.boardLostEventId = data.id;
                                        this.serviceGetAvailableBoards();
                                    }

                                    break;
                            }
                        }
                    });
                this.persistenceSub = this._persistence
                    .sslConfiguration
                    .subscribe((data: SslConfiguration) => {
                        if (data) {
                            if (data.currentTerminal) {
                                this.currentTerminal = data.currentTerminal;
                            }

                            this.availableSystemLocations = data.availableSystemLocations;
                            this.availableConnectionTypes = data.availableConnectionTypes;

                            this.getLatestSystems();
                        }
                    });

                this.persistenceSysConfigSub = this._persistence
                    .systemConfiguration
                    .subscribe((data: SystemConfiguration) => {
                        if (data) {
                            this.currentInstallationType =
                                data.systemConfiguration.installationType;
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.onSystemChangeSub.unsubscribe();
            this.persistenceSub.unsubscribe();
            this.persistenceEventsSub.unsubscribe();
            this.persistenceSysConfigSub.unsubscribe();
        }
    }

    // Template Helpers
    public selectSystem(systemsSelected: any): void {
        this.selectedSystem = systemsSelected.selected[0];
    }

    public confirmActivateSystem(location: SystemLocationEnum): void {
        const activation: ActivateSystemDto = new ActivateSystemDto({
            systemId: this.selectedSystem.id,
            newLocation: location
        });

        this.serviceActivateSystem(activation).add(() => {
            this.getLatestSystems();
        });
    }

    public confirmDeactivateSystem(): void {
        const deactivation: DeactivateSystemDto = new DeactivateSystemDto({
            systemId: this.selectedSystem.id,
        });

        this.serviceDeactivateSystem(deactivation).add(() => {
            this.getLatestSystems();
        });
    }

    public confirmEditSystem(): void {
        this._router.navigate(['/ssl', 'systems', 'edit', this.selectedSystem.id]);
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'SSL',
            path: '/ssl'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'SYSTEMS',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    private getLatestSystems(): void {
        if (this.currentTerminal) {
            this.serviceGetAllSystems(this.currentTerminal.id);
            this.serviceGetAvailableBoards();
            this.serviceGetMaxActivatableSystems();
            this.serviceGetBoardActivationRules();
        }
    }

    private isSystemUsable(system: ConnectionSystem): boolean {
        if (system.esdConfiguration &&
            system.esdConfiguration.isEnabled &&
            this.availableConnectionTypes.indexOf(system.esdConfiguration.connectionType) === -1) {
            return false;
        }

        if (system.telecommConfiguration &&
            system.telecommConfiguration.isEnabled &&
            this.availableConnectionTypes
                .indexOf(system.telecommConfiguration.connectionType) === -1) {
            return false;
        }

        return true;
    }

    // Service calls
    private websocketGetEsdSystemStateChange(): Subscription {
        return this._esdService
            .websocketGetEsdSystemStateChange()
            .subscribe((data: WebsocketEsdSystemStateChange) => {
                this.getLatestSystems();
            });
    }

    private serviceGetAllSystems(terminalId: string): Subscription {
        this.isLoading = true;
        const terminalConnectionSystems = this._systemService
            .getByTerminal(terminalId);
        const activeConnectionSystems = this._systemService
            .getAllActive();
        const additionalConnectionSystems = this._systemService
            .getAdditional();

        const systemIdsAlreadyAdded: string[] = [];
        return zip(terminalConnectionSystems, activeConnectionSystems, additionalConnectionSystems)
            .subscribe(([terminalSystems, activeSystems, additionalSystems]) => {
                const connectionSystems: ConnectionSystem[] =
                    terminalSystems.concat(activeSystems.concat(additionalSystems));
                const uniqueSystems: ConnectionSystem[] = [];
                for (const nonUniqueSystem of connectionSystems) {
                    if (systemIdsAlreadyAdded.indexOf(nonUniqueSystem.id) === -1 &&
                        this.isSystemUsable(nonUniqueSystem)) {
                        systemIdsAlreadyAdded.push(nonUniqueSystem.id);
                        uniqueSystems.push(nonUniqueSystem);
                    }
                }

                this.systemRows = [];
                this.allSystems = uniqueSystems;
                let activatedSystemCount: number = 0;
                for (const system of this.allSystems) {
                    this.systemRows.push(system);

                    if (this.selectedSystem && this.selectedSystem.id == system.id) {
                        this.selectedSystem = system;
                    }

                    if (system.isActive) {
                        activatedSystemCount++;
                    }
                }

                this.activatedSystems = activatedSystemCount;
                this.isLoading = false;
            });
    }

    private serviceActivateSystem(system: ActivateSystemDto): Subscription {
        return this._systemService
            .activate(system)
            .subscribe();
    }

    private serviceDeactivateSystem(system: DeactivateSystemDto): Subscription {
        return this._systemService
            .deactivate(system)
            .subscribe();
    }

    private serviceGetMaxActivatableSystems(): Subscription {
        return this._systemService
            .getMaxActivatableSystems()
            .subscribe((data: number) => {
                this.maxActivateableSystems = data;
            });
    }

    private serviceGetAvailableBoards(): Subscription {
        return this._esdService
            .getAvailableBoards()
            .subscribe((data: BoardLookup[]) => {
                this.availableBoardLookups = data;
            });
    }

    private serviceGetBoardActivationRules(): Subscription {
        return this._esdService
            .getBoardActivationRules()
            .subscribe((data: BoardActivation[]) => {
                this.boardActivationRules = data;
            });
    }
}
