import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';

import { Category } from '../models/category.model';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';
import { WebsocketCategory } from '../models/websocket/category.model';

@Injectable()
export class CategoryService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl = '/assets/mock/mod_esds/categories.json';
    }

    public getCategories(): Observable<Category[]> {
        return this._http
            .get<Category[]>(this.actionUrl,
                this.getRequestOptions());
    }

    public websocketGetCategories(): Observable<WebsocketCategory> {
        return this._ws.get('/wsapi/esds/categories');
    }
}
