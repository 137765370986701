/**
 * Example use:
 *	Basic Array of single type:
 *  	*ngFor="let item of todoService.todos | orderBy : '-'"
 *	Multidimensional Array Sort on single column:
 *  	*ngFor="let item of todoService.todos | orderBy : ['-status']"
 *	Multidimensional Array Sort on multiple columns:
 *  	*ngFor="let item of todoService.todos | orderBy : ['status', '-title']"
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    pure: false
})
export class OrderByPipe implements PipeTransform {

    private static orderByComparator(a: any, b: any): number {
        let numA = parseFloat(a);
        let numB = parseFloat(b);

        if (a === null || typeof a === 'undefined') { return 0; }
        if (b === null || typeof b === 'undefined') { return 0; }

        if ((a === true || a === false) && (b === true || b === false)) {
            // Its a boolean
            if (numA == numB) {
                return 0;
            } else {
                numA = a ? 1 : -1;
                numB = b ? 1 : -1;
            }
        }

        if (((isNaN(numA) || !isFinite(numA)) || (isNaN(numB) || !isFinite(numB)))) {
            // Isn't a number so lowercase the string to properly compare
            if (a.toLowerCase() < b.toLowerCase()) { return -1; }
            if (a.toLowerCase() > b.toLowerCase()) { return 1; }
        } else {
            // Parse strings as numbers to compare properly
            if (numA < numB) { return -1; }
            if (numA > numB) { return 1; }
        }

        return 0; // equal each other
    }

    private value: string[] = [];

    public transform(input: any, [config = '+']: string[]): any {

        // Make a copy of the inputs reference
        this.value = [...input];
        const value = this.value;

        if (!Array.isArray(value)) {
            return value;
        }

        if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
            const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
            const desc: boolean = propertyToCheck.substr(0, 1) === '-';

            // Basic array
            if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
                return !desc ? value.sort() : value.sort().reverse();
            } else {
                const property: string = propertyToCheck.substr(0, 1) === '+' ||
                propertyToCheck.substr(0, 1) === '-'
                    ? propertyToCheck.substr(1)
                    : propertyToCheck;

                return value.sort((a: any, b: any) => {
                    return !desc
                        ? OrderByPipe.orderByComparator(a[property], b[property])
                        : -OrderByPipe.orderByComparator(a[property], b[property]);
                });
            }
        } else {
            // Loop over property of the array in order and sort
            return value.sort((a: any, b: any) => {
                for (let i: number = 0; i < config.length; i++) {
                    const desc: boolean = config[i].substr(0, 1) === '-';
                    const property: string = config[i].substr(0, 1) === '+' ||
                    config[i].substr(0, 1) === '-'
                        ? config[i].substr(1)
                        : config[i];

                    const comparison: number = !desc
                        ? OrderByPipe.orderByComparator(a[property], b[property])
                        : -OrderByPipe.orderByComparator(a[property], b[property]);

                    // Don't return 0 yet in case of needing to sort by next property
                    if (comparison !== 0) {
                        return comparison;
                    }
                }

                return 0; // Equal each other
            });
        }
    }
}
