import { Component, ViewChild, Input, OnDestroy } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap';

import { EsdSystem } from '../../../../shared/models/esd/esd-system.model';
import {
    WebsocketEsdSystemStatus
} from '../../../../shared/models/esd/websocket/esd-system-status.model';
import { ConnectionTypeEnum } from '../../../../shared/enums/connection-type.enum';
import { MlmSourceStateEnum } from '../../../../shared/enums/mlm-source-state.enum';
import { EsdService } from '../../../../shared/services/esd/esd.service';
import { EslStateEnum } from '../../../enums/esl-state.enum';
import { SslConfiguration } from '../../../models/ssl-configuration.model';
import { PersistenceService } from '../../../../shared/services/system/persistence.service';
import { MiscInformation } from '../../../../shared/models/safety/misc-information.model';

@Component({
    selector: 'ssl-shared-change-settings-modal',
    styleUrls: ['./change-settings.component.css'],
    templateUrl: './change-settings.component.html'
})
export class SslOperationsSharedChangeSettingsModalComponent implements OnDestroy {

    @ViewChild('changeSettingsModal') public changeSettingsModal: ModalDirective;

    @Input() public systemId: string;
    @Input() public systemName: string;

    public isBusy: boolean = false;
    public connectionTypeEnum = ConnectionTypeEnum;
    public mlmSourceStateEnum = MlmSourceStateEnum;
    public eslStateEnum = EslStateEnum;
    public esdSystem: EsdSystem;
    public sslConfiguration: SslConfiguration;
    public miscInformation: MiscInformation;

    private wsEsdSystemSub: Subscription;
    private wsMiscInformationSub: Subscription;
    private wsSslConfigSub: Subscription;

    constructor(
        private _persistence: PersistenceService,
        private _esdService: EsdService) {}

    public ngOnDestroy(): void {
        if (this.wsEsdSystemSub) {
            this.wsEsdSystemSub.unsubscribe();
        }

        if (this.wsMiscInformationSub) {
            this.wsMiscInformationSub.unsubscribe();
        }

        if (this.wsSslConfigSub) {
            this.wsSslConfigSub.unsubscribe();
        }
    }

    public open(): void {
        this.isBusy = false;
        this.esdSystem = undefined;
        this.changeSettingsModal.show();
        this.wsEsdSystemSub = this.websocketGetEsdSystemStatus();
        this.wsMiscInformationSub = this.websocketGetMiscInformation();
        this.wsSslConfigSub = this._persistence
            .sslConfiguration
            .subscribe((data: SslConfiguration) => {
                if (data) {
                    this.sslConfiguration = data;
                }
            });
        this.updateEsdSystem();
    }

    public close(): void {
        this.changeSettingsModal.hide();
        this.wsEsdSystemSub.unsubscribe();
        this.wsSslConfigSub.unsubscribe();
    }

    // Template Helpers
    public etuToggle(etuEnabled: boolean): void {
        this._esdService.putEtu(this.systemId, !etuEnabled).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public etuPinsToggle(etuPins3536: boolean): void {
        this._esdService.putEtuPins(this.systemId, etuPins3536).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public contLinkToggle(contLinkEnabled: boolean): void {
        this._esdService.putContLink(this.systemId, !contLinkEnabled).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public contLinkOverrideToggle(contLinkOverrideInput: boolean): void {
        this._esdService.putContLinkOverride(this.systemId, !contLinkOverrideInput)
            .subscribe(() => {
                this.updateEsdSystem();
            });
    }

    public invertMlmTxRxToggle(invertMlmTxRx: boolean): void {
        this._esdService.putInvertMlmPolarity(this.systemId, !invertMlmTxRx).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public exchangeToggle(exchangeEnabled: boolean): void {
        this._esdService.putExchange(this.systemId, !exchangeEnabled).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public privateLineToggle(privateLineEnabled: boolean): void {
        this._esdService.putPrivateLine(this.systemId, privateLineEnabled).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public fibreOnBankAToggle(isElectricOnFibreA: boolean): void {
        this._esdService.putFibreOnBankA(this.systemId, !isElectricOnFibreA).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public fibreMlmToggle(isFibreMlm: boolean): void {
        this._esdService.putFibreMlm(this.systemId, !isFibreMlm).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public automaticResetToggle(isAutomaticReset: boolean): void {
        this._esdService.putAutomaticReset(this.systemId, !isAutomaticReset).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public mlmSourceStateChange(newMlmSourceState: MlmSourceStateEnum): void {
        this._esdService.putMlmBackplaneSwitch(this.systemId, newMlmSourceState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public eslStateChange(newEslState: EslStateEnum): void {
        this._esdService.putEslSwitch(this.systemId, newEslState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public modemSoundPoweredBankAChange(newState: boolean): void {
        this._esdService.putModemSoundPoweredBankA(this.systemId, !newState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public modemSoundPoweredBankBChange(newState: boolean): void {
        this._esdService.putModemSoundPoweredBankB(this.systemId, !newState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public modemOneConnectedChange(newState: boolean): void {
        this._esdService.putModemOneConnected(this.systemId, !newState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public modemTwoConnectedChange(newState: boolean): void {
        this._esdService.putModemTwoConnected(this.systemId, !newState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public modemOneFibreElectricChange(newState: boolean): void {
        this._esdService.putModemOneFibreElectric(this.systemId, !newState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public modemTwoFibreElectricChange(newState: boolean): void {
        this._esdService.putModemTwoFibreElectric(this.systemId, !newState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public mlmBridgedChange(newState: boolean): void {
        this._esdService.putMlmBridged(this.systemId, !newState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public mlmCrossedChange(newState: boolean): void {
        this._esdService.putMlmCrossed(this.systemId, !newState).subscribe(() => {
            this.updateEsdSystem();
        });
    }

    public fibreAnalogueChannelSetGain(channelNumber: number, newGain: number): void {
        this.isBusy = true;
        this._esdService.putFibreAnalogueGainSet(this.systemId, channelNumber, newGain)
            .subscribe(() => {
                this.updateEsdSystem().add(() => {
                    this.isBusy = false;
                });
            }, () => {
                this.isBusy = false;
            });
    }

    // Template helpers
    public increaseFibreAnalogueGain(channelNumber: number, currentGain: number): void {
        let gain = currentGain;

        gain += 5;
        if (gain > 255) {
            gain = 255;
        }

        this.fibreAnalogueChannelSetGain(channelNumber, gain);
    }

    public decreaseFibreAnalogueGain(channelNumber: number, currentGain: number): void {
        let gain = currentGain;

        gain -= 5;
        if (gain < 0) {
            gain = 0;
        }

        this.fibreAnalogueChannelSetGain(channelNumber, gain);
    }

    public calculateFibreAnalogueGain(currentGain: number): string {
        return ((currentGain / 255) * 100).toFixed(0) + '%';
    }

    // Helpers
    private updateEsdSystem(): Subscription {
        this.serviceGetMiscInformation();
        return this.serviceGetEsdSystem(this.systemId);
    }

    // Service Calls
    private serviceGetEsdSystem(systemId: string): Subscription {
        return this._esdService
            .getEsdSystems()
            .subscribe((data: EsdSystem[]) => {
                for (const system of data) {
                    if (system.id == systemId) {
                        if (this.esdSystem) {
                            this.esdSystem.updateEsdSystemState(system);
                        } else {
                            this.esdSystem = new EsdSystem(system);
                        }

                        break;
                    }
                }
            });
    }

    private serviceGetMiscInformation(): Subscription {
        return this._esdService
            .getMiscInformation()
            .subscribe((data: MiscInformation) => {
                this.miscInformation = data;
            });
    }

    private websocketGetEsdSystemStatus(): Subscription {
        return this._esdService
            .websocketGetEsdSystemStatus()
            .subscribe((data: WebsocketEsdSystemStatus) => {
                if (this.esdSystem && (data.id == this.esdSystem.id)) {
                    this.esdSystem.updateWebsocketEsdSystemState(data);
                }
            });
    }

    private websocketGetMiscInformation(): Subscription {
        return this._esdService
            .websocketGetMiscInformation()
            .subscribe((data: MiscInformation) => {
                this.miscInformation = data;
            });
    }
}
