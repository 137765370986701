import { Component, OnInit, OnDestroy } from '@angular/core';

import { PageConfigService } from '../shared/services/system/page-config.service';
import { ModuleTypeEnum } from '../shared/enums/module-type.enum';
import { ConfigService } from './services/config.service';
import { PersistenceService } from '../shared/services/system/persistence.service';
import { MlmConfiguration } from './models/mlm-configuration.model';
import { Subscription } from 'rxjs';
import { Jetty } from './models/jetty.model';
import { OperationService } from './services/operation.service';
import { PollStatus } from './models/poll-status.model';
import { HandshakeState } from './models/handshake-state.model';

@Component({
    selector: 'mlm',
    templateUrl: './mod_mlm.component.html',
    styleUrls: ['./mod_mlm.component.css']
})
export class MlmComponent implements OnInit, OnDestroy {

    public mlmConfiguration: MlmConfiguration;
    public currentJetty: Jetty = undefined;
    public canAcknowledgeAlarm: boolean = false;
    public pollStatus: PollStatus;
    public handshakeState: HandshakeState;
    public serverTimeSeconds: number = 0;
    public timeLeftProgressPercentage: number = 100;
    private webSockets: Subscription[] = [];

    constructor(
        private _configService: PageConfigService,
        private _mlmService: ConfigService,
        private _operationService: OperationService,
        private _persistence: PersistenceService,
    ) {}

    public ngOnInit(): void {
        this._mlmService
            .getConfig()
            .subscribe((data: MlmConfiguration) => {
                this._configService.setCurrentContext(ModuleTypeEnum.MLM);
                this.serviceGetPollStatus();
                this.serviceGetHandshakeState();
                this.mlmConfiguration = data;
                this._persistence.mlmConfiguration.next(data);
            });

        this.webSockets.push(this._mlmService
            .websocketGetConfig()
            .subscribe((data: MlmConfiguration) => {
                this.mlmConfiguration = data;
                this._persistence.mlmConfiguration.next(data);
            }));

        this.webSockets.push(this._operationService
            .websocketGetPollStatus()
            .subscribe((data: PollStatus) => {
                this.pollStatus = data;
            }));

        this.webSockets.push(this._operationService
            .websocketGetHandshakeState()
            .subscribe((data: HandshakeState) => {
                this.handshakeState = data;
            }));

        this.webSockets.push(this._persistence
            .isDebugToggled
            .subscribe((data) => {
                this._mlmService.toggleDebugMode().subscribe();
            }));

        this.webSockets.push(this._persistence
            .mlmConfiguration
            .subscribe((data: MlmConfiguration) => {
                if (data) {
                    this.currentJetty = data.currentJetty;
                }
            }));

        this.webSockets.push(this._persistence
            .mlmCanAcknowledgeAlarms
            .subscribe((data: boolean) => {
                this.canAcknowledgeAlarm = data;
            }));

        this.webSockets.push(this._persistence
            .serverTimeSeconds
            .subscribe((data: number) => {
                this.serverTimeSeconds = data;

                if (this.pollStatus && this.pollStatus.isPoll && this.pollStatus.pollInterval > 0) {
                    let timeRemainingPercentage: number =
                        (
                            (this.pollStatus.timeOfNextPoll - (this.serverTimeSeconds * 1000)) /
                            this.pollStatus.pollInterval
                        ) * 100;

                    if (timeRemainingPercentage < 0) {
                        timeRemainingPercentage = 0;
                    }

                    this.timeLeftProgressPercentage = timeRemainingPercentage;
                }
            }));
    }

    public ngOnDestroy(): void {
        for (const val of this.webSockets) {
            if (val) {
                val.unsubscribe();
            }
        }
    }

    public acknowledgeAlarms(): void {
        this.serviceAcknowledgeAlarm();
    }

    public pollNow(): void {
        this.servicePollNow();
    }

    private serviceAcknowledgeAlarm(): Subscription {
        return this._operationService
            .putAlarmAcknowledge()
            .subscribe(() => {
                this._persistence.mlmCanAcknowledgeAlarms.next(false);
            });
    }

    private servicePollNow(): Subscription {
        return this._operationService
            .putPollNow()
            .subscribe();
    }

    private serviceGetPollStatus(): Subscription {
        return this._operationService
            .getPollStatus()
            .subscribe((data: PollStatus) => {
                this.pollStatus = data;
            });
    }

    private serviceGetHandshakeState(): Subscription {
        return this._operationService
            .getHandshakeState()
            .subscribe((data: HandshakeState) => {
                this.handshakeState = data;
            });
    }
}
