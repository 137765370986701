import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ElectricLinePairConfigEnum } from '../enums/electric-line-pair-config.enum';

@Pipe({
    name: 'electricLinePairMiyakiEsd'
})
export class ElectricLinePairMiyakiEsdPipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(linePair: number): string {
        if (linePair == ElectricLinePairConfigEnum.NOT_CONNECTED) {
            return this._translate.instant('SSL.TERMINAL_CONN_NONE');
        }

        let leftPin = this.getLeftPinData(linePair);
        let rightPin = this.getRightPinData(linePair);
        let result = 'MIY ';

        switch (leftPin) {
            case ElectricLinePairConfigEnum.MIYAKI_1:
                result += '1';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_2:
                result += '2';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_3:
                result += '3';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_4:
                result += '4';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_5:
                result += '5';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_6:
                result += '6';
                break;
        }

        result += '/';

        switch (rightPin) {
            case ElectricLinePairConfigEnum.MIYAKI_1:
                result += '1';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_2:
                result += '2';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_3:
                result += '3';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_4:
                result += '4';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_5:
                result += '5';
                break;
            case ElectricLinePairConfigEnum.MIYAKI_6:
                result += '6';
                break;
        }

        return result;
    }

    public getLeftPinData(data: number): number {
        let buffer = new ArrayBuffer(4);
        let dataView = new DataView(buffer);
        dataView.setUint32(0, data, false);

        return dataView.getUint16(0, false);
    }

    public getRightPinData(data: number): number {
        let buffer = new ArrayBuffer(4);
        let dataView = new DataView(buffer);
        dataView.setUint32(0, data, false);

        return dataView.getUint16(2, false);
    }
}
