import { Component, Input, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap';
import { UslSystemV0Log } from '../../../../../models/usl-system/v0/log.model';
import { Subscription } from 'rxjs';
import { UslLogV0Service } from '../../../../../services/usl-system/v0-log.service';
import { Log as SslLog } from '../../../../../../mod_ssl/models/log.model';
import { LogService as SslLogService } from '../../../../../../mod_ssl/services/log.service';
import { UslSystemV0AlarmLog } from '../../../../../models/usl-system/v0/alarm-log.model';
import { Paged } from '../../../../../../shared/models/common/paged.model';
import { PersistenceService } from '../../../../../../shared/services/system/persistence.service';

@Component({
    selector: 'usl-display-v0-history-modal',
    styleUrls: ['./history.component.css'],
    templateUrl: './history.component.html'
})
export class UslDisplayV0ModalHistoryComponent {

    @Input() public activeSystemId: string;
    @Input() public uslSystemId: string;
    @Input() public isLocal: boolean;
    @ViewChild('historyModal') public historyModal: ModalDirective;

    public pageSize: number = 13;
    public isLoading: boolean = false;
    public isLoadingTabs: boolean = false;
    public currentTimeZone: string = 'UTC';

    public uslLogTableSortBy: string = 'timestamp';
    public uslLogTableSortByDesc: boolean = true;
    public uslLogRows: Paged<UslSystemV0Log>;

    public uslAlarmLogTableSortBy: string = 'timestamp';
    public uslAlarmLogTableSortByDesc: boolean = true;
    public uslAlarmLogRows: Paged<UslSystemV0AlarmLog>;

    public sslLogTableSortBy: string = 'timestamp';
    public sslLogTableSortByDesc: boolean = true;
    public sslLogRows: Paged<SslLog>;

    private webSockets: Subscription[] = [];

    constructor(
       private _logService: UslLogV0Service,
       private _sslLogService: SslLogService,
       private _persistence: PersistenceService,
    ) {}

    public onTabChange(): void {
        this.isLoadingTabs = true;
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            this.isLoadingTabs = false;
        }, 250);
    }

    public open(): void {
        this.webSockets = [];

        if (this.isLocal) {
            this.serviceGetLogs(this.uslLogTableSortBy,
                this.uslLogTableSortByDesc,
                1);
            this.serviceGetLocalAlarmLogs(this.uslAlarmLogTableSortBy,
                this.uslAlarmLogTableSortByDesc,
                1);
            this.serviceGetSslLogs(this.sslLogTableSortBy,
                this.sslLogTableSortByDesc,
                1);
            this.webSockets.push(this.websocketGetLogs());
            this.webSockets.push(this.websocketGetAlarmLogs());
            this.webSockets.push(this.websocketGetSslLogs());
        } else {
            this.serviceGetRemoteAlarmLogs(this.uslAlarmLogTableSortBy,
                this.uslAlarmLogTableSortByDesc,
                1);
            this.webSockets.push(this.websocketGetAlarmLogs());
        }

        this.webSockets.push(this._persistence
            .currentTimeZoneIANA
            .subscribe((data: string) => {
                this.currentTimeZone = data;
            }));

        this.historyModal.show();
    }

    public close(): void {
        this.historyModal.hide();
        for (const val of this.webSockets) {
            if (val) {
                val.unsubscribe();
            }
        }

        this.uslLogRows = undefined;
        this.sslLogRows = undefined;
        this.uslAlarmLogRows = undefined;
        this.webSockets = [];
    }

    public goToLogPage(pageInfo: any): void {
        this.serviceGetLogs(this.uslLogTableSortBy,
            this.uslLogTableSortByDesc,
            pageInfo.offset + 1);
    }

    public sortLogPage(sortInfo: any): void {
        this.uslLogTableSortBy = sortInfo.column.prop;
        this.uslLogTableSortByDesc = sortInfo.newValue == 'desc';
        this.serviceGetLogs(this.uslLogTableSortBy,
            this.uslLogTableSortByDesc,
            this.uslLogRows.pageIndex);
    }

    public goToUslAlarmLogPage(pageInfo: any): void {
        if (this.isLocal) {
            this.serviceGetLocalAlarmLogs(this.uslAlarmLogTableSortBy,
                this.uslAlarmLogTableSortByDesc,
                pageInfo.offset + 1);
        } else {
            this.serviceGetRemoteAlarmLogs(this.uslAlarmLogTableSortBy,
                this.uslAlarmLogTableSortByDesc,
                pageInfo.offset + 1);
        }
    }

    public sortUslAlarmLogPage(sortInfo: any): void {
        this.uslAlarmLogTableSortBy = sortInfo.column.prop;
        this.uslAlarmLogTableSortByDesc = sortInfo.newValue == 'desc';

        if (this.isLocal) {
            this.serviceGetLocalAlarmLogs(this.uslAlarmLogTableSortBy,
                this.uslAlarmLogTableSortByDesc,
                this.uslAlarmLogRows.pageIndex);
        } else {
            this.serviceGetRemoteAlarmLogs(this.uslAlarmLogTableSortBy,
                this.uslAlarmLogTableSortByDesc,
                this.uslAlarmLogRows.pageIndex);
        }
    }

    public goToSslLogPage(pageInfo: any): void {
        this.serviceGetSslLogs(this.sslLogTableSortBy,
            this.sslLogTableSortByDesc,
            pageInfo.offset + 1);
    }

    public sortSslLogPage(sortInfo: any): void {
        this.sslLogTableSortBy = sortInfo.column.prop;
        this.sslLogTableSortByDesc = sortInfo.newValue == 'desc';
        this.serviceGetSslLogs(this.sslLogTableSortBy,
            this.sslLogTableSortByDesc,
            this.sslLogRows.pageIndex);
    }

    // Service Calls
    private serviceGetLogs(orderByField: string,
                           isDesc: boolean,
                           pageIndex: number): Subscription {
        this.isLoading = true;
        return this._logService
            .getLogs(orderByField, isDesc, pageIndex, this.pageSize)
            .subscribe((data: Paged<UslSystemV0Log>) => {
                this.uslLogRows = data;
                this.isLoading = false;
            });
    }

    private serviceGetSslLogs(orderByField: string,
                              isDesc: boolean,
                              pageIndex: number): Subscription {
        this.isLoading = true;
        return this._sslLogService
            .getLogsBySystem(this.uslSystemId, orderByField, isDesc, pageIndex, this.pageSize)
            .subscribe((data: Paged<SslLog>) => {
                this.sslLogRows = data;
                this.isLoading = false;
            });
    }

    private serviceGetLocalAlarmLogs(orderByField: string,
                                     isDesc: boolean,
                                     pageIndex: number): Subscription {
        this.isLoading = true;
        return this._logService
            .getLocalAlarmLogs(orderByField, isDesc, pageIndex, this.pageSize)
            .subscribe((data: Paged<UslSystemV0AlarmLog>) => {
                this.uslAlarmLogRows = data;
                this.isLoading = false;
            });
    }

    private serviceGetRemoteAlarmLogs(orderByField: string,
                                      isDesc: boolean,
                                      pageIndex: number): Subscription {
        this.isLoading = true;
        return this._logService
            .getRemoteAlarmLogs(orderByField, isDesc, pageIndex, this.pageSize)
            .subscribe((data: Paged<UslSystemV0AlarmLog>) => {
                this.uslAlarmLogRows = data;
                this.isLoading = false;
            });
    }

    private websocketGetLogs(): Subscription {
        return this._logService
            .websocketGetLogs()
            .subscribe((data: UslSystemV0Log) => {
                if (data && this.uslLogRows) {
                    this.uslLogRows.items.push(data);
                }
            });
    }

    private websocketGetSslLogs(): Subscription {
        return this._sslLogService
            .websocketGetLogs()
            .subscribe((data: SslLog) => {
                if (data &&
                    (data.systemId == this.activeSystemId || data.systemId == this.uslSystemId)
                    && this.sslLogRows) {
                    this.sslLogRows.items.push(data);
                }
            });
    }

    private websocketGetAlarmLogs(): Subscription {
        return this._logService
            .websocketGetAlarmLogs()
            .subscribe((data: UslSystemV0AlarmLog) => {
                if (data && this.uslAlarmLogRows && data.isLocal == this.isLocal) {
                    this.uslAlarmLogRows.items.push(data);
                }
            });
    }
}
