export class LoadOption {

    public maxTonnage: number;
    public tonnageLines: number;

    constructor(data: any) {
        this.maxTonnage = data.maxTonnage;
        this.tonnageLines = data.tonnageLines;
    }
}
