import { SystemLocationEnum } from '../../shared/enums/system-location.enum';

export class PatternJoin {

    public hookId: string;
    public tetherId: string;
    public patternId: string;
    public shipSide: SystemLocationEnum;

    constructor(data: any) {
        this.hookId = data.hookId || undefined;
        this.tetherId = data.tetherId || undefined;
        this.patternId = data.patternId || undefined;
        this.shipSide = data.shipSide;
    }
}
