import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BoardTypeEnum } from '../enums/board-type.enum';

@Pipe({
    name: 'boardType'
})
export class BoardTypePipe implements PipeTransform {

    constructor(private _translate: TranslateService) {
    }

    public transform(boardType: BoardTypeEnum): string {
        switch (boardType) {
            case BoardTypeEnum.ELECTRIC:
                return 'ELECTRIC';
            case BoardTypeEnum.FIBRE_DIGITAL:
                return 'FIBRE DIGITAL';
            case BoardTypeEnum.FIBRE_ANALOGUE:
                return 'FIBRE ANALOGUE';
            case BoardTypeEnum.LOCALBOARD:
                return 'LOCAL';
            case BoardTypeEnum.Esl:
                return 'ESL';
            case BoardTypeEnum.Pneumatic:
                return 'PNEUMATIC';
            default:
                return this._translate.instant('SSL.TERMINAL_CONN_NONE');
        }
    }
}
