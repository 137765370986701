import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'number-element',
    styleUrls: ['./number.component.css'],
    templateUrl: './number.component.html'
})
export class NumberElementComponent {

    @Input() public value: number;
    @Input() public step: number;
    @Input() public min: number;
    @Input() public max: number;
    @Output() public onChange: EventEmitter<number> = new EventEmitter<number>();

    // Template Helpers
    public increaseNumber(): void {
        this.value += this.step;

        if (this.value > this.max) {
            this.value = this.max;
        }

        this.isChanged(this.value);
    }

    public decreaseNumber(): void {
        this.value -= this.step;

        if (this.value < this.min) {
            this.value = this.min;
        }

        this.isChanged(this.value);
    }

    // Helpers
    private isChanged(newValue: number): void {
        this.onChange.emit(newValue);
    }
}
