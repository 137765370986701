import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Event,
    Router
} from '@angular/router';
import { Subscription, interval } from 'rxjs';
import * as sync from 'css-animation-sync';

import { CONFIGURATION } from './app.constants';

// Third Party
import { setTheme } from 'ngx-bootstrap';

// Models
import { PageConfig } from './shared/models/system/page-config.model';

// Service
import { PageConfigService } from './shared/services/system/page-config.service';
import { PersistenceService } from './shared/services/system/persistence.service';
import { ScreenOptionService } from './shared/services/system/screen-option.service';
import { ScreenOption } from './shared/models/system/screen-option.model';
import { EsdService } from './shared/services/esd/esd.service';
import { BaseSafetyEvent } from './shared/models/safety/events/base-safety-event.model';
import { CommonConfigurationService } from './shared/services/common/common-configuration.service';
import { LocalboardConfiguration } from './shared/models/common/localboard-configuration.model';
import { CssSync } from './shared/helpers/css-sync.helper';
import { DateTimeService } from './shared/services/platform/datetime.service';
import { TimezoneDto } from './shared/models/system/dto/timezone-dto.model';

export const ROOT_SELECTOR = 'mi-app';

@Component({
    selector: ROOT_SELECTOR,
    styleUrls: ['./app.component.css'],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    public isLoading: boolean = false;
    public loadingProgress: number = 0;

    private fakeBrightness: number = 0.0;
    private loaderSub: Subscription;
    private persistencePageSub: Subscription;
    private persistenceBrainSub: Subscription;
    private persistenceConfigSub: Subscription;
    private persistenceSafetyEventSub: Subscription;
    private persistenceLocalboardConfigSub: Subscription;
    private persistenceTimezoneChangesSub: Subscription;

    constructor(
        private _router: Router,
        private _configService: PageConfigService,
        private _persistence: PersistenceService,
        private _titleService: Title,
        private _esdService: EsdService,
        private _screenOptionService: ScreenOptionService,
        private _commonConfigService: CommonConfigurationService,
        private _dateTimeService: DateTimeService,
    ) {
        setTheme('bs3');
        const cssSyncManager = new CssSync();
        cssSyncManager.sync([
            'flash-red',
            'flash-slow-red',
            'flash-cable-red',
            'flash-orange',
            'flash-light-blue',
            'flash-blue',
            'flash-green',
        ]);
    }

    public ngOnInit(): void {
        this._router.events.subscribe((event: Event) => {
            this.navigationInterceptor(event);
        });

        this._persistence
            .screenOptions
            .next(this._screenOptionService.getScreenOption());

        this.persistencePageSub = this._configService
            .getConfig()
            .subscribe((config: PageConfig) => {
                let pageTitle: string = '';
                if (config.breadcrumbs.length > 0) {
                    for (const breadcrumb of config.breadcrumbs) {
                        pageTitle += breadcrumb.title + ' / ';
                    }
                }

                pageTitle += config.title;
                this._titleService.setTitle(pageTitle + ' - ' + CONFIGURATION.siteTitle);
            });

        this.persistenceBrainSub = this._persistence
            .brainSystemError
            .subscribe((data: number) => {
                if (data != -1) {
                    this._router.navigate(['/system-error', data]);
                }
            });

        this.persistenceConfigSub = this._persistence
            .screenOptions
            .subscribe((data: ScreenOption) => {
                if (data) {
                    this.fakeBrightness = data.fakeBrightness;
                }
            });

        this._commonConfigService
            .getLocalboardConfiguration()
            .subscribe((data: LocalboardConfiguration) => {
                this._persistence.localboardConfiguration.next(data);
            });

        this.persistenceLocalboardConfigSub = this._commonConfigService
            .websocketGetLocalboardConfiguration()
            .subscribe((data: LocalboardConfiguration) => {
                this._persistence.localboardConfiguration.next(data);
            });

        this.persistenceSafetyEventSub = this._esdService
            .websocketGetSafetyEvents()
            .subscribe((data: BaseSafetyEvent) => {
                this._persistence.lastSafetyEvent.next(data);
            });

        this._dateTimeService
            .getTimezone()
            .subscribe((data: TimezoneDto) => {
                if (data) {
                    this._persistence.currentTimeZoneIANA.next(data.timeZoneIANA);
                }
            });

        this.persistenceTimezoneChangesSub = this._dateTimeService
            .webSocketGetTimezone()
            .subscribe((data: TimezoneDto) => {
                if (data) {
                    this._persistence.currentTimeZoneIANA.next(data.timeZoneIANA);
                }
            });
    }

    public ngOnDestroy(): void {
        this.persistencePageSub.unsubscribe();
        this.persistenceBrainSub.unsubscribe();
        this.persistenceConfigSub.unsubscribe();
        this.persistenceSafetyEventSub.unsubscribe();
        this.persistenceLocalboardConfigSub.unsubscribe();
    }

    // Template helpers
    public calculateFakeBrightness(): number {
        if (this.fakeBrightness &&
            this.fakeBrightness >= CONFIGURATION.minMaxFakeBrightness[0] &&
            this.fakeBrightness <= CONFIGURATION.minMaxFakeBrightness[1]) {
            return (100 - this.fakeBrightness) / 100;
        }
        return 0.0;
    }

    // Helpers
    private navigationInterceptor(event: Event): void {
        if (event instanceof NavigationStart) {
            this.loadingProgress = 0;
            this.isLoading = true;
            this.startLoader();
        }

        if (event instanceof NavigationEnd) {
            this.loadingProgress = 100;
            setTimeout(() => {
                this.isLoading = false;
                setTimeout(() => {
                    this.stopLoader();
                }, 400);
            }, 200);
        }

        if (event instanceof NavigationCancel) {
            this.isLoading = false;
            this.stopLoader();
        }

        if (event instanceof NavigationError) {
            this.stopLoader();
        }
    }

    private startLoader(): void {
        this.stopLoader();
        this.loadingProgress = 30;
        this.loaderSub = interval(1000)
            .subscribe(() => {
                if (this.loadingProgress < 70) {
                    this.loadingProgress += 1;
                }
            });
    }

    private stopLoader(): void {
        if (this.loaderSub) {
            this.loaderSub.unsubscribe();
        }
    }
}
