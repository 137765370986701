import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { interval, Observable, Subscriber, Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { BaseService } from '../base.service';
import { PersistenceService } from './persistence.service';

import { SystemConfiguration } from '../../models/system/configuration.model';
import { CONFIGURATION } from '../../../app.constants';
import { SystemConfigurationDto } from '../../models/system/dto/system-configuration-dto.model';
import { WebSocketService } from '../websocket/websocket.service';
import { BrainMetadataDto } from '../../models/system/dto/brain-metadata-dto.model';
import { KeepAlive } from '../../models/system/keep-alive.model';
import { ConnectionStateEnum } from '../../enums/connection-state.enum';
import { MetaService } from './meta.service';
import { StabilityEnum } from '../../enums/system/stability.enum';
import { Version } from '../../models/system/version.model';

@Injectable()
export class SystemConfigurationService extends BaseService implements OnDestroy {

    private mirrors: BrainMetadataDto[] = [];
    private brainLastMessage: number = Date.now();
    private monitorBrainSub: Subscription;
    private pollTimer: Subscription = undefined;

    constructor(
        _http: HttpClient,
        private _persistence: PersistenceService,
        private _translateService: TranslateService,
        private _metaService: MetaService,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'system/configuration';
    }

    public ngOnDestroy(): void {
        if (this.monitorBrainSub) {
            this.monitorBrainSub.unsubscribe();
        }
    }

    // NOTE: This should only be called once from the APP_INITIALIZER
    public load(): Promise<SystemConfiguration> {
        const inputAccessToken = this.getParameterByName('access_token', window.location.href);
        const screenVersion = this.getParameterByName('screen_version', window.location.href);

        const currentAuthToken = localStorage.getItem(CONFIGURATION.authTokenName);
        if (inputAccessToken && inputAccessToken.length > 0) {
            localStorage.setItem(CONFIGURATION.authTokenName, inputAccessToken);
        } else if (!currentAuthToken || currentAuthToken.length <= 0) {
            // Production Testing Token: 5c39695d3f4e4a3cab6388ae21d75d21
            if (!CONFIGURATION.isProduction) {
                localStorage.setItem(CONFIGURATION.authTokenName, '65c6b61620f544c69bcb3180eff63651');
            }
        }

        if (screenVersion && screenVersion.length > 0) {
            localStorage.setItem(CONFIGURATION.screenTokenName, screenVersion);
            this._persistence.screenVersion.next(screenVersion);
        } else {
            const storedScreenVersion = localStorage.getItem(CONFIGURATION.screenTokenName);

            if (storedScreenVersion) {
                this._persistence.screenVersion.next(storedScreenVersion);
            }
        }

        return new Promise<SystemConfiguration>((resolve, reject) => {
            this.getConfiguration()
                .subscribe((data: SystemConfiguration) => {
                    // Initial Blocking App Load (Keep light, try defer load for non-crucial setup)
                    this._translateService.setDefaultLang(CONFIGURATION.siteDefaultLang);
                    this._translateService
                        .use(data.languageCode)
                        .subscribe(() => {
                            if (CONFIGURATION.useMockData) { // Only for testing/demo purposes
                                this._persistence.brainConnectionState.next(ConnectionStateEnum.CONNECTED);
                                this._persistence.brainSystemError.next(-1);
                                this._persistence.commonAlarm.next({activeAlarms: [1], rawState: 2, isTripped: true});
                                this._persistence.brainMirrors.next([]);
                                this._persistence.serverTimeSeconds.next(Date.now() / 1000);
                                this._persistence.neuralTransfersInProgress.next(0);
                                resolve(data);
                                return;
                            }

                            this._metaService
                                .getStability()
                                .subscribe((stability: StabilityEnum) => {
                                    if (stability == StabilityEnum.Stable) {
                                        this.webSocketGetSystemConfiguration()
                                            .subscribe((config: SystemConfiguration) => {
                                                const currentConfig = this._persistence
                                                    .systemConfiguration
                                                    .getValue();
                                                if (config && config.id == currentConfig.id) {
                                                    this._persistence.systemConfiguration.next(config);
                                                } else if (config && config.id != currentConfig.id) {
                                                    currentConfig.systemConfiguration =
                                                        config.systemConfiguration;
                                                    this._persistence.systemConfiguration.next(currentConfig);
                                                }
                                            });
                                        this.monitorBrain();
                                        resolve(data);
                                    } else {
                                        this._persistence.brainConnectionState.next(ConnectionStateEnum.DISCONNECTED);
                                        this._persistence.brainSystemError.next(599);
                                        resolve(undefined);
                                    }
                                },
                                (error) => {
                                    this.startPolling();
                                    let errorCode = 0;
                                    if (error.status) {
                                        errorCode = error.status;
                                    }
                                    this._persistence.brainSystemError.next(errorCode);
                                    this._persistence.brainConnectionState
                                        .next(ConnectionStateEnum.DISCONNECTED);
                                    resolve(undefined);
                                });
                        },
                        (error) => {
                            this.startPolling();
                            let errorCode = 0;
                            if (error.status) {
                                errorCode = error.status;
                            }
                            this._persistence.brainSystemError.next(errorCode);
                            this._persistence.brainConnectionState
                                .next(ConnectionStateEnum.DISCONNECTED);
                            resolve(undefined);
                        });
                },
                (error: Response) => {
                    this.startPolling();
                    let errorCode = 0;
                    if (error.status) {
                        errorCode = error.status;
                    }
                    this._persistence.brainSystemError.next(errorCode);
                    this._persistence.brainConnectionState
                        .next(ConnectionStateEnum.DISCONNECTED);
                    resolve(undefined);
                });
        });
    }

    public getConfiguration(): Observable<SystemConfiguration> {
        return new Observable<SystemConfiguration>((subscriber: Subscriber<SystemConfiguration>) => {
            if (!this._persistence.systemConfiguration.getValue()) {
                this._http
                    .get<SystemConfiguration>(this.actionUrl, this.getRequestOptions())
                    .pipe(timeout(CONFIGURATION.pingTimeout))
                    .subscribe((data: SystemConfiguration) => {
                        this._persistence.systemConfiguration.next(data);
                        subscriber.next(this._persistence.systemConfiguration.getValue());
                        subscriber.complete();
                    }, (error: Response) => {
                        subscriber.error(error);
                    });
            } else {
                subscriber.next(this._persistence.systemConfiguration.getValue());
                subscriber.complete();
            }
        });
    }

    public putUpdateConfiguration(updatedConfig: SystemConfigurationDto): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl,
                JSON.stringify(updatedConfig), this.getFullRawRequestOptions());
    }

    public setBootupModule(config: SystemConfiguration): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/bootup',
                JSON.stringify(config), this.getFullRawRequestOptions());
    }

    public postDefaultSettings(): Observable<HttpResponse<string>> {
        return this._http
            .post(this.actionUrl + '/default',
                JSON.stringify({}), this.getFullRawRequestOptions());
    }

    public getVersions(): Observable<any> {
        return this._http
            .get(this.actionUrl + '/versions',
                this.getRequestOptions());
    }

    public webSocketGetSystemConfiguration(): Observable<SystemConfiguration> {
        return this._ws.get('/wsapi/system/screen-config');
    }

    public webSocketGetKeepAlive(): Observable<KeepAlive> {
        return this._ws.get('/wsapi/system/keep-alive');
    }

    public webSocketGetVersions(): Observable<Version> {
        return this._ws.get('/wsapi/system/version');
    }

    public webSocketGetMirrorKeepAlive(host: string): Observable<KeepAlive> {
        return this._ws.getFullPath(host + '/wsapi/system/keep-alive');
    }

    // Helpers
    private startPolling(): void {
        if (this.pollTimer !== undefined) {
            return;
        }

        // TODO: Maybe look at changing this to set timeout??
        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                this.isBrainAlive()
                    .subscribe((brainFound: boolean) => {
                        this._persistence.brainConnectionState.next(ConnectionStateEnum.CONNECTING);
                        if (brainFound) {
                            this._metaService
                                .getStability()
                                .subscribe((data: StabilityEnum) => {
                                    if (data == StabilityEnum.Stable) {
                                        this._persistence.brainSystemError.next(303);

                                        if (!CONFIGURATION.isHmr) {
                                            window.location.href = '/';
                                        }
                                    } else {
                                        this._persistence.brainConnectionState.next(ConnectionStateEnum.DISCONNECTED);
                                        this._persistence.brainSystemError.next(599);
                                    }
                                }, () => {
                                    this._persistence.brainConnectionState.next(ConnectionStateEnum.DISCONNECTED);
                                    this._persistence.brainSystemError.next(0);
                                    this.startPolling();
                                });
                        } else {
                            this._persistence.brainConnectionState.next(ConnectionStateEnum.CONNECTING);
                            this._persistence.brainSystemError.next(1);
                            this.isMirrorsAlive()
                                .subscribe((mirrorFound: boolean) => {
                                    if (!mirrorFound) {
                                        this._persistence.brainConnectionState.next(ConnectionStateEnum.DISCONNECTED);
                                        this._persistence.brainSystemError.next(0);
                                        this.startPolling();
                                    } else {
                                        this._persistence.brainSystemError.next(303);
                                    }
                                });
                        }
                    });
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
            this.pollTimer = undefined;
        }
    }

    private monitorBrain(): void {
        this._persistence.brainConnectionState.next(ConnectionStateEnum.CONNECTING);
        const timeoutChecker = interval(CONFIGURATION.pingTimeout)
            .subscribe(() => {
                if ((this.brainLastMessage + CONFIGURATION.pingTimeout) <= Date.now()) {
                    timeoutChecker.unsubscribe();
                    this._persistence.brainConnectionState.next(ConnectionStateEnum.DISCONNECTED);
                    this._persistence.brainSystemError.next(1);
                    this.startPolling();
                }
            });

        this.monitorBrainSub = this.webSocketGetKeepAlive()
            .subscribe((data: KeepAlive) => {
                if (this._persistence.brainConnectionState.getValue() != ConnectionStateEnum.CONNECTED) {
                    this._persistence.brainConnectionState.next(ConnectionStateEnum.CONNECTED);
                }

                if (this._persistence.brainSystemError.getValue() != -1) {
                    this._persistence.brainSystemError.next(-1);
                }

                this.brainLastMessage = Date.now();
                this.mirrors = data.mirrors;

                this._persistence.commonAlarm.next(data.commonAlarm);
                this._persistence.brainMirrors.next(data.mirrors);
                this._persistence.serverTimeSeconds.next(data.timestamp);
                this._persistence.neuralTransfersInProgress.next(data.neuralTransfersInProgress);
            }, () => {
                this.monitorBrainSub.unsubscribe();
                this._persistence.brainConnectionState.next(ConnectionStateEnum.DISCONNECTED);
                this._persistence.brainSystemError.next(1);
                this.startPolling();
            });
    }

    private isBrainAlive(): Observable<boolean> {
        return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
            const tmpKeepAliveSocket = this.webSocketGetKeepAlive()
                .pipe(timeout(CONFIGURATION.pingTimeout))
                .subscribe(() => {
                    tmpKeepAliveSocket.unsubscribe();
                    subscriber.next(true);
                    subscriber.complete();
                }, () => {
                    tmpKeepAliveSocket.unsubscribe();
                    subscriber.next(false);
                    subscriber.complete();
                });
        });
    }

    private isMirrorsAlive(): Observable<boolean> {
        return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
            if (!this.mirrors || this.mirrors.length <= 0) {
                subscriber.next(false);
                subscriber.complete();
            }

            let mirrorsResponded = 0;
            let isFound: boolean = false;
            for (const mirror of this.mirrors) {
                for (const ipAddresses of mirror.ipAddresses) {
                    const mirrorUri = 'ws://' + ipAddresses + ':' + CONFIGURATION.prodConfig.port;
                    const tmpKeepAliveSocket = this.webSocketGetMirrorKeepAlive(mirrorUri)
                        .pipe(timeout(CONFIGURATION.pingTimeout))
                        .subscribe(() => {
                            tmpKeepAliveSocket.unsubscribe();
                            if (isFound) {
                                return;
                            }

                            isFound = true;
                            mirrorsResponded += 1;
                            window.location.href = this.getMirrorRedirectPath(ipAddresses);
                        }, () => {
                            tmpKeepAliveSocket.unsubscribe();
                            mirrorsResponded += 1;
                        });
                }
            }

            let currentTries = 0;
            const maxTries = 20;
            const checkInterval = setInterval(() => {
                if (currentTries >= maxTries || mirrorsResponded >= this.mirrors.length) {
                    clearInterval(checkInterval);
                    subscriber.next(isFound);
                    subscriber.complete();
                }

                currentTries++;
            }, 500);
        });
    }

    private getParameterByName(name: string, url: string): string {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results) {
            return undefined;
        }

        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    private getMirrorRedirectPath(ipAddress: string): string {
        const currentAuthToken = localStorage.getItem(CONFIGURATION.authTokenName);
        const screenVersion = localStorage.getItem(CONFIGURATION.screenTokenName);
        const showCursor = localStorage.getItem(CONFIGURATION.showCursorOptionTokenName);

        if (CONFIGURATION.isProduction) {
            let httpPrefix = 'http://';
            if (CONFIGURATION.prodConfig.useHttps) {
                httpPrefix = 'https://';
            }

            let redirectPath = httpPrefix + ipAddress +
                ':' + CONFIGURATION.prodConfig.port +
                '/?access_token=' + currentAuthToken;

            if (screenVersion) {
                redirectPath += '&screen_version=' + screenVersion;
            }

            if (showCursor) {
                redirectPath += '&show_cursor=' + showCursor;
            }

            return  redirectPath;
        } else {
            let httpPrefix = 'http://';
            if (CONFIGURATION.devConfig.useHttps) {
                httpPrefix = 'https://';
            }

            let redirectPath = httpPrefix + ipAddress +
                ':' + CONFIGURATION.devConfig.port +
                '/?access_token=' + currentAuthToken;

            if (screenVersion) {
                redirectPath += '&screen_version=' + screenVersion;
            }

            if (showCursor) {
                redirectPath += '&show_cursor=' + showCursor;
            }

            return redirectPath;
        }
    }
}
