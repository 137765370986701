import { Component, Input } from '@angular/core';

import { Jetty } from '../../models/jetty.model';
import { EnvironmentalOption } from '../../models/environmental-option.model';
import { EnvironmentalDataTypeEnum } from '../../enums/environmental-data-type.enum';
import { EnvironmentalDataCompass } from '../../models/environmental-data-compass.model';
import { CompassTypeEnum } from '../../enums/compass-type.enum';
import { CompassDisplayLine } from '../../models/compass-display-line.model';

@Component({
    selector: 'mlm-operations-weather-display',
    styleUrls: ['./weather-display.component.css'],
    templateUrl: './weather-display.component.html'
})
export class MlmOperationsWeatherDisplayComponent {

    @Input() public currentJetty: Jetty;
    @Input() public environmentalOption: EnvironmentalOption;
    @Input() public notchCount: number = 180;
    @Input() public notchSpacing: number = 8;
    @Input() public bigNotchEvery: number = 10;

    @Input() public compassLineCount: number = 20;
    @Input() public compassThickLineEvery: number = 5;

    public environmentalDataType = EnvironmentalDataTypeEnum;
    public compassDataType = CompassTypeEnum;
    public notchOffsets: number[] = [];
    public mediumNotchOffsets: number[] = [];
    public bigNotchOffsets: number[] = [];
    public compassLines: CompassDisplayLine[] = [];
    public mediumNotchEvery: number = this.bigNotchEvery / 2;

    constructor() {
        this.mediumNotchEvery = this.bigNotchEvery / 2;
        const mediumNotchSpacing: number = this.notchSpacing * this.mediumNotchEvery;
        const bigNotchSpacing: number = this.notchSpacing * this.bigNotchEvery;

        for (let small = 0; small <= this.notchCount; ++small) {
            this.notchOffsets.push(small * this.notchSpacing);
        }

        for (let medium = 0; medium <= (this.notchCount / this.mediumNotchEvery); ++medium) {
            this.mediumNotchOffsets.push(medium * mediumNotchSpacing);
        }

        for (let big = 0; big <= (this.notchCount / this.bigNotchEvery); ++big) {
            this.bigNotchOffsets.push(big * bigNotchSpacing);
        }

        const degreesSplit = 180 / this.compassLineCount;
        for (let lineIndex = 0; lineIndex < this.compassLineCount; ++lineIndex) {
            this.compassLines[lineIndex] = new CompassDisplayLine({
                rotation: degreesSplit * lineIndex,
                isThickLine: (lineIndex % this.compassThickLineEvery == 0),
            });
        }
    }

    public getDisplayValue(value: number, displayFormat: string, unit: string): string {
        if (displayFormat && displayFormat.includes('##0.0')) {
            return `${displayFormat.replace('##0.0', value.toFixed(1))} ${unit}`;
        } else if (displayFormat && displayFormat.includes('##0')) {
            return `${displayFormat.replace('##0', value.toFixed(0))} ${unit}`;
        } else {
            return `${value.toFixed(0)} ${unit}`;
        }
    }

    public getTrueRotationValue(compassData: EnvironmentalDataCompass): number {
        return compassData.channelValue;
    }

    public getRelativeRotationValue(compassData: EnvironmentalDataCompass): number {
        return compassData.northOffset + compassData.channelValue;
    }

    public buildArrayOfSize(n: number): any[] {
        return Array(n);
    }
}
