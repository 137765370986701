import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';
import { MlmConfiguration } from '../models/mlm-configuration.model';

@Component({
    selector: 'mlm-home',
    styleUrls: ['./home.component.css'],
    templateUrl: './home.component.html'
})
export class MlmHomeComponent implements OnInit, OnDestroy {

    public isLoading: boolean = true;
    private routerSub: Subscription;
    private persistenceSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _configService: PageConfigService,
        private _persistence: PersistenceService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.isLoading = true;
                this.updateNavigation();
                this.persistenceSub = this._persistence
                    .mlmConfiguration
                    .subscribe((data: MlmConfiguration) => {
                        if (data) {
                            if (data.currentJetty) {
                                this._router.navigate(['/mlm', 'operations']);
                            } else {
                                this._router.navigate(['/mlm', 'jetties']);
                            }
                        }
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 2000);
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        this._configService.setConfig(new PageConfig({
            title: 'MLM',
            breadcrumbs: pageBreadcrumbs
        }));
    }
}
