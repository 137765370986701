export class Jetty {

    public id: string;
    public name: string;
    public isFavourite: boolean;
    public isImported: boolean;

    constructor(data: any) {
        this.id = data.id || undefined;
        this.name = data.name || undefined;

        this.isFavourite = false;
        if (data.isFavourite != null) {
            this.isFavourite = data.isFavourite;
        }

        this.isImported = false;
        if (data.isImported != null) {
            this.isImported = data.isImported;
        }
    }
}
