import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Country } from '../../models/system/country.model';
import { Subscriber, Observable } from 'rxjs';
import { PersistenceService } from './persistence.service';
import { CONFIGURATION } from '../../../app.constants';

@Injectable()
export class CountryService extends BaseService {

    constructor(
        _http: HttpClient,
        private _persistence: PersistenceService
    ) {
        super(_http);
        this.actionUrl += 'system/country';
    }

    public getCountries(): Observable<Country[]> {
        return new Observable<Country[]>((subscriber: Subscriber<Country[]>) => {
            if (this._persistence.countries.getValue().length <= 0) {
                this._http
                    .get<Country[]>(this.actionUrl, this.getRequestOptions())
                    .subscribe((data: Country[]) => {
                        this._persistence.countries.next(data);
                        subscriber.next(this._persistence.countries.getValue());
                        subscriber.complete();
                    });
            } else {
                subscriber.next(this._persistence.countries.getValue());
                subscriber.complete();
            }
        });
    }
}
