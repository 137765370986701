import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Terminal } from '../../../../models/terminal.model';
import { ConnectionSystem } from '../../../../models/connection-system.model';
import { ConnectionTypeEnum } from '../../../../../shared/enums/connection-type.enum';
import { ConnectionConfiguration } from '../../../../models/connection-configuration.model';
import { SslConfiguration } from '../../../../models/ssl-configuration.model';

@Component({
    selector: 'ssl-terminals-config-step-3',
    styleUrls: ['./step-3.component.css', '../step-common/step-common.component.css'],
    templateUrl: './step-3.component.html'
})
export class SslTerminalsConfigStep3Component implements OnInit {

    @Input() public currentStep: number;
    @Input() public sslConfiguration: SslConfiguration;
    @Input() public availableConnectionTypes: ConnectionTypeEnum[];
    @Input() public currentTerminal: Terminal;
    @Input() public isLoading: boolean;
    @Output() public loadingState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public validatedState: EventEmitter<boolean> = new EventEmitter<boolean>();

    public selectedSystem: ConnectionSystem;

    public ngOnInit(): void {
        this.selectedSystem = undefined;
        this.loadingState.emit(false);
        this.doValidation();
    }

    // Helper methods
    public toggleSelectSystem(system: ConnectionSystem): void {
        if (!this.selectedSystem || this.selectedSystem != system) {
            this.selectedSystem = system;
        } else if (this.selectedSystem == system) {
            this.selectedSystem = undefined;
        }

        this.doValidation();
    }

    public systemSaved(config: ConnectionConfiguration): void {
        this.selectedSystem.esdConfiguration = config;
        let index = this.currentTerminal.connectionSystems.indexOf(this.selectedSystem);
        if (index !== -1) {
            this.currentTerminal.connectionSystems[index] = this.selectedSystem;
        }
        this.selectedSystem = undefined;
        this.doValidation();
    }

    public systemCancelled(): void {
        this.selectedSystem = undefined;
        this.doValidation();
    }

    public getAvailableConnectionSystems(): ConnectionSystem[] {
        return this.currentTerminal.connectionSystems
            .filter((system: ConnectionSystem) => this.isSystemUsable(system));
    }

    // Internal Helpers
    private doValidation(): void {
        if (this.selectedSystem) {
            this.validatedState.emit(false);
        } else {
            this.validatedState.emit(true);
        }
    }

    private isSystemUsable(system: ConnectionSystem): boolean {
        if (system.esdConfiguration &&
            system.esdConfiguration.isEnabled &&
            this.availableConnectionTypes.indexOf(system.esdConfiguration.connectionType) === -1) {
            return false;
        }

        if (system.telecommConfiguration &&
            system.telecommConfiguration.isEnabled &&
            this.availableConnectionTypes
                .indexOf(system.telecommConfiguration.connectionType) === -1) {
            return false;
        }

        return true;
    }
}
