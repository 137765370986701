import { Component, Input } from '@angular/core';

import { ConnectionSystem } from '../../../../../models/connection-system.model';
import { ConnectionTypeEnum } from '../../../../../../shared/enums/connection-type.enum';
import { BoardTypeEnum } from '../../../../../../shared/enums/board-type.enum';
import { ConnectionSystemRelatedSystem } from '../../../../../models/connection-system-related-system.model';

@Component({
    selector: 'ssl-terminals-config-remote-switch-config',
    styleUrls: ['./remote-switch-config.component.css',
        '../../step-common/step-common.component.css'],
    templateUrl: './remote-switch-config.component.html'
})
export class SslTerminalsConfigRemoteSwitchConfigComponent {

    @Input() public availableConnectionTypes: ConnectionTypeEnum[];
    @Input() public allConnectionSystems: ConnectionSystem[];
    @Input() public currentConnectionSystem: ConnectionSystem;

    public connectionTypeEnum = ConnectionTypeEnum;
    public boardTypeEnum = BoardTypeEnum;

    public electricConnectionTypes: ConnectionTypeEnum = ConnectionTypeEnum.ITT_CANNON |
        ConnectionTypeEnum.THIRTYSEVENWAY |
        ConnectionTypeEnum.MIYAKI;

    // Template Helpers
    public getSystemsByConnectionType(connectionType: ConnectionTypeEnum): ConnectionSystem[] {
        let foundConnectionSystems: ConnectionSystem[] = [];

        for (let connectionSystem of this.allConnectionSystems) {
            if (connectionSystem.esdConfiguration
                && connectionSystem.esdConfiguration.connectionType & connectionType) {
                foundConnectionSystems.push(connectionSystem);
            } else if (connectionSystem.telecommConfiguration
                && connectionSystem.telecommConfiguration.connectionType & connectionType) {
                foundConnectionSystems.push(connectionSystem);
            }
        }

        return foundConnectionSystems;
    }

    public getRelatedSystemOfType(connectionType: ConnectionTypeEnum): ConnectionSystem {
        if (this.currentConnectionSystem.relatedToSystems) {
            for (let relatedTo of this.currentConnectionSystem.relatedToSystems) {
                if (connectionType & relatedTo.relationConnectionType) {
                    if (relatedTo.connectionSystemRelationId &&
                        relatedTo.connectionSystemRelationId.length > 0) {
                        return this.findSystemById(relatedTo.connectionSystemRelationId);
                    }
                } else if (connectionType & relatedTo.originConnectionType) {
                    if (relatedTo.connectionSystemOriginId &&
                        relatedTo.connectionSystemOriginId.length > 0) {
                        return this.findSystemById(relatedTo.connectionSystemOriginId);
                    }
                }
            }
        }

        if (this.currentConnectionSystem.relatedFromSystems) {
            for (let relatedFrom of this.currentConnectionSystem.relatedFromSystems) {
                if (connectionType & relatedFrom.relationConnectionType) {
                    if (relatedFrom.connectionSystemRelationId &&
                        relatedFrom.connectionSystemRelationId.length > 0) {
                        return this.findSystemById(relatedFrom.connectionSystemRelationId);
                    }
                } else if (connectionType & relatedFrom.originConnectionType) {
                    if (relatedFrom.connectionSystemOriginId &&
                        relatedFrom.connectionSystemOriginId.length > 0) {
                        return this.findSystemById(relatedFrom.connectionSystemOriginId);
                    }
                }
            }
        }

        return undefined;
    }

    public findSystemById(systemId: string): ConnectionSystem {
        for (let connectionSystem of this.allConnectionSystems) {
            if (connectionSystem.id == systemId) {
                return connectionSystem;
            }
        }

        return undefined;
    }

    public changeRelatedSystem(newSystem: ConnectionSystem,
                               newSystemConnectionType: ConnectionTypeEnum): void {
        if (!this.currentConnectionSystem.relatedFromSystems) {
            this.currentConnectionSystem.relatedFromSystems = [];
        }

        if (!this.currentConnectionSystem.relatedToSystems) {
            this.currentConnectionSystem.relatedToSystems = [];
        }

        for (let i = this.currentConnectionSystem.relatedFromSystems.length - 1; i >= 0; i--) {
            let relatedFrom = this.currentConnectionSystem.relatedFromSystems[i];

            if (relatedFrom.originConnectionType & newSystemConnectionType) {
                this.currentConnectionSystem.relatedFromSystems.splice(i, 1);
            }
        }

        for (let i = this.currentConnectionSystem.relatedToSystems.length - 1; i >= 0; i--) {
            let relatedTo = this.currentConnectionSystem.relatedToSystems[i];

            if (relatedTo.relationConnectionType & newSystemConnectionType) {
                this.currentConnectionSystem.relatedToSystems.splice(i, 1);
            }
        }

        for (let connectionSystem of this.allConnectionSystems) {
            if (!connectionSystem.relatedFromSystems) {
                connectionSystem.relatedFromSystems = [];
            }

            if (!connectionSystem.relatedToSystems) {
                connectionSystem.relatedToSystems = [];
            }

            for (let i = connectionSystem.relatedFromSystems.length - 1; i >= 0; i--) {
                let relatedFrom = connectionSystem.relatedFromSystems[i];

                if (relatedFrom.relationConnectionType & newSystemConnectionType &&
                    relatedFrom.connectionSystemOriginId &&
                    relatedFrom.connectionSystemOriginId.length > 0 &&
                    relatedFrom.connectionSystemOriginId == this.currentConnectionSystem.id) {
                    connectionSystem.relatedFromSystems.splice(i, 1);
                }
            }

            for (let i = connectionSystem.relatedToSystems.length - 1; i >= 0; i--) {
                let relatedTo = connectionSystem.relatedToSystems[i];

                if (relatedTo.originConnectionType & newSystemConnectionType &&
                    relatedTo.connectionSystemRelationId &&
                    relatedTo.connectionSystemRelationId.length > 0 &&
                    relatedTo.connectionSystemRelationId == this.currentConnectionSystem.id) {
                    connectionSystem.relatedToSystems.splice(i, 1);
                }
            }
        }

        if (newSystem && newSystem.id && newSystem.id.length > 0) {
            let currentSystemConnectionType: ConnectionTypeEnum = ConnectionTypeEnum.NONE;

            if (this.currentConnectionSystem.esdConfiguration &&
                this.currentConnectionSystem.esdConfiguration.isEnabled) {
                currentSystemConnectionType =
                    this.currentConnectionSystem.esdConfiguration.connectionType;
            } else if (this.currentConnectionSystem.telecommConfiguration &&
                this.currentConnectionSystem.telecommConfiguration.isEnabled) {
                currentSystemConnectionType =
                    this.currentConnectionSystem.telecommConfiguration.connectionType;
            }

            let newRelationship = new ConnectionSystemRelatedSystem({
                connectionSystemOriginId: this.currentConnectionSystem.id,
                connectionSystemRelationId: newSystem.id,
                originConnectionType: currentSystemConnectionType,
                relationConnectionType: newSystemConnectionType
            });
            
            newSystem.relatedFromSystems.push(newRelationship);
            this.currentConnectionSystem.relatedToSystems.push(newRelationship);
        }
    }

    public isUsingBoardType(boardType: BoardTypeEnum): boolean {
        if (this.currentConnectionSystem.esdConfiguration) {
            return (this.currentConnectionSystem.esdConfiguration.boardType == boardType);
        } else if (this.currentConnectionSystem.telecommConfiguration) {
            return (this.currentConnectionSystem.telecommConfiguration.boardType == boardType);
        }

        return false;
    }
}
