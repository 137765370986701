/**
 * Angular bootstrapping
 */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { ApplicationRef, NgModuleRef } from '@angular/core';

/**
 * App Module
 * our top level module that holds all of our components
 */
import { AppModule } from './app/app.module';

/**
 * Third Party
 */
import { createNewHosts } from '@angularclass/hmr';

/**
 * Bootstrap our Angular app with a top level NgModule
 */
export function main(): Promise<any> {
    const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
    if (environment.hmr) {
        if (module['hot']) {
            let ngModule: NgModuleRef<AppModule>;
            module.hot.accept();
            const bootstrapped = bootstrap().then((mod) => ngModule = mod);
            module.hot.dispose(() => {
                const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
                const elements = appRef.components.map((c) => c.location.nativeElement);
                const makeVisible = createNewHosts(elements);
                ngModule.destroy();
                makeVisible();
            });

            return bootstrapped;
        } else {
            console.error('HMR is not enabled for webpack-dev-server!');
            console.log('Are you using the --hmr flag for ng serve?');
        }
    }

    return bootstrap().then(environment.decorateModuleRef).catch((err) => console.error(err));
}

/**
 * Needed for hmr
 * in prod this is replace for document ready
 */
switch (document.readyState) {
    case 'loading':
        document.addEventListener('DOMContentLoaded', _domReadyHandler, false);
        break;
    case 'interactive':
    case 'complete':
    default:
        main();
}

function _domReadyHandler() {
    document.removeEventListener('DOMContentLoaded', _domReadyHandler, false);
    main();
}
