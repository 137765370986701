export enum OperationStateEnum {
    Idle,
    NotConnected,
    Connected,
    ClearTransfer,
    Polling,
    WaitingForData,
    SetupScreens,
    Running,
    CheckVersion,
}
