import { ConnectionTypeEnum } from '../../shared/enums/connection-type.enum';
import { BoardTypeEnum } from '../../shared/enums/board-type.enum';
import { ConnectionEsd } from './connection-esd.model';
import { ConnectionTelecommChannel } from './connection-telecomm-channel.model';
import { EslStateEnum } from '../enums/esl-state.enum';

export class ConnectionConfiguration {

    public id: string;
    public isEnabled: boolean;
    public connectionType: ConnectionTypeEnum;
    public boardType: BoardTypeEnum;
    public availableEsds: ConnectionEsd[];
    public availableTelecommChannels: ConnectionTelecommChannel[];
    public outToEsdsSubtextLangKey: string;

    public isMlmTxRxInvert: boolean;
    public isContLinkInput: boolean;
    public isExchangeEnabled: boolean;
    public isPrivateLine: boolean;
    public isPrivateLineOverride: boolean;
    public isFibreMlm: boolean;
    public isFibreMlmOverride: boolean;
    public inhibitIndex: number;
    public resetIndex: number;
    public firstUpIndex: number;
    public localInputIndex: number;
    public localOutputIndex: number;
    public continuityLinkIndex: number;
    public carrierDetectThreshold: number;
    public demodulationType: number;
    public eslState: EslStateEnum;
    public useSecondaryTrips: boolean;
    public usePseudoBoard: boolean;
    public masterConnectionConfigurationId: string;

    constructor(data: any) {
        this.id = data.id || undefined;
        this.connectionType = data.connectionType;
        this.boardType = data.boardType;
        this.availableEsds = data.availableEsds;
        this.availableTelecommChannels = data.availableTelecommChannels;
        this.outToEsdsSubtextLangKey = data.outToEsdsSubtextLangKey;
        this.inhibitIndex = data.inhibitIndex;
        this.resetIndex = data.resetIndex;
        this.firstUpIndex = data.firstUpIndex;
        this.localInputIndex = data.localInputIndex;
        this.localOutputIndex = data.localOutputIndex;
        this.continuityLinkIndex = data.continuityLinkIndex;
        this.carrierDetectThreshold = data.carrierDetectThreshold;
        this.demodulationType = data.demodulationType;
        this.eslState = data.eslState;
        this.masterConnectionConfigurationId = data.masterConnectionConfigurationId;

        // Workaround because of boolean type (Silly JS)
        this.isEnabled = false;
        if (data.isEnabled != null) {
            this.isEnabled = data.isEnabled;
        }

        this.isMlmTxRxInvert = false;
        if (data.isMlmTxRxInvert != null) {
            this.isMlmTxRxInvert = data.isMlmTxRxInvert;
        }

        this.isContLinkInput = false;
        if (data.isContLinkInput != null) {
            this.isContLinkInput = data.isContLinkInput;
        }

        this.isExchangeEnabled = false;
        if (data.isExchangeEnabled != null) {
            this.isExchangeEnabled = data.isExchangeEnabled;
        }

        this.isPrivateLine = false;
        if (data.isPrivateLine != null) {
            this.isPrivateLine = data.isPrivateLine;
        }

        this.isPrivateLineOverride = false;
        if (data.isPrivateLineOverride != null) {
            this.isPrivateLineOverride = data.isPrivateLineOverride;
        }

        this.isFibreMlm = false;
        if (data.isFibreMlm != null) {
            this.isFibreMlm = data.isFibreMlm;
        }

        this.isFibreMlmOverride = false;
        if (data.isFibreMlmOverride != null) {
            this.isFibreMlmOverride = data.isFibreMlmOverride;
        }

        this.useSecondaryTrips = false;
        if (data.useSecondaryTrips != null) {
            this.useSecondaryTrips = data.useSecondaryTrips;
        }

        this.usePseudoBoard = false;
        if (data.usePseudoBoard != null) {
            this.usePseudoBoard = data.usePseudoBoard;
        }
    }
}
