import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PageConfig } from '../../shared/models/system/page-config.model';
import { ModuleTypeEnum } from '../../shared/enums/module-type.enum';
import { SslConfiguration } from '../../mod_ssl/models/ssl-configuration.model';
import { MlmConfiguration } from '../../mod_mlm/models/mlm-configuration.model';
import { Terminal } from '../../mod_ssl/models/terminal.model';
import { Jetty } from '../../mod_mlm/models/jetty.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PersistenceService } from '../../shared/services/system/persistence.service';
import { ModuleTypeWrapper } from '../../shared/models/system/module-type-wrapper.model';

@Component({
    selector: 'breadcrumb',
    styleUrls: ['./breadcrumb.component.css'],
    templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

    @Input() public pageConfig: PageConfig;

    public moduleType = ModuleTypeEnum;
    public currentContext: ModuleTypeWrapper = new ModuleTypeWrapper(ModuleTypeEnum.NONE);
    public sslContext: Terminal;
    public uslContext: string;
    public mlmContext: Jetty;
    private pageContextSub: Subscription;
    private persistenceSubSsl: Subscription;
    private persistenceSubUsl: Subscription;
    private persistenceSubMlm: Subscription;

    constructor(
        private _pageConfigService: PageConfigService,
        private _persistence: PersistenceService,
    ) {}

    public ngOnInit(): void {
        this.persistenceSubSsl = this._persistence
            .sslConfiguration
            .subscribe((data: SslConfiguration) => {
                if (data) {
                    this.sslContext = data.currentTerminal;
                }
            });

        this.persistenceSubUsl = this._persistence
            .uslRemoteName
            .subscribe((data: string) => {
                this.uslContext = data;
            });

        this.persistenceSubMlm = this._persistence
            .mlmConfiguration
            .subscribe((data: MlmConfiguration) => {
                if (data) {
                    this.mlmContext = data.currentJetty;
                }
            });

        this.pageContextSub = this._pageConfigService
            .getCurrentContext()
            .subscribe((data: ModuleTypeEnum) => {
                this.currentContext = new ModuleTypeWrapper(data);
            });
    }

    public ngOnDestroy(): void {
        this.persistenceSubSsl.unsubscribe();
        this.persistenceSubUsl.unsubscribe();
        this.persistenceSubMlm.unsubscribe();
        this.pageContextSub.unsubscribe();
    }
}
