import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ElectricLinePairConfigEnum } from '../enums/electric-line-pair-config.enum';

@Pipe({
    name: 'electricLinePairMiyakiTel'
})
export class ElectricLinePairMiyakiTelPipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(linePair: ElectricLinePairConfigEnum): string {
        switch (linePair) {
            case ElectricLinePairConfigEnum.MIYAKI_B2_1_2:
                return 'MIY 2 1/2';
            case ElectricLinePairConfigEnum.MIYAKI_B2_3_4:
                return 'MIY 2 3/4';
            case ElectricLinePairConfigEnum.MIYAKI_B2_5_6:
                return 'MIY 2 5/6';
            case ElectricLinePairConfigEnum.MIYAKI_B3_1_2:
                return 'MIY 3 1/2';
            case ElectricLinePairConfigEnum.MIYAKI_B3_3_4:
                return 'MIY 3 3/4';
            case ElectricLinePairConfigEnum.MIYAKI_B3_5_6:
                return 'MIY 3 5/6';
            default:
            case ElectricLinePairConfigEnum.NOT_CONNECTED:
                return this._translate.instant('SSL.TERMINAL_CONN_NONE');
        }
    }
}
