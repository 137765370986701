export class CompassDisplayLine {

    public rotation: number;
    public isThickLine: boolean;

    constructor(data: CompassDisplayLine) {
        this.rotation = data.rotation;

        this.isThickLine = false;
        if (data.isThickLine != null) {
            this.isThickLine = data.isThickLine;
        }
    }
}
