import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { interval, Subscription } from 'rxjs';

import { CONFIGURATION } from '../../../app.constants';

import { PageConfig } from '../../../shared/models/system/page-config.model';
import { PageConfigService } from '../../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../../shared/models/system/page-breadcrumb.model';

import { Terminal } from '../../models/terminal.model';
import { PersistenceService } from '../../../shared/services/system/persistence.service';
import { SslConfiguration } from '../../models/ssl-configuration.model';
import { EsdSystem } from '../../../shared/models/esd/esd-system.model';
import { WebsocketTrip } from '../../../shared/models/esd/websocket/trip.model';
import { WebsocketEsdSystemStatus } from '../../../shared/models/esd/websocket/esd-system-status.model';
import { WebsocketTelecomm } from '../../../shared/models/esd/websocket/telecomm.model';
import { EsdService } from '../../../shared/services/esd/esd.service';
import { WebsocketEsdSystemStateChange } from '../../../shared/models/esd/websocket/esd-system-state-change.model';
import { LocalboardConfiguration } from '../../../shared/models/common/localboard-configuration.model';
import { FibreLoopbackResult } from '../../../shared/models/esd/fibre-loopback-result.model';
import { ElectricLoopbackResult } from '../../../shared/models/esd/electric-loopback-result.model';
import { SystemLocationEnum } from '../../../shared/enums/system-location.enum';
import { OrderByPipe } from '../../../shared/pipes/order-by.pipe';

@Component({
    selector: 'ssl-operations-detailed',
    styleUrls: ['./detailed.component.css'],
    templateUrl: './detailed.component.html'
})
export class SslOperationsDetailedComponent implements OnInit, OnDestroy {

    public currentTerminal: Terminal = undefined;
    public currentEsdSystems: EsdSystem[] = [];
    public totalSystemCount: number = 0;
    public sslConfig: SslConfiguration;
    public localboardConfiguration: LocalboardConfiguration;
    public isLoading: boolean = false;
    public selectedEsdSystem: EsdSystem = undefined;

    private webSockets: Subscription[] = [];
    private pollTimer: Subscription = undefined;
    private routerSub: Subscription;
    private persistenceSub: Subscription;
    private persistenceLocalboardConfigSub: Subscription;

    constructor(private _route: ActivatedRoute,
                private _esdService: EsdService,
                private _persistence: PersistenceService,
                private _configService: PageConfigService) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.isLoading = true;
                this.updateNavigation();
                this.webSockets.push(this.websocketGetEsdSystemStateChange());
                this.webSockets.push(this.websocketGetEsdSystemStatus());
                this.webSockets.push(this.websocketGetTelecommStatus());
                this.webSockets.push(this.websocketGetTripStatus());
                this.webSockets.push(this.websocketGetFibreLoopbackResult());
                this.webSockets.push(this.websocketGetElectricLoopbackResult());
                this.persistenceSub = this._persistence
                    .sslConfiguration
                    .subscribe((data: SslConfiguration) => {
                        if (data) {
                            this.sslConfig = data;
                            this.currentTerminal = data.currentTerminal;
                            if (this.currentTerminal) {
                                this.isLoading = true;
                                this.serviceGetEsdSystems(true).add(() => {
                                    this.isLoading = false;
                                });
                            }
                        }
                    });

                this.persistenceLocalboardConfigSub = this._persistence
                    .localboardConfiguration
                    .subscribe((data: LocalboardConfiguration) => {
                        if (data) {
                            this.localboardConfiguration = data;
                        }
                    });

                this.stopPolling();
                this.serviceGetEsdSystems(false).add(() => {
                    this.startPolling();
                    this.isLoading = false;
                });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.routerSub = undefined;
            this.stopPolling();
            this.persistenceSub.unsubscribe();
            this.persistenceLocalboardConfigSub.unsubscribe();
            for (const subscription of this.webSockets) {
                if (subscription) {
                    subscription.unsubscribe();
                }
            }
        }
    }

    // Template Helpers
    public maximizeSystem(id: string): void {
        const foundSystems = this.currentEsdSystems.filter((system) => system && system.id == id);
        if (foundSystems.length > 0) {
            this.selectedEsdSystem = foundSystems[0];
        }

    }

    public minimizeSystem(id: string): void {
        this.selectedEsdSystem = undefined;
    }

    public doInhibit(id: string): void {
        this.serviceDoInhibit(id);
    }

    public doReset(id: string): void {
        this.serviceDoReset(id);
    }

    public doCancelInhibit(id: string): void {
        this.serviceDoCancelInhibit(id);
    }

    public doAcceptTrips(id: string): void {
        this.serviceDoAcceptTrips(id).add(() => {
            this.serviceGetEsdSystems(false);
        });
    }

    public doBeginFibreLoopbackTest(id: string, isShore: boolean): void {
        this.serviceDoBeginFibreLoopbackTest(id, isShore).add(() => {
            this.serviceGetEsdSystems(false);
        });
    }

    public doEndFibreLoopbackTest(id: string, isShore: boolean): void {
        this.serviceDoEndFibreLoopbackTest(id, isShore).add(() => {
            this.serviceGetEsdSystems(false);
        });
    }

    public doBeginElectricLoopbackTest(id: string, isMiyaki: boolean): void {
        this.serviceDoBeginElectricLoopbackTest(id, isMiyaki).add(() => {
            this.serviceGetEsdSystems(false);
        });
    }

    public doEndElectricLoopbackTest(id: string, isMiyaki: boolean): void {
        this.serviceDoEndElectricLoopbackTest(id, isMiyaki).add(() => {
            this.serviceGetEsdSystems(false);
        });
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'SSL',
            path: '/ssl'
        }));
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'OPERATIONS',
            path: '/ssl/operations'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'DETAILED',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    private startPolling(): void {
        if (this.routerSub === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                if (this.currentTerminal) {
                    this.serviceGetEsdSystems(false).add(() => {
                        this.startPolling();
                    });
                } else {
                    this.startPolling();
                }
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    // Service Calls
    private websocketGetEsdSystemStateChange(): Subscription {
        return this._esdService
            .websocketGetEsdSystemStateChange()
            .subscribe((data: WebsocketEsdSystemStateChange) => {
                this.serviceGetEsdSystems(true);
            });
    }

    private websocketGetTripStatus(): Subscription {
        return this._esdService
            .websocketGetTripStatus()
            .subscribe((data: WebsocketTrip) => {
                for (const system of this.currentEsdSystems) {
                    if (system && data.systemId === system.id) {
                        system.updateWebsocketEsdSystemTrip(data);
                    }
                }
            });
    }

    private websocketGetEsdSystemStatus(): Subscription {
        return this._esdService
            .websocketGetEsdSystemStatus()
            .subscribe((data: WebsocketEsdSystemStatus) => {
                for (const system of this.currentEsdSystems) {
                    if (system && data.id === system.id) {
                        system.updateWebsocketEsdSystemState(data);
                    }
                }
            });
    }

    private websocketGetTelecommStatus(): Subscription {
        return this._esdService
            .websocketGetTelecommStatus()
            .subscribe((data: WebsocketTelecomm) => {
                for (const system of this.currentEsdSystems) {
                    if (system && data.systemId === system.id) {
                        system.updateWebsocketEsdSystemTelecomm(data);
                    }
                }
            });
    }

    private websocketGetFibreLoopbackResult(): Subscription {
        return this._esdService
            .websocketGetFibreLoopbackResult()
            .subscribe((data: FibreLoopbackResult) => {
                for (const system of this.currentEsdSystems) {
                    if (system && data.systemId === system.id) {
                        system.fibreLoopbackResult = data;
                    }
                }
            });
    }

    private websocketGetElectricLoopbackResult(): Subscription {
        return this._esdService
            .websocketGetElectricLoopbackResult()
            .subscribe((data: ElectricLoopbackResult) => {
                for (const system of this.currentEsdSystems) {
                    if (system && data.systemId === system.id) {
                        system.electricLoopbackResult = data;
                    }
                }
            });
    }

    private serviceGetEsdSystems(forceRefresh: boolean): Subscription {
        return this._esdService
            .getEsdSystems()
            .subscribe((data: EsdSystem[]) => {
                if (!this.currentEsdSystems || this.currentEsdSystems.length <= 0 || forceRefresh) {
                    this.totalSystemCount = data.length;
                    data = new OrderByPipe().transform(data, ['primaryOrder', 'systemLocation']);
                    let quadOrderData: boolean = false;
                    if (data.length > 2) {
                        quadOrderData = true;
                        this.currentEsdSystems = [undefined, undefined, undefined, undefined];
                    } else {
                        this.currentEsdSystems = [];
                    }

                    for (const newState of data) {
                        const newEsdSystem = new EsdSystem(newState);
                        if (quadOrderData) {
                            switch (newEsdSystem.systemLocation) {
                                case SystemLocationEnum.PORT:
                                case SystemLocationEnum.PORT_FORWARD:
                                case SystemLocationEnum.PORT_REAR:
                                    if (this.currentEsdSystems[0]) {
                                        this.currentEsdSystems[2] = newEsdSystem;
                                    } else {
                                        this.currentEsdSystems[0] = newEsdSystem;
                                    }

                                    break;
                                case SystemLocationEnum.STARBOARD:
                                case SystemLocationEnum.STARBOARD_FORWARD:
                                case SystemLocationEnum.STARBOARD_REAR:
                                    if (this.currentEsdSystems[1]) {
                                        this.currentEsdSystems[3] = newEsdSystem;
                                    } else {
                                        this.currentEsdSystems[1] = newEsdSystem;
                                    }

                                    break;
                                default:
                                    for (let i = 0; i < this.currentEsdSystems.length; i++) {
                                        if (!this.currentEsdSystems[i]) {
                                            this.currentEsdSystems[i] = newEsdSystem;
                                            break;
                                        }
                                    }

                                    break;
                            }
                        } else {
                            this.currentEsdSystems.push(newEsdSystem);
                        }
                    }
                }

                let outOfSync: boolean = false;
                for (const newState of data) {
                    for (const oldState of this.currentEsdSystems) {
                        if (oldState && newState.id === oldState.id) {
                            if (newState.stateIdentifier != oldState.stateIdentifier) {
                                this.currentEsdSystems = [];
                                outOfSync = true;
                            } else {
                                oldState.updateEsdSystemState(newState);
                            }

                            break;
                        }
                    }

                    if (outOfSync) {
                        break;
                    }
                }
            });
    }

    private serviceDoInhibit(systemId: string): Subscription {
        return this._esdService.putInhibit(systemId, true).subscribe();
    }

    private serviceDoCancelInhibit(systemId: string): Subscription {
        return this._esdService.putInhibit(systemId, false).subscribe();
    }

    private serviceDoReset(systemId: string): Subscription {
        return this._esdService.putReset(systemId).subscribe();
    }

    private serviceDoAcceptTrips(systemId: string): Subscription {
        return this._esdService.putAcceptTrips(systemId).subscribe();
    }

    private serviceDoBeginFibreLoopbackTest(systemId: string, isShore: boolean): Subscription {
        return this._esdService.putFibreLoopbackTest(systemId, isShore, true).subscribe();
    }

    private serviceDoEndFibreLoopbackTest(systemId: string, isShore: boolean): Subscription {
        return this._esdService.putFibreLoopbackTest(systemId, isShore, false).subscribe();
    }

    private serviceDoBeginElectricLoopbackTest(systemId: string, isMiyaki: boolean): Subscription {
        return this._esdService.putElectricLoopbackTest(systemId, isMiyaki, true).subscribe();
    }

    private serviceDoEndElectricLoopbackTest(systemId: string, isMiyaki: boolean): Subscription {
        return this._esdService.putElectricLoopbackTest(systemId, isMiyaki, false).subscribe();
    }
}
