import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { PageConfig } from '../../models/system/page-config.model';
import { ModuleTypeEnum } from '../../enums/module-type.enum';

@Injectable()
export class PageConfigService {

    // Using null byte as default value as it allows certain pages to calculate height properly.
    private config: BehaviorSubject<PageConfig> = new BehaviorSubject<PageConfig>(
        new PageConfig({
            title: '\u2008',
            breadcrumbs: []
        }));

    private currentContext: BehaviorSubject<ModuleTypeEnum> =
        new BehaviorSubject<ModuleTypeEnum>(ModuleTypeEnum.NONE);

    public getConfig(): BehaviorSubject<PageConfig> {
        return this.config;
    }

    public setConfig(pageConfig: PageConfig): void {
        this.config.next(pageConfig);
    }

    // Current Context
    public setCurrentContext(newModule: ModuleTypeEnum): void {
        this.currentContext.next(newModule);
    }

    public getCurrentContext(): BehaviorSubject<ModuleTypeEnum> {
        return this.currentContext;
    }
}
