import { Routes } from '@angular/router';

// Guard
import { MlmModuleGuard } from './guards/module.guard';

// Pages
import { MlmComponent } from './mod_mlm.component';
import { MlmHomeComponent } from './home/home.component';
import { MlmJettiesConfigComponent } from './jetties/config/config.component';
import { MlmJettiesListComponent } from './jetties/list/list.component';
import { MlmOperationsComponent } from './operations/operations.component';
import { MlmJettiesComponent } from './jetties/jetties.component';
import { MlmLogsComponent } from './logs/logs.component';
import { MlmPatternsComponent } from './patterns/patterns.component';

export const MLM_ROUTES: Routes = [
    {
        path: 'mlm',
        component: MlmComponent,
        children: [
            { path: '', component: MlmHomeComponent, pathMatch: 'full' },
            { path: 'home', component: MlmHomeComponent },
            { path: 'operations', component: MlmOperationsComponent },
            {
                path: 'jetties',
                component: MlmJettiesComponent,
                children: [
                    { path: '', component: MlmJettiesListComponent },
                    { path: 'edit/:id', component: MlmJettiesConfigComponent },
                    { path: 'new', component: MlmJettiesConfigComponent },
                ]
            },
            { path: 'logs', component: MlmLogsComponent },
            { path: 'patterns', component: MlmPatternsComponent }
        ],
        canActivate: [MlmModuleGuard]
    },
];
