import { EnvironmentalDataTypeEnum } from '../enums/environmental-data-type.enum';

export abstract class EnvironmentalData {

    public channelId: string;
    public type: EnvironmentalDataTypeEnum;
    public channelValue: number;
    public name: string;
    public unit: string;
    public displayFormat: string;
    public lastDataUpdate: number;
    public isStale: boolean;

    protected constructor(data: EnvironmentalData) {
        this.channelId = data.channelId || undefined;
        this.type = data.type;
        this.channelValue = data.channelValue;
        this.name = data.name || undefined;
        this.unit = data.unit || undefined;
        this.displayFormat = data.displayFormat || undefined;
        this.lastDataUpdate = data.lastDataUpdate;

        this.isStale = false;
        if (data.isStale != null) {
            this.isStale = data.isStale;
        }
    }

    public updateState(newState: EnvironmentalData): void {
        this.channelValue = newState.channelValue;
        this.lastDataUpdate = newState.lastDataUpdate;
        this.isStale = newState.isStale;
    }
}
