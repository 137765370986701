import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { EsdSystem } from '../../../../../shared/models/esd/esd-system.model';
import { SslConfiguration } from '../../../../models/ssl-configuration.model';
import { SystemLocationEnum } from '../../../../../shared/enums/system-location.enum';
import { InstallationTypeEnum } from '../../../../../shared/enums/installation-type.enum';
import {
    LocalboardConfiguration
} from '../../../../../shared/models/common/localboard-configuration.model';

@Component({
    selector: 'ssl-operations-graphical-multi-view',
    styleUrls: ['./multi-view.component.css'],
    templateUrl: './multi-view.component.html'
})
export class SslOperationsGraphicalMultiViewComponent implements OnChanges {

    @Input() public sslConfig: SslConfiguration;
    @Input() public localboardConfiguration: LocalboardConfiguration;
    @Input() public installationType: InstallationTypeEnum;
    @Input() public installationName: string; // shipName
    @Input() public terminalName: string; // remoteName
    @Input() public esdSystems: EsdSystem[];
    @Input() public localIndexes: number[];
    @Input() public remoteIndexes: number[];
    @Input() public esdsIndexes: number[];

    public installationTypeEnum = InstallationTypeEnum;
    public portForwardIndex: number = -1;
    public portRearIndex: number = -1;
    public starboardForwardIndex: number = -1;
    public starboardRearIndex: number = -1;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['esdSystems']) {
            this.portForwardIndex = -1;
            this.portRearIndex = -1;
            this.starboardForwardIndex = -1;
            this.starboardRearIndex = -1;

            const genericPortIndexes: number[] = [];
            const genericStarboardIndexes: number[] = [];

            this.esdSystems.forEach((item, index) => {
                switch (item.systemLocation) {
                    case SystemLocationEnum.PORT_FORWARD:
                        this.portForwardIndex = index;
                        break;
                    case SystemLocationEnum.PORT_REAR:
                        this.portRearIndex = index;
                        break;
                    case SystemLocationEnum.STARBOARD_FORWARD:
                        this.starboardForwardIndex = index;
                        break;
                    case SystemLocationEnum.STARBOARD_REAR:
                        this.starboardRearIndex = index;
                        break;
                    case SystemLocationEnum.PORT:
                        genericPortIndexes.push(index);
                        break;
                    case SystemLocationEnum.STARBOARD:
                        genericStarboardIndexes.push(index);
                        break;
                }
            });

            for (const genericPortIndex of genericPortIndexes) {
                if (this.portForwardIndex == -1) {
                    this.portForwardIndex = genericPortIndex;
                } else if (this.portRearIndex == -1) {
                    this.portRearIndex = genericPortIndex;
                }
            }

            for (const genericStbdIndex of genericStarboardIndexes) {
                if (this.starboardForwardIndex == -1) {
                    this.starboardForwardIndex = genericStbdIndex;
                } else if (this.starboardRearIndex == -1) {
                    this.starboardRearIndex = genericStbdIndex;
                }
            }
        }
    }
}
