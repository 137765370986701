export enum ConnectionTypeEnum {
    NONE = 0,
    THIRTYSEVENWAY = 1,
    MIYAKI = 2,
    ITT_CANNON = 4,
    FIBRE_ANALOGUE = 8,
    FIBRE_DIGITAL = 16,
    ESL = 32,
    PNEUMATIC = 64
}
