export const CONFIGURATION = {
    devConfig: {
        //server: 'localhost',
        server: undefined, // API Server, set to null if to use the server this is hosted on.
        port: 5000, // API Server port.
        apiUrl: '/api/', // Path of the API Endpoint
        //webSocketHost: 'localhost:5000',
        webSocketHost: undefined, // WebSocket server and port, set to null if to use the server this is hosted on.
        screenApiServer: '127.0.0.1:5100', // Screen API Server
        screenApiUrl: '/api/', // Path of the Screen API Endpoint
        useHttps: false // Whether services should use HTTPS or not.
    },
    prodConfig: {
        server: undefined,
        port: 5000,
        apiUrl: '/api/',
        webSocketHost: undefined,
        screenApiServer: '127.0.0.1:5100',
        screenApiUrl: '/api/',
        useHttps: false
    },
    isProduction: (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production'),
    useMockData: MOCK, // Should services use mock data.
    isHmr: HMR, // Is currently in Hot-Module Reloading mode
    pingTimeout: 10000, // What the timeout for the brain ping should be.
    pollInterval: 5000, // How often data should be polled as a backup of WebSockets.
    longPollInterval: 60000, // How long before persisted data is polled for.
    minMaxFakeBrightness: [10, 100], // The min and max "fake" brightness applied to the app.
    authTokenName: 'SCREEN_TOKEN', // Auth token key name used for authentication.
    screenTokenName: 'SCREEN_VERSION', // Version token key name used for storing screen version.
    screenOptionTokenName: 'SCREEN_OPTS', // Screen option token name
    showCursorOptionTokenName: 'SCREEN_SHOW_CURSOR', // Auth token key name used for authentication.
    siteTitle: 'SSL10K', // Title displayed on the window.
    siteDefaultLang: 'en', // Default fallback language.
    guiVersion: (APP_VERSION || '0.0.0') // GUI Version.
};
