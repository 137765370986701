import { WebsocketTelecomm } from './websocket/telecomm.model';
import { PhoneTypeEnum } from '../../enums/phone-type.enum';
import { TelecommTypeEnum } from '../../enums/telecomm-type.enum';
import { ConnectionTypeEnum } from '../../enums/connection-type.enum';

export abstract class Telecomm {

    public id: string;
    public name: string;
    public channel: number;
    public channelIndex: number;
    public telecommType: TelecommTypeEnum;
    public connectionType: ConnectionTypeEnum;
    public isBankB: boolean;
    public isRemoteRinging: boolean;
    public isLocalRinging: boolean;
    public isConnected: boolean;
    public isHealthy: boolean;
    public isWarning: boolean;
    public isTripAccepted: boolean;

    protected constructor(data: any) {
        this.id = data.id || undefined;
        this.name = data.name || '';
        this.channel = data.channel;
        this.channelIndex = data.channelIndex;
        this.telecommType = data.telecommType;
        this.connectionType = data.connectionType;

        // Workaround because of boolean type (Silly JS)
        if (data.isConnected != null) {
            this.isConnected = data.isConnected;
        }

        if (data.isHealthy != null) {
            this.isHealthy = data.isHealthy;
        }

        if (data.isWarning != null) {
            this.isWarning = data.isWarning;
        }

        if (data.isTripAccepted != null) {
            this.isTripAccepted = data.isTripAccepted;
        }

        if (data.isBankB != null) {
            this.isBankB = data.isBankB;
        }

        if (data.isRemoteRinging != null) {
            this.isRemoteRinging = data.isRemoteRinging;
        }

        if (data.isLocalRinging != null) {
            this.isLocalRinging = data.isLocalRinging;
        }
    }

    public updateTelecommState(newState: Telecomm): void {
        this.isConnected = newState.isConnected;
        this.isHealthy = newState.isHealthy;
        this.isWarning = newState.isWarning;
        this.isTripAccepted = newState.isTripAccepted;
        this.isLocalRinging = newState.isLocalRinging;
        this.isRemoteRinging = newState.isRemoteRinging;
    }

    public updateWebsocketTelecommState(newState: WebsocketTelecomm): void {
        this.isConnected = newState.isConnected;
        this.isHealthy = newState.isHealthy;
        this.isWarning = newState.isWarning;
        this.isTripAccepted = newState.isTripAccepted;
        this.isLocalRinging = newState.isLocalRinging;
        this.isRemoteRinging = newState.isRemoteRinging;
    }
}
