import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';

import { EsdsConfiguration } from '../models/esds-configuration.model';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';

@Injectable()
export class ConfigService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl = '/assets/mock/mod_esds/config.json';
    }

    public getConfig(): Observable<EsdsConfiguration> {
        return this._http
            .get<EsdsConfiguration>(this.actionUrl,
                this.getRequestOptions());
    }

    public updateConfig(updatedConfig: EsdsConfiguration): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl,
                JSON.stringify(updatedConfig),
                this.getFullRawRequestOptions());
    }

    public websocketGetConfig(): Observable<EsdsConfiguration> {
        return this._ws.get('/wsapi/esds/config');
    }
}
