import { Component, Input, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap';

import { Recording } from '../../../models/recording.model';
import { RecordingTypeEnum } from '../../../enums/recording-type.enum';
import { RecordingService } from '../../../services/recording.service';
import { PersistenceService } from '../../../../shared/services/system/persistence.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ssl-operations-modal-playback',
    styleUrls: ['./playback.component.css'],
    templateUrl: './playback.component.html'
})
export class SslRecordingsModalPlaybackComponent {

    @ViewChild('playbackModal') public playbackModal: ModalDirective;

    @Input() public recording: Recording;
    public isModalOpen: boolean;
    public recordingType = RecordingTypeEnum;
    public currentTimeZone: string = 'UTC';

    private persistenceCurrentTimeZoneSub: Subscription;

    constructor(
        private _recordingService: RecordingService,
        private _persistence: PersistenceService,
    ) {}

    public open(): void {
        this.isModalOpen = true;
        this.persistenceCurrentTimeZoneSub = this._persistence
            .currentTimeZoneIANA
            .subscribe((data: string) => {
                this.currentTimeZone = data;
            });
        this.playbackModal.show();
    }

    public close(): void {
        this.persistenceCurrentTimeZoneSub.unsubscribe();
        this.isModalOpen = false;
        this.playbackModal.hide();
    }

    public getMediaSource(): string {
        return this._recordingService.getRecordingDataUrl(this.recording.id);
    }
}
