import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { PersistenceService } from './persistence.service';
import { CONFIGURATION } from '../../../app.constants';

@Injectable()
export class LanguageService extends BaseService {

    constructor(
        _http: HttpClient,
        private _persistence: PersistenceService
    ) {
        super(_http);
        this.actionUrl += 'system/language/';
    }

    public getLanguageLinesByGroup(groupName: string): Observable<any> {
        const cleanGroupName = groupName.replace(/_/gi, '-');
        const langCode = this._persistence.systemConfiguration.value.languageCode;
        return this._http
            .get(this.actionUrl + langCode + '/group/' + cleanGroupName,
                this.getRequestOptions());
    }
}
