export class EsdConfiguration {

    public id: string;
    public isDebugMode: boolean;
    public isAutomaticReset: boolean;
    public isInhibitEnabled: boolean;
    public isTripsAcceptAvailable: boolean;

    constructor(data: EsdConfiguration) {
        this.id = data.id || undefined;

        // Workaround because of boolean type (Silly JS)
        this.isDebugMode = false;
        if (data.isDebugMode != null) {
            this.isDebugMode = data.isDebugMode;
        }

        this.isAutomaticReset = false;
        if (data.isAutomaticReset != null) {
            this.isAutomaticReset = data.isAutomaticReset;
        }

        this.isInhibitEnabled = false;
        if (data.isInhibitEnabled != null) {
            this.isInhibitEnabled = data.isInhibitEnabled;
        }

        this.isTripsAcceptAvailable = false;
        if (data.isTripsAcceptAvailable != null) {
            this.isTripsAcceptAvailable = data.isTripsAcceptAvailable;
        }
    }
}
