import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../../shared/services/base.service';
import { UslSystemV0Alarm } from '../../models/usl-system/v0/alarm.model';
import { WebSocketService } from '../../../shared/services/websocket/websocket.service';
import { UslAlarmActiveLite } from '../../models/usl-system/lite/alarm-active-lite.model';
import { UslAlarmLite } from '../../models/usl-system/lite/alarm-lite.model';

@Injectable()
export class UslAlarmV0Service extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'usl/alarm';
    }

    public getLocalAlarms(): Observable<UslSystemV0Alarm[]> {
        return this._http
            .get<UslSystemV0Alarm[]>(this.actionUrl + '/local', this.getRequestOptions());
    }

    public getLocalActiveAlarms(): Observable<UslSystemV0Alarm[]> {
        return this._http
            .get<UslSystemV0Alarm[]>(this.actionUrl + '/local/active', this.getRequestOptions());
    }

    public addLocalAlarm(val: UslSystemV0Alarm): Observable<HttpResponse<string>> {
        return this._http
            .post(this.actionUrl + '/local',
                JSON.stringify(val),
                this.getFullRawRequestOptions());
    }

    public updateLocalAlarm(val: UslSystemV0Alarm): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/local/' + val.id,
                JSON.stringify(val),
                this.getFullRawRequestOptions());
    }

    public deleteLocalAlarm(val: UslSystemV0Alarm): Observable<HttpResponse<string>> {
        return this._http
            .delete(this.actionUrl + '/local/' + val.id,
                this.getFullRawRequestOptions());
    }

    public acknowledgeLocalAlarm(val: UslSystemV0Alarm): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/local/acknowledge/' + val.id,
                JSON.stringify(val),
                this.getFullRawRequestOptions());
    }

    public getRemoteAlarms(): Observable<UslSystemV0Alarm[]> {
        return this._http
            .get<UslSystemV0Alarm[]>(this.actionUrl + '/remote',
                this.getRequestOptions());
    }

    public getRemoteActiveAlarms(): Observable<UslSystemV0Alarm[]> {
        return this._http
            .get<UslSystemV0Alarm[]>(this.actionUrl + '/remote/active',
                this.getRequestOptions());
    }

    public acknowledgeRemoteAlarm(val: UslSystemV0Alarm): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/remote/acknowledge/' + val.id,
                JSON.stringify(val),
                this.getFullRawRequestOptions());
    }

    public websocketGetAlarms(): Observable<UslAlarmLite> {
        return this._ws.get('/wsapi/usl/alarm');
    }

    public websocketGetActiveAlarms(): Observable<UslAlarmActiveLite> {
        return this._ws.get('/wsapi/usl/alarm-active');
    }
}
