import { BaseSafetyEvent } from './base-safety-event.model';

export class TerminalActivatingSafetyEvent extends BaseSafetyEvent {

    public terminalId: string;

    constructor(data: any) {
        super(data);
        this.terminalId = data.terminalId;
    }
}
