import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { PageConfig } from '../../shared/models/system/page-config.model';
import { RemoteScreen } from '../../shared/models/system/remote-screen.model';
import { RemoteScreenService } from '../../shared/services/system/remote-screen.service';

@Component({
    selector: 'remote-view',
    styleUrls: ['./view.component.css'],
    templateUrl: './view.component.html'
})
export class RemoteViewComponent implements OnInit, OnDestroy {

    public isLoading: boolean;
    public remoteScreen: RemoteScreen;
    private routerSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _configService: PageConfigService,
        private _remoteScreenService: RemoteScreenService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.isLoading = true;
                let remoteScreenId = params['id'];
                if (remoteScreenId) {
                    this.serviceGetScreen(remoteScreenId).add(() => {
                        this.updateNavigation(this.remoteScreen.name);
                        this.isLoading = false;
                    });
                }
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    }

    // Template helpers
    public getUrl(): string {
        let screen = this.remoteScreen;
        let path = 'http://' + screen.host +
            ':' + screen.port +
            '/vnc.html?host=' + screen.host +
            '&port=' + screen.port +
            '&password=' + screen.password;

        if (screen.isInteractable) {
            path += '&view_only=false&cursor=true';
        } else {
            path += '&view_only=true&cursor=false';
        }

        path += '&resize=scale&stylesheet=Black&autoconnect=true';
        return path;
    }

    // Helper methods
    private updateNavigation(remoteScreenName: string): void {
        let pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'REMOTE',
            path: '/remote'
        }));
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'VIEW',
            path: '/view'
        }));
        this._configService.setConfig(new PageConfig({
            title: remoteScreenName.toUpperCase(),
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service Calls
    private serviceGetScreen(screenId: string): Subscription {
        return this._remoteScreenService
            .getRemoteScreen(screenId)
            .subscribe((data: RemoteScreen) => {
                this.remoteScreen = data;
            });
    }
}
