export class Paged<T> {

    public pageIndex: number;
    public pageSize: number;
    public totalPages: number;
    public total: number;
    public hasPreviousPage: boolean;
    public hasNextPage: boolean;
    public items: T[];

    constructor(data: Paged<T>) {
        this.pageIndex = data.pageIndex;
        this.pageSize = data.pageSize;
        this.totalPages = data.totalPages;
        this.total = data.total;
        this.items = data.items || undefined;

        this.hasPreviousPage = false;
        if (data.hasPreviousPage != null) {
            this.hasPreviousPage = data.hasPreviousPage;
        }

        this.hasNextPage = false;
        if (data.hasNextPage != null) {
            this.hasNextPage = data.hasNextPage;
        }
    }
}
