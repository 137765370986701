import {
    Component, Input, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit
} from '@angular/core';

import * as WaveSurfer from 'wavesurfer.js';
import { BaseService } from '../../../services/base.service';

// AMOUNT OF FREQUENCIES TO RENDER
const BAR_MIN: number = 0;
const BAR_MAX: number = 128;

@Component({
    selector: 'audio-player',
    styleUrls: ['./audio-player.component.css'],
    templateUrl: './audio-player.component.html'
})
export class AudioPlayerElementComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() public audioSrc: string;

    public isLoading: boolean = true;
    public isLoadingWaveform: boolean = true;
    public barWidth: number = 0;
    public frequencyData: Uint8Array;

    public audioCurrentTime: number = 0;
    public audioVolume: number = 0;
    public audioDuration: number = 0;
    public audioIsPlaying: boolean = false;
    public audioTimePosition: number = 0;

    // Audio Nodes
    public frequencyAnalyserNode: AnalyserNode;
    public gainNode: GainNode;
    public channelGainNodes: GainNode[] = [];
    public channelMergerNode: ChannelMergerNode;
    public channelSplitterNode: ChannelSplitterNode;
    public audioBufferSourceNode: AudioBufferSourceNode;

    @ViewChild('waveform') public waveTimelineContainer: ElementRef;

    private stopRender: boolean = false;
    private waveSurfer: any;

    private audioBuffer: AudioBuffer = null;
    private audioContext: AudioContext;

    public ngOnInit(): void {
        this.isLoading = true;
        this.isLoadingWaveform = true;
        this.stopRender = false;
        this.cleanUp();
    }

    public ngAfterViewInit(): void {
        this.updateSrc(this.audioSrc);
    }

    public ngOnDestroy(): void {
        this.stopRender = true;
        this.cleanUp();
    }

    // Template Helpers
    public togglePlay(): void {
        if (this.audioIsPlaying) {
            this.audioBufferSourceNode.stop();
        } else {
            if (this.audioTimePosition >= this.audioDuration) {
                this.setPlayback(0);
            } else {
                this.setPlayback(this.audioTimePosition);
            }
        }

        this.audioIsPlaying = !this.audioIsPlaying;
    }

    public toggleChannel(channelIndex: number): void {
        if (this.channelGainNodes[channelIndex]) {
            if (this.channelGainNodes[channelIndex].gain.value <= 0) {
                this.channelGainNodes[channelIndex].gain.value = 1;
            } else {
                this.channelGainNodes[channelIndex].gain.value = 0;
            }
        }
    }

    public stop(): void {
        this.audioIsPlaying = false;
        this.audioBufferSourceNode.stop();
        this.setAllTime(0);
    }

    public skipForward(amount: number): void {
        this.setPlayback(this.audioTimePosition + amount);
    }

    public skipBackward(amount: number): void {
        this.setPlayback(this.audioTimePosition - amount);
    }

    public volumeUp(): void {
        let newVolume = this.audioVolume + 0.1;
        if (newVolume > 1) {
            newVolume = 1;
        }
        this.setVolume(newVolume);
    }

    public volumeDown(): void {
        let newVolume = this.audioVolume - 0.1;
        if (newVolume < 0) {
            newVolume = 0;
        }
        this.setVolume(newVolume);
    }

    public setPlayback(setTo: number): void {
        if (setTo < 0) {
            setTo = 0;
        }

        if (setTo > this.audioDuration) {
            setTo = this.audioDuration;
        }

        this.startAt(setTo);
        this.setAllTime(setTo);
    }

    // Helpers
    private updateSrc(newSrc: string): void {
        const currentWindow: any = window;
        const PlatformAudioContext = currentWindow.AudioContext || currentWindow.webkitAudioContext;
        this.audioContext = new PlatformAudioContext();
        const authToken = BaseService.getScreenAuth();

        const request = new XMLHttpRequest();
        request.responseType = 'arraybuffer';
        request.open('GET', newSrc, true);
        if (authToken) {
            request.setRequestHeader('X-Screen-Id', authToken);
        }
        request.onload = (ev: Event) => {
            this.createWaveTimeline(request.response.slice(0));
            this.audioContext
                .decodeAudioData(request.response,
                    (data) => {
                        this.audioBuffer = data;
                        // TODO: Find out how many channels
                        this.initAudioNodes(2);
                        this.render();
                    }, (err) => {
                        console.log(err);
                    });
        };
        request.send();
    }

    private cleanUp(): void {
        if (this.waveSurfer) {
            this.waveSurfer.destroy();
            this.waveSurfer = undefined;
        }

        if (this.audioBufferSourceNode) {
            if (this.audioIsPlaying) {
                this.audioBufferSourceNode.stop();
            }

            this.audioBufferSourceNode.disconnect();
            this.channelSplitterNode.disconnect();
            for (const channelGainNode of this.channelGainNodes) {
                channelGainNode.disconnect();
            }

            this.channelMergerNode.disconnect();
            this.frequencyAnalyserNode.disconnect();
            this.gainNode.disconnect();

            this.audioBufferSourceNode = undefined;
            this.channelSplitterNode = undefined;
            this.frequencyAnalyserNode = undefined;
            this.channelMergerNode = undefined;
            this.channelGainNodes = [];
            this.gainNode = undefined;
            this.audioBuffer = undefined;
        }

        if (this.audioContext) {
            this.audioContext = undefined;
        }
    }

    private setAllTime(newVal: number): void {
        this.audioTimePosition = newVal;
        this.seekWaveSurfer(newVal);
    }

    private seekWaveSurfer(newVal: number): void {
        if (this.waveSurfer) {
            this.waveSurfer.seekTo((newVal / this.audioBuffer.duration));
        }
    }

    private setVolume(newVal: number): void {
        this.gainNode.gain.value = newVal;
    }

    private startAt(newVal: number): void {
        this.createBufferNode();
        this.audioBufferSourceNode.start(this.audioCurrentTime, newVal, this.audioDuration);
    }

    private createBufferNode(): void {
        if (this.audioBufferSourceNode) {
            if (this.audioIsPlaying) {
                this.audioBufferSourceNode.stop();
            }

            this.audioBufferSourceNode.disconnect();
            this.audioBufferSourceNode = undefined;
        }

        // Audio Buffer Source Node
        this.audioBufferSourceNode = this.audioContext.createBufferSource();
        this.audioBufferSourceNode.buffer = this.audioBuffer;

        // Connect Nodes
        this.audioBufferSourceNode.connect(this.channelSplitterNode);
    }

    private createWaveTimeline(audioData: ArrayBuffer): void {
        const deferredRendering = setInterval(() => {
            if (this.stopRender) {
                clearInterval(deferredRendering);
            } else if (this.waveTimelineContainer) {
                clearInterval(deferredRendering);
                this.waveSurfer = WaveSurfer.create({
                    audioContext: this.audioContext,
                    mediaType: 'audio',
                    container: this.waveTimelineContainer.nativeElement,
                    normalize: true,
                    height: 255,
                    interact: false,
                });

                const loadEvent = () => {
                    this.isLoadingWaveform = false;
                    this.waveSurfer.un('ready', loadEvent);
                };

                this.waveSurfer.on('ready', loadEvent);
                this.waveSurfer.loadArrayBuffer(audioData);
            }
        }, 500);
    }

    private initAudioNodes(channels: number): void {
        // Gain Node
        this.gainNode = this.audioContext.createGain();
        this.gainNode.connect(this.audioContext.destination);

        // Frequency Analyzer Node
        this.frequencyAnalyserNode = this.audioContext.createAnalyser();
        this.frequencyAnalyserNode.fftSize = 256;
        this.frequencyAnalyserNode.connect(this.gainNode);

        this.frequencyData = new Uint8Array(this.frequencyAnalyserNode.frequencyBinCount);
        this.barWidth = 100 / this.frequencyAnalyserNode.frequencyBinCount;

        // Merger node
        this.channelMergerNode = this.audioContext.createChannelMerger(channels);
        this.channelMergerNode.connect(this.frequencyAnalyserNode);

        // Individual channel gains
        for (let channel = 0; channel < channels; channel++) {
            const channelGainNode = this.audioContext.createGain();
            channelGainNode.connect(this.channelMergerNode, 0, channel);
            this.channelGainNodes.push(channelGainNode);
        }

        // Splitter node
        this.channelSplitterNode = this.audioContext.createChannelSplitter(channels);
        for (let channel = 0; channel < channels; channel++) {
            this.channelSplitterNode.connect(this.channelGainNodes[channel], channel, 0);
        }

        // Audio Buffer Source Node
        this.createBufferNode();

        this.isLoading = false;
    }

    private render(): void {
        if (this.stopRender) {
            return;
        }

        requestAnimationFrame(() => this.render());

        const currentTime = this.audioContext.currentTime;
        const delta = currentTime - this.audioCurrentTime;
        this.audioCurrentTime = currentTime;

        if (this.audioBufferSourceNode) {
            this.audioDuration = this.audioBuffer.duration;
            this.audioVolume = this.gainNode.gain.value;

            if (this.audioTimePosition < this.audioDuration && this.audioIsPlaying) {
                this.audioTimePosition += delta;
            }

            if (this.audioIsPlaying) {
                this.seekWaveSurfer(this.audioTimePosition);

                if (this.frequencyAnalyserNode) {
                    this.frequencyAnalyserNode.getByteFrequencyData(this.frequencyData);
                }

                if (this.audioTimePosition >= this.audioDuration) {
                    this.togglePlay();
                }
            }
        }
    }
}
