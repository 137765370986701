import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';

import { Cause } from '../models/cause.model';
import { MockDbService } from '../../shared/services/system/mock-db.service';
import { WebsocketCause } from '../models/websocket/cause.model';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';

@Injectable()
export class CauseService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService,
        private _db: MockDbService
    ) {
        super(_http);
        this.actionUrl = '/assets/mock/mod_esds/causes.json';
    }

    public getAllCauses(): Observable<Cause[]> {
        return new Observable<Cause[]>((subscriber: Subscriber<Cause[]>) => {
            this._http
                .get<Cause[]>(this.actionUrl,
                    this.getRequestOptions())
                .subscribe((data: Cause[]) => {
                    this._db.causes = data;
                    subscriber.next(data);
                    subscriber.complete();
                });
        });
    }

    public getCause(id: number): Observable<Cause> {
        return new Observable<Cause>((subscriber: Subscriber<Cause>) => {
            this.getAllCauses()
                .subscribe((data: Cause[]) => {
                    let causeFound: Cause;

                    for (const val of data) {
                        if (val.id == id) {
                            causeFound = val;
                            break;
                        }
                    }

                    if (causeFound) {
                        subscriber.next(causeFound);
                    } else {
                        subscriber.error('Cause Not Found');
                    }
                    subscriber.complete();
                });
        });
    }

    public websocketGetCauses(): Observable<WebsocketCause> {
        return this._ws.get('/wsapi/esds/causes');
    }
}
