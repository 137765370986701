import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { CONFIGURATION } from '../../../app.constants';
import { ScreenOption } from '../../models/system/screen-option.model';
import { PersistenceService } from './persistence.service';

@Injectable()
export class ScreenOptionService extends BaseService {

    constructor(
        _http: HttpClient,
        private _persistence: PersistenceService,
    ) {
        super(_http);
    }

    public getScreenOption(): ScreenOption {
        const screenOptData = localStorage.getItem(CONFIGURATION.screenOptionTokenName);

        if (!screenOptData) {
            const newScreenOptions = new ScreenOption({});
            newScreenOptions.fakeBrightness = 100;
            newScreenOptions.isTerminalFavourite = false;
            this.updateScreenOption(newScreenOptions);
            return newScreenOptions;
        }

        return new ScreenOption(JSON.parse(screenOptData));
    }

    public updateScreenOption(updatedScreenOption: ScreenOption): void {
        const screenOptJson = JSON.stringify(updatedScreenOption);

        localStorage.setItem(CONFIGURATION.screenOptionTokenName, screenOptJson);
        this._persistence.screenOptions.next(updatedScreenOption);
    }
}
