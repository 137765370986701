import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';

import { PersistenceService } from '../../shared/services/system/persistence.service';
import { Pattern } from '../models/pattern.model';
import { MlmConfiguration } from '../models/mlm-configuration.model';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { Dolphin } from '../models/dolphin.model';
import { CONFIGURATION } from '../../app.constants';
import { ConfigService } from '../services/config.service';
import { SystemLocationEnum } from '../../shared/enums/system-location.enum';
import { PatternService } from '../services/pattern.service';
import { YesNoDialogModalComponent } from '../../shared/components/dialog/yesno/yesno.component';
import { KeyboardDialogModalComponent } from '../../shared/components/dialog/keyboard/keyboard.component';
import { BrainConfiguration } from '../../shared/models/system/brain-configuration.model';
import { SystemConfiguration } from '../../shared/models/system/configuration.model';
import { Jetty } from '../models/jetty.model';
import { EnvironmentalOption } from '../models/environmental-option.model';
import { Hook } from '../models/hook.model';
import { OperationService } from '../services/operation.service';
import { Shape } from '../models/shape.model';
import { LoadOption } from '../models/load-option.model';
import { ShapeDolphin } from '../models/shape-dolphin.model';
import { ShapeSquare } from '../models/shape-square.model';
import { ShapeDiagonal } from '../models/shape-diagonal.model';
import { ShapeTypeEnum } from '../enums/shape-type.enum';
import { MlmConfigurationDto } from '../models/dto/mlm-configuration-dto.model';
import { EnvironmentalData } from '../models/environmental-data.model';
import { DasOption } from '../models/das-option.model';
import { DasData } from '../models/das-data.model';
import { AlarmStateEnum } from '../enums/alarm-state.enum';

@Component({
    selector: 'mlm-operations',
    styleUrls: ['./operations.component.css'],
    templateUrl: './operations.component.html'
})
export class MlmOperationsComponent implements OnInit, OnDestroy {

    @ViewChild('updatePatternModal') public updatePatternModal: YesNoDialogModalComponent;
    @ViewChild('savePatternKeyboardModal') public addPatternModal: KeyboardDialogModalComponent;

    public mlmConfig: MlmConfiguration;
    public currentDolphins: Dolphin[] = [];
    public selectedDolphin: Dolphin;
    public currentPattern: Pattern;
    public currentJetty: Jetty;
    public hasCurrentPatternChanged: boolean = false;
    public brainConfiguration: BrainConfiguration;
    public loadOption: LoadOption;
    public environmentalOption: EnvironmentalOption;
    public dasOption: DasOption;
    public dolphinShapes: ShapeDolphin[] = [];
    public squareShapes: ShapeSquare[] = [];
    public diagonalShapes: ShapeDiagonal[] = [];
    public tonnageDisplayFormat: string = '##';
    public tonnageUnit: string = 'T';

    private routerSub: Subscription;
    private persistenceSub: Subscription;
    private brainPersistenceSub: Subscription;
    private webSockets: Subscription[] = [];
    private pollTimer: Subscription = undefined;

    constructor(
        private _configService: PageConfigService,
        private _route: ActivatedRoute,
        private _operationService: OperationService,
        private _patternService: PatternService,
        private _mlmConfig: ConfigService,
        private _persistence: PersistenceService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.brainPersistenceSub = this._persistence
                    .systemConfiguration
                    .subscribe((data: SystemConfiguration) => {
                        if (data) {
                            this.brainConfiguration = data.systemConfiguration;
                        }
                    });

                this.persistenceSub = this._persistence
                    .mlmConfiguration
                    .subscribe((data: MlmConfiguration) => {
                        if (data) {
                            this.mlmConfig = data;
                            this.currentPattern = data.currentPattern;
                            this.serviceGetDolphins()
                                .add(() => {
                                    this.serviceGetHooks();
                                });
                            this.serviceGetEnvironmentalOptions()
                                .add(() => {
                                    if (this.environmentalOption) {
                                        this.serviceGetEnvironmentalData();
                                    }
                                });
                            this.serviceGetDasOptions()
                                .add(() => {
                                    if (this.dasOption && this.dasOption.hasDas) {
                                        this.serviceGetDasData();
                                    }
                                });
                            this.serviceGetLoadOptions();
                            this.serviceGetShapes();
                            this.serviceGetCurrentPattern();
                        }
                    });

                this.webSockets.push(this.websocketGetHooks());
                this.webSockets.push(this.websocketGetEnvironmentalOption());
                this.webSockets.push(this.websocketGetDasOption());
                this.serviceGetHooks().add(() => {
                    this.startPolling();
                });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.routerSub = undefined;
            this.stopPolling();
            this.persistenceSub.unsubscribe();
            this.brainPersistenceSub.unsubscribe();
            for (const val of this.webSockets) {
                if (val) {
                    val.unsubscribe();
                }
            }
        }
    }

    // Template Helpers
    public selectDolphin(selectedDolphin: Dolphin): void {
        this.selectedDolphin = selectedDolphin;
    }

    public updatePattern(newPattern: Pattern): void {
        this.currentPattern = newPattern;
        if (this.currentPattern.patternJoins && this.currentPattern.patternJoins.length <= 0) {
            if (this.currentPattern.id &&
                this.currentPattern.id.length > 0 &&
                !this.currentPattern.name &&
                this.currentPattern.name.length <= 0) {
                this.currentPattern.id = '';
            }
            this.hasCurrentPatternChanged = false;
        } else {
            this.hasCurrentPatternChanged = true;
        }
    }

    public switchShipSides(pin: string): void {
        if (this.mlmConfig.currentShipSide == SystemLocationEnum.PORT) {
            this.mlmConfig.currentShipSide = SystemLocationEnum.STARBOARD;
        } else if (this.mlmConfig.currentShipSide == SystemLocationEnum.STARBOARD) {
            this.mlmConfig.currentShipSide = SystemLocationEnum.PORT;
        }

        this.serviceUpdateMlmConfig({
            settingsPin: pin,
            configuration: this.mlmConfig
        });
    }

    public openSavePatternModal(): void {
        if (this.currentPattern.id && this.currentPattern.id.length >= 0) {
            this.updatePatternModal.open();
        } else {
            this.addPatternModal.open(undefined);
        }
    }

    public savePattern(newPatternName: string): void {
        if (newPatternName && newPatternName.length > 0) {
            this.currentPattern.name = newPatternName;
        }

        this.updatePattern(this.currentPattern);
        this.hasCurrentPatternChanged = false;
        if (this.currentPattern.id && this.currentPattern.id.length >= 0) {
            this.serviceUpdatePattern(this.currentPattern);
        } else {
            this.serviceAddPattern(this.currentPattern);
        }
    }

    public newPattern(): void {
        this.currentPattern = new Pattern({});
        this.currentPattern.patternJoins = [];
        this.currentPattern.jettyId = this.mlmConfig.currentJetty.id;
        this.updatePattern(this.currentPattern);
    }

    public loadPattern(loadedPattern: Pattern): void {
        this.serviceSetCurrentPattern(loadedPattern);
    }

    public isNewPattern(): boolean {
        return ((!this.currentPattern) ||
        ((!this.currentPattern.id || this.currentPattern.id.length <= 0) &&
        (!this.currentPattern.patternJoins || this.currentPattern.patternJoins.length <= 0)));
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'MLM',
            path: '/mlm'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'OPERATIONS',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    private startPolling(): void {
        if (this.routerSub === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                this.serviceGetHooks().add(() => {
                    if (this.environmentalOption) {
                        this.serviceGetEnvironmentalData().add(() => {
                            if (this.dasOption && this.dasOption.hasDas) {
                                this.serviceGetDasData().add(() => {
                                    this.startPolling();
                                });
                            } else {
                                this.startPolling();
                            }
                        });
                    } else if (this.dasOption && this.dasOption.hasDas) {
                        this.serviceGetDasData().add(() => {
                            this.startPolling();
                        });
                    } else {
                        this.startPolling();
                    }
                });
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    // Service Calls
    private websocketGetHooks(): Subscription {
        return this._operationService
            .websocketGetHooks()
            .subscribe((newState: Hook) => {
                if (this.currentDolphins) {
                    for (const dolphin of this.currentDolphins) {
                        if (newState.dolphinId === dolphin.id) {
                            dolphin.updateState(newState);

                            if (!newState.isAlarmAcknowledged &&
                                newState.alarmState != AlarmStateEnum.Normal) {
                                this._persistence.mlmCanAcknowledgeAlarms.next(true);
                            }
                        }
                    }
                }
            });
    }

    private websocketGetEnvironmentalOption(): Subscription {
        return this._operationService
            .websocketGetEnvironmentalData()
            .subscribe((newState: EnvironmentalData) => {
                if (this.environmentalOption) {
                    this.environmentalOption.updateState(newState);
                }
            });
    }

    private websocketGetDasOption(): Subscription {
        return this._operationService
            .websocketGetDasData()
            .subscribe((newState: DasData) => {
                if (this.dasOption) {
                    this.dasOption.updateState(newState);

                    if (!newState.isAlarmAcknowledged &&
                        newState.alarmState == AlarmStateEnum.Alarmed) {
                        this._persistence.mlmCanAcknowledgeAlarms.next(true);
                    }
                }
            });
    }

    private serviceGetDolphins(): Subscription {
        this.currentDolphins = [];
        return this._operationService
            .getDolphins()
            .subscribe((data: Dolphin[]) => {
                this.currentDolphins = [];
                for (const val of data) {
                    this.currentDolphins.push(new Dolphin(val));
                }
            });
    }

    private serviceGetHooks(): Subscription {
        return this._operationService
            .getHooks()
            .subscribe((data: Hook[]) => {
                let isSet: boolean = false;
                if (this.currentDolphins && this.currentDolphins.length > 0) {
                    let canAcknowledge: boolean = false;
                    for (const newHookState of data) {
                        if (!isSet) {
                            this.tonnageDisplayFormat = newHookState.displayFormat;
                            this.tonnageUnit = newHookState.unit;
                            isSet = true;
                        }

                        for (const dolphin of this.currentDolphins) {
                            if (newHookState.dolphinId === dolphin.id) {
                                dolphin.updateState(newHookState);
                            }
                        }

                        if (!newHookState.isAlarmAcknowledged &&
                            newHookState.alarmState != AlarmStateEnum.Normal) {
                            canAcknowledge = true;
                        }
                    }

                    this._persistence.mlmCanAcknowledgeAlarms.next(canAcknowledge);
                }
            });
    }

    private serviceGetEnvironmentalOptions(): Subscription {
        this.environmentalOption = undefined;

        return this._operationService
            .getEnvironmentalOptions()
            .subscribe((data: EnvironmentalOption) => {
                if (data) {
                    this.environmentalOption = new EnvironmentalOption(data);
                }
            });
    }

    private serviceGetEnvironmentalData(): Subscription {
        return this._operationService
            .getEnvironmentalData()
            .subscribe((data: EnvironmentalData[]) => {
                if (data && this.environmentalOption) {
                    for (const environmentalData of data) {
                        this.environmentalOption.updateState(environmentalData);
                    }
                }
            });
    }

    private serviceGetDasOptions(): Subscription {
        this.dasOption = undefined;

        return this._operationService
            .getDasOptions()
            .subscribe((data: DasOption) => {
                if (data) {
                    this.dasOption = new DasOption(data);
                }
            });
    }

    private serviceGetDasData(): Subscription {
        return this._operationService
            .getDasData()
            .subscribe((data: DasData[]) => {
                if (data && this.dasOption) {
                    let canAcknowledge: boolean = false;
                    for (const dasData of data) {
                        this.dasOption.updateState(dasData);

                        if (!dasData.isAlarmAcknowledged &&
                            dasData.alarmState == AlarmStateEnum.Alarmed) {
                            canAcknowledge = true;
                        }
                    }

                    this._persistence.mlmCanAcknowledgeAlarms.next(canAcknowledge);
                }
            });
    }

    private serviceGetLoadOptions(): Subscription {
        this.loadOption = undefined;

        return this._operationService
            .getLoadOptions()
            .subscribe((data: LoadOption) => {
                this.loadOption = data;
            });
    }

    private serviceGetShapes(): Subscription {
        this.diagonalShapes = [];
        this.dolphinShapes = [];
        this.squareShapes = [];

        return this._operationService
            .getShapes()
            .subscribe((data: Shape[]) => {
                if (data) {
                    for (const shape of data) {
                        switch (shape.type) {
                            case ShapeTypeEnum.Diagonal:
                                this.diagonalShapes.push(shape as ShapeDiagonal);
                                break;
                            case ShapeTypeEnum.Dolphin:
                                this.dolphinShapes.push(shape as ShapeDolphin);
                                break;
                            case ShapeTypeEnum.Square:
                                this.squareShapes.push(shape as ShapeSquare);
                                break;
                        }
                    }
                }
            });
    }

    private serviceGetCurrentPattern(): Subscription {
        this.currentPattern = undefined;

        return this._patternService
            .getCurrent()
            .subscribe((data: Pattern) => {
                if (data) {
                    this.updatePattern(data);
                }
            });
    }

    private serviceUpdateMlmConfig(mlmConfig: MlmConfigurationDto): Subscription {
        return this._mlmConfig
            .updateConfig(mlmConfig)
            .subscribe();
    }

    private serviceSetCurrentPattern(newPattern: Pattern): Subscription {
        return this._patternService.setCurrent(newPattern).subscribe();
    }

    private serviceAddPattern(newPattern: Pattern): Subscription {
        return this._patternService.add(newPattern).subscribe();
    }

    private serviceUpdatePattern(updatedPattern: Pattern): Subscription {
        return this._patternService.update(updatedPattern).subscribe();
    }
}
