import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CONFIGURATION } from '../../app.constants';

export interface RequestOptions {
    headers: HttpHeaders;
    withCredentials: boolean;
}

export interface FullRequestOptions {
    headers: HttpHeaders;
    observe: 'response';
    withCredentials: boolean;
}

export interface FullRawRequestOptions {
    headers: HttpHeaders;
    observe: 'response';
    withCredentials: boolean;
    responseType: 'text';
}

export class BaseService {

    public static getScreenAuth(): string {
        const authTokenData = localStorage.getItem(CONFIGURATION.authTokenName);

        if (!authTokenData) {
            return undefined;
        }

        return authTokenData;
    }

    protected static handleError(error: HttpErrorResponse): Observable<never> {
        console.error(error);
        return throwError(error.message || 'Server error');
    }

    protected httpPrefix: string;
    protected host: string;
    protected actionUrl: string;
    protected wsHost: string;
    protected webSocketUrl: string;
    protected screenActionUrl: string;

    private headers: HttpHeaders;

    constructor(
        protected _http: HttpClient
    ) {
        this.headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        if (CONFIGURATION.isProduction) {
            if (CONFIGURATION.prodConfig.server && CONFIGURATION.prodConfig.port) {
                this.host = CONFIGURATION.prodConfig.server + ':' + CONFIGURATION.prodConfig.port;
            } else {
                this.host = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            }

            if (CONFIGURATION.prodConfig.webSocketHost) {
                this.wsHost = CONFIGURATION.prodConfig.webSocketHost;
            } else {
                this.wsHost = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            }

            this.httpPrefix = CONFIGURATION.prodConfig.useHttps ? 'https://' : 'http://';
            this.actionUrl = this.httpPrefix + this.host + CONFIGURATION.prodConfig.apiUrl;
            this.webSocketUrl = 'ws://' + this.wsHost;
            this.screenActionUrl = 'http://' + CONFIGURATION.prodConfig.screenApiServer +
                CONFIGURATION.prodConfig.screenApiUrl;
        } else {
            if (CONFIGURATION.devConfig.server && CONFIGURATION.devConfig.port) {
                this.host = CONFIGURATION.devConfig.server + ':' + CONFIGURATION.devConfig.port;
            } else {
                this.host = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            }

            if (CONFIGURATION.devConfig.webSocketHost) {
                this.wsHost = CONFIGURATION.devConfig.webSocketHost;
            } else {
                this.wsHost = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            }

            this.httpPrefix = CONFIGURATION.devConfig.useHttps ? 'https://' : 'http://';
            this.actionUrl = this.httpPrefix + this.host + CONFIGURATION.devConfig.apiUrl;
            this.webSocketUrl = 'ws://' + this.wsHost;
            this.screenActionUrl = 'http://' + CONFIGURATION.devConfig.screenApiServer +
                CONFIGURATION.devConfig.screenApiUrl;
        }
    }

    protected getRequestOptions(): RequestOptions {
        const hasCredentials: boolean = false;

        return {
            headers: this.getHeaders(),
            withCredentials: hasCredentials
        };
    }

    protected getFullRequestOptions(): FullRequestOptions {
        const hasCredentials: boolean = false;

        return {
            headers: this.getHeaders(),
            observe: 'response',
            withCredentials: hasCredentials
        };
    }

    protected getFullRawRequestOptions(): FullRawRequestOptions {
        const hasCredentials: boolean = false;

        return {
            headers: this.getHeaders(),
            observe: 'response',
            withCredentials: hasCredentials,
            responseType: 'text',
        };
    }

    private getHeaders(): HttpHeaders {
        const authToken = BaseService.getScreenAuth();

        if (authToken) {
            this.headers = this.headers.set('X-Screen-Id', authToken);
        } else {
            this.headers = this.headers.delete('X-Screen-Id');
        }

        return this.headers;
    }
}
