import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { USL_ROUTES } from './mod_usl.routes';

// Thirdparty
import {
    AlertModule,
    ButtonsModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    TabsModule
} from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';
import { SharedComponentsModule } from '../shared/components/shared-components.module';

// Services
import { ConfigService } from './services/config.service';
import { UslSystemV0Service } from './services/usl-system/v0-system.service';
import { UslLogV0Service } from './services/usl-system/v0-log.service';
import { UslAlarmV0Service } from './services/usl-system/v0-alarm.service';

// Components
import { UslComponent } from './mod_usl.component';
import { UslHomeComponent } from './home/home.component';
import { UslDisplayV0Component } from './home/display-versions/v0/display-v0.component';
import { UslDisplayV1Component } from './home/display-versions/v1/display-v1.component';
import {
    UslDisplayV0ModalSettingsComponent
} from './home/display-versions/v0/modals/settings/settings.component';
import {
    UslDisplayV0ModalHistoryComponent
} from './home/display-versions/v0/modals/history/history.component';
import { UslDisplayV0ModalAlarmsComponent } from './home/display-versions/v0/modals/alarms/alarms.component';
import { UslDisplayV0ModalAlarmLogComponent } from './home/display-versions/v0/modals/history/alarm-log/alarm-log.component';
import { UslDisplayV0ModalSslLogComponent } from './home/display-versions/v0/modals/history/ssl-log/ssl-log.component';
import { UslDisplayV0ModalLogComponent } from './home/display-versions/v0/modals/history/log/log.component';
import { UslDisplayV0ModalReadingConfigComponent } from './home/display-versions/v0/modals/settings/reading-config/reading-config.component';
import { UslDisplayV0ModalAlarmConfigComponent } from './home/display-versions/v0/modals/settings/alarm-config/alarm-config.component';
import { UslChangeSettingsModalComponent } from './home/change-settings/change-settings.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    declarations: [
        UslComponent,
        UslHomeComponent,
        UslChangeSettingsModalComponent,

        // V0
        UslDisplayV0Component,
        UslDisplayV0ModalSettingsComponent,
        UslDisplayV0ModalHistoryComponent,
        UslDisplayV0ModalAlarmsComponent,
        UslDisplayV0ModalLogComponent,
        UslDisplayV0ModalAlarmLogComponent,
        UslDisplayV0ModalSslLogComponent,
        UslDisplayV0ModalReadingConfigComponent,
        UslDisplayV0ModalAlarmConfigComponent,

        // V1
        UslDisplayV1Component
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(USL_ROUTES),
        TranslateModule,

        // Thirdparty
        AlertModule,
        ButtonsModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        TabsModule,
        NgxDatatableModule,

        // Pipes
        SharedPipesModule,

        // Directives
        SharedDirectivesModule,

        // Components
        SharedComponentsModule,
    ],
    exports: [
        RouterModule
    ],
    providers: [
        ConfigService,
        UslSystemV0Service,
        UslLogV0Service,
        UslAlarmV0Service
    ]
})
export class UslModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: UslModule
        };
    }
}
