import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription, Observable, interval } from 'rxjs';

import { CONFIGURATION } from '../../../app.constants';

import { PageConfig } from '../../../shared/models/system/page-config.model';
import { PageConfigService } from '../../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../../shared/models/system/page-breadcrumb.model';

import { SslConfiguration } from '../../models/ssl-configuration.model';
import { Terminal } from '../../models/terminal.model';
import { PersistenceService } from '../../../shared/services/system/persistence.service';
import { GraphicalTypeEnum } from '../../enums/graphical-type.enum';
import { SystemConfiguration } from '../../../shared/models/system/configuration.model';
import { EsdSystem } from '../../../shared/models/esd/esd-system.model';
import { TripTypeEnum } from '../../../shared/enums/trip-type.enum';
import { WebsocketTrip } from '../../../shared/models/esd/websocket/trip.model';
import {
    WebsocketEsdSystemStatus
} from '../../../shared/models/esd/websocket/esd-system-status.model';
import { WebsocketTelecomm } from '../../../shared/models/esd/websocket/telecomm.model';
import { EsdService } from '../../../shared/services/esd/esd.service';
import {
    WebsocketEsdSystemStateChange
} from '../../../shared/models/esd/websocket/esd-system-state-change.model';
import { InstallationTypeEnum } from '../../../shared/enums/installation-type.enum';
import {
    LocalboardConfiguration
} from '../../../shared/models/common/localboard-configuration.model';
import { FibreLoopbackResult } from '../../../shared/models/esd/fibre-loopback-result.model';
import { ElectricLoopbackResult } from '../../../shared/models/esd/electric-loopback-result.model';
import { OrderByPipe } from '../../../shared/pipes/order-by.pipe';

@Component({
    selector: 'ssl-operations-graphical',
    styleUrls: ['./graphical.component.css'],
    templateUrl: './graphical.component.html'
})
export class SslOperationsGraphicalComponent implements OnInit, OnDestroy {

    public installationName: string;
    public installationType: InstallationTypeEnum;
    public currentTerminal: Terminal = undefined;
    public currentEsdSystems: EsdSystem[];
    public graphicalType = GraphicalTypeEnum;
    public localIndexes: number[] = [];
    public remoteIndexes: number[] = [];
    public esdsIndexes: number[] = [];
    public sslConfig: SslConfiguration;
    public localboardConfiguration: LocalboardConfiguration;
    public isLoading: boolean = false;

    private webSockets: Subscription[] = [];
    private pollTimer: Subscription = undefined;
    private routerSub: Subscription;
    private resizeEventListener: any;
    private persistenceSubSys: Subscription;
    private persistenceSubSsl: Subscription;
    private persistenceLocalboardConfigSub: Subscription;
    private syncStates: boolean[] = [];

    constructor(private _route: ActivatedRoute,
                private _esdService: EsdService,
                private _persistence: PersistenceService,
                private _configService: PageConfigService) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.isLoading = true;
                this.updateNavigation();
                this.webSockets.push(this.websocketGetEsdSystemStateChange());
                this.webSockets.push(this.websocketGetEsdSystemStatus());
                this.webSockets.push(this.websocketGetTelecommStatus());
                this.webSockets.push(this.websocketGetTripStatus());
                this.webSockets.push(this.websocketGetFibreLoopbackResult());
                this.webSockets.push(this.websocketGetElectricLoopbackResult());
                this.persistenceSubSys = this._persistence
                    .systemConfiguration
                    .subscribe((data: SystemConfiguration) => {
                        if (data) {
                            this.installationType = data.systemConfiguration.installationType;
                            this.installationName = data.systemConfiguration.installationName;
                        }
                    });
                this.persistenceSubSsl = this._persistence
                    .sslConfiguration
                    .subscribe((data: SslConfiguration) => {
                        if (data) {
                            this.sslConfig = data;
                            this.currentTerminal = data.currentTerminal;
                            if (this.currentTerminal) {
                                this.isLoading = true;
                                this.serviceGetEsdSystems(true).add(() => {
                                    this.isLoading = false;
                                });
                            }
                        }
                    });

                this.persistenceLocalboardConfigSub = this._persistence
                    .localboardConfiguration
                    .subscribe((data: LocalboardConfiguration) => {
                        if (data) {
                            this.localboardConfiguration = data;
                        }
                    });

                this.stopPolling();
                this.serviceGetEsdSystems(false).add(() => {
                    this.startPolling();
                    this.isLoading = false;
                });
            });
    }

    public ngOnDestroy(): void {
        window.removeEventListener('resize', this.resizeEventListener);
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.routerSub = undefined;
            this.stopPolling();
            this.persistenceSubSys.unsubscribe();
            this.persistenceSubSsl.unsubscribe();
            this.persistenceLocalboardConfigSub.unsubscribe();
            for (const subscription of this.webSockets) {
                if (subscription) {
                    subscription.unsubscribe();
                }
            }
        }
    }

    // Template helpers
    public isOutOfSync(): boolean {
        for (const syncState of this.syncStates) {
            if (!syncState) {
                return true;
            }
        }

        return false;
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'SSL',
            path: '/ssl'
        }));
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'OPERATIONS',
            path: '/ssl/operations'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'GRAPHICAL',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    private updateIndexes(): void {
        if (!this.currentEsdSystems || this.currentEsdSystems.length <= 0) {
            return;
        }

        this.localIndexes = [];
        this.remoteIndexes = [];
        this.esdsIndexes = [];
        this.syncStates = [];

        let systemIndex: number = 0;
        for (const system of this.currentEsdSystems) {
            let tripIndex: number = 0;
            this.localIndexes.push(-1);
            this.remoteIndexes.push(-1);
            this.esdsIndexes.push(-1);
            this.syncStates.push(false);
            for (const trip of system.trips) {
                switch (trip.tripType) {
                    case TripTypeEnum.ESDS:
                        this.esdsIndexes[systemIndex] = tripIndex;
                        break;
                    case TripTypeEnum.LOCAL:
                        this.localIndexes[systemIndex] = tripIndex;
                        break;
                    case TripTypeEnum.REMOTE:
                        this.remoteIndexes[systemIndex] = tripIndex;
                        break;
                }
                tripIndex += 1;
            }
            systemIndex += 1;
        }
    }

    private startPolling(): void {
        if (this.routerSub === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                if (this.currentTerminal) {
                    this.serviceGetEsdSystems(false).add(() => {
                        this.startPolling();
                    });
                } else {
                    this.startPolling();
                }
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    // Service Calls
    private websocketGetEsdSystemStateChange(): Subscription {
        return this._esdService
            .websocketGetEsdSystemStateChange()
            .subscribe((data: WebsocketEsdSystemStateChange) => {
                this.serviceGetEsdSystems(true);
            });
    }

    private websocketGetTripStatus(): Subscription {
        return this._esdService
            .websocketGetTripStatus()
            .subscribe((data: WebsocketTrip) => {
                for (const system of this.currentEsdSystems) {
                    if (data.systemId === system.id) {
                        system.updateWebsocketEsdSystemTrip(data);
                    }
                }
            });
    }

    private websocketGetEsdSystemStatus(): Subscription {
        return this._esdService
            .websocketGetEsdSystemStatus()
            .subscribe((data: WebsocketEsdSystemStatus) => {
                for (const system of this.currentEsdSystems) {
                    if (data.id === system.id) {
                        system.updateWebsocketEsdSystemState(data);
                    }
                }
            });
    }

    private websocketGetTelecommStatus(): Subscription {
        return this._esdService
            .websocketGetTelecommStatus()
            .subscribe((data: WebsocketTelecomm) => {
                for (const system of this.currentEsdSystems) {
                    if (data.systemId === system.id) {
                        system.updateWebsocketEsdSystemTelecomm(data);
                    }
                }
            });
    }

    private websocketGetFibreLoopbackResult(): Subscription {
        return this._esdService
            .websocketGetFibreLoopbackResult()
            .subscribe((data: FibreLoopbackResult) => {
                for (const system of this.currentEsdSystems) {
                    if (data.systemId === system.id) {
                        system.fibreLoopbackResult = data;
                    }
                }
            });
    }

    private websocketGetElectricLoopbackResult(): Subscription {
        return this._esdService
            .websocketGetElectricLoopbackResult()
            .subscribe((data: ElectricLoopbackResult) => {
                for (const system of this.currentEsdSystems) {
                    if (data.systemId === system.id) {
                        system.electricLoopbackResult = data;
                    }
                }
            });
    }

    private serviceGetEsdSystems(forceRefresh: boolean): Subscription {
        return this._esdService
            .getEsdSystems()
            .subscribe((data: EsdSystem[]) => {
                if (!this.currentEsdSystems || this.currentEsdSystems.length <= 0 || forceRefresh) {
                    data = new OrderByPipe().transform(data, ['primaryOrder', 'systemLocation']);
                    this.currentEsdSystems = [];
                    for (const newState of data) {
                        this.currentEsdSystems.push(new EsdSystem(newState));
                    }
                    this.updateIndexes();
                }

                let outOfSync: boolean = false;
                let systemIndex = 0;
                for (const newState of data) {
                    for (const oldState of this.currentEsdSystems) {
                        if (newState.id === oldState.id) {
                            if (newState.stateIdentifier != oldState.stateIdentifier) {
                                this.currentEsdSystems = [];
                                outOfSync = true;
                                break;
                            } else {
                                oldState.updateEsdSystemState(newState);
                                break;
                            }
                        }
                    }

                    if (outOfSync) {
                        break;
                    }

                    this.syncStates[systemIndex] = !newState.isStale;
                    systemIndex += 1;
                }
            });
    }
}
