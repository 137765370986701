import { Component, Input } from '@angular/core';

@Component({
    selector: 'alert-box-element',
    styleUrls: ['./alert-box.component.css'],
    templateUrl: './alert-box.component.html'
})
export class AlertBoxElementComponent {

    @Input() public prefix: string;
    @Input() public message: string;
    @Input() public type: string;

}
