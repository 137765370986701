import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';

import { MlmConfiguration } from '../models/mlm-configuration.model';
import { WebSocketService } from '../../shared/services/websocket/websocket.service';
import { CheckPinDto } from '../../mod_ssl/models/dto/check-pin-dto.model';
import { MlmConfigurationDto } from '../models/dto/mlm-configuration-dto.model';

@Injectable()
export class ConfigService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'mlm/configuration';
    }

    public getConfig(): Observable<MlmConfiguration> {
        return this._http
            .get<MlmConfiguration>(this.actionUrl,
                this.getRequestOptions());
    }

    public updateConfig(updatedConfig: MlmConfigurationDto): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl,
                JSON.stringify(updatedConfig),
                this.getFullRawRequestOptions());
    }

    public toggleDebugMode(): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/debug',
                undefined,
                this.getFullRawRequestOptions());
    }

    public checkPin(pinToCheck: string): Observable<HttpResponse<string>> {
        return this._http
            .put(this.actionUrl + '/check-pin',
                JSON.stringify(new CheckPinDto({ pin: pinToCheck })),
                this.getFullRawRequestOptions());
    }

    public websocketGetConfig(): Observable<MlmConfiguration> {
        return this._ws.get('/wsapi/mlm/config');
    }
}
