import { Component, Input } from '@angular/core';

@Component({
    selector: 'ssl-shared-status-cable',
    styleUrls: ['./status-cable.component.css'],
    templateUrl: './status-cable.component.html'
})
export class SslOperationsSharedStatusCableComponent {

    @Input() public isFirstUp: boolean;
    @Input() public isInhibited: boolean;
    @Input() public isTripped: boolean;
    @Input() public isAccepted: boolean;
    @Input() public isNoDirection: boolean;
    @Input() public isLeft: boolean;
    @Input() public isInhibitTop: boolean = true;
    @Input() public isFirstUpTop: boolean = false;
    @Input() public inhibitedLangKey: string = 'SSL.STAT_INHIBITED';
    @Input() public trippedLangKey: string = 'SSL.STAT_TRIPPED';
    @Input() public healthyLangKey: string = 'SSL.STAT_HEALTHY';
    @Input() public firstUpLangKey: string = 'SSL.STAT_FIRST_UP';

}
