import { TripTypeEnum } from '../../shared/enums/trip-type.enum';

export abstract class ConnectionEsd {

    public $type: string;
    public id: string;
    public connectionConfigurationId: string;
    public esdLangKey: string;
    public esdSubtextLangKey: string;
    public esdType: TripTypeEnum;

    constructor(data: any, type: string) {
        this.$type = type || 'Trelleborg.SSL10K.Brain.Database.Models.Ssl.EntityConnectionEsd, Trelleborg.SSL10K.Brain.Database';
        this.id = data.id || undefined;
        this.connectionConfigurationId = data.connectionConfigurationId || undefined;
        this.esdLangKey = data.esdLangKey || undefined;
        this.esdSubtextLangKey = data.esdSubtextLangKey || undefined;
        this.esdType = data.esdType;
    }
}
