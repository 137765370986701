import { ConnectionEsd } from './connection-esd.model';

export class ConnectionEsdElectric extends ConnectionEsd {

    public isInput: boolean;
    public defaultData: number;
    public data: number;

    constructor(data: any) {
        super(data, 'Trelleborg.SSL10K.Brain.Database.Models.Ssl.EntityConnectionEsdElectric, Trelleborg.SSL10K.Brain.Database');
        this.defaultData = data.defaultData;
        this.data = data.data;

        // Workaround because of boolean type (Silly JS)
        this.isInput = false;
        if (data.isInput != null) {
            this.isInput = data.isInput;
        }
    }
}
