import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';

import { Region } from '../models/region.model';

@Injectable()
export class RegionService extends BaseService {

    constructor(_http: HttpClient) {
        super(_http);
        this.actionUrl = '/assets/mock/mod_esds/regions.json';
    }

    public getRegions(): Observable<Region[]> {
        return this._http
            .get<Region[]>(this.actionUrl,
                this.getRequestOptions());
    }
}
