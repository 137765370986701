import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'status-led',
    styleUrls: ['./status-led.component.css'],
    templateUrl: './status-led.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('statusLed', [
            state('inactive', style({
                color: '#000000'
            })),
            state('active', style({
                color: '#5ddc32'
            })),
            transition('inactive => active', animate('0ms')),
            transition('active => inactive', animate('50ms'))
        ]),
    ]
})
export class StatusLedElementComponent {

    @Input() public state: string;
}
