import { Telecomm } from '../telecomm.model';
import { WebsocketTelecomm } from '../websocket/telecomm.model';
import { TelecommTypeEnum } from '../../../enums/telecomm-type.enum';
import { ElectricLinePairConfigEnum } from '../../../enums/electric-line-pair-config.enum';

export class ElectricPhone extends Telecomm {

    public voltage: number;
    public linePair: ElectricLinePairConfigEnum;

    private voltageView = new DataView(new ArrayBuffer(4));

    constructor(data: any) {
        super(data);
        this.telecommType = TelecommTypeEnum.ELECTRIC;
        this.voltage = data.voltage || -1;
        this.linePair = data.linePair;
    }

    public updateTelecommState(newState: ElectricPhone): void {
        super.updateTelecommState(newState);
        this.voltage = newState.voltage;
        this.linePair = newState.linePair;
    }

    public updateWebsocketTelecommState(newState: WebsocketTelecomm): void {
        super.updateWebsocketTelecommState(newState);
        if (newState.data) {
            this.voltageView.setUint8(3, newState.data[0]);
            this.voltageView.setUint8(2, newState.data[1]);
            this.voltageView.setUint8(1, newState.data[2]);
            this.voltageView.setUint8(0, newState.data[3]);
            this.voltage = this.voltageView.getFloat32(0);
        }
    }
}
