export enum TripTypeEnum {
    NONE = 100,
    LOCAL = 1,
    ESDS = 2,
    REMOTE = 3,
    TANK_HIGH = 10,
    FIRST_STAGE = 11,
    SECOND_STAGE = 12,
    FIBRE_SIGNAL_STRENGTH = 13,
    FIBRE_ESD_CARRIER = 14,
    FIBRE_BIT_ERROR_RATE = 15,
    CONTINUITY_LINK = 200,
}
