import {
    Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[tms-draggable]'
})
export class DraggableDirective implements OnDestroy {
    @Input() public parentElement: HTMLElement;
    @Input() public dragX: boolean = true;
    @Input() public dragY: boolean = true;

    @Output() public dragStart: EventEmitter<any> = new EventEmitter();
    @Output() public dragging: EventEmitter<any> = new EventEmitter();
    @Output() public dragEnd: EventEmitter<any> = new EventEmitter();

    private position: { x: number, y: number } = { x: 0, y: 0 };
    private isDragging: boolean = false;
    private subscription: Subscription;

    constructor(private element: ElementRef) {}

    public ngOnDestroy(): void {
        this.destroySubscription();
    }

    @HostListener('mousedown', ['$event'])
    private onMouseDown(event: MouseEvent): void {
        if (event.button === 2) {
            return;
        }

        if (this.dragX || this.dragY) {
            event.preventDefault();
            this.isDragging = true;
            const dragStartPos = {
                x: event.clientX - this.element.nativeElement.offsetLeft - (this.element.nativeElement.offsetWidth / 2),
                y: event.clientY - this.element.nativeElement.offsetTop - (this.element.nativeElement.offsetHeight / 2),
                maxX: window.innerWidth,
                maxY: window.innerHeight,
            };
            this.position.x = event.clientX;
            this.position.y = event.clientY;
            if (this.parentElement) {
                dragStartPos.maxX = this.parentElement.clientWidth;
                dragStartPos.maxY = this.parentElement.clientHeight;
            }

            const mouseup = fromEvent(document, 'mouseup');
            this.subscription = mouseup.subscribe((ev: MouseEvent) => this.onMouseUp(ev));

            const mouseMoveSub = fromEvent(document, 'mousemove')
                .pipe(takeUntil(mouseup))
                .subscribe((ev: MouseEvent) => this.move(ev, dragStartPos));

            this.subscription.add(mouseMoveSub);

            this.dragStart.emit({
                position: this.position,
                element: this.element
            });
        }
    }

    private onMouseUp(event: MouseEvent): void {
        if (!this.isDragging) {
            return;
        }

        this.isDragging = false;
        this.element.nativeElement.classList.remove('dragging');

        if (this.subscription) {
            this.destroySubscription();
            this.dragEnd.emit({
                position: this.position,
                element: this.element.nativeElement,
            });
        }
    }

    @HostListener('touchstart', ['$event'])
    private onTouchStart(event: TouchEvent): void {
        if (this.dragX || this.dragY) {
            event.stopPropagation();
            this.isDragging = true;
            const dragStartPos = {
                x: event.changedTouches[0].clientX - this.element.nativeElement.offsetLeft - (this.element.nativeElement.offsetWidth / 2),
                y: event.changedTouches[0].clientY - this.element.nativeElement.offsetTop - (this.element.nativeElement.offsetHeight / 2),
                maxX: window.innerWidth,
                maxY: window.innerHeight,
            };
            this.position.x = event.changedTouches[0].clientX;
            this.position.y = event.changedTouches[0].clientY;
            if (this.parentElement) {
                dragStartPos.maxX = this.parentElement.clientWidth;
                dragStartPos.maxY = this.parentElement.clientHeight;
            }

            const touchend = fromEvent(document, 'touchend');
            this.subscription = touchend.subscribe((ev: TouchEvent) => this.onTouchEnd(ev));

            const touchMoveSub = fromEvent(document, 'touchmove')
                .pipe(takeUntil(touchend))
                .subscribe((ev: TouchEvent) => this.move(ev.changedTouches[0], dragStartPos));

            this.subscription.add(touchMoveSub);

            this.dragStart.emit({
                position: this.position,
                element: this.element
            });
        }
    }

    private onTouchEnd(event: TouchEvent): void {
        if (!this.isDragging) {
            return;
        }

        this.isDragging = false;
        this.element.nativeElement.classList.remove('dragging');

        if (this.subscription) {
            this.destroySubscription();
            this.dragEnd.emit({
                position: this.position,
                element: this.element.nativeElement,
            });
        }
    }

    private move(event: MouseEvent | Touch,
                 dragOffsetPos: {x: number; y: number, maxX: number, maxY: number }): void {
        if (!this.isDragging) {
            return;
        }

        if (this.dragX) {
            this.position.x = event.clientX - dragOffsetPos.x;
            if (this.position.x < 0) {
                this.position.x = 0;
            }

            if (this.position.x > dragOffsetPos.maxX) {
                this.position.x = dragOffsetPos.maxX;
            }

            this.element.nativeElement.style.left = `${this.position.x}px`;
        }

        if (this.dragY) {
            this.position.y = event.clientY - dragOffsetPos.y;
            if (this.position.y < 0) {
                this.position.y = 0;
            }

            if (this.position.y > dragOffsetPos.maxY) {
                this.position.y = dragOffsetPos.maxY;
            }

            this.element.nativeElement.style.top = `${this.position.y}px`;
        }

        this.element.nativeElement.classList.add('dragging');

        this.dragging.emit({
            position: this.position,
            element: this.element.nativeElement,
        });
    }

    private destroySubscription(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
    }
}
