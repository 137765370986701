import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, Observable, interval } from 'rxjs';

import {
    UslSystemV0SystemConfiguration
} from '../../../models/usl-system/v0/system-configuration.model';
import { UslSystemV0Service } from '../../../services/usl-system/v0-system.service';
import { UslSystemV0Control } from '../../../models/usl-system/v0/control.model';
import { UslSystemV0DisplayReading } from '../../../models/usl-system/v0/display-readings.model';
import { FontStyleEnum } from './enums/font-style.enum';
import { ContentAlignmentEnum } from './enums/content-alignment.enum';
import { HorizontalAlignmentEnum } from './enums/horizontal-alignment.enum';
import { UpdateDirectionEnum } from './enums/update-direction.enum';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { PersistenceService } from '../../../../shared/services/system/persistence.service';
import { UslReadingLite } from '../../../models/usl-system/lite/reading-lite.model';
import { UslSystemV0Alarm } from '../../../models/usl-system/v0/alarm.model';
import { CONFIGURATION } from '../../../../app.constants';
import { UslAlarmActiveLite } from '../../../models/usl-system/lite/alarm-active-lite.model';
import { UslAlarmLite } from '../../../models/usl-system/lite/alarm-lite.model';
import { UslAlarmV0Service } from '../../../services/usl-system/v0-alarm.service';
import { UslConfiguration } from '../../../models/usl-configuration.model';

// 0 = Exact threshold has to be met, larger number allows for more leeway for the threshold.
const TRANSPARENCY_THRESHOLD_STRENGTH: number = 0;

@Component({
    selector: 'usl-display-v0',
    styleUrls: ['./display-v0.component.css'],
    templateUrl: './display-v0.component.html'
})
export class UslDisplayV0Component implements OnInit, OnDestroy {

    @Input() public uslConfig: UslConfiguration;
    @Input() public isLocal: boolean;
    public systemConfiguration: UslSystemV0SystemConfiguration;
    public systemReadings: { [id: number]: UslSystemV0DisplayReading; } = {};
    public alarms: UslSystemV0Alarm[] = [];
    public activeAlarms: UslSystemV0Alarm[] = [];

    public overlayOffsets: { [id: number]: any; } = {};
    public closedBackgroundImages: { [id: number]: any; } = {};
    public openBackgroundImages: { [id: number]: any; } = {};
    public nullBackgroundImages: { [id: number]: any; } = {};
    public pageCount: number = 0;
    public pages: number[] = [];
    public currentPage: number = 0;

    private webSockets: Subscription[] = [];
    private pollTimer: Subscription = undefined;

    constructor(
        private _uslSystemV0Service: UslSystemV0Service,
        private _uslAlarmV0Service: UslAlarmV0Service,
        private _persistence: PersistenceService,
        private _sanitizer: DomSanitizer
    ) {}

    public ngOnInit(): void {
        if (this.isLocal) {
            this.serviceGetLocalV0System();
            this.serviceGetLocalV0SystemReadings();
            this.serviceGetLocalV0Alarms().add(() => {
               this.serviceGetLocalV0ActiveAlarms().add(() => {
                   this.startPolling();
               });
            });
        } else {
            this.serviceGetRemoteV0System().add(() => {
                this._persistence.uslRemoteName.next(this.systemConfiguration.installationName);
            });
            this.serviceGetRemoteV0SystemReadings();
            this.serviceGetRemoteV0Alarms().add(() => {
               this.serviceGetRemoteV0ActiveAlarms().add(() => {
                  this.startPolling();
               });
            });
        }

        this.webSockets = [];
        this.webSockets.push(this.websocketGetReadings());
        this.webSockets.push(this.websocketGetActiveAlarms());
        this.webSockets.push(this.websocketGetAlarms());
    }

    public ngOnDestroy(): void {
        for (const val of this.webSockets) {
            if (val) {
                val.unsubscribe();
            }
        }

        this.webSockets = undefined;
        this.stopPolling();
    }

    // Template helper
    public goToPage(newPage: number): void {
        this.currentPage = newPage - 1;
    }

    public getContentAlignment(contentAlignment: ContentAlignmentEnum): string {
        switch (contentAlignment) {
            case ContentAlignmentEnum.BOTTOM_CENTER:
            case ContentAlignmentEnum.MIDDLE_CENTER:
            case ContentAlignmentEnum.TOP_CCENTER:
                return 'center';
            case ContentAlignmentEnum.BOTTOM_LEFT:
            case ContentAlignmentEnum.MIDDLE_LEFT:
            case ContentAlignmentEnum.TOP_LEFT:
                return 'left';
            case ContentAlignmentEnum.BOTTOM_RIGHT:
            case ContentAlignmentEnum.MIDDLE_RIGHT:
            case ContentAlignmentEnum.TOP_RIGHT:
                return 'right';
            default:
                return 'initial';
        }
    }

    public getControlHeight(control: UslSystemV0Control): string {
        if (control.controlType == 'System.Windows.Forms.Panel') {
             return '100%';
        }

        return control.height + 'px';
    }

    public getControlWidth(control: UslSystemV0Control): string {
        if (control.controlType == 'System.Windows.Forms.Panel') {
            return '100%';
        }

        return control.width + 'px';
    }

    public getTextAlignment(textAlign: HorizontalAlignmentEnum): string {
        switch (textAlign) {
            case HorizontalAlignmentEnum.CENTER:
                return 'center';
            case HorizontalAlignmentEnum.LEFT:
                return 'left';
            case HorizontalAlignmentEnum.RIGHT:
                return 'right';
            default:
                return 'initial';
        }
    }

    public getFontFamily(fontFamily: string): string {
        return fontFamily;
    }

    public getFontWeight(fontStyle: FontStyleEnum, isAlarmed: boolean): string {
        if (isAlarmed) {
            return 'bold';
        }

        switch (fontStyle) {
            case FontStyleEnum.BOLD:
                return 'bold';
            case FontStyleEnum.REGULAR:
            default:
                return 'normal';
        }
    }

    public getFontStyle(fontStyle: FontStyleEnum): string {
        switch (fontStyle) {
            case FontStyleEnum.ITALIC:
                return 'italic';
            default:
                return 'normal';
        }
    }

    public getTextDecoration(fontStyle: FontStyleEnum): string {
        switch (fontStyle) {
            case FontStyleEnum.STRIKEOUT:
                return 'line-through';
            case FontStyleEnum.UNDERLINE:
                return 'underline';
            default:
                return 'none';
        }
    }

    public getRedGreenBlue(signedColor: number): number[] {
        const argb: number[] = [0, 0, 0, 0];
        const unsignedColor: number = signedColor >>> 0;
        argb[0] = unsignedColor >> 32 & 0xFF;
        argb[1] = unsignedColor >> 16 & 0xFF;
        argb[2] = unsignedColor >> 8 & 0xFF;
        argb[3] = unsignedColor & 0xFF;
        return argb;
    }

    public getHexFromBinary(signedColor: number): string {
        const rgb = this.getRedGreenBlue(signedColor);
        return '#' + ((1 << 24) + (rgb[1] << 16) + (rgb[2] << 8) + rgb[3]).toString(16).slice(1);
    }

    public isOpenShown(reading: UslSystemV0DisplayReading): boolean {
        if (reading && (reading.reading || reading.reading == 0.0)) {
            if (reading.isBoolean) {
                return (reading.reading == 1.0);
            }

            return true;
        }

        return false;
    }

    public isClosedShown(reading: UslSystemV0DisplayReading): boolean {
        if (reading && (reading.reading || reading.reading == 0.0) && reading.isBoolean) {
            return (reading.reading == 0.0);
        }

        return true;
    }

    public calculateAbsoluteClipPath(control: UslSystemV0Control,
                                     reading: UslSystemV0DisplayReading): SafeStyle {
        // Only the sith deal in absolutes
        if (reading && !reading.isBoolean) {
            const displayOffsets = this.overlayOffsets[control.controlId];
            const percentage = this.getPercentageOfValue(reading);
            const displayResultHeight: number = (((control.height - displayOffsets.top -
            displayOffsets.bottom) * (100 - percentage)) / 100);
            const displayResultWidth: number = (((control.width - displayOffsets.left -
            displayOffsets.right) * (100 - percentage)) / 100);

            switch (control.imageUpdateDirection) {
                case UpdateDirectionEnum.BOTTOM_TO_TOP:
                    return this._sanitizer.bypassSecurityTrustStyle('inset(' +
                        (displayResultHeight + displayOffsets.top) + 'px 0px 0px 0px)');
                case UpdateDirectionEnum.TOP_TO_BOTTOM:
                    return this._sanitizer.bypassSecurityTrustStyle('inset(0px 0x ' +
                        (displayResultHeight + displayOffsets.bottom) + 'px 0px)');
                case UpdateDirectionEnum.LEFT_TO_RIGHT:
                    return this._sanitizer.bypassSecurityTrustStyle('inset(0px 0px 0px ' +
                        (displayResultWidth + displayOffsets.right) + 'px)');
                default:
                case UpdateDirectionEnum.RIGHT_TO_LEFT:
                    return this._sanitizer.bypassSecurityTrustStyle('inset(0px ' +
                        (displayResultWidth + displayOffsets.left) + 'px 0px 0px)');
            }
        }

        return this._sanitizer.bypassSecurityTrustStyle('initial');
    }

    public hasUnacknowledgedAlarms(): boolean {
        for (const activeAlarm of this.activeAlarms) {
            if (!activeAlarm.isAcknowledged) {
                return true;
            }
        }

        return false;
    }

    // Helper
    private startPolling(): void {
        if (this.webSockets === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                if (this.isLocal) {
                    this.serviceGetLocalV0ActiveAlarms().add(() => {
                        this.serviceGetLocalV0Alarms().add(() => {
                            this.startPolling();
                        });
                    });
                } else {
                    this.serviceGetRemoteV0Alarms().add(() => {
                        this.serviceGetRemoteV0ActiveAlarms().add(() => {
                            this.startPolling();
                        });
                    });
                }
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    private getPercentageOfValue(reading: UslSystemV0DisplayReading): number {
        return (reading.reading / (reading.min + reading.max)) * 100;
    }

    private getImage(imgSource: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = (ev) => { resolve(ev.target); };
            img.onerror = img.onabort = () => { reject('Error loading image'); };
            img.src = 'data:image/png;base64,' + imgSource;
        });
    }

    private fixLayout(data: UslSystemV0SystemConfiguration) {
        for (const val of data.viewControls) {
            if (val.controlType == 'System.Windows.Forms.Panel') {
                this.pageCount += 1;
                this.pages.push(this.pageCount);
            }

            if (val.controlType == 'Trelleborg.USL.Configuator.CustomControls.DraggablePictureBox'
                && val.imageB64) {
                this.getImage(val.imageB64).then((image: any) => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    context.drawImage(image, 0, 0, image.width, image.height);
                    const pixelData = context.getImageData(0, 0, image.width, image.height);
                    const closedImageData = context.createImageData(pixelData);
                    const openImageData = context.createImageData(pixelData);
                    const nullImageData = context.createImageData(pixelData);
                    const closedColor = this.getRedGreenBlue(val.closedColor);
                    const openColor = this.getRedGreenBlue(val.openColor);
                    const nullColor = this.getRedGreenBlue(val.nullColor);
                    const whiteColor = this.getRedGreenBlue(-1);

                    const overlayX: number[] = [];
                    const overlayY: number[] = [];

                    const transparencyMin: number = val.transparencyThreshold -
                        (TRANSPARENCY_THRESHOLD_STRENGTH / 2);
                    const transparencyMax: number = val.transparencyThreshold +
                        (TRANSPARENCY_THRESHOLD_STRENGTH / 2);

                    for (let i = 0; i < image.width; i++) {
                        for (let j = 0; j < image.height; j++) {
                            const pixelLocation: number = ((j * (image.width * 4)) + (i * 4));
                            const alpha = pixelData.data[((j * (image.width * 4)) + (i * 4)) + 3];

                            if (alpha !== undefined && alpha >= transparencyMin &&
                                alpha <= transparencyMax) {
                                overlayX.push(i);
                                overlayY.push(j);

                                if (val.closedColor || val.closedColor == 0) {
                                    closedImageData.data[pixelLocation] = closedColor[1]; // red
                                    closedImageData.data[pixelLocation + 1] = closedColor[2]; // gre
                                    closedImageData.data[pixelLocation + 2] = closedColor[3]; // blu
                                    closedImageData.data[pixelLocation + 3] = 255; // alpha
                                }

                                if (val.openColor || val.openColor == 0) {
                                    openImageData.data[pixelLocation] = openColor[1]; // red
                                    openImageData.data[pixelLocation + 1] = openColor[2]; // gre
                                    openImageData.data[pixelLocation + 2] = openColor[3]; // blu
                                    openImageData.data[pixelLocation + 3] = 255; // alpha
                                }

                                if (val.nullColor && val.nullColor != 0) {
                                    nullImageData.data[pixelLocation] = nullColor[1]; // red
                                    nullImageData.data[pixelLocation + 1] = nullColor[2]; // gre
                                    nullImageData.data[pixelLocation + 2] = nullColor[3]; // blu
                                    nullImageData.data[pixelLocation + 3] = 255; // alpha
                                } else if (val.nullColor == 0) {
                                    nullImageData.data[pixelLocation] = whiteColor[1]; // red
                                    nullImageData.data[pixelLocation + 1] = whiteColor[2]; // gre
                                    nullImageData.data[pixelLocation + 2] = whiteColor[3]; // blu
                                    nullImageData.data[pixelLocation + 3] = 255; // alpha
                                }
                            }
                        }
                    }

                    overlayX.sort((a: number, b: number) => a - b);
                    overlayY.sort((a: number, b: number) => a - b);
                    this.overlayOffsets[val.controlId] = {};
                    this.overlayOffsets[val.controlId].top = overlayY[0];
                    this.overlayOffsets[val.controlId].bottom = val.height - overlayY[overlayY.length - 1];
                    this.overlayOffsets[val.controlId].left = overlayX[0];
                    this.overlayOffsets[val.controlId].right = val.width - overlayX[overlayX.length - 1];

                    if (val.closedColor || val.closedColor == 0) {
                        const closedImageCanvas = document.createElement('canvas');
                        closedImageCanvas.width = closedImageData.width;
                        closedImageCanvas.height = closedImageData.height;
                        closedImageCanvas.getContext('2d').putImageData(closedImageData, 0, 0);
                        this.closedBackgroundImages[val.controlId] = closedImageCanvas.toDataURL();
                    }

                    if (val.openColor || val.openColor == 0) {
                        const openImageCanvas = document.createElement('canvas');
                        openImageCanvas.width = openImageData.width;
                        openImageCanvas.height = openImageData.height;
                        openImageCanvas.getContext('2d').putImageData(openImageData, 0, 0);
                        this.openBackgroundImages[val.controlId] = openImageCanvas.toDataURL();
                    }

                    if (val.nullColor || val.nullColor == 0) {
                        const nullImageCanvas = document.createElement('canvas');
                        nullImageCanvas.width = nullImageData.width;
                        nullImageCanvas.height = nullImageData.height;
                        nullImageCanvas.getContext('2d').putImageData(nullImageData, 0, 0);
                        this.nullBackgroundImages[val.controlId] = nullImageCanvas.toDataURL();
                    }
                }, (error) => {
                    console.log(error);
                });
            }
        }
    }

    private addOrUpdateAlarms(alarms: UslSystemV0Alarm[]): void {
        for (const newAlarm of alarms) {
            let isAlarmFound: boolean = false;
            for (const existingAlarm of this.alarms) {
                if (newAlarm.id == existingAlarm.id) {
                    isAlarmFound = true;
                    existingAlarm.updateAlarmState(newAlarm);
                    break;
                }
            }

            if (!isAlarmFound) {
                this.alarms.push(new UslSystemV0Alarm(newAlarm));
            }
        }

        const alarmsToRemove: UslSystemV0Alarm[] = [];
        for (const existingAlarm of this.alarms) {
            let isAlarmFound: boolean = false;
            for (const newAlarm of alarms) {
                if (newAlarm.id == existingAlarm.id) {
                    isAlarmFound = true;
                    break;
                }
            }

            if (!isAlarmFound) {
                alarmsToRemove.push(existingAlarm);
            }
        }

        for (const alarmToRemove of alarmsToRemove) {
            const index = this.alarms.indexOf(alarmToRemove);
            if (index !== -1) {
                this.alarms.splice(index, 1);
            }
        }
    }

    private addOrUpdateActiveAlarms(alarms: UslSystemV0Alarm[]): void {
        for (const newAlarm of alarms) {
            let isAlarmFound: boolean = false;
            for (const existingAlarm of this.activeAlarms) {
                if (newAlarm.id == existingAlarm.id) {
                    isAlarmFound = true;
                    existingAlarm.updateAlarmState(newAlarm);
                    break;
                }
            }

            if (!isAlarmFound) {
                this.activeAlarms.push(new UslSystemV0Alarm(newAlarm));
            }
        }

        const alarmsToRemove: UslSystemV0Alarm[] = [];
        for (const existingAlarm of this.activeAlarms) {
            let isAlarmFound: boolean = false;
            for (const newAlarm of alarms) {
                if (newAlarm.id == existingAlarm.id) {
                    isAlarmFound = true;
                    break;
                }
            }

            if (!isAlarmFound) {
                alarmsToRemove.push(existingAlarm);
            }
        }

        for (const alarmToRemove of alarmsToRemove) {
            const index = this.activeAlarms.indexOf(alarmToRemove);
            if (index !== -1) {
                this.activeAlarms.splice(index, 1);
            }
        }
    }

    private addOrUpdateNewActiveAlarm(newAlarm: UslAlarmActiveLite): void {
        let isAlarmExisting: boolean = false;
        for (const existingAlarm of this.activeAlarms) {
            if (newAlarm.id == existingAlarm.id) {
                isAlarmExisting = true;
                existingAlarm.isAcknowledged = newAlarm.isAcknowledged;
                existingAlarm.isTripped = newAlarm.isTripped;
                break;
            }
        }

        if (isAlarmExisting) {
            return;
        }

        let isAlarmFound: boolean = false;
        for (const alarm of this.alarms) {
            if (alarm.id == newAlarm.id) {
                isAlarmFound = true;
                alarm.isAcknowledged = newAlarm.isAcknowledged;
                alarm.isTripped = newAlarm.isTripped;
                this.activeAlarms.push(alarm);
                break;
            }
        }

        if (!isAlarmFound) {
            if (this.isLocal) {
                this.serviceGetLocalV0ActiveAlarms();
            } else {
                this.serviceGetRemoteV0ActiveAlarms();
            }
        }
    }

    private addOrUpdateNewAlarm(newAlarm: UslAlarmLite): void {
        let isAlarmFound = false;
        for (const alarm of this.alarms) {
            if (alarm.id == newAlarm.id) {
                isAlarmFound = true;
                alarm.updateWebsocketAlarmState(newAlarm);
                break;
            }
        }

        if (!isAlarmFound) {
            this.alarms.push(new UslSystemV0Alarm(newAlarm));
        }
    }

    // Service calls
    private serviceGetLocalV0System(): Subscription {
        return this._uslSystemV0Service
            .getLocalConfiguration()
            .subscribe((data: UslSystemV0SystemConfiguration) => {
                this.fixLayout(data);
                this.systemConfiguration = data;
            });
    }

    private serviceGetRemoteV0System(): Subscription {
        return this._uslSystemV0Service
            .getRemoteConfiguration()
            .subscribe((data: UslSystemV0SystemConfiguration) => {
                this.fixLayout(data);
                this.systemConfiguration = data;
            });
    }

    private serviceGetLocalV0SystemReadings(): Subscription {
        return this._uslSystemV0Service
            .getLocalReadings()
            .subscribe((data: UslSystemV0DisplayReading[]) => {
                for (let val of data) {
                    this.systemReadings[val.readingId] = val;
                }
            });
    }

    private serviceGetRemoteV0SystemReadings(): Subscription {
        return this._uslSystemV0Service
            .getRemoteReadings()
            .subscribe((data: UslSystemV0DisplayReading[]) => {
                for (let val of data) {
                    this.systemReadings[val.readingId] = val;
                }
            });
    }

    private serviceGetLocalV0Alarms(): Subscription {
        return this._uslAlarmV0Service
            .getLocalAlarms()
            .subscribe((data: UslSystemV0Alarm[]) => {
                this.addOrUpdateAlarms(data);
            });
    }

    private serviceGetRemoteV0Alarms(): Subscription {
        return this._uslAlarmV0Service
            .getRemoteAlarms()
            .subscribe((data: UslSystemV0Alarm[]) => {
                this.addOrUpdateAlarms(data);
            });
    }

    private serviceGetLocalV0ActiveAlarms(): Subscription {
        return this._uslAlarmV0Service
            .getLocalActiveAlarms()
            .subscribe((data: UslSystemV0Alarm[]) => {
                this.addOrUpdateActiveAlarms(data);
            });
    }

    private serviceGetRemoteV0ActiveAlarms(): Subscription {
        return this._uslAlarmV0Service
            .getRemoteActiveAlarms()
            .subscribe((data: UslSystemV0Alarm[]) => {
                this.addOrUpdateActiveAlarms(data);
            });
    }

    private websocketGetReadings(): Subscription {
        return this._uslSystemV0Service
            .websocketGetReadings()
            .subscribe((data: UslReadingLite) => {
                if (data.isLocal == this.isLocal && this.systemReadings[data.readingId]) {
                    this.systemReadings[data.readingId].isAlarmed = data.isAlarmed;
                    this.systemReadings[data.readingId].reading = data.reading;
                }
            });
    }

    private websocketGetActiveAlarms(): Subscription {
        return this._uslAlarmV0Service
            .websocketGetActiveAlarms()
            .subscribe((data: UslAlarmActiveLite) => {
                if (data.isLocal == this.isLocal) {
                    this.addOrUpdateNewActiveAlarm(data);
                }
            });
    }

    private websocketGetAlarms(): Subscription {
        return this._uslAlarmV0Service
            .websocketGetAlarms()
            .subscribe((data: UslAlarmLite) => {
                if (data.isLocal == this.isLocal) {
                    this.addOrUpdateNewAlarm(data);
                    if (this.isLocal) {
                        this.serviceGetLocalV0ActiveAlarms();
                    } else {
                        this.serviceGetRemoteV0ActiveAlarms();
                    }
                }
            });
    }
}
