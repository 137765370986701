import { Routes } from '@angular/router';

// Guard
import { UslModuleGuard } from './guards/module.guard';

// Pages
import { UslComponent } from './mod_usl.component';
import { UslHomeComponent } from './home/home.component';

export const USL_ROUTES: Routes = [
    {
        path: 'usl',
        component: UslComponent,
        children: [
            { path: '', component: UslHomeComponent, pathMatch: 'full' }
        ],
        canActivate: [UslModuleGuard]
    },
];
