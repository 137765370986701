export class TerminalConfiguration {

    public id: string;
    public carrierDetectThreshold: number;
    public noiseThreshold: number;
    public isCompandingEnabled: boolean;
    public isAudioLowPassFilterEnabled: boolean;
    public maxActivatableSystems: number;
    public isFibreMlm: boolean;
    public isPrivateLine: boolean;
    public mk2IsModemOneFibre: boolean;
    public mk2IsModemTwoFibre: boolean;
    public mk2IsSoundPoweredBankA: boolean;
    public mk2IsSoundPoweredBankB: boolean;
    public mk2IsModemOneConnected: boolean;
    public mk2IsModemTwoConnected: boolean;
    public mk2IsMlmCrossed: boolean;
    public mk2IsMlmBridged: boolean;

    constructor(data: any) {
        this.id = data.id;
        this.carrierDetectThreshold = data.carrierDetectThreshold;
        this.noiseThreshold = data.noiseThreshold;
        this.maxActivatableSystems = data.maxActivatableSystems;

        // Workaround because of boolean type (Silly JS)
        this.isFibreMlm = false;
        if (data.isFibreMlm != null) {
            this.isFibreMlm = data.isFibreMlm;
        }

        this.isPrivateLine = false;
        if (data.isPrivateLine != null) {
            this.isPrivateLine = data.isPrivateLine;
        }

        this.isCompandingEnabled = false;
        if (data.isCompandingEnabled != null) {
            this.isCompandingEnabled = data.isCompandingEnabled;
        }

        this.isAudioLowPassFilterEnabled = false;
        if (data.isAudioLowPassFilterEnabled != null) {
            this.isAudioLowPassFilterEnabled = data.isAudioLowPassFilterEnabled;
        }

        this.mk2IsModemOneFibre = false;
        if (data.mk2IsModemOneFibre != null) {
            this.mk2IsModemOneFibre = data.mk2IsModemOneFibre;
        }

        this.mk2IsModemTwoFibre = false;
        if (data.mk2IsModemTwoFibre != null) {
            this.mk2IsModemTwoFibre = data.mk2IsModemTwoFibre;
        }

        this.mk2IsSoundPoweredBankA = false;
        if (data.mk2IsSoundPoweredBankA != null) {
            this.mk2IsSoundPoweredBankA = data.mk2IsSoundPoweredBankA;
        }

        this.mk2IsSoundPoweredBankB = false;
        if (data.mk2IsSoundPoweredBankB != null) {
            this.mk2IsSoundPoweredBankB = data.mk2IsSoundPoweredBankB;
        }

        this.mk2IsModemOneConnected = false;
        if (data.mk2IsModemOneConnected != null) {
            this.mk2IsModemOneConnected = data.mk2IsModemOneConnected;
        }

        this.mk2IsModemTwoConnected = false;
        if (data.mk2IsModemTwoConnected != null) {
            this.mk2IsModemTwoConnected = data.mk2IsModemTwoConnected;
        }

        this.mk2IsMlmCrossed = false;
        if (data.mk2IsMlmCrossed != null) {
            this.mk2IsMlmCrossed = data.mk2IsMlmCrossed;
        }

        this.mk2IsMlmBridged = false;
        if (data.mk2IsMlmBridged != null) {
            this.mk2IsMlmBridged = data.mk2IsMlmBridged;
        }
    }
}
