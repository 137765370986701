import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { CommonAlarm } from '../../../models/system/common-alarm.model';

@Component({
    selector: 'common-alarm-dialog-modal',
    templateUrl: './common-alarm.component.html',
    styleUrls: ['./common-alarm.component.css']
})
export class CommonAlarmDialogModalComponent {

    @ViewChild('commonAlarmModal') public commonAlarmModal: ModalDirective;
    @Input() public commonAlarm: CommonAlarm;

    // Template helpers
    public open(): void {
        this.commonAlarmModal.show();
    }

    public close(): void {
        this.commonAlarmModal.hide();
    }
}
