import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { Jetty } from '../../models/jetty.model';
import { JettyService } from '../../services/jetty.service';
import { PageConfigService } from '../../../shared/services/system/page-config.service';
import { PageConfig } from '../../../shared/models/system/page-config.model';
import { PageBreadcrumb } from '../../../shared/models/system/page-breadcrumb.model';
import { ScreenOptionService } from '../../../shared/services/system/screen-option.service';
import { PersistenceService } from '../../../shared/services/system/persistence.service';
import { ConfigService } from '../../services/config.service';
import { MlmConfiguration } from '../../models/mlm-configuration.model';
import { ScreenOption } from '../../../shared/models/system/screen-option.model';
import { BaseSafetyEvent } from '../../../shared/models/safety/events/base-safety-event.model';
import { SafetyEventType } from '../../../shared/enums/safety/safety-event-type.enum';
import { JettyDeactivatingSafetyEvent } from '../../../shared/models/safety/events/jetty-deactivating-safety-event.model';
import { JettyActivatingSafetyEvent } from '../../../shared/models/safety/events/jetty-activating-safety-event.model';

@Component({
    selector: 'mlm-jetties-list',
    styleUrls: ['./list.component.css'],
    templateUrl: './list.component.html'
})
export class MlmJettiesListComponent implements OnInit, OnDestroy {

    public rows: Jetty[] = [];
    public filterCharacter: string = '';
    public possibleFilters: string[] = [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
        'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
        'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ];
    public isShowFavouriteOnly: boolean = false;
    public selectedJetty: Jetty;
    public currentJetty: Jetty;
    public jettyChangingToId: string = undefined;
    public mlmConfiguration: MlmConfiguration;

    private persistenceMlmConfigSub: Subscription;
    private persistenceEventsSub: Subscription;
    private routerSub: Subscription;
    private screenOptions: ScreenOption = new ScreenOption({});

    constructor(private _route: ActivatedRoute,
                private _router: Router,
                private _screenOpts: ScreenOptionService,
                private _persistence: PersistenceService,
                private _jettyService: JettyService,
                private _configurationService: ConfigService,
                private _configService: PageConfigService) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.updateNavigation();
                const screenOpts = this._screenOpts.getScreenOption();
                if (screenOpts) {
                    this.screenOptions = screenOpts;
                    this.isShowFavouriteOnly = this.screenOptions.isJettyFavourite;
                }

                this.serviceGetJetties();
                this.persistenceMlmConfigSub = this._persistence
                    .mlmConfiguration
                    .subscribe((data: MlmConfiguration) => {
                        if (data) {
                            this.mlmConfiguration = data;
                            this.currentJetty = data.currentJetty;
                        }
                    });

                this.persistenceEventsSub = this._persistence
                    .lastSafetyEvent
                    .subscribe((data: BaseSafetyEvent) => {
                        if (data) {
                            switch (data.eventType) {
                                case SafetyEventType.JettyActivating:
                                    const jettyActivatingEvent =
                                        new JettyActivatingSafetyEvent(data);
                                    this.jettyChangingToId = jettyActivatingEvent.jettyId;
                                    break;
                                case SafetyEventType.JettyActivated:
                                case SafetyEventType.JettyActivatingFailed:
                                    this.jettyChangingToId = undefined;
                                    break;
                                case SafetyEventType.JettyDeactivating:
                                    const jettyDeactivatingEvent =
                                        new JettyDeactivatingSafetyEvent(data);
                                    this.jettyChangingToId =
                                        jettyDeactivatingEvent.jettyId;
                                    break;
                                case SafetyEventType.JettyDeactivated:
                                case SafetyEventType.JettyDeactivatingFailed:
                                    this.jettyChangingToId = undefined;
                                    break;
                            }
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.persistenceMlmConfigSub.unsubscribe();
            this.persistenceEventsSub.unsubscribe();
        }
    }

    // Template Helpers
    public selectJetty(selectedJetty: Jetty): void {
        this.selectedJetty = selectedJetty;
    }

    public confirmSetCurrentJetty(): void {
        this.currentJetty = this.selectedJetty;
        this.serviceSetCurrentJetty(this.currentJetty);
    }

    public confirmUnsetCurrentJetty(): void {
        this.serviceUnsetCurrentJetty();
    }

    public confirmUnsetFavouriteJetty(): void {
        this.selectedJetty.isFavourite = false;
        this.serviceUpdateJetty(this.selectedJetty).add(() => {
            this.serviceGetJetties();
        });
    }

    public confirmSetFavouriteJetty(): void {
        this.selectedJetty.isFavourite = true;
        this.serviceUpdateJetty(this.selectedJetty).add(() => {
            this.serviceGetJetties();
        });
    }

    public setFilter(character: string): void {
        this.filterCharacter = character;
    }

    public clearFilter(): void {
        this.filterCharacter = '';
    }

    public toggleFavouriteFilter(): void {
        this.isShowFavouriteOnly = !this.isShowFavouriteOnly;
        this.screenOptions.isJettyFavourite = this.isShowFavouriteOnly;
        this._screenOpts.updateScreenOption(this.screenOptions);
    }

    public jettiesToShowCount(): number {
        let jettiesToShow: number = 13;
        if (this.currentJetty) {
            jettiesToShow--;
        }

        return jettiesToShow;
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'MLM',
            path: '/mlm'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'JETTIES',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service calls
    private serviceGetJetties(): Subscription {
        return this._jettyService
            .getAll()
            .subscribe((data: Jetty[]) => {
                this.rows = data;
            });
    }

    private serviceSetCurrentJetty(newJetty: Jetty): Subscription {
        return this._jettyService
            .setCurrent(newJetty)
            .subscribe((data: Jetty) => {
                this.currentJetty = data;
            });
    }

    private serviceUnsetCurrentJetty(): Subscription {
        return this._jettyService
            .unsetCurrent()
            .subscribe((data: HttpResponse<string>) => {
                this.currentJetty = undefined;
            });
    }

    private serviceUpdateJetty(jetty: Jetty): Subscription {
        return this._jettyService.update(jetty).subscribe();
    }
}
