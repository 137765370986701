import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ModuleTypeEnum } from '../shared/enums/module-type.enum';
import { PageConfigService } from '../shared/services/system/page-config.service';
import { UslConfiguration } from './models/usl-configuration.model';
import { PersistenceService } from '../shared/services/system/persistence.service';
import { ConfigService } from './services/config.service';

@Component({
    selector: 'usl',
    templateUrl: './mod_usl.component.html',
    styleUrls: ['./mod_usl.component.css']
})
export class UslComponent implements OnInit, OnDestroy {

    private webSockets: Subscription[] = [];

    constructor(
        private _configService: PageConfigService,
        private _uslService: ConfigService,
        private _persistence: PersistenceService,
    ) {}

    public ngOnInit(): void {
        this._uslService
            .getConfig()
            .subscribe((data: UslConfiguration) => {
                this._configService.setCurrentContext(ModuleTypeEnum.USL);
                this._persistence.uslConfiguration.next(data);
            });

        this.webSockets.push(this._uslService
            .websocketGetConfig()
            .subscribe((data: UslConfiguration) => {
                this._persistence.uslConfiguration.next(data);

                if (!data || data.remoteApiVersion == undefined || data.remoteApiVersion == null) {
                    this._persistence.uslRemoteName.next(undefined);
                }
            }));

        this.webSockets.push(this._persistence
            .isDebugToggled
            .subscribe((data) => {
                this._uslService.toggleDebugMode().subscribe();
            }));
    }

    public ngOnDestroy(): void {
        for (let val of this.webSockets) {
            if (val) {
                val.unsubscribe();
            }
        }
    }
}
