import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../base.service';
import { WebSocketService } from '../websocket/websocket.service';
import { CanMessageLog } from '../../models/system/can-message-log.model';
import { BaseState } from '../../models/system/comms/states/base-state.model';

@Injectable()
export class DeviceCommsService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'system/device-comms';
    }

    public getCanMessageLogs(): Observable<CanMessageLog[]> {
        return this._http
            .get<CanMessageLog[]>(this.actionUrl + '/can',
                this.getRequestOptions());
    }

    public getCanToggleDiagnosticMode(): Observable<HttpResponse<string>> {
        return this._http
            .get(this.actionUrl + '/can/diagnostic',
                this.getFullRawRequestOptions());
    }

    public getCanToggleLoggingMode(): Observable<HttpResponse<string>> {
        return this._http
            .get(this.actionUrl + '/can/logging',
                this.getFullRawRequestOptions());
    }

    public getDeviceStates(): Observable<BaseState[]> {
        return this._http
            .get<BaseState[]>(this.actionUrl + '/states', this.getRequestOptions());
    }

    public websocketGetCanMessageLogs(): Observable<CanMessageLog> {
        return this._ws.get('/wsapi/comms/can');
    }

    public websocketGetRxStateReport(): Observable<string> {
        return this._ws.get('/wsapi/comms/rx');
    }

    public websocketGetTxStateReport(): Observable<string> {
        return this._ws.get('/wsapi/comms/tx');
    }
}
