import { Component, Input, ViewChild } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap';

import { Telecomm } from '../../../models/esd/telecomm.model';
import { EsdConfiguration } from '../../../models/system/esd-configuration.model';
import { LocalboardConfiguration } from '../../../models/common/localboard-configuration.model';

@Component({
    selector: 'esd-telecomms-modal',
    styleUrls: ['./telecomms.component.css'],
    templateUrl: './telecomms.component.html'
})
export class EsdTelecommsModalComponent {

    @Input() public localboardConfiguration: LocalboardConfiguration;
    @Input() public esdConfiguration: EsdConfiguration;
    @Input() public telecommSystems: Telecomm[];

    @ViewChild('telecommsModal') public telecommsModal: ModalDirective;

    public open(): void {
        this.telecommsModal.show();
    }
}
