import { Component, Input } from '@angular/core';

@Component({
    selector: 'trip-status-box',
    styleUrls: ['./status-box.component.css'],
    templateUrl: './status-box.component.html'
})
export class TripStatusBoxComponent {

    @Input() public isWarning: boolean;
    @Input() public isError: boolean;
    @Input() public isAccepted: boolean;
    @Input() public isFirstUp: boolean;
    @Input() public isUnknown: boolean;
    @Input() public statusText: string;

}
