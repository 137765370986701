import { GraphicalComponentTypeEnum } from '../../enums/graphical-component-type.enum';
import { TripTypeEnum } from '../../enums/trip-type.enum';
import { DirectionTypeEnum } from '../../enums/direction-type.enum';

export class Trip {

    public id: string;
    public graphicalComponentType: GraphicalComponentTypeEnum;
    public langKey: string;
    public subtextLangKey: string;
    public directionType: DirectionTypeEnum;
    public isInhibited: boolean;
    public isHealthy: boolean;
    public isWarning: boolean;
    public isFirstUp: boolean;
    public isHealthyValue: number;
    public isTripAccepted: boolean;
    public tripType: TripTypeEnum;

    constructor(data: any) {
        this.id = data.id || undefined;
        this.graphicalComponentType = data.graphicalComponentType;
        this.langKey = data.langKey || '';
        this.subtextLangKey = data.subtextLangKey || '';
        this.isHealthyValue = data.isHealthyValue;
        this.tripType = data.tripType;
        this.directionType = data.directionType;

        // Workaround because of boolean type (Silly JS)
        if (data.isInhibited != null) {
            this.isInhibited = data.isInhibited;
        }

        if (data.isWarning != null) {
            this.isWarning = data.isWarning;
        }

        if (data.isHealthy != null) {
            this.isHealthy = data.isHealthy;
        }

        if (data.isFirstUp != null) {
            this.isFirstUp = data.isFirstUp;
        }

        if (data.isTripAccepted != null) {
            this.isTripAccepted = data.isTripAccepted;
        }
    }
}
