import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SSL_ROUTES } from './mod_ssl.routes';

// Thirdparty
import { TranslateModule } from '@ngx-translate/core';
import {
    AlertModule,
    ButtonsModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    TabsModule
} from 'ngx-bootstrap';

// Modules
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';
import { SharedComponentsModule } from '../shared/components/shared-components.module';

// Services
import { ConfigService } from './services/config.service';
import { CountryService } from '../shared/services/system/country.service';
import { TerminalService } from './services/terminal.service';
import { RecordingService } from './services/recording.service';
import { LogService } from './services/log.service';
import { EsdService } from '../shared/services/esd/esd.service';

// Pipes
import { RecordingTypePipe } from './pipes/recording-type.pipe';
import { GraphicalTypePipe } from './pipes/graphical-type.pipe';

// Components
import { SslComponent } from './mod_ssl.component';
import { SslHomeComponent } from './home/home.component';
import { SslOperationsComponent } from './operations/operations.component';
import { SslSettingsComponent } from './settings/settings.component';
import { SslTerminalsComponent } from './terminals/terminals.component';
import { SslOperationsDetailedComponent } from './operations/detailed/detailed.component';
import { SslOperationsGraphicalComponent } from './operations/graphical/graphical.component';
import { SslTerminalsConfigComponent } from './terminals/config/config.component';
import { SslTerminalsListComponent } from './terminals/list/list.component';
import { SslRecordingsComponent } from './recordings/recordings.component';
import {
    SslRecordingsModalPlaybackComponent
} from './recordings/modal/playback/playback.component';
import {
    SslOperationsSharedStatusCableComponent
} from './operations/shared/status-cable/status-cable.component';
import { SslLogsComponent } from './logs/logs.component';
import {
    SslOperationsSharedVerticalCableColumnComponent
} from './operations/shared/vertical-cable-column/vertical-cable-column.component';
import { SslSystemsListComponent } from './systems/list/list.component';
import { SslSystemsComponent } from './systems/systems.component';
import { SystemService } from './services/system.service';
import {
    SslSystemsListActivateSystemModalComponent
} from './systems/list/modal/activate-system/activate-system.component';
import { SslTerminalsConfigStep1Component } from './terminals/config/steps/step-1/step-1.component';
import { SslTerminalsConfigStep2Component } from './terminals/config/steps/step-2/step-2.component';
import {
    SslTerminalsConfigSystemProfileConfigModalComponent
} from './terminals/config/steps/step-2/config-system/config-system.component';
import { SslTerminalsConfigStep3Component } from './terminals/config/steps/step-3/step-3.component';
import { SslTerminalsConfigStep4Component } from './terminals/config/steps/step-4/step-4.component';
import {
    SslTerminalsConfigEsdConfigComponent
} from './terminals/config/steps/step-3/esd-config/esd-config.component';
import {
    SslTerminalsConfigTelecommConfigComponent
} from './terminals/config/steps/step-4/telecomm-config/telecomm-config.component';
import {
    SslTerminalsConfigStepRemoteSwitchComponent
} from './terminals/config/steps/step-remote-switch-config/step-remote-switch.component';
import {
    SslTerminalsConfigRemoteSwitchConfigComponent
} from './terminals/config/steps/step-remote-switch-config/remote-switch-config/remote-switch-config.component';
import {
    SslDebugComponent
} from './debug/debug.component';
import {
    SslOperationsSharedChangeSettingsModalComponent
} from './operations/shared/change-settings/change-settings.component';
import {
    SslTerminalsConfigStepAdditionalConfigurationComponent
} from './terminals/config/steps/step-additional-configuration/step-additional-configuration.component';
import { SafetyLogService } from '../shared/services/esd/log.service';
import {
    SslOperationsGraphicalMultiViewComponent
} from './operations/graphical/display/multi-view/multi-view.component';
import {
    SslOperationsGraphicalMultiViewLocalColumnComponent
} from './operations/graphical/display/multi-view/local-column/local-column.component';
import {
    SslOperationsSharedControlAreaComponent
} from './operations/shared/control-area/control-area.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    declarations: [
        SslComponent,
        SslHomeComponent,
        SslOperationsComponent,
        SslOperationsSharedStatusCableComponent,
        SslOperationsSharedVerticalCableColumnComponent,
        SslOperationsSharedChangeSettingsModalComponent,
        SslOperationsSharedControlAreaComponent,
        SslOperationsDetailedComponent,
        SslOperationsGraphicalComponent,
        SslOperationsGraphicalMultiViewComponent,
        SslOperationsGraphicalMultiViewLocalColumnComponent,
        SslSettingsComponent,
        SslTerminalsComponent,
        SslTerminalsConfigComponent,
        SslTerminalsConfigStep1Component,
        SslTerminalsConfigStep2Component,
        SslTerminalsConfigSystemProfileConfigModalComponent,
        SslTerminalsConfigStep3Component,
        SslTerminalsConfigEsdConfigComponent,
        SslTerminalsConfigStep4Component,
        SslTerminalsConfigTelecommConfigComponent,
        SslTerminalsConfigStepRemoteSwitchComponent,
        SslTerminalsConfigRemoteSwitchConfigComponent,
        SslTerminalsConfigStepAdditionalConfigurationComponent,
        SslTerminalsListComponent,
        SslSystemsComponent,
        SslSystemsListComponent,
        SslSystemsListActivateSystemModalComponent,
        SslRecordingsComponent,
        SslRecordingsModalPlaybackComponent,
        SslLogsComponent,
        SslDebugComponent,

        // Pipes
        GraphicalTypePipe,
        RecordingTypePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(SSL_ROUTES),
        TranslateModule,

        // Thirdparty
        AlertModule,
        ButtonsModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        TabsModule,
        NgxDatatableModule,

        // Pipes
        SharedPipesModule,

        // Directives
        SharedDirectivesModule,

        // Components
        SharedComponentsModule,
    ],
    exports: [
        RouterModule
    ],
    providers: [
        ConfigService,
        CountryService,
        EsdService,
        RecordingService,
        LogService,
        SystemService,
        TerminalService,
        SafetyLogService,
    ]
})
export class SslModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SslModule
        };
    }
}
