import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { SystemConfiguration } from '../../models/system/configuration.model';
import { Country } from '../../models/system/country.model';
import { SslConfiguration } from '../../../mod_ssl/models/ssl-configuration.model';
import { EsdsConfiguration } from '../../../mod_esds/models/esds-configuration.model';
import { UslConfiguration } from '../../../mod_usl/models/usl-configuration.model';
import { MlmConfiguration } from '../../../mod_mlm/models/mlm-configuration.model';
import { ConnectionStateEnum } from '../../enums/connection-state.enum';
import { CommonAlarm } from '../../models/system/common-alarm.model';
import { BrainMetadataDto } from '../../models/system/dto/brain-metadata-dto.model';
import { ScreenOption } from '../../models/system/screen-option.model';
import { BaseSafetyEvent } from '../../models/safety/events/base-safety-event.model';
import { LocalboardConfiguration } from '../../models/common/localboard-configuration.model';
import { AuthUser } from '../../../admin/shared/models/auth/user.model';

@Injectable()
export class PersistenceService {

    public brainSystemError: BehaviorSubject<number> = new BehaviorSubject(-1);
    public systemConfiguration: BehaviorSubject<SystemConfiguration> =
        new BehaviorSubject(undefined);
    public countries: BehaviorSubject<Country[]> =
        new BehaviorSubject([]);
    public localboardConfiguration: BehaviorSubject<LocalboardConfiguration> =
        new BehaviorSubject(undefined);
    public sslConfiguration: BehaviorSubject<SslConfiguration> =
        new BehaviorSubject(undefined);
    public esdsConfiguration: BehaviorSubject<EsdsConfiguration> =
        new BehaviorSubject(undefined);
    public uslConfiguration: BehaviorSubject<UslConfiguration> =
        new BehaviorSubject(undefined);
    public uslRemoteName: BehaviorSubject<string> =
        new BehaviorSubject('');
    public mlmConfiguration: BehaviorSubject<MlmConfiguration> =
        new BehaviorSubject(undefined);
    public mlmCanAcknowledgeAlarms: BehaviorSubject<boolean> =
        new BehaviorSubject(false);
    public brainConnectionState: BehaviorSubject<ConnectionStateEnum> =
        new BehaviorSubject(ConnectionStateEnum.UNKNOWN);
    public brainMirrors: BehaviorSubject<BrainMetadataDto[]> =
        new BehaviorSubject([]);
    public commonAlarm: BehaviorSubject<CommonAlarm> =
        new BehaviorSubject(new CommonAlarm({}));
    public screenOptions: BehaviorSubject<ScreenOption> =
        new BehaviorSubject(new ScreenOption({}));
    public serverTimeSeconds: BehaviorSubject<number> =
        new BehaviorSubject(0);
    public lastSafetyEvent: BehaviorSubject<BaseSafetyEvent> =
        new BehaviorSubject(undefined);
    public neuralTransfersInProgress: BehaviorSubject<number> =
        new BehaviorSubject(0);
    public screenVersion: BehaviorSubject<string> = new BehaviorSubject(undefined);
    public authUser: BehaviorSubject<AuthUser> = new BehaviorSubject(undefined);
    public currentTimeZoneIANA: BehaviorSubject<string> = new BehaviorSubject('UTC');
    public isDebugToggled: Subject<boolean> =
        new Subject(); // This is a Subject as it should only broadcast when changed.
}
