import { ModuleTypeEnum } from '../../enums/module-type.enum';
import { SystemVersions } from './system-versions.model';
import { BrainConfiguration } from './brain-configuration.model';

export class SystemConfiguration {

    public id: string;
    public languageCode: string;
    public enabledModules: ModuleTypeEnum[];
    public bootupModule: ModuleTypeEnum;
    public fakeBrightness: number;
    public systemConfiguration: BrainConfiguration;

    constructor(data: SystemConfiguration) {
        this.id = data.id || undefined;
        this.languageCode = data.languageCode || '';
        this.enabledModules = data.enabledModules || [];
        this.bootupModule = data.bootupModule;
        this.fakeBrightness = data.fakeBrightness;

        this.systemConfiguration = undefined;
        if (data.systemConfiguration) {
            this.systemConfiguration = new BrainConfiguration(data.systemConfiguration);
        }
    }
}
