import { BaseSafetyEvent } from './base-safety-event.model';

export class SystemActivatingSafetyEvent extends BaseSafetyEvent {

    public systemId: string;

    constructor(data: any) {
        super(data);
        this.systemId = data.systemId;
    }
}
