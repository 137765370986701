import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Terminal } from '../../../../models/terminal.model';
import { ConnectionSystem } from '../../../../models/connection-system.model';
import { ConnectionTypeEnum } from '../../../../../shared/enums/connection-type.enum';

@Component({
    selector: 'ssl-terminals-config-step-remote-switch',
    styleUrls: ['./step-remote-switch.component.css', '../step-common/step-common.component.css'],
    templateUrl: './step-remote-switch.component.html'
})
export class SslTerminalsConfigStepRemoteSwitchComponent implements OnInit {

    @Input() public currentStep: number;
    @Input() public availableConnectionTypes: ConnectionTypeEnum[];
    @Input() public currentTerminal: Terminal;
    @Input() public isLoading: boolean;
    @Output() public loadingState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public validatedState: EventEmitter<boolean> = new EventEmitter<boolean>();

    public selectedSystem: ConnectionSystem;

    public ngOnInit(): void {
        this.selectedSystem = undefined;
        this.loadingState.emit(false);
        this.doValidation();
    }

    // Helper methods
    public getRelatedProfileCount(system: ConnectionSystem): number {
        let relatedProfiles: number = 0;
        if (system.relatedToSystems) {
            relatedProfiles += system.relatedToSystems.length;
        }

        if (system.relatedFromSystems) {
            relatedProfiles += system.relatedFromSystems.length;
        }

        return relatedProfiles;
    }

    public isUsableSystem(system: ConnectionSystem): boolean {
        if (system.id && system.id.length > 0 &&
            system.esdConfiguration && system.esdConfiguration.isEnabled &&
            system.telecommConfiguration && system.telecommConfiguration.isEnabled) {
            return (system.esdConfiguration.boardType == system.telecommConfiguration.boardType);
        } else {
            return false;
        }
    }

    public toggleSelectSystem(system: ConnectionSystem): void {
        if (!this.selectedSystem || this.selectedSystem != system) {
            this.selectedSystem = system;
        } else if (this.selectedSystem == system) {
            this.selectedSystem = undefined;
        }
        this.doValidation();
    }

    public systemSaved(config: ConnectionSystem): void {
        this.selectedSystem = config;
        let index = this.currentTerminal.connectionSystems.indexOf(this.selectedSystem);
        if (index !== -1) {
            this.currentTerminal.connectionSystems[index] = this.selectedSystem;
        }
        this.selectedSystem = undefined;
        this.doValidation();
    }

    public systemCancelled(): void {
        this.selectedSystem = undefined;
        this.doValidation();
    }

    public getAvailableConnectionSystems(): ConnectionSystem[] {
        return this.currentTerminal.connectionSystems
            .filter((system: ConnectionSystem) => this.isSystemUsable(system));
    }

    // Internal Helpers
    private doValidation(): void {
        if (this.selectedSystem) {
            this.validatedState.emit(false);
        } else {
            this.validatedState.emit(true);
        }
    }

    private isSystemUsable(system: ConnectionSystem): boolean {
        if (system.esdConfiguration &&
            system.esdConfiguration.isEnabled &&
            this.availableConnectionTypes.indexOf(system.esdConfiguration.connectionType) === -1) {
            return false;
        }

        if (system.telecommConfiguration &&
            system.telecommConfiguration.isEnabled &&
            this.availableConnectionTypes
                .indexOf(system.telecommConfiguration.connectionType) === -1) {
            return false;
        }

        return true;
    }
}
