import { ModuleTypeEnum } from '../../enums/module-type.enum';

export class ModuleTypeWrapper {

    public type: ModuleTypeEnum;

    constructor(type: ModuleTypeEnum) {
        this.type = type;
    }
}
