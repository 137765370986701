import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// Third-party
import { TranslateModule } from '@ngx-translate/core';
import {
    AlertModule,
    ButtonsModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    TabsModule
} from 'ngx-bootstrap';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

// Modules
import { SharedPipesModule } from '../pipes/shared-pipes.module';

// Components
import { YesNoDialogModalComponent } from './dialog/yesno/yesno.component';
import { TripStatusBoxComponent } from './status-box/status-box.component';
import { DatePickerDialogModalComponent } from './dialog/date-picker/date-picker.component';
import { EsdSystemComponent } from './esd-system/esd-system.component';
import { EsdTelecommsModalComponent } from './dialog/telecomms/telecomms.component';
import { EsdTelecommsComponent } from './telecomms/telecomms.component';
import { CheckboxElementComponent } from './elements/checkbox/checkbox.component';
import { KeyboardDialogModalComponent } from './dialog/keyboard/keyboard.component';
import { NumberElementComponent } from './elements/number/number.component';
import { KeypadDialogModalComponent } from './dialog/keypad/keypad.component';
import { AudioPlayerElementComponent } from './elements/audio-player/audio-player.component';
import { FibreLoopbackTestDialogModalComponent } from './dialog/fibre-loopback-test/fibre-loopback-test.component';
import { CommonAlarmDialogModalComponent } from './dialog/common-alarm/common-alarm.component';
import { DiagnosticsInfoModalComponent } from './dialog/diagnostics-info/diagnostics-info.component';
import { AlertBoxElementComponent } from './elements/alert-box/alert-box.component';
import { StatusLedElementComponent } from './elements/status-led/status-led.component';
import { ScreenNotificationDialogModalComponent } from './dialog/screen-notification/screen-notification.component';
import { ElectricLoopbackTestDialogModalComponent } from './dialog/electric-loopback-test/electric-loopback-test.component';
import { Angular2DataTableDirective } from './external/angular2-datatable/angular2-data-table.directive';
import { Angular2DefaultSorterComponent } from './external/angular2-datatable/angular2-default-sorter.component';
import { Angular2PaginatorComponent } from './external/angular2-datatable/angular2-paginator.component';

@NgModule({
    declarations: [
        YesNoDialogModalComponent,
        TripStatusBoxComponent,
        DatePickerDialogModalComponent,
        KeyboardDialogModalComponent,
        AudioPlayerElementComponent,
        NumberElementComponent,
        KeypadDialogModalComponent,
        EsdSystemComponent,
        EsdTelecommsModalComponent,
        EsdTelecommsComponent,
        CheckboxElementComponent,
        FibreLoopbackTestDialogModalComponent,
        ElectricLoopbackTestDialogModalComponent,
        CommonAlarmDialogModalComponent,
        DiagnosticsInfoModalComponent,
        AlertBoxElementComponent,
        StatusLedElementComponent,
        ScreenNotificationDialogModalComponent,
        Angular2DataTableDirective,
        Angular2DefaultSorterComponent,
        Angular2PaginatorComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        AlertModule,
        ButtonsModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        TabsModule,
        TranslateModule,
        NouisliderModule,
        NgxDatatableModule,

        SharedPipesModule
    ],
    exports: [
        YesNoDialogModalComponent,
        TripStatusBoxComponent,
        DatePickerDialogModalComponent,
        KeyboardDialogModalComponent,
        KeypadDialogModalComponent,
        AudioPlayerElementComponent,
        NumberElementComponent,
        EsdSystemComponent,
        EsdTelecommsModalComponent,
        EsdTelecommsComponent,
        CheckboxElementComponent,
        FibreLoopbackTestDialogModalComponent,
        ElectricLoopbackTestDialogModalComponent,
        CommonAlarmDialogModalComponent,
        DiagnosticsInfoModalComponent,
        AlertBoxElementComponent,
        StatusLedElementComponent,
        ScreenNotificationDialogModalComponent,
        Angular2DataTableDirective,
        Angular2DefaultSorterComponent,
        Angular2PaginatorComponent,
    ]
})
export class SharedComponentsModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedComponentsModule
        };
    }
}
