import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';

import { Jetty } from '../../models/jetty.model';
import { Dolphin } from '../../models/dolphin.model';
import { Pattern } from '../../models/pattern.model';
import { Hook } from '../../models/hook.model';
import { PatternJoin } from '../../models/pattern-join.model';
import { Tether } from '../../models/tether.model';
import { SystemLocationEnum } from '../../../shared/enums/system-location.enum';
import { ShapeSquare } from '../../models/shape-square.model';
import { ShapeDolphin } from '../../models/shape-dolphin.model';
import { ShapeDiagonal } from '../../models/shape-diagonal.model';
import { EnvironmentalOption } from '../../models/environmental-option.model';
import { DasOption } from '../../models/das-option.model';
import { AlarmStateEnum } from '../../enums/alarm-state.enum';
import { MooringLine } from '../../models/mooring-line.model';

@Component({
    selector: 'mlm-operations-ship-display',
    styleUrls: ['./ship-display.component.css'],
    templateUrl: './ship-display.component.html'
})
export class MlmOperationsShipDisplayComponent implements
    OnInit,
    OnDestroy,
    OnChanges,
    AfterViewInit {

    @Input() public shipImageB64: string;
    @Input() public shipImageWidth: number;
    @Input() public shipImageHeight: number;
    @Input() public shipSide: SystemLocationEnum;
    @Input() public currentJetty: Jetty;
    @Input() public currentPattern: Pattern;
    @Input() public selectedDolphin: Dolphin;
    @Input() public currentShipTethers: Tether[];
    @Input() public diagonalShapes: ShapeDiagonal[];
    @Input() public dolphinShapes: ShapeDolphin[];
    @Input() public squareShapes: ShapeSquare[];
    @Input() public currentDolphins: Dolphin[];
    @Input() public environmentalOption: EnvironmentalOption;
    @Input() public dasOption: DasOption;
    @Output() public onPatternUpdate: EventEmitter<Pattern> = new EventEmitter<Pattern>();
    @ViewChild('displayContainer') public displayContainerElement: ElementRef;

    public selectedHook: Hook = undefined;
    public selectedShipTetherId: string = undefined;
    public containerHeight: number;
    public containerWidth: number;
    public systemLocationEnum = SystemLocationEnum;
    public alarmStateEnum = AlarmStateEnum;
    public mooringLines: MooringLine[] = [];

    public ngOnInit(): void {
        window.addEventListener('resize', this.gatherContainerHeightData);
        window.addEventListener('resize', this.recalculateAllMooringLineTransforms);
    }

    public ngOnDestroy(): void {
        window.removeEventListener('resize', this.gatherContainerHeightData);
        window.removeEventListener('resize', this.recalculateAllMooringLineTransforms);
    }

    public ngAfterViewInit(): void {
        this.gatherContainerHeightData();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['shipSide']) {
            this.selectedHook = undefined;
            this.selectedShipTetherId = undefined;
        }

        if (changes['currentPattern']) {
            this.recalculateAllMooringLineTransforms();
        }

        if (changes['environmentalOption']) {
            this.gatherContainerHeightData();
            this.recalculateAllMooringLineTransforms();
        }
    }

    // Template Helpers
    public selectHook(hook: Hook): void {
        if (this.selectedHook == hook) {
            this.selectedHook = undefined;
        } else if (this.isHookConnected(hook.id)) {
            this.clearHookRelationship(hook.id);
        } else {
            this.selectedHook = hook;
            this.marryHookAndTether();
        }
    }

    public selectTether(shipTetherId: string): void {
        if (this.selectedShipTetherId == shipTetherId) {
            this.selectedShipTetherId = undefined;
        } else if (this.isTetherConnected(shipTetherId)) {
            this.clearTetherRelationship(shipTetherId);
        } else {
            this.selectedShipTetherId = shipTetherId;
            this.marryHookAndTether();
        }
    }

    public isHookConnected(hookId: string): boolean {
        let isConnected: boolean = false;

        if (this.currentPattern && this.currentPattern.patternJoins) {
            for (const val of this.currentPattern.patternJoins) {
                if (val.shipSide == this.shipSide && val.hookId == hookId) {
                    isConnected = true;
                    break;
                }
            }
        }

        return isConnected;
    }

    public isTetherConnected(tetherId: string): boolean {
        let isConnected: boolean = false;

        if (this.currentPattern && this.currentPattern.patternJoins) {
            for (const val of this.currentPattern.patternJoins) {
                if (val.shipSide == this.shipSide && val.tetherId == tetherId) {
                    isConnected = true;
                    break;
                }
            }
        }

        return isConnected;
    }

    public getDisplayValue(value: number, displayFormat: string, unit: string): string {
        if (displayFormat && displayFormat.includes('##0.0')) {
            return `${displayFormat.replace('##0.0', value.toFixed(1))}`;
        } else if (displayFormat && displayFormat.includes('##0')) {
            return `${displayFormat.replace('##0', value.toFixed(0))}`;
        } else {
            return `${value.toFixed(0)}`;
        }
    }

    public getDisplayValueFromHook(hook: Hook): string {
        if (hook.displayFormat && hook.displayFormat.includes('##0.0')) {
            return `${hook.displayFormat.replace('##0.0', hook.pullTonnage.toFixed(1))} ${hook.unit}`;
        } else if (hook.displayFormat && hook.displayFormat.includes('##0')) {
            return `${hook.displayFormat.replace('##0', hook.pullTonnage.toFixed(0))} ${hook.unit}`;
        } else {
            return `${hook.pullTonnage.toFixed(0)} ${hook.unit}`;
        }
    }

    public getDasInfo(): string {
        if (this.dasOption && this.dasOption.hasDas) {
            return `${this.dasOption.dasData[0].name} (${this.dasOption.dasData[0].unit})`;
        }

        return '';
    }

    public getHookConnectionStatus(hook: Hook): string {
        const isConnected = this.isHookConnected(hook.id);
        const isSelected = this.selectedHook == hook;
        let finalClass: string = '';

        if (isConnected && !isSelected) {
            finalClass = 'btn-success';
        } else if (!isConnected && !isSelected) {
            finalClass = 'btn-default';
        } else if (isSelected) {
            finalClass = 'btn-primary';
        }

        return finalClass;
    }

    public getTetherConnectionStatus(tetherId: string): string {
        const isConnected = this.isTetherConnected(tetherId);
        const isSelected = this.selectedShipTetherId == tetherId;
        let finalClass: string = '';

        if (isConnected && !isSelected) {
            finalClass = 'btn-success';
        } else if (!isConnected && !isSelected) {
            finalClass = 'btn-default';
        } else if (isSelected) {
            finalClass = 'btn-primary';
        }

        return finalClass;
    }

    public calculateMooringLineTransforms(relationship: PatternJoin): MooringLine {
        if (!this.currentDolphins || this.currentDolphins.length <= 0) {
            return undefined;
        }

        const foundDolphin: Dolphin = this.findDolphinByHookId(relationship.hookId);
        const foundHook: Hook = this.findHook(foundDolphin, relationship.hookId);
        const foundTether: Tether = this.findTether(relationship.tetherId);
        const indexOfHook = foundDolphin.hooks.indexOf(foundHook);

        if (!foundTether || !foundDolphin || !foundHook || indexOfHook === -1) {
            return undefined;
        }

        const dolphinHookWidth: number = (foundDolphin.width * this.containerWidth)
            / foundDolphin.hooks.length;

        const originX: number = foundTether.left * this.containerWidth;
        const originY: number = foundTether.top * this.containerHeight;
        const destinationX: number = (foundDolphin.left * this.containerWidth) +
            ((dolphinHookWidth * indexOfHook) + dolphinHookWidth / 2);
        const destinationY: number = (foundDolphin.top * this.containerHeight) -
            (foundDolphin.height / 2);

        const xLength: number = (originX - destinationX) * (originX - destinationX);
        const yLength: number = (originY - destinationY) * (originY - destinationY);

        const rotation = Math.atan2(destinationY - originY, destinationX - originX) * 180 / Math.PI;
        const length = Math.sqrt(xLength + yLength);

        return new MooringLine({
            patternJoinId: relationship.patternId,
            tetherId: relationship.tetherId,
            hookId: relationship.hookId,
            textFlipY: rotation > 90,
            hook: foundHook,
            width: length,
            transform: 'rotate(' + rotation + 'deg)',
            top: originY,
            left: originX
        });
    }

    public recalculateAllMooringLineTransforms(): void {
        if (this.currentPattern) {
            const newMooringLines: MooringLine[] = [];

            for (const patternJoin of this.currentPattern.patternJoins) {
                if (patternJoin.shipSide == this.shipSide) {
                    const newMooringLine = this.calculateMooringLineTransforms(patternJoin);
                    if (newMooringLine) {
                        newMooringLines.push(newMooringLine);
                    }
                }
            }

            this.mooringLines = newMooringLines;
        }
    }

    public getStatusClass(hook: Hook): string {
        let finalClass: string = 'stale';
        if (!hook.isStale) {
            switch (hook.alarmState) {
                case AlarmStateEnum.High:
                    finalClass = 'high';
                    break;
                case AlarmStateEnum.Low:
                    finalClass = 'low';
                    break;
                case AlarmStateEnum.Normal:
                    finalClass = 'normal';
                    break;
            }

            if (!hook.isAlarmAcknowledged) {
                finalClass += ' flash sync-animation';
            }
        }

        return finalClass;
    }

    // Helpers
    private gatherContainerHeightData(): void {
        if (this.displayContainerElement) {
            this.containerHeight = this.displayContainerElement.nativeElement.offsetHeight;
            this.containerWidth = this.displayContainerElement.nativeElement.offsetWidth;
        }
    }

    private marryHookAndTether(): void {
        if (this.selectedHook && this.selectedShipTetherId) {
            if (!this.currentPattern) {
                this.currentPattern = new Pattern({});
                this.currentPattern.patternJoins = [];
                this.currentPattern.jettyId = this.currentJetty.id;
            }

            const newRelationship = new PatternJoin({});
            newRelationship.hookId = this.selectedHook.id;
            newRelationship.tetherId = this.selectedShipTetherId;
            newRelationship.shipSide = this.shipSide;
            this.currentPattern.patternJoins.push(newRelationship);
            this.mooringLines.push(this.calculateMooringLineTransforms(newRelationship));

            this.updatePattern(this.currentPattern);

            this.selectedHook = undefined;
            this.selectedShipTetherId = undefined;
        }
    }

    private clearTetherRelationship(tetherId: string): void {
        const relationships = this.currentPattern.patternJoins;

        for (let i = 0; i < relationships.length; ++i) {
            if (relationships[i].tetherId == tetherId) {
                this.currentPattern.patternJoins.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < this.mooringLines.length; ++i) {
            if (this.mooringLines[i].tetherId == tetherId) {
                this.mooringLines.splice(i, 1);
                break;
            }
        }

        this.updatePattern(this.currentPattern);
    }

    private clearHookRelationship(hookId: string): void {
        const relationships = this.currentPattern.patternJoins;

        for (let i = 0; i < relationships.length; ++i) {
            if (relationships[i].hookId == hookId) {
                this.currentPattern.patternJoins.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < this.mooringLines.length; ++i) {
            if (this.mooringLines[i].hookId == hookId) {
                this.mooringLines.splice(i, 1);
                break;
            }
        }

        this.updatePattern(this.currentPattern);
    }

    private findHook(dolphin: Dolphin, hookId: string): Hook {
        let foundHook: Hook;

        for (const val of dolphin.hooks) {
            if (val.id == hookId) {
                foundHook = val;
                break;
            }
        }

        return foundHook;
    }

    private findTether(tetherId: string): Tether {
        let foundTether: Tether;

        for (const val of this.currentShipTethers) {
            if (val.id == tetherId && val.location == this.shipSide) {
                foundTether = val;
                break;
            }
        }

        return foundTether;
    }

    private findDolphinByHookId(hookId: string): Dolphin {
        let foundDolphin: Dolphin;

        for (const dolphin of this.currentDolphins) {
            for (const val of dolphin.hooks) {
                if (val.id == hookId) {
                    foundDolphin = dolphin;
                    break;
                }
            }

            if (foundDolphin) {
                break;
            }
        }

        return foundDolphin;
    }

    private updatePattern(updatedPattern: Pattern): void {
        this.onPatternUpdate.emit(updatedPattern);
    }
}
