import { SystemLocationEnum } from '../../shared/enums/system-location.enum';
import { ConnectionConfiguration } from './connection-configuration.model';
import { InstallationTypeEnum } from '../../shared/enums/installation-type.enum';
import { ConnectionSystemRelatedSystem } from './connection-system-related-system.model';
import { ElectricLinePairConfigEnum } from '../../shared/enums/electric-line-pair-config.enum';

export class ConnectionSystem {

    public id: string;
    public name: string;
    public terminalId: string;
    public systemLocation: SystemLocationEnum;
    public esdConfigurationId: string;
    public esdConfiguration: ConnectionConfiguration;
    public telecommConfigurationId: string;
    public telecommConfiguration: ConnectionConfiguration;
    public remoteType: InstallationTypeEnum;
    public remoteName: string;
    public isPrimary: boolean;
    public primaryOrder: number;
    public isActive: boolean;
    public relatedToSystems: ConnectionSystemRelatedSystem[];
    public relatedFromSystems: ConnectionSystemRelatedSystem[];
    public isEtuEnabled: boolean;
    public etuPins: ElectricLinePairConfigEnum;

    constructor(data: any) {
        this.id = data.id || undefined;
        this.name = data.name || '';
        this.terminalId = data.terminalId || undefined;
        this.systemLocation = data.systemLocation;
        this.esdConfigurationId = data.esdConfigurationId || undefined;
        this.esdConfiguration = data.esdConfiguration || undefined;
        this.telecommConfigurationId = data.telecommConfigurationId || undefined;
        this.telecommConfiguration = data.telecommConfiguration || undefined;
        this.remoteType = data.remoteType;
        this.remoteName = data.remoteName;
        this.relatedToSystems = data.relatedToSystems || [];
        this.relatedFromSystems = data.relatedFromSystems || [];
        this.etuPins = data.etuPins;
        this.primaryOrder = data.primaryOrder;

        // Workaround because of boolean type (Silly JS)
        this.isPrimary = false;
        if (data.isPrimary != null) {
            this.isPrimary = data.isPrimary;
        }

        this.isActive = false;
        if (data.isActive != null) {
            this.isActive = data.isActive;
        }

        this.isEtuEnabled = false;
        if (data.isEtuEnabled != null) {
            this.isEtuEnabled = data.isEtuEnabled;
        }
    }
}
