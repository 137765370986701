import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {

    public transform(array: any[], query: string, property: string): any {
        if (query) {
            return array.filter(
                (row) => row[property].toLowerCase().startsWith(query.toLowerCase())
            );
        }

        return array;
    }
}
