import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../base.service';
import { BrainMetadataDto } from '../../models/system/dto/brain-metadata-dto.model';
import { StabilityEnum } from '../../enums/system/stability.enum';

@Injectable()
export class MetaService extends BaseService {

    constructor(
        _http: HttpClient,
    ) {
        super(_http);
        this.actionUrl += 'meta';
    }

    public getCurrent(): Observable<BrainMetadataDto> {
        return this._http
            .get<BrainMetadataDto>(this.actionUrl,
                this.getRequestOptions());
    }

    public getMirrors(): Observable<BrainMetadataDto[]> {
        return this._http
            .get<BrainMetadataDto[]>(this.actionUrl + '/mirrors',
                this.getRequestOptions());
    }

    public getStability(): Observable<StabilityEnum> {
        return this._http
            .get<StabilityEnum>(this.actionUrl + '/stability',
                this.getRequestOptions());
    }
}
