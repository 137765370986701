import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ElectricLinePairConfigEnum } from '../enums/electric-line-pair-config.enum';

@Pipe({
    name: 'electricLinePairIttCannon'
})
export class ElectricLinePairIttCannonPipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(linePair: ElectricLinePairConfigEnum): string {
        switch (linePair) {
            case ElectricLinePairConfigEnum.ITT_CANNON_A_B:
                return 'ITT A/B';
            case ElectricLinePairConfigEnum.ITT_CANNON_D_E:
                return 'ITT D/E';
            case ElectricLinePairConfigEnum.ITT_CANNON_F_G:
                return 'ITT F/G';
            case ElectricLinePairConfigEnum.ITT_CANNON_H_J:
                return 'ITT H/J';
            case ElectricLinePairConfigEnum.ITT_CANNON_K_L:
                return 'ITT K/L';
            default:
            case ElectricLinePairConfigEnum.NOT_CONNECTED:
                return this._translate.instant('SSL.TERMINAL_CONN_NONE');
        }
    }
}
