import { Component, Input, ViewChild } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';

import { ModalDirective } from 'ngx-bootstrap';
import { UslSystemV0Alarm } from '../../../../../models/usl-system/v0/alarm.model';
import { UslSystemV0DisplayReading } from '../../../../../models/usl-system/v0/display-readings.model';
import { UslAlarmV0Service } from '../../../../../services/usl-system/v0-alarm.service';

@Component({
    selector: 'usl-display-v0-settings-modal',
    styleUrls: ['./settings.component.css'],
    templateUrl: './settings.component.html',
    animations: [
        trigger('flyInOut', [
            state('flyLeft', style({transform: 'translateX(0)'})),
            state('flyRight', style({transform: 'translateX(0)'})),
            transition('* => flyLeft', [
                animate(200, keyframes([
                    style({opacity: 0, transform: 'translateX(100%)', offset: 0}),
                    style({opacity: 1, transform: 'translateX(0)', offset: 1.0})
                ]))
            ]),
            transition('* => flyRight', [
                animate(200, keyframes([
                    style({opacity: 0, transform: 'translateX(-100%)', offset: 0}),
                    style({opacity: 1, transform: 'translateX(0)', offset: 1.0})
                ]))
            ]),
            transition('* => void', [
                animate(0, keyframes([
                    style({opacity: 0, offset: 1.0})
                ]))
            ])
        ])
    ]
})
export class UslDisplayV0ModalSettingsComponent {

    @Input() public isLocal: boolean;
    @Input() public systemReadings: { [id: number]: UslSystemV0DisplayReading; };
    @Input() public systemAlarms: UslSystemV0Alarm[];
    @ViewChild('settingsModal') public settingsModal: ModalDirective;

    public configStep: number = 0;
    public readings: UslSystemV0DisplayReading[] = [];
    public alarms: { [id: number]: UslSystemV0Alarm[]; } = {};
    public selectedItem: UslSystemV0DisplayReading = undefined;
    public selectedAlarm: UslSystemV0Alarm = undefined;
    public flyDirection: string = 'flyLeft';
    public subFlyDirection: string = 'flyLeft';

    constructor(
        private _alarmService: UslAlarmV0Service
    ) {}

    public open(): void {
        this.configStep = 0;
        this.flyDirection = 'flyLeft';
        this.subFlyDirection = 'flyLeft';
        this.selectedItem = undefined;
        this.parseAlarms();
        this.settingsModal.show();
    }

    // Template Helpers
    public selectItem(selectedItem: UslSystemV0DisplayReading): void {
        this.selectedItem = selectedItem;
    }

    public goToReadingConfig(): void {
        this.goForward();
    }

    public selectAlarm(selectedAlarm: UslSystemV0Alarm): void {
        this.selectedAlarm = selectedAlarm;
        this.goForward();
    }

    public confirmAddAlarm(newAlarm: UslSystemV0Alarm): void {
        this.selectedAlarm = undefined;
        this.goBack();
        this.serviceAddAlarm(newAlarm).add(() => {
            if (this.isLocal) {
                this.serviceGetLocalV0Alarms().add(() => {
                    this.parseAlarms();
                });
            } else {
                this.serviceGetRemoteV0Alarms().add(() => {
                    this.parseAlarms();
                });
            }
        });
    }

    public confirmUpdateAlarm(updatedAlarm: UslSystemV0Alarm): void {
        this.selectedAlarm = undefined;
        this.goBack();
        this.serviceUpdateAlarm(updatedAlarm).add(() => {
            if (this.isLocal) {
                this.serviceGetLocalV0Alarms().add(() => {
                    this.parseAlarms();
                });
            } else {
                this.serviceGetRemoteV0Alarms().add(() => {
                    this.parseAlarms();
                });
            }
        });
    }

    public confirmRemoveAlarm(removedAlarm: UslSystemV0Alarm): void {
        this.selectedAlarm = undefined;
        this.serviceRemoveAlarm(removedAlarm).add(() => {
            if (this.isLocal) {
                this.serviceGetLocalV0Alarms().add(() => {
                    this.parseAlarms();
                });
            } else {
                this.serviceGetRemoteV0Alarms().add(() => {
                    this.parseAlarms();
                });
            }
        });
    }

    public goForward(): void {
        this.flyDirection = 'flyLeft';
        this.subFlyDirection = 'flyLeft';
        this.configStep++;
    }

    public goBack(): void {
        switch (this.configStep) {
            case 2:
                this.subFlyDirection = 'flyRight';
                this.selectedAlarm = undefined;
                break;
            case 1:
                this.flyDirection = 'flyRight';
                this.selectedItem = undefined;
                break;
        }

        this.configStep--;
        if (this.configStep < 0) {
            this.configStep = 0;
        }
    }

    // Internal Helpers
    private parseAlarms(): void {
        this.readings = [];
        this.alarms = [];
        for (let key in this.systemReadings) {
            if (!this.systemReadings.hasOwnProperty(key)) {
                continue;
            }

            this.readings.push(this.systemReadings[key]);
        }

        for (let alarm of this.systemAlarms) {
            if (this.alarms[alarm.readingId]) {
                this.alarms[alarm.readingId].push(alarm);
            } else {
                this.alarms[alarm.readingId] = [];
                this.alarms[alarm.readingId].push(alarm);
            }
        }
    }

    // Service Calls
    private serviceAddAlarm(newAlarm: UslSystemV0Alarm): Subscription {
        return this._alarmService
            .addLocalAlarm(newAlarm)
            .subscribe();
    }

    private serviceUpdateAlarm(newAlarm: UslSystemV0Alarm): Subscription {
        return this._alarmService
            .updateLocalAlarm(newAlarm)
            .subscribe();
    }

    private serviceRemoveAlarm(newAlarm: UslSystemV0Alarm): Subscription {
        return this._alarmService
            .deleteLocalAlarm(newAlarm)
            .subscribe();
    }

    private serviceGetLocalV0Alarms(): Subscription {
        return this._alarmService
            .getLocalAlarms()
            .subscribe((data: UslSystemV0Alarm[]) => {
                this.systemAlarms = data;
            });
    }

    private serviceGetRemoteV0Alarms(): Subscription {
        return this._alarmService
            .getRemoteAlarms()
            .subscribe((data: UslSystemV0Alarm[]) => {
                this.systemAlarms = data;
            });
    }
}
