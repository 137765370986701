import { Routes } from '@angular/router';

// Pages
import { RemoteComponent } from './mod_remote.component';
import { RemoteHomeComponent } from './home/home.component';
import { RemoteViewComponent } from './view/view.component';

export const REMOTE_ROUTES: Routes = [
    {
        path: 'remote',
        component: RemoteComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: RemoteHomeComponent },
            { path: 'view/:id', component: RemoteViewComponent },
        ]
    },
];
