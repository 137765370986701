import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Log as SslLog } from '../../../../../../../mod_ssl/models/log.model';
import { Paged } from '../../../../../../../shared/models/common/paged.model';

@Component({
    selector: 'usl-display-v0-ssl-log-modal',
    styleUrls: ['./ssl-log.component.css'],
    templateUrl: './ssl-log.component.html'
})
export class UslDisplayV0ModalSslLogComponent {

    @Input() public sslLogRows: Paged<SslLog>;
    @Input() public isLoadingSslLogs: boolean;
    @Input() public currentTimeZone: string = 'UTC';
    @Output() public onSort: EventEmitter<any> = new EventEmitter();
    @Output() public onPage: EventEmitter<any> = new EventEmitter();

}
