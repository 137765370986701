import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MLM_ROUTES } from './mod_mlm.routes';

// Thirdparty
import { TranslateModule } from '@ngx-translate/core';
import {
    AlertModule,
    ButtonsModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    TabsModule
} from 'ngx-bootstrap';

// Modules
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';
import { SharedComponentsModule } from '../shared/components/shared-components.module';

// Services
import { ConfigService } from './services/config.service';
import { JettyService } from './services/jetty.service';
import { LogService } from './services/log.service';
import { PatternService } from './services/pattern.service';

// Pipes

// Components
import { MlmComponent } from './mod_mlm.component';
import { MlmHomeComponent } from './home/home.component';
import { MlmJettiesComponent } from './jetties/jetties.component';
import { MlmJettiesConfigComponent } from './jetties/config/config.component';
import { MlmJettiesListComponent } from './jetties/list/list.component';
import { MlmOperationsComponent } from './operations/operations.component';
import { MlmLogsComponent } from './logs/logs.component';
import { MlmOperationsHooksGraphComponent } from './operations/hooks-graph/hooks-graph.component';
import {
    MlmOperationsShipDisplayComponent
} from './operations/ship-display/ship-display.component';
import {
    MlmOperationsWeatherDisplayComponent
} from './operations/weather-display/weather-display.component';
import { MlmPatternsComponent } from './patterns/patterns.component';
import {
    MlmOperationsLoadPatternModalComponent
} from './operations/modal/load-pattern/load-pattern.component';
import { OperationService } from './services/operation.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PollStatePipe } from './pipes/poll-state.pipe';

@NgModule({
    declarations: [
        MlmComponent,
        MlmHomeComponent,
        MlmJettiesComponent,
        MlmJettiesConfigComponent,
        MlmJettiesListComponent,
        MlmOperationsComponent,
        MlmOperationsHooksGraphComponent,
        MlmOperationsShipDisplayComponent,
        MlmOperationsWeatherDisplayComponent,
        MlmOperationsLoadPatternModalComponent,
        MlmLogsComponent,
        MlmPatternsComponent,

        // Pipes
        PollStatePipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(MLM_ROUTES),
        TranslateModule,

        // Thirdparty
        AlertModule,
        ButtonsModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        TabsModule,
        NgxDatatableModule,

        // Pipes
        SharedPipesModule,

        // Directives
        SharedDirectivesModule,

        // Components
        SharedComponentsModule,
    ],
    exports: [
        RouterModule
    ],
    providers: [
        ConfigService,
        JettyService,
        OperationService,
        LogService,
        PatternService,
    ]
})
export class MlmModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: MlmModule
        };
    }
}
