import { Routes } from '@angular/router';

// Guard
import { SslModuleGuard } from './guards/module.guard';

// Pages
import { SslComponent } from './mod_ssl.component';
import { SslHomeComponent } from './home/home.component';
import { SslOperationsComponent } from './operations/operations.component';
import { SslTerminalsComponent } from './terminals/terminals.component';
import { SslSettingsComponent } from './settings/settings.component';
import { SslOperationsDetailedComponent } from './operations/detailed/detailed.component';
import { SslOperationsGraphicalComponent } from './operations/graphical/graphical.component';
import { SslTerminalsListComponent } from './terminals/list/list.component';
import { SslTerminalsConfigComponent } from './terminals/config/config.component';
import { SslRecordingsComponent } from './recordings/recordings.component';
import { SslLogsComponent } from './logs/logs.component';
import { SslSystemsComponent } from './systems/systems.component';
import { SslSystemsListComponent } from './systems/list/list.component';
import { SslDebugComponent } from './debug/debug.component';

export const SSL_ROUTES: Routes = [
    {
        path: 'ssl',
        component: SslComponent,
        children: [
            { path: '', component: SslHomeComponent, pathMatch: 'full' },
            {
                path: 'operations',
                component: SslOperationsComponent,
                children: [
                    { path: '', component: SslHomeComponent, pathMatch: 'full' },
                    { path: 'detailed', component: SslOperationsDetailedComponent },
                    { path: 'graphical', component: SslOperationsGraphicalComponent }
                ]
            },
            {
                path: 'terminals',
                component: SslTerminalsComponent,
                children: [
                    { path: '', component: SslTerminalsListComponent },
                    { path: 'edit/:id', component: SslTerminalsConfigComponent },
                    { path: 'new', component: SslTerminalsConfigComponent },
                ]
            },
            {
                path: 'systems',
                component: SslSystemsComponent,
                children: [
                    { path: '', component: SslSystemsListComponent },
                ]
            },
            { path: 'recordings', component: SslRecordingsComponent },
            { path: 'logs', component: SslLogsComponent },
            { path: 'settings', component: SslSettingsComponent },
            { path: 'debug', component: SslDebugComponent }
        ],
        canActivate: [SslModuleGuard]
    },
];
