import { Telecomm } from '../telecomm.model';
import { WebsocketTelecomm } from '../websocket/telecomm.model';
import { TelecommTypeEnum } from '../../../enums/telecomm-type.enum';

export class FibreDigitalPhone extends Telecomm {

    public transmitStatus: boolean;
    public receiveStatus: boolean;
    public bitErrorRate: number;

    private bitErrorRateView = new DataView(new ArrayBuffer(2));

    constructor(data: any) {
        super(data);
        this.telecommType = TelecommTypeEnum.FIBRE_DIGITAL;
        this.bitErrorRate = data.bitErrorRate || -1;

        // Workaround because of boolean type (Silly JS)
        this.transmitStatus = false;
        if (data.transmitStatus != null) {
            this.transmitStatus = data.transmitStatus;
        }

        this.receiveStatus = false;
        if (data.receiveStatus != null) {
            this.receiveStatus = data.receiveStatus;
        }
    }

    public updateTelecommState(newState: FibreDigitalPhone): void {
        super.updateTelecommState(newState);

        this.transmitStatus = newState.transmitStatus;
        this.receiveStatus = newState.receiveStatus;
        this.bitErrorRate = newState.bitErrorRate;
    }

    public updateWebsocketTelecommState(newState: WebsocketTelecomm): void {
        super.updateWebsocketTelecommState(newState);
        if (newState.data) {
            this.transmitStatus = (newState.data[0] & 1) != 0;
            this.receiveStatus = (newState.data[0] & (1 << 1)) != 0;
            this.bitErrorRateView.setUint8(1, newState.data[1]);
            this.bitErrorRateView.setUint8(0, newState.data[2]);
            this.bitErrorRate = this.bitErrorRateView.getUint16(0);
        }
    }
}
