import { TestResultEnum } from '../../enums/test-result.enum';

export class FibreLoopbackResult {

    public systemId: string;
    public allForcedHealthy: TestResultEnum;
    public disableCarriers: TestResultEnum;
    public enableCarriers: TestResultEnum;
    public forceEsdTripped: TestResultEnum;
    public forceEsdHealthy: TestResultEnum;
    public isShore: boolean;
    public isRun: boolean;
    public isFinished: boolean;

    constructor(data: any) {
        this.systemId = data.systemId || undefined;
        this.allForcedHealthy = data.allForcedHealthy;
        this.disableCarriers = data.disableCarriers;
        this.enableCarriers = data.enableCarriers;
        this.forceEsdTripped = data.forceEsdTripped;
        this.forceEsdHealthy = data.forceEsdHealthy;

        // Workaround because of boolean type (Silly JS)
        this.isShore = false;
        if (data.isShore != null) {
            this.isShore = data.isShore;
        }

        this.isRun = false;
        if (data.isRun != null) {
            this.isRun = data.isRun;
        }

        this.isFinished = false;
        if (data.isFinished != null) {
            this.isFinished = data.isFinished;
        }
    }
}
