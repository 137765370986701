import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Subscriber, Observable, Subscription } from 'rxjs';

import { PersistenceService } from '../../shared/services/system/persistence.service';
import { SystemConfiguration } from '../../shared/models/system/configuration.model';
import { ModuleTypeEnum } from '../../shared/enums/module-type.enum';

@Injectable()
export class UslModuleGuard implements CanActivate, OnDestroy {

    private persistenceSub: Subscription;

    constructor(
        private _router: Router,
        private _persistence: PersistenceService
    ) {}

    public canActivate(next: ActivatedRouteSnapshot,
                       state: RouterStateSnapshot): Observable<boolean> {
        return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
            this.persistenceSub = this._persistence
                .systemConfiguration
                .subscribe((data: SystemConfiguration) => {
                    if (data) {
                        if ((data &&
                            (data.enabledModules.indexOf(ModuleTypeEnum.USL) !== -1))) {
                            subscriber.next(true);
                        } else {
                            this._router.navigate(['/']);
                            subscriber.next(false);
                        }

                        subscriber.complete();
                    }
                });
        });
    }

    public ngOnDestroy(): void {
        if (this.persistenceSub) {
            this.persistenceSub.unsubscribe();
        }
    }
}
