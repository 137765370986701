import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'checkbox-element',
    styleUrls: ['./checkbox.component.css'],
    templateUrl: './checkbox.component.html'
})
export class CheckboxElementComponent {

    @Input() public value: boolean;
    @Output() public onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Template Helpers
    public isChanged(newValue: boolean): void {
        this.onChange.emit(newValue);
    }
}
