import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { FibreLoopbackResult } from '../../../models/esd/fibre-loopback-result.model';
import { TestResultEnum } from '../../../enums/test-result.enum';

@Component({
    selector: 'fibre-loopback-test-dialog-modal',
    styleUrls: ['./fibre-loopback-test.component.css'],
    templateUrl: './fibre-loopback-test.component.html'
})
export class FibreLoopbackTestDialogModalComponent {

    @Input() public title: string;
    @Input() public testResult: FibreLoopbackResult;
    @Input() public isDigital: boolean = false;
    @Output() public beginTest: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public endTest: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('fibreLoopbackModal') public fibreLoopbackModal: ModalDirective;

    public testResultEnum = TestResultEnum;

    public open(): void {
        this.fibreLoopbackModal.show();
    }

    public close(): void {
        this.doEndTest();
        this.fibreLoopbackModal.hide();
    }

    // Template helpers
    public doBeginShipTest(): void {
        this.beginTest.emit(false);
    }

    public doBeginShoreTest(): void {
        this.beginTest.emit(true);
    }

    public doEndTest(): void {
        if (this.testResult) {
            this.endTest.emit(this.testResult.isShore);
        }
    }
}
