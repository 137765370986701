import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { PageConfigService } from '../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../shared/models/system/page-breadcrumb.model';
import { PageConfig } from '../shared/models/system/page-config.model';

@Component({
    selector: 'no-content',
    styleUrls: ['./no-content.component.css'],
    templateUrl: './no-content.component.html'
})
export class NoContentComponent implements OnInit, OnDestroy {

    private routerSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _configService: PageConfigService,
        private _location: Location
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    }

    // Template Helpers
    public goBack(): void {
        this._location.back();
    }

    // Helper methods
    private updateNavigation(): void {
        let pageBreadcrumbs: PageBreadcrumb[] = [];
        this._configService.setConfig(new PageConfig({
            title: 'NOT FOUND',
            breadcrumbs: pageBreadcrumbs
        }));
    }

}
