import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
    selector: 'yesno-dialog-modal',
    styleUrls: ['./yesno.component.css'],
    templateUrl: './yesno.component.html'
})
export class YesNoDialogModalComponent {

    @Input() public title: string;
    @Input() public isClosable: boolean;

    @Output() public yes: EventEmitter<undefined> = new EventEmitter<undefined>();
    @Output() public no: EventEmitter<undefined> = new EventEmitter<undefined>();

    @ViewChild('yesnoModal') public yesnoModal: ModalDirective;

    public open(): void {
        this.yesnoModal.show();
    }

    public yesPressed(): void {
        this.yesnoModal.hide();
        this.yes.emit(undefined);
    }

    public noPressed(): void {
        this.yesnoModal.hide();
        this.no.emit(undefined);
    }
}
