import { SystemLocationEnum } from '../../../shared/enums/system-location.enum';

export class ActivateSystemDto {

    public systemId: string;
    public newLocation: SystemLocationEnum;

    constructor(data: ActivateSystemDto) {
        this.systemId = data.systemId;
        this.newLocation = data.newLocation;
    }
}
