import { Trip } from './trip.model';
import { Telecomm } from './telecomm.model';
import { ElectricPhone } from './telecomm/electric-phone.model';
import { FibreAnaloguePhone } from './telecomm/fibre-analogue.model';
import { FibreDigitalPhone } from './telecomm/fibre-digital.model';
import { WebsocketTrip } from './websocket/trip.model';
import { WebsocketTelecomm } from './websocket/telecomm.model';
import { WebsocketEsdSystemStatus } from './websocket/esd-system-status.model';
import { SystemLocationEnum } from '../../enums/system-location.enum';
import { ConnectionTypeEnum } from '../../enums/connection-type.enum';
import { TelecommTypeEnum } from '../../enums/telecomm-type.enum';
import { InstallationTypeEnum } from '../../enums/installation-type.enum';
import { FibreLoopbackResult } from './fibre-loopback-result.model';
import { EslStateEnum } from '../../../mod_ssl/enums/esl-state.enum';
import { PathConfiguration } from './path-configuration.model';
import { ElectricLoopbackResult } from './electric-loopback-result.model';

export class EsdSystem {

    public id: string;
    public name: string;
    public esdStatusLangKey: string;
    public systemLocation: SystemLocationEnum;
    public trips: Trip[];
    public telecomms: Telecomm[];
    public esdBoardId: number;
    public esdConnectionType: ConnectionTypeEnum;
    public telecommBoardId: number;
    public telecommConnectionType: ConnectionTypeEnum;
    public remoteName: string;
    public remoteType: InstallationTypeEnum;
    public fibreLoopbackResult: FibreLoopbackResult;
    public electricLoopbackResult: ElectricLoopbackResult;
    public isTelecommsTripped: boolean;
    public isResetRequired: boolean;
    public isInhibited: boolean;
    public isTripsAcceptAvailable: boolean;
    public isContinuityLinkOverride: boolean;
    public isMlmPolarityInverted: boolean;
    public isContinuityLinkInput: boolean;
    public isEtuPins3536: boolean;
    public isEtuEnabled: boolean;
    public isPseudoBoard: boolean;
    public eslState: EslStateEnum;
    public isExchangeEnabled: boolean;
    public isAutomaticReset: boolean;
    public isStale: boolean;
    public stateIdentifier: string;
    public lastDataUpdate: number;
    public pathConfiguration: PathConfiguration;

    constructor(data: any) {
        this.id = data.id || undefined;
        this.name = data.name || '';
        this.esdStatusLangKey = data.esdStatusLangKey || '';
        this.systemLocation = data.systemLocation;
        this.remoteName = data.remoteName;
        this.remoteType = data.remoteType;
        this.lastDataUpdate = data.lastDataUpdate;
        this.stateIdentifier = data.stateIdentifier;
        this.eslState = data.eslState;
        this.fibreLoopbackResult = data.fibreLoopbackResult;
        this.electricLoopbackResult = data.electricLoopbackResult;
        this.pathConfiguration = data.pathConfiguration;
        this.trips = [];
        if (data.trips) {
            for (const val of data.trips) {
                this.trips.push(new Trip(val));
            }
        }

        this.telecomms = [];
        if (data.telecomms) {
            for (const val of data.telecomms) {
                switch (val.telecommType) {
                    case TelecommTypeEnum.ELECTRIC:
                        this.telecomms.push(new ElectricPhone(val));
                        break;
                    case TelecommTypeEnum.FIBRE_ANALOGUE:
                        this.telecomms.push(new FibreAnaloguePhone(val));
                        break;
                    default:
                    case TelecommTypeEnum.FIBRE_DIGITAL:
                        this.telecomms.push(new FibreDigitalPhone(val));
                        break;
                }
            }
        }

        this.esdBoardId = data.esdBoardId;
        this.esdConnectionType = data.esdConnectionType;
        this.telecommBoardId = data.telecommBoardId;
        this.telecommConnectionType = data.telecommConnectionType;

        // Workaround because of boolean type (Silly JS)
        if (data.isResetRequired != null) {
            this.isResetRequired = data.isResetRequired;
        }

        if (data.isInhibited != null) {
            this.isInhibited = data.isInhibited;
        }

        if (data.isTripsAcceptAvailable != null) {
            this.isTripsAcceptAvailable = data.isTripsAcceptAvailable;
        }

        if (data.isTelecommsTripped != null) {
            this.isTelecommsTripped = data.isTelecommsTripped;
        }

        if (data.isContinuityLinkOverride != null) {
            this.isContinuityLinkOverride = data.isContinuityLinkOverride;
        }

        if (data.isMlmPolarityInverted != null) {
            this.isMlmPolarityInverted = data.isMlmPolarityInverted;
        }

        if (data.isContinuityLinkInput != null) {
            this.isContinuityLinkInput = data.isContinuityLinkInput;
        }

        if (data.isEtuPins3536 != null) {
            this.isEtuPins3536 = data.isEtuPins3536;
        }

        if (data.isEtuEnabled != null) {
            this.isEtuEnabled = data.isEtuEnabled;
        }

        if (data.isExchangeEnabled != null) {
            this.isExchangeEnabled = data.isExchangeEnabled;
        }

        if (data.isAutomaticReset != null) {
            this.isAutomaticReset = data.isAutomaticReset;
        }

        if (data.isPseudoBoard != null) {
            this.isPseudoBoard = data.isPseudoBoard;
        }

        if (data.isStale != null) {
            this.isStale = data.isStale;
        }
    }

    public updateEsdSystemState(newState: EsdSystem): void {
        this.esdBoardId = newState.esdBoardId;
        this.esdConnectionType = newState.esdConnectionType;
        this.telecommBoardId = newState.telecommBoardId;
        this.telecommConnectionType = newState.telecommConnectionType;
        this.esdStatusLangKey = newState.esdStatusLangKey;
        this.systemLocation = newState.systemLocation;
        this.isInhibited = newState.isInhibited;
        this.isResetRequired = newState.isResetRequired;
        this.isTripsAcceptAvailable = newState.isTripsAcceptAvailable;
        this.isTelecommsTripped = newState.isTelecommsTripped;
        this.isContinuityLinkOverride = newState.isContinuityLinkOverride;
        this.isMlmPolarityInverted = newState.isMlmPolarityInverted;
        this.isContinuityLinkInput = newState.isContinuityLinkInput;
        this.isEtuPins3536 = newState.isEtuPins3536;
        this.isEtuEnabled = newState.isEtuEnabled;
        this.isExchangeEnabled = newState.isExchangeEnabled;
        this.isPseudoBoard = newState.isPseudoBoard;
        this.isStale = newState.isStale;
        this.stateIdentifier = newState.stateIdentifier;
        this.lastDataUpdate = newState.lastDataUpdate;
        this.eslState = newState.eslState;
        this.isAutomaticReset = newState.isAutomaticReset;
        this.esdBoardId = newState.esdBoardId;
        this.telecommBoardId = newState.telecommBoardId;
        this.remoteName = newState.remoteName;
        this.fibreLoopbackResult = newState.fibreLoopbackResult;
        this.electricLoopbackResult = newState.electricLoopbackResult;
        this.pathConfiguration = newState.pathConfiguration;

        if (newState.trips) {
            this.updateEsdSystemTrips(newState.trips);
        }

        if (newState.telecomms) {
            this.updateEsdSystemTelecomms(newState.telecomms);
        }
    }

    public updateWebsocketEsdSystemState(newState: WebsocketEsdSystemStatus): void {
        this.esdStatusLangKey = newState.esdStatusLangKey;
        this.isInhibited = newState.isInhibited;
        this.isResetRequired = newState.isResetRequired;
        this.isTripsAcceptAvailable = newState.isTripsAcceptAvailable;
        this.isTelecommsTripped = newState.isTelecommsTripped;
        this.isContinuityLinkOverride = newState.isContinuityLinkOverride;
        this.isMlmPolarityInverted = newState.isMlmPolarityInverted;
        this.isContinuityLinkInput = newState.isContinuityLinkInput;
        this.isEtuPins3536 = newState.isEtuPins3536;
        this.isEtuEnabled = newState.isEtuEnabled;
        this.isExchangeEnabled = newState.isExchangeEnabled;
        this.eslState = newState.eslState;
        this.isAutomaticReset = newState.isAutomaticReset;
    }

    public updateEsdSystemTrips(newTripStates: Trip[]): void {
        for (const newState of newTripStates) {
            for (const oldState of this.trips) {
                if (newState.id === oldState.id) {
                    oldState.isHealthy = newState.isHealthy;
                    oldState.isInhibited = newState.isInhibited;
                    oldState.isWarning = newState.isWarning;
                    oldState.isFirstUp = newState.isFirstUp;
                    oldState.isHealthyValue = newState.isHealthyValue;
                    oldState.isTripAccepted = newState.isTripAccepted;
                    oldState.directionType = newState.directionType;
                    break;
                }
            }
        }
    }

    public updateWebsocketEsdSystemTrip(newTrip: WebsocketTrip): void {
        for (const currentTrip of this.trips) {
            if (currentTrip.id === newTrip.id) {
                currentTrip.isHealthy = newTrip.isHealthy;
                currentTrip.isInhibited = newTrip.isInhibited;
                currentTrip.isWarning = newTrip.isWarning;
                currentTrip.isFirstUp = newTrip.isFirstUp;
                currentTrip.isHealthyValue = newTrip.isHealthyValue;
                currentTrip.isTripAccepted = newTrip.isTripAccepted;
                currentTrip.directionType = newTrip.directionType;
                break;
            }
        }
    }

    public updateEsdSystemTelecomms(newTelecommsStates: Telecomm[]): void {
        for (const newState of newTelecommsStates) {
            for (const oldState of this.telecomms) {
                if (newState.id === oldState.id) {
                    oldState.updateTelecommState(newState);
                    break;
                }
            }
        }
    }

    public updateWebsocketEsdSystemTelecomm(newTelecomm: WebsocketTelecomm): void {
        for (const currentTelecomm of this.telecomms) {
            if (currentTelecomm.id === newTelecomm.id) {
                currentTelecomm.updateWebsocketTelecommState(newTelecomm);
                break;
            }
        }
    }
}
