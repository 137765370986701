import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ESDS_ROUTES } from './mod_esds.routes';

// Thirdparty
import {
    AlertModule,
    ButtonsModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    TabsModule
} from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

// Modules
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';
import { SharedComponentsModule } from '../shared/components/shared-components.module';

// Services
import { ConfigService } from './services/config.service';
import { CauseService } from './services/cause.service';
import { EffectService } from './services/effect.service';
import { RegionService } from './services/region.service';
import { CategoryService } from './services/category.service';
import { CommonAlarmService } from './services/common-alarm.service';
import { HistoryService } from './services/history.service';

// Pipes
import { StatusTypePipe } from './pipes/status-type.pipe';

// Components
import { EsdsComponent } from './mod_esds.component';
import { EsdsOverviewComponent } from './overview/overview.component';
import { EsdsSharedStatusIconComponent } from './components/status-icon/status-icon.component';
import { EsdsCausesComponent } from './causes/causes.component';
import { EsdsHistoryComponent } from './history/history.component';
import { EsdsCauseAndEffectComponent } from './cause-and-effects/cause-and-effect.component';
import { EsdsCauseComponentComponent } from './causes/component/component.component';

@NgModule({
    declarations: [
        EsdsComponent,
        EsdsOverviewComponent,
        EsdsCausesComponent,
        EsdsCauseComponentComponent,
        EsdsHistoryComponent,
        EsdsCauseAndEffectComponent,

        // ESDS Shared Components
        EsdsSharedStatusIconComponent,

        // Pipes
        StatusTypePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(ESDS_ROUTES),
        TranslateModule,

        // Thirdparty
        AlertModule,
        ButtonsModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        TabsModule,

        // Pipes
        SharedPipesModule,

        // Directives
        SharedDirectivesModule,

        // Components
        SharedComponentsModule,
    ],
    exports: [
        RouterModule
    ],
    providers: [
        ConfigService,
        CauseService,
        EffectService,
        RegionService,
        CategoryService,
        CommonAlarmService,
        HistoryService
    ]
})
export class EsdsModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: EsdsModule
        };
    }
}
