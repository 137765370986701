import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Terminal } from '../models/terminal.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';
import { SslConfiguration } from '../models/ssl-configuration.model';
import { CommonAlarm } from '../models/common-alarm.model';

@Component({
    selector: 'ssl-operations',
    styleUrls: ['./operations.component.css'],
    templateUrl: './operations.component.html'
})
export class SslOperationsComponent implements OnInit, OnDestroy {

    public currentTerminal: Terminal;
    public isGraphicalPageEnabled: boolean;

    private routerSub: Subscription;
    private persistenceSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _persistence: PersistenceService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.persistenceSub = this._persistence
                    .sslConfiguration
                    .subscribe((data: SslConfiguration) => {
                        if (data) {
                            if (!data.currentTerminal) {
                                this._router.navigate(['/ssl', 'terminals']);
                            } else {
                                this.currentTerminal = data.currentTerminal;
                                this.isGraphicalPageEnabled = data.isGraphicsPageEnabled;
                            }
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.persistenceSub.unsubscribe();
        }
    }
}
