import { EnvironmentalData } from './environmental-data.model';
import { CompassTypeEnum } from '../enums/compass-type.enum';

export class EnvironmentalDataCompass extends EnvironmentalData {

    public northOffset: number;
    public compassType: CompassTypeEnum;

    constructor(data: EnvironmentalDataCompass) {
        super(data);
        this.northOffset = data.northOffset;
        this.compassType = data.compassType;
    }
}
