import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { PageConfig } from '../../shared/models/system/page-config.model';
import { RemoteScreenService } from '../../shared/services/system/remote-screen.service';
import { RemoteScreen } from '../../shared/models/system/remote-screen.model';

@Component({
    selector: 'remote-home',
    styleUrls: ['./home.component.css'],
    templateUrl: './home.component.html'
})
export class RemoteHomeComponent implements OnInit, OnDestroy {

    public isLoading: boolean;
    public remoteScreens: RemoteScreen[] = [];
    private routerSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _configService: PageConfigService,
        private _remoteScreenService: RemoteScreenService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.isLoading = true;
                this.updateNavigation();
                this.serviceGetScreens().add(() => {
                    this.isLoading = false;
                });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    }

    // Helper methods
    private updateNavigation(): void {
        let pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'REMOTE',
            path: '/remote'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'HOME',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service calls
    private serviceGetScreens(): Subscription {
        return this._remoteScreenService
            .getRemoteScreens()
            .subscribe((data: RemoteScreen[]) => {
                this.remoteScreens = data;
            });
    }
}
