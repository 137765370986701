import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { ConfigService } from '../services/config.service';
import { SslConfiguration } from '../models/ssl-configuration.model';
import { PersistenceService } from '../../shared/services/system/persistence.service';
import { SslConfigurationDto } from '../models/dto/ssl-configuration-dto.model';

@Component({
    selector: 'ssl-settings',
    styleUrls: ['./settings.component.css'],
    templateUrl: './settings.component.html'
})
export class SslSettingsComponent implements OnInit, OnDestroy {

    public sslConfiguration: SslConfiguration;
    public isSettingsChanged: boolean = false;
    public isError: boolean = false;
    private routerSub: Subscription;
    private persistenceSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _configService: PageConfigService,
        private _persistence: PersistenceService,
        private _sslConfigService: ConfigService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.persistenceSub = this._persistence
                    .sslConfiguration
                    .subscribe((data: SslConfiguration) => {
                        if (data) {
                            this.sslConfiguration = new SslConfiguration(data);
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.persistenceSub.unsubscribe();
        }
    }

    // Template Helpers
    public markChange(): void {
        this.isError = false;
        this.isSettingsChanged = true;
    }

    public resetChanges(): void {
        this.sslConfiguration = new SslConfiguration(this._persistence.sslConfiguration.getValue());
        this.isSettingsChanged = false;
    }

    public saveChanges(pin: string): void {
        this.isError = false;
        this.serviceUpdateSslConfiguration(new SslConfigurationDto({
            settingsPin: pin,
            configuration: this.sslConfiguration
        })).add(() => {
           if (!this.isError) {
               this.isSettingsChanged = false;
           }
        });
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'SSL',
            path: '/ssl'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'SETTINGS',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service Calls
    private serviceUpdateSslConfiguration(updatedConfig: SslConfigurationDto): Subscription {
        return this._sslConfigService
            .updateConfig(updatedConfig)
            .subscribe(undefined, () => {
                this.isError = true;
            });
    }
}
