import { BaseSafetyEvent } from './base-safety-event.model';

export class JettyActivatingSafetyEvent extends BaseSafetyEvent {

    public jettyId: string;

    constructor(data: any) {
        super(data);
        this.jettyId = data.jettyId;
    }
}
