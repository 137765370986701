import {
    Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap';

import { ConnectionSystem } from '../../../../../models/connection-system.model';
import { SystemLocationEnum } from '../../../../../../shared/enums/system-location.enum';
import { PersistenceService } from '../../../../../../shared/services/system/persistence.service';
import { SslConfiguration } from '../../../../../models/ssl-configuration.model';
import { InstallationTypeEnum } from '../../../../../../shared/enums/installation-type.enum';

@Component({
    selector: 'ssl-terminals-config-system-profile-config-modal',
    styleUrls: ['./config-system.component.css', '../../step-common/step-common.component.css'],
    templateUrl: './config-system.component.html'
})
export class SslTerminalsConfigSystemProfileConfigModalComponent implements OnInit, OnDestroy {

    @Output() public systemAdded: EventEmitter<ConnectionSystem> =
        new EventEmitter<ConnectionSystem>();
    @Output() public systemSaved: EventEmitter<ConnectionSystem> =
        new EventEmitter<ConnectionSystem>();

    @ViewChild('addSystemModal') public addSystemModal: ModalDirective;

    public isRemoteTypeSettable: boolean = false;
    public systemsForm: FormGroup = undefined;
    public availableSystemLocations: SystemLocationEnum[] = [];
    public selectedConnectionSystem: ConnectionSystem = undefined;
    public isEdit: boolean = false;

    private sslConfigSub: Subscription;

    constructor(
        private _fb: FormBuilder,
        private _persistence: PersistenceService,
    ) {}

    public ngOnInit(): void {
        this.sslConfigSub =  this._persistence
            .sslConfiguration
            .subscribe((data: SslConfiguration) => {
                if (data) {
                    this.availableSystemLocations = data.availableSystemLocations;
                }
            });
    }

    public ngOnDestroy(): void {
        if (this.sslConfigSub) {
            this.sslConfigSub.unsubscribe();
        }
    }

    public add(canSetRemoteType: boolean): void {
        this.isEdit = false;
        this.isRemoteTypeSettable = canSetRemoteType;
        this.selectedConnectionSystem = new ConnectionSystem({});
        if (this.isRemoteTypeSettable) {
            this.systemsForm = this._fb.group({
                systemName: [undefined, [Validators.required]],
                systemLocation: [undefined, [Validators.required]],
                remoteType: [undefined, [Validators.required]],
                remoteName: [undefined, []]
            });
        } else {
            this.systemsForm = this._fb.group({
                systemName: [undefined, [Validators.required]],
                systemLocation: [undefined, [Validators.required]]
            });
        }
        this.systemsForm.reset();
        this.addSystemModal.show();
    }

    public edit(currentConnectionSystem: ConnectionSystem, canSetRemoteType: boolean): void {
        this.isEdit = true;
        this.isRemoteTypeSettable = canSetRemoteType;
        this.selectedConnectionSystem = new ConnectionSystem(currentConnectionSystem);
        if (this.isRemoteTypeSettable) {
            if (this.selectedConnectionSystem.remoteType == undefined) {
                this.selectedConnectionSystem.remoteType = InstallationTypeEnum.SHORE;
            }

            this.systemsForm = this._fb.group({
                systemName: [this.selectedConnectionSystem.name, [Validators.required]],
                systemLocation: [this.selectedConnectionSystem.systemLocation, [Validators.required]],
                remoteType: [this.selectedConnectionSystem.remoteType.toString(10), [Validators.required]],
                remoteName: [this.selectedConnectionSystem.remoteName, []]
            });
        } else {
            this.systemsForm = this._fb.group({
                systemName: [this.selectedConnectionSystem.name, [Validators.required]],
                systemLocation: [this.selectedConnectionSystem.systemLocation, [Validators.required]]
            });
        }
        this.addSystemModal.show();
    }

    // Helper methods
    public saveSystem(): void {
        this.addSystemModal.hide();
        this.selectedConnectionSystem.name = this.systemsForm.get('systemName').value;
        this.selectedConnectionSystem.systemLocation = this.systemsForm.get('systemLocation').value;

        if (this.isRemoteTypeSettable) {
            this.selectedConnectionSystem.remoteType = this.systemsForm.get('remoteType').value;
            this.selectedConnectionSystem.remoteName = this.systemsForm.get('remoteName').value;
        }

        if (this.isEdit) {
            this.systemSaved.emit(this.selectedConnectionSystem);
        } else {
            this.systemAdded.emit(this.selectedConnectionSystem);
        }
    }

    public getValue(id: string): string {
        return this.systemsForm.get(id).value;
    }

    public setValue(id: string, newVal: any): void {
        let vals: any = {};
        vals[id] = newVal;

        this.systemsForm.patchValue(vals);
    }

    public installationTypeKeys(): string[] {
        let keys = Object.keys(InstallationTypeEnum);
        return keys.slice(0, keys.length / 2);
    }
}
