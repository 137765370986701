import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';
import { DateTimeDto } from '../../models/system/dto/datetime-dto.model';
import { TimezoneDto } from '../../models/system/dto/timezone-dto.model';
import { WebSocketService } from '../websocket/websocket.service';

@Injectable()
export class DateTimeService extends BaseService {

    constructor(
        _http: HttpClient,
        private _ws: WebSocketService
    ) {
        super(_http);
        this.actionUrl += 'platform/datetime';
    }

    public getDateTime(): Observable<DateTimeDto> {
        return this._http
            .get<DateTimeDto>(this.actionUrl, this.getRequestOptions());
    }

    public setDateTime(newDateTime: DateTimeDto): Observable<HttpResponse<string>> {
        return this._http
            .post(this.actionUrl,
                JSON.stringify(newDateTime),
                this.getFullRawRequestOptions());
    }

    public getTimezone(): Observable<TimezoneDto> {
        return this._http
            .get<TimezoneDto>(this.actionUrl + '/timezone',
                this.getRequestOptions());
    }

    public getAvailableTimezones(): Observable<TimezoneDto[]> {
        return this._http
            .get<TimezoneDto[]>(this.actionUrl + '/timezone/available',
                this.getRequestOptions());
    }

    public setTimezone(newTimezone: TimezoneDto): Observable<HttpResponse<string>> {
        return this._http
            .post(this.actionUrl + '/timezone',
                JSON.stringify(newTimezone),
                this.getFullRawRequestOptions());
    }

    public webSocketGetTimezone(): Observable<TimezoneDto> {
        return this._ws.get('/wsapi/system/timezone');
    }
}
