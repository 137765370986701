import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { Terminal } from '../../../../models/terminal.model';
import { CountryService } from '../../../../../shared/services/system/country.service';
import { Country } from '../../../../../shared/models/system/country.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphicalTypeEnum } from '../../../../enums/graphical-type.enum';

@Component({
    selector: 'ssl-terminals-config-step-1',
    styleUrls: ['./step-1.component.css', '../step-common/step-common.component.css'],
    templateUrl: './step-1.component.html'
})
export class SslTerminalsConfigStep1Component implements OnInit, OnDestroy {

    @Input() public currentStep: number;
    @Input() public currentTerminal: Terminal;
    @Input() public isLoading: boolean;
    @Output() public loadingState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public validatedState: EventEmitter<boolean> = new EventEmitter<boolean>();

    public terminalForm: FormGroup;
    public countries: Country[];
    public noneCountry: Country;

    private formSubscription: Subscription;

    constructor(
        private _countryService: CountryService,
        private _fb: FormBuilder
    ) {}

    public ngOnInit(): void {
        this.terminalForm = this._fb.group({
            name: [this.currentTerminal.name, [Validators.required]],
            countryId: [this.currentTerminal.countryId, [Validators.required]],
            graphicalType: [this.currentTerminal.graphicalType, [Validators.required]]
        });

        this.formSubscription = this.terminalForm
            .valueChanges
            .subscribe(() => {
                this.doValidation();
                this.updateValues();
            });

        this.serviceGetCountries().add(() => {
           this.loadingState.emit(false);
           this.doValidation();
        });
    }

    public ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    }

    // Helper methods
    public graphicalTypeKeys(): string[] {
        let keys = Object.keys(GraphicalTypeEnum);
        return keys.slice(0, keys.length / 2);
    }

    public setValue(id: string, newVal: any): void {
        let vals: any = {};
        vals[id] = newVal;

        this.terminalForm.patchValue(vals);
    }

    public updateTerminalName(): void {
        this.currentTerminal.name = this.terminalForm.get('name').value;
    }

    // Internal Helpers
    private doValidation(): void {
        this.validatedState.emit(this.terminalForm.valid);
    }

    private updateValues(): void {
        this.currentTerminal.countryId = this.terminalForm.get('countryId').value;
        this.currentTerminal.graphicalType = this.terminalForm.get('graphicalType').value;
    }

    // Service calls
    private serviceGetCountries(): Subscription {
        return this._countryService
            .getCountries()
            .subscribe((data: Country[]) => {
                this.countries = data;

                let noneCounties = data.filter((x) => x.code == 'NONE');
                if (noneCounties) {
                    this.noneCountry = noneCounties[0];
                }

            });
    }
}
