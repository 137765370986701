import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { GraphicalTypeEnum } from '../enums/graphical-type.enum';

@Pipe({
    name: 'graphicalType'
})
export class GraphicalTypePipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(graphicalType: GraphicalTypeEnum): Observable<string> {
        switch (parseInt(graphicalType.toString(), 10)) {
            case GraphicalTypeEnum.DOUBLE_BANK:
                return this._translate.get('SSL.GRAPHICAL_TYPE_DOUBLE_BANK');
            case GraphicalTypeEnum.SHIP_SHIP:
                return this._translate.get('SSL.GRAPHICAL_TYPE_SHIP_SHIP');
            case GraphicalTypeEnum.SHIP_SHORE:
                return this._translate.get('SSL.GRAPHICAL_TYPE_SHIP_SHORE');
            default:
            case GraphicalTypeEnum.TANDEM:
                return this._translate.get('SSL.GRAPHICAL_TYPE_TANDEM');
        }
    }
}
