import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EslStateEnum } from '../../mod_ssl/enums/esl-state.enum';

@Pipe({
    name: 'eslType'
})
export class EslTypePipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(eslState: EslStateEnum): Observable<string> {
        switch (parseInt(eslState.toString(), 10)) {
            case EslStateEnum.MASTER:
                return this._translate.get('SSL.OPTION_ESL_STATE_MASTER');
            case EslStateEnum.SLAVE:
                return this._translate.get('SSL.OPTION_ESL_STATE_SLAVE');
            case EslStateEnum.PENDANT:
                return this._translate.get('SSL.OPTION_ESL_STATE_PENDANT');
            default:
            case EslStateEnum.TEST:
                return this._translate.get('SSL.OPTION_ESL_STATE_TEST');
        }
    }
}
