import { Pipe, PipeTransform } from '@angular/core';
import { SystemLocationEnum } from '../enums/system-location.enum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'systemLocationShort'
})
export class SystemLocationShortPipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(nauticalDirectionType: SystemLocationEnum): string {
        switch (nauticalDirectionType) {
            case SystemLocationEnum.PORT:
                return this._translate.instant('GENERAL.NAUT_PORT');
            case SystemLocationEnum.STARBOARD:
                return this._translate.instant('GENERAL.NAUT_STARB_SH');
            case SystemLocationEnum.BOW:
                return this._translate.instant('GENERAL.NAUT_BOW');
            case SystemLocationEnum.STERN:
                return this._translate.instant('GENERAL.NAUT_STERN_SH');
            case SystemLocationEnum.PORT_FORWARD:
                return this._translate.instant('GENERAL.NAUT_PORT_FWD_SH');
            case SystemLocationEnum.PORT_REAR:
                return this._translate.instant('GENERAL.NAUT_PORT_REAR_SH');
            case SystemLocationEnum.STARBOARD_FORWARD:
                return this._translate.instant('GENERAL.NAUT_STARB_FWD_SH');
            case SystemLocationEnum.STARBOARD_REAR:
                return this._translate.instant('GENERAL.NAUT_STARB_REAR_SH');
            default:
                return '';
        }
    }
}
