import { EnvironmentalData } from './environmental-data.model';
import { EnvironmentalDataTypeEnum } from '../enums/environmental-data-type.enum';
import { EnvironmentalDataCompass } from './environmental-data-compass.model';
import { EnvironmentalDataText } from './environmental-data-text.model';

export class EnvironmentalOption {

    public hasWindSpeed: boolean;
    public hasWindDirection: boolean;
    public hasCurrentSpeed: boolean;
    public hasCurrentDirection: boolean;

    public environmentalData: EnvironmentalData[];

    constructor(data: EnvironmentalOption) {
        this.hasWindSpeed = false;
        if (data.hasWindSpeed != null) {
            this.hasWindSpeed = data.hasWindSpeed;
        }

        this.hasWindDirection = false;
        if (data.hasWindDirection != null) {
            this.hasWindDirection = data.hasWindDirection;
        }

        this.hasCurrentSpeed = false;
        if (data.hasCurrentSpeed != null) {
            this.hasCurrentSpeed = data.hasCurrentSpeed;
        }

        this.hasCurrentDirection = false;
        if (data.hasCurrentDirection != null) {
            this.hasCurrentDirection = data.hasCurrentDirection;
        }

        this.environmentalData = [];
        if (data.environmentalData) {
            for (const newState of data.environmentalData) {
                switch (newState.type) {
                    case EnvironmentalDataTypeEnum.Compass:
                        this.environmentalData.push(new EnvironmentalDataCompass(newState as EnvironmentalDataCompass));
                        break;
                    case EnvironmentalDataTypeEnum.Text:
                        this.environmentalData.push(new EnvironmentalDataText(newState as EnvironmentalDataText));
                        break;
                }
            }
        }
    }

    public updateState(newState: EnvironmentalData): void {
        if (this.environmentalData) {
            for (const oldState of this.environmentalData) {
                if (oldState.channelId == newState.channelId) {
                    oldState.updateState(newState);
                }
            }
        } else {
            this.environmentalData = [];
            switch (newState.type) {
                case EnvironmentalDataTypeEnum.Compass:
                    this.environmentalData.push(new EnvironmentalDataCompass(newState as EnvironmentalDataCompass));
                    break;
                case EnvironmentalDataTypeEnum.Text:
                    this.environmentalData.push(new EnvironmentalDataText(newState as EnvironmentalDataText));
                    break;
            }
        }
    }
}
