import { Hook } from './hook.model';

export class Dolphin {

    public id: string;
    public name: string;
    public index: number;
    public left: number;
    public top: number;
    public width: number;
    public height: number;
    public hooks: Hook[];

    constructor(data: any) {
        this.id = data.id || undefined;
        this.name = data.name || undefined;
        this.index = data.index;
        this.height = data.height;
        this.width = data.width;
        this.left = data.left;
        this.top = data.top;
        this.hooks = data.hooks || [];
    }

    public updateState(newState: Hook): void {
        for (const val of this.hooks) {
            if (val.id === newState.id) {
                val.pullTonnage = newState.pullTonnage;
                val.isStale = newState.isStale;
                val.lastDataUpdate = newState.lastDataUpdate;
                val.alarmState = newState.alarmState;
                val.isAlarmAcknowledged = newState.isAlarmAcknowledged;
            }
        }
    }
}
