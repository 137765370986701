export class UslSystemV0DisplayReading {

    public id: string;
    public readingId: number;
    public description: string;
    public bitMask: number;
    public variableIdIn: number;
    public variableIdOut: number;
    public forceScale: boolean;
    public isBoolean: boolean;
    public min: number;
    public max: number;
    public minRaw: number;
    public maxRaw: number;
    public readingType: string;
    public infrastructureLocation: string;
    public inputType: string;
    public outputType: string;
    public isAlarmed: boolean;
    public rawReading: number;
    public reading: number;

    constructor(data: any) {
        this.id = data.id || undefined;
        this.readingId = data.readingId;
        this.description = data.description || undefined;
        this.bitMask = data.bitMask;
        this.variableIdIn = data.variableIdIn;
        this.variableIdOut = data.variableIdOut;
        this.min = data.min;
        this.max = data.max;
        this.minRaw = data.minRaw;
        this.maxRaw = data.maxRaw;
        this.readingType = data.readingType || undefined;
        this.infrastructureLocation = data.infrastructureLocation || undefined;
        this.inputType = data.inputType || undefined;
        this.outputType = data.outputType || undefined;
        this.rawReading = data.rawReading;
        this.reading = data.reading;

        // Workaround because of boolean type (Silly JS)
        this.forceScale = false;
        if (data.forceScale != null) {
            this.forceScale = data.forceScale;
        }

        this.isBoolean = false;
        if (data.isBoolean != null) {
            this.isBoolean = data.isBoolean;
        }

        this.isAlarmed = false;
        if (data.isAlarmed != null) {
            this.isAlarmed = data.isAlarmed;
        }
    }
}
