import { ConnectionTelecommChannel } from './connection-telecomm-channel.model';

export class ConnectionTelecommChannelFibreDigital extends ConnectionTelecommChannel {

    public isEnabled: boolean;
    public isShore: boolean;

    constructor(data: any) {
        super(data, 'Trelleborg.SSL10K.Brain.Database.Models.Ssl.EntityConnectionTelecommChannelFibreDigital, Trelleborg.SSL10K.Brain.Database');
        this.isEnabled = data.isEnabled;
        this.isShore = data.isShore;
    }
}
