import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageConfig } from '../../../shared/models/system/page-config.model';
import { PageConfigService } from '../../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../../shared/models/system/page-breadcrumb.model';
import { CauseService } from '../../services/cause.service';
import { Cause } from '../../models/cause.model';
import { ComponentWire } from '../../models/component-wire.model';

@Component({
    selector: 'esds-cause-component',
    styleUrls: ['./component.component.css'],
    templateUrl: './component.component.html'
})
export class EsdsCauseComponentComponent implements OnInit, OnDestroy {

    public selectedCause: Cause = undefined;

    private routerSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _configService: PageConfigService,
        private _causeService: CauseService,
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                if (params['id']) {
                    let causeId: number = params['id'];
                    this.serviceGetCause(causeId).add(() => {
                        this.updateNavigation(this.selectedCause);
                    });
                } else {
                    this._router.navigate(['/esds']);
                }
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    }

    // Template helpers
    public calculateRotation(wire: ComponentWire): number {
        return Math.atan2(wire.destinationY - wire.originY,
                wire.destinationX - wire.originX) * 180 / Math.PI;
    }

    public calculateWidth(wire: ComponentWire): number {
        let xLength: number = (wire.originX - wire.destinationX) *
            (wire.originX - wire.destinationX);
        let yLength: number = (wire.originY - wire.destinationY) *
            (wire.originY - wire.destinationY);
        return Math.sqrt(xLength + yLength);
    }

    // Helper methods
    private updateNavigation(cause: Cause): void {
        let pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'ESDS',
            path: '/esds'
        }));
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'CAUSES',
            path: '/esds/causes'
        }));
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: cause.name,
            path: '/esds/causes/' + cause.id
        }));
        this._configService.setConfig(new PageConfig({
            title: 'COMPONENT',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service Calls
    private serviceGetCause(causeId: number): Subscription {
        return this._causeService
            .getCause(causeId)
            .subscribe((data: Cause) => {
                this.selectedCause = data;
            });
    }
}
