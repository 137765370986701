import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { PageConfig } from '../../shared/models/system/page-config.model';
import { Pattern } from '../models/pattern.model';
import { PatternService } from '../services/pattern.service';

@Component({
    selector: 'mlm-patterns',
    styleUrls: ['./patterns.component.css'],
    templateUrl: './patterns.component.html'
})
export class MlmPatternsComponent implements OnInit, OnDestroy {

    public rows: Pattern[] = [];
    public selectedPattern: Pattern;
    public filterCharacter: string = '';
    public possibleFilters: string[] = [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
        'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
        'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ];
    private routerSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _configService: PageConfigService,
        private _patternService: PatternService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.serviceGetPatterns();
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    }

    // Template Helpers
    public selectPattern(selectedPattern: Pattern): void {
        this.selectedPattern = selectedPattern;
    }

    public confirmDeletePattern(): void {
        this.serviceDeletePattern(this.selectedPattern);
        this.serviceGetPatterns();
        this.selectedPattern = undefined;
    }

    public setFilter(character: string): void {
        this.filterCharacter = character;
    }

    public clearFilter(): void {
        this.filterCharacter = '';
    }

    // Helper methods
    private updateNavigation(): void {
        let pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'MLM',
            path: '/mlm'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'PATTERNS',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service Calls
    private serviceGetPatterns(): Subscription {
        return this._patternService
            .getAll()
            .subscribe((data: Pattern[]) => {
                this.rows = data;
            });
    }

    private serviceDeletePattern(pattern: Pattern): void {
        this._patternService.delete(pattern);
    }
}
