import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { DateTimeService } from '../../../services/platform/datetime.service';
import { DateTimeDto } from '../../../models/system/dto/datetime-dto.model';

const START_YEAR: number = 2017;

@Component({
    selector: 'datetime-config-modal',
    styleUrls: ['./datetime-config.component.css'],
    templateUrl: './datetime-config.component.html'
})
export class DateTimeConfigModalComponent {

    @Input() public title: string;
    @Output() public dateTimeSaved: EventEmitter<number> = new EventEmitter<number>();

    public isSaving: boolean = false;
    public secondNumber: number = 0;
    public minuteNumber: number = 0;
    public hourNumber: number = 0;
    public dayNumber: number = 0;
    public monthNumber: number = 0;
    public yearNumber: number = START_YEAR;
    public secondsInMinutes: number[] = [];
    public minutesInHour: number[] = [];
    public hoursInDay: number[] = [];
    public daysInMonth: number[] = [];
    public monthsInYear: number[] = [];
    public yearsCount: number[] = [];

    @ViewChild('dateTimeConfigModal') public dateTimeConfigModal: ModalDirective;

    constructor(
        private _dateTimeService: DateTimeService
    ) {
        this.secondsInMinutes = Array(60).fill(60).map((x, i) => i);
        this.minutesInHour = Array(60).fill(60).map((x, i) => i);
        this.hoursInDay = Array(24).fill(24).map((x, i) => i);
        this.monthsInYear = Array(12).fill(12).map((x, i) => i);
        const yearCount = 9999 - START_YEAR + 1;
        this.yearsCount = new Array(yearCount).fill(yearCount).map((x, i) => (i + START_YEAR));
    }

    public open(timestampSeconds: number): void {
        this.initialiseTimes(timestampSeconds * 1000);
        this.dateTimeConfigModal.show();
    }

    // Template helpers
    public saveDateTime(): void {
        this.isSaving = true;
        const timestampInMs = Date.UTC(this.yearNumber,
            this.monthNumber,
            this.dayNumber,
            this.hourNumber,
            this.minuteNumber,
            this.secondNumber);

        const finalTimestamp = timestampInMs / 1000;
        this._dateTimeService
            .setDateTime(new DateTimeDto({
                unixTimeStampSeconds: finalTimestamp
            }))
            .subscribe(() => {
                this.dateTimeConfigModal.hide();
                this.dateTimeSaved.emit(finalTimestamp);
                this.isSaving = false;
            }, () => {
                this.dateTimeConfigModal.hide();
                this.isSaving = false;
            });
    }

    public updateDaysInMonth(): void {
        const dayCount = new Date(this.yearNumber, this.monthNumber + 1, 0)
            .getUTCDate();
        this.daysInMonth = new Array(dayCount).fill(dayCount).map((x, i) => i + 1);

        if (this.dayNumber > dayCount) {
            this.dayNumber = dayCount;
        }
    }

    public modifyHour(sign: string): void {
        const index = this.hoursInDay.indexOf(this.hourNumber);

        if (sign == '+') {
            if (index + 1 > (this.hoursInDay.length - 1)) {
                this.modifyDay(sign);
                this.hourNumber = this.hoursInDay[0];
            } else {
                this.hourNumber = this.hoursInDay[index + 1];
            }
        } else {
            if (index - 1 < 0) {
                this.modifyDay(sign);
                this.hourNumber = this.hoursInDay[this.hoursInDay.length - 1];
            } else {
                this.hourNumber = this.hoursInDay[index - 1];
            }
        }
    }

    public modifyMinute(sign: string): void {
        const index = this.minutesInHour.indexOf(this.minuteNumber);

        if (sign == '+') {
            if (index + 1 > (this.minutesInHour.length - 1)) {
                this.modifyHour(sign);
                this.minuteNumber = this.minutesInHour[0];
            } else {
                this.minuteNumber = this.minutesInHour[index + 1];
            }
        } else {
            if (index - 1 < 0) {
                this.modifyHour(sign);
                this.minuteNumber = this.minutesInHour[this.minutesInHour.length - 1];
            } else {
                this.minuteNumber = this.minutesInHour[index - 1];
            }
        }
    }

    public modifySecond(sign: string): void {
        const index = this.secondsInMinutes.indexOf(this.secondNumber);

        if (sign == '+') {
            if (index + 1 > (this.secondsInMinutes.length - 1)) {
                this.modifyMinute(sign);
                this.secondNumber = this.secondsInMinutes[0];
            } else {
                this.secondNumber = this.secondsInMinutes[index + 1];
            }
        } else {
            if (index - 1 < 0) {
                this.modifyMinute(sign);
                this.secondNumber = this.secondsInMinutes[this.secondsInMinutes.length - 1];
            } else {
                this.secondNumber = this.secondsInMinutes[index - 1];
            }
        }
    }

    public modifyDay(sign: string): void {
        const index = this.daysInMonth.indexOf(this.dayNumber);

        if (sign == '+') {
            if (index + 1 > (this.daysInMonth.length - 1)) {
                this.modifyMonth(sign);
                this.dayNumber = this.daysInMonth[0];
            } else {
                this.dayNumber = this.daysInMonth[index + 1];
            }
        } else {
            if (index - 1 < 0) {
                this.modifyMonth(sign);
                this.dayNumber = this.daysInMonth[this.daysInMonth.length - 1];
            } else {
                this.dayNumber = this.daysInMonth[index - 1];
            }
        }
    }

    public modifyMonth(sign: string): void {
        const index = this.monthsInYear.indexOf(this.monthNumber);

        if (sign == '+') {
            if (index + 1 > (this.monthsInYear.length - 1)) {
                this.modifyYear(sign);
                this.monthNumber = this.monthsInYear[0];
            } else {
                this.monthNumber = this.monthsInYear[index + 1];
            }
        } else {
            if (index - 1 < 0) {
                this.modifyYear(sign);
                this.monthNumber = this.monthsInYear[this.monthsInYear.length - 1];
            } else {
                this.monthNumber = this.monthsInYear[index - 1];
            }
        }

        this.updateDaysInMonth();
    }

    public modifyYear(sign: string): void {
        const index = this.yearsCount.indexOf(this.yearNumber);

        if (sign == '+') {
            this.yearNumber = (index + 1 > (this.yearsCount.length - 1)) ?
                this.yearsCount[0] : this.yearsCount[index + 1];
        } else {
            this.yearNumber = (index - 1 < 0) ?
                this.yearsCount[this.yearsCount.length - 1] : this.yearsCount[index - 1];
        }

        this.updateDaysInMonth();
    }

    // Helpers
    private initialiseTimes(timestampMiliseconds: number): void {
        const date = new Date(timestampMiliseconds);
        this.yearNumber = date.getUTCFullYear();
        this.monthNumber = date.getUTCMonth();
        this.dayNumber = date.getUTCDate();
        this.hourNumber = date.getUTCHours();
        this.minuteNumber = date.getUTCMinutes();
        this.secondNumber = date.getUTCSeconds();
        this.updateDaysInMonth();
    }
}
