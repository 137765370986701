import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'macAddress'
})
export class MacAddressPipe implements PipeTransform {

    public transform(macAddress: string): any {
        let r = /([a-f0-9]{2})([a-f0-9]{2})/i;
        let str = macAddress.replace(/[^a-f0-9]/ig, '');

        while (r.test(str)) {
            str = str.replace(r, '$1' + ':' + '$2');
        }

        return str;
    }
}
