import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';

import { PageConfigService } from '../shared/services/system/page-config.service';
import { ModuleTypeEnum } from '../shared/enums/module-type.enum';
import { PersistenceService } from '../shared/services/system/persistence.service';
import { SystemConfiguration } from '../shared/models/system/configuration.model';
import { ConnectionStateEnum } from '../shared/enums/connection-state.enum';
import { PageConfig } from '../shared/models/system/page-config.model';
import { CommonAlarm } from '../shared/models/system/common-alarm.model';
import { RemoteScreenService } from '../shared/services/system/remote-screen.service';
import { RemoteScreen } from '../shared/models/system/remote-screen.model';
import { BrainMetadataDto } from '../shared/models/system/dto/brain-metadata-dto.model';
import { ScreenService } from '../shared/services/system/screen.service';
import { ScreenNotification } from '../shared/models/system/screen-notification.model';
import { Paged } from '../shared/models/common/paged.model';

@Component({
    selector: 'navbar',
    styleUrls: ['./navbar.component.css'],
    templateUrl: './navbar.component.html'
})
export class NavBarComponent implements OnInit, OnDestroy {

    public pageSize: number = 12;
    public isNavbarCollapsed: boolean = true;
    public moduleTypeEnum = ModuleTypeEnum;
    public systemConfiguration: SystemConfiguration;
    public pageConfig: PageConfig;
    public connectionState: ConnectionStateEnum = ConnectionStateEnum.DISCONNECTED;
    public remoteScreens: RemoteScreen[] = [];
    public mirrors: BrainMetadataDto[] = [];
    public commonAlarm: CommonAlarm;
    public serverTimeSeconds: number = 0;
    public transfersInProgress: number = 0;
    public hasFlippedLogo: boolean = false;
    public isFetchingNotifications: boolean = false;
    public currentTimeZone: string = 'UTC';

    public unreadNotificationsTableSortBy: string = 'timestamp';
    public unreadNotificationsTableSortByDesc: boolean = true;
    public unreadNotifications: Paged<ScreenNotification>;

    public readNotificationsTableSortBy: string = 'timestamp';
    public readNotificationsTableSortByDesc: boolean = true;
    public readNotifications: Paged<ScreenNotification>;

    private debugSelectorCount: number = 0;
    private debugSelectorTimer: any;

    private webSockets: Subscription[] = [];

    constructor(
        private _pageConfigService: PageConfigService,
        private _persistence: PersistenceService,
        private _remoteScreenService: RemoteScreenService,
        private _screenService: ScreenService,
    ) {}

    public ngOnInit(): void {
        this.serviceGetRemoteScreens();
        this.webSockets.push(this._persistence
            .systemConfiguration
            .subscribe((data: SystemConfiguration) => {
                if (data) {
                    this.systemConfiguration = data;
                }
            }));

        this.webSockets.push(this._pageConfigService
            .getConfig()
            .subscribe((config: PageConfig) => {
                this.pageConfig = config;
            }));

        this.webSockets.push(this._persistence
            .brainConnectionState
            .subscribe((data: ConnectionStateEnum) => {
                this.changeState(data);
            }));

        this.webSockets.push(this._persistence
            .commonAlarm
            .subscribe((data: CommonAlarm) => {
                this.commonAlarm = data;
            }));

        this.webSockets.push(this._persistence
            .brainMirrors
            .subscribe((data: BrainMetadataDto[]) => {
                this.mirrors = data;
            }));

        this.webSockets.push(this._persistence
            .serverTimeSeconds
            .subscribe((data: number) => {
                this.serverTimeSeconds = data;
            }));

        this.webSockets.push(this._persistence
            .neuralTransfersInProgress
            .subscribe((data: number) => {
                this.transfersInProgress = data;
            }));

        this.webSockets.push(this._persistence
            .currentTimeZoneIANA
            .subscribe((data: string) => {
                this.currentTimeZone = data;
            }));

        this.webSockets.push(this._screenService
            .webSocketGetNotifications()
            .subscribe((data: ScreenNotification) => {
                if ((!data.screenId || data.screenId == this.systemConfiguration.id) &&
                    this.unreadNotifications) {
                    this.fetchNotifications();
                }
            }));

        this.fetchNotifications();
    }

    public ngOnDestroy(): void {
        this.changeState(ConnectionStateEnum.CONNECTING);
        for (const val of this.webSockets) {
            if (val) {
                val.unsubscribe();
            }
        }
    }

    // Template Helper
    public getOverBarMessages(): string[] {
        const messages: string[] = [];

        if (this.isBrainAwake()) {
            if (!this.systemConfiguration.systemConfiguration.serialNumber) {
                messages.push('NOTIFICATION.SYSTEM_NOT_PROVISIONED');
            }

            // TODO: Additional messages that can appear only when brain is awake
        }

        return messages;
    }

    public isModuleEnabled(moduleType: ModuleTypeEnum): boolean {
        return (this.systemConfiguration &&
        (this.systemConfiguration.enabledModules.indexOf(moduleType) !== -1));
    }

    public toggleNavbarCollapse(): void {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
    }

    public isBrainAwake(): boolean {
        return (this.connectionState != ConnectionStateEnum.DISCONNECTED) &&
            (this.connectionState != ConnectionStateEnum.CONNECTING) &&
            (this.connectionState != ConnectionStateEnum.UNKNOWN);
    }

    public startDebugSelector(): void {
        if (this.debugSelectorCount == 0) {
            clearInterval(this.debugSelectorTimer);
            this.debugSelectorTimer = setTimeout(() => {
                this.debugSelectorCount = 0;
            }, 7500);
        }

        this.debugSelectorCount++;

        if (this.debugSelectorCount >= 5) {
            this._persistence.isDebugToggled.next(this.hasFlippedLogo);
            this.hasFlippedLogo = !this.hasFlippedLogo;
            clearTimeout(this.debugSelectorTimer);
            this.debugSelectorCount = 0;
        }

    }

    public markNotificationAsRead(notificationId: string): void {
        this.serviceMarkNotificationRead(notificationId).add(() => {
            this.fetchNotifications();
        });
    }

    public markAllNotificationAsRead(): void {
        this.serviceMarkAllNotificationRead().add(() => {
            this.fetchNotifications();
        });
    }

    public fetchNotifications(): void {
        this.isFetchingNotifications = true;
        forkJoin(
            this._screenService.getUnreadNotifications(this.unreadNotificationsTableSortBy,
                this.unreadNotificationsTableSortByDesc,
                1,
                this.pageSize),
            this._screenService.getReadNotifications(this.readNotificationsTableSortBy,
                this.readNotificationsTableSortByDesc,
                1,
                this.pageSize))
            .subscribe((results) => {
                this.unreadNotifications = results[0];
                this.readNotifications = results[1];
                this.isFetchingNotifications = false;
            });
    }

    public getNotificationClass(): string {
        if (this.unreadNotifications && this.unreadNotifications.items.length > 0) {
            const notificationPriority: number = Math.max.apply(Math,
                this.unreadNotifications.items.map((o) => o.priority));
            switch (notificationPriority) {
                case 5:
                    return 'btn-status-error';
                case 4:
                case 3:
                    return 'btn-status-warning';
                case 0:
                    return 'btn-status-none';
                default:
                    return 'btn-status-info';
            }
        }

        return '';
    }

    public goToUnreadNotificationPage(pageInfo: any): void {
        this.isFetchingNotifications = true;
        this._screenService.getUnreadNotifications(this.unreadNotificationsTableSortBy,
            this.unreadNotificationsTableSortByDesc,
            pageInfo.offset + 1,
            this.pageSize)
            .subscribe((result: Paged<ScreenNotification>) => {
                this.unreadNotifications = result;
                this.isFetchingNotifications = false;
            });
    }

    public sortUnreadNotificationPage(sortInfo: any): void {
        this.isFetchingNotifications = true;
        this.unreadNotificationsTableSortBy = sortInfo.column.prop;
        this.unreadNotificationsTableSortByDesc = sortInfo.newValue == 'desc';
        this._screenService.getUnreadNotifications(this.unreadNotificationsTableSortBy,
            this.unreadNotificationsTableSortByDesc,
            this.unreadNotifications.pageIndex,
            this.pageSize)
            .subscribe((result: Paged<ScreenNotification>) => {
                this.unreadNotifications = result;
                this.isFetchingNotifications = false;
            });
    }

    public goToReadNotificationPage(pageInfo: any): void {
        this.isFetchingNotifications = true;
        this._screenService.getReadNotifications(this.readNotificationsTableSortBy,
            this.readNotificationsTableSortByDesc,
            pageInfo.offset + 1,
            this.pageSize)
            .subscribe((result: Paged<ScreenNotification>) => {
                this.readNotifications = result;
                this.isFetchingNotifications = false;
            });
    }

    public sortReadNotificationPage(sortInfo: any): void {
        this.isFetchingNotifications = true;
        this.readNotificationsTableSortBy = sortInfo.column.prop;
        this.readNotificationsTableSortByDesc = sortInfo.newValue == 'desc';
        this._screenService.getReadNotifications(this.readNotificationsTableSortBy,
            this.readNotificationsTableSortByDesc,
            this.readNotifications.pageIndex,
            this.pageSize)
            .subscribe((result: Paged<ScreenNotification>) => {
                this.readNotifications = result;
                this.isFetchingNotifications = false;
            });
    }

    // Helpers
    private changeState(newState: ConnectionStateEnum): void {
        this.connectionState = newState;
    }

    // Service calls
    private serviceGetRemoteScreens(): Subscription {
        return this._remoteScreenService
            .getRemoteScreens()
            .subscribe((data: RemoteScreen[]) => {
                this.remoteScreens = data;
            });
    }

    private serviceMarkAllNotificationRead(): Subscription {
        return this._screenService
            .getMarkAllNotificationRead()
            .subscribe();
    }

    private serviceMarkNotificationRead(notificationId: string): Subscription {
        return this._screenService
            .getMarkNotificationRead(notificationId)
            .subscribe();
    }
}
