import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription, interval } from 'rxjs';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { CauseService } from '../services/cause.service';
import { Cause } from '../models/cause.model';
import { EffectService } from '../services/effect.service';
import { Effect } from '../models/effect.model';
import { WebsocketCause } from '../models/websocket/cause.model';
import { WebsocketEffect } from '../models/websocket/effect.model';
import { StatusTypeEnum } from '../enums/status-type.enum';
import { Debounce } from '../../shared/helpers/debounce.helper';
import { CONFIGURATION } from '../../app.constants';

const MAX_EFFECT_COLUMS: number = 5;

@Component({
    selector: 'esds-causes',
    styleUrls: ['./causes.component.css'],
    templateUrl: './causes.component.html'
})
export class EsdsCausesComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild('causeHeader') public causeHeaderRef: ElementRef;

    public selectedCause: Cause = undefined;
    public selectedEffects: Effect[] = [];
    public statusTypeEnum = StatusTypeEnum;
    public effectItemWidth: number = 20;
    public effectItemHeight: number = 10;
    public causeHeaderHeight: number = 0;

    private webSockets: Subscription[] = [];
    private resizeEventListener: any;
    private pollTimer: Subscription = undefined;
    private routerSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _configService: PageConfigService,
        private _causeService: CauseService,
        private _effectService: EffectService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                if (params['id']) {
                    const causeId: number = params['id'];
                    this.serviceGetCause(causeId).add(() => {
                        this.updateNavigation(this.selectedCause.name);
                        this.serviceGetEffects(this.selectedCause.effectIds).add(() => {
                            this.calculateEffectItemSizes();
                            this.startPolling();
                        });
                        this.webSockets.push(this.websocketGetEffects());
                        this.webSockets.push(this.websocketGetCauses());
                    });
                } else {
                    this._router.navigate(['/esds']);
                }
            });
    }

    public ngOnDestroy(): void {
        window.removeEventListener('resize', this.resizeEventListener);
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.routerSub = undefined;
            this.stopPolling();
            for (const val of this.webSockets) {
                if (val) {
                    val.unsubscribe();
                }
            }
        }
    }

    public ngAfterViewInit(): void {
        this.resizeEffectList();
        setTimeout(() => { this.resizeEffectList(); }, 250);
        this.resizeEventListener = Debounce.debounce(() => {
            this.resizeEffectList();
        }, 250);
        window.addEventListener('resize', this.resizeEventListener);
    }

    // Helper methods
    private updateNavigation(causeName: string): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'ESDS',
            path: '/esds'
        }));
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'CAUSES',
            path: '/esds/causes'
        }));
        this._configService.setConfig(new PageConfig({
            title: causeName,
            breadcrumbs: pageBreadcrumbs
        }));
    }

    private resizeEffectList(): void {
        if (this.causeHeaderRef) {
            this.causeHeaderHeight = this.causeHeaderRef.nativeElement.offsetHeight;
        }
    }

    private calculateEffectItemSizes(): void {
        // Calculate height
        if (this.selectedEffects.length > MAX_EFFECT_COLUMS) {
            this.effectItemWidth = 100 / MAX_EFFECT_COLUMS;
        } else {
            this.effectItemWidth = 100 / this.selectedEffects.length;
        }

        const itemRows = Math.ceil(this.selectedEffects.length / MAX_EFFECT_COLUMS);
        if (itemRows < 1) {
            this.effectItemHeight = 100;
        } else {
            this.effectItemHeight = 100 / itemRows;
        }
    }

    private startPolling(): void {
        if (this.routerSub === undefined) {
            return;
        }

        this.pollTimer = interval(CONFIGURATION.pollInterval)
            .subscribe(() => {
                this.stopPolling();
                if (this.selectedCause) {
                    this.serviceGetCause(this.selectedCause.id).add(() => {
                        this.serviceGetEffects(this.selectedCause.effectIds).add(() => {
                            this.calculateEffectItemSizes();
                            this.startPolling();
                        });
                    });
                }
            });
    }

    private stopPolling(): void {
        if (this.pollTimer) {
            this.pollTimer.unsubscribe();
        }
    }

    // Service Calls
    private serviceGetCause(causeId: number): Subscription {
        return this._causeService
            .getCause(causeId)
            .subscribe((data: Cause) => {
                if (!this.selectedCause) {
                    this.selectedCause = data;
                } else {
                    this.selectedCause.status = data.status;
                    this.selectedCause.isFirstUp = data.isFirstUp;
                }
            }, () => {
                if (this.selectedCause) {
                    this.selectedCause.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }

    private serviceGetEffects(effectIds: number[]): Subscription {
        return this._effectService
            .getEffectsByIds(effectIds)
            .subscribe((data: Effect[]) => {
                if (!this.selectedEffects || this.selectedEffects.length <= 0) {
                    this.selectedEffects = data;
                }

                for (const newState of data) {
                    for (const oldState of this.selectedEffects) {
                        if (newState.id == oldState.id) {
                            oldState.status = newState.status;
                            break;
                        }
                    }
                }
            }, () => {
                for (const val of this.selectedEffects) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }

    private websocketGetCauses(): Subscription {
        return this._causeService
            .websocketGetCauses()
            .subscribe((data: WebsocketCause) => {
                if (data.id == this.selectedCause.id) {
                    this.selectedCause.status = data.status;
                    this.selectedCause.isFirstUp = data.isFirstUp;
                }
            }, () => {
                this.selectedCause.status = StatusTypeEnum.UNKNOWN;
            });
    }

    private websocketGetEffects(): Subscription {
        return this._effectService
            .websocketGetEffects()
            .subscribe((data: WebsocketEffect) => {
                for (const oldState of this.selectedEffects) {
                    if (data.id == oldState.id) {
                        oldState.status = data.status;
                        break;
                    }
                }
            }, () => {
                for (const val of this.selectedEffects) {
                    val.status = StatusTypeEnum.UNKNOWN;
                }
            });
    }
}
