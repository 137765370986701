import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { SystemLocationEnum } from '../../../../../shared/enums/system-location.enum';
import { ConnectionSystem } from '../../../../models/connection-system.model';
import { BoardLookup } from '../../../../../shared/models/esd/board-lookup.model';
import { BoardInputLocationEnum } from '../../../../../shared/enums/board-input-location.enum';
import { InstallationTypeEnum } from '../../../../../shared/enums/installation-type.enum';
import { BoardActivation } from '../../../../../shared/models/esd/board-activation.model';
import { BoardTypeEnum } from '../../../../../shared/enums/board-type.enum';

@Component({
    selector: 'ssl-systems-list-activate-system-modal',
    styleUrls: ['./activate-system.component.css'],
    templateUrl: './activate-system.component.html'
})
export class SslSystemsListActivateSystemModalComponent {

    @ViewChild('activateSystemModal') public activateSystemModal: ModalDirective;

    @Input() public system: ConnectionSystem;
    @Input() public availableLocations: SystemLocationEnum[];
    @Input() public currentSystems: ConnectionSystem[];
    @Input() public boardActivationRules: BoardActivation[];
    @Input() public availableBoardLookups: BoardLookup[];
    @Input() public currentInstallationType: InstallationTypeEnum;
    @Output() public onActivate: EventEmitter<SystemLocationEnum> =
        new EventEmitter<SystemLocationEnum>();

    public installationTypeEnum = InstallationTypeEnum;
    public selectedLocation: SystemLocationEnum = undefined;

    public open(): void {
        if (this.availableLocations.length == 1) {
            this.selectedLocation = this.availableLocations[0];
        } else {
            this.selectedLocation = undefined;
        }

        this.activateSystemModal.show();
    }

    public activatePressed(): void {
        this.activateSystemModal.hide();
        this.onActivate.emit(this.selectedLocation);
    }

    // Template Helpers
    public inUse(location: SystemLocationEnum): boolean {
        if (this.getInUseWithSystem(location) !== undefined) {
            return true;
        }

        return false;
    }

    public getInUse(location: SystemLocationEnum): ConnectionSystem[] {
        let inUse: ConnectionSystem[] = [];
        if (location == undefined) {
            return inUse;
        }

        for (let val of this.currentSystems) {
            if (val.systemLocation == location && val.isActive) {
                inUse.push(val);
            }
        }

        return inUse;
    }

    public getInUseWithSystem(location: SystemLocationEnum): ConnectionSystem {
        let inUse: ConnectionSystem = undefined;
        if (location == undefined) {
            return inUse;
        }

        let esdBoardType = null;
        let telecommBoardType = null;
        let hasEsd = this.system &&
            this.system.esdConfiguration &&
            this.system.esdConfiguration.isEnabled;
        let hasTelecomm = this.system &&
            this.system.telecommConfiguration &&
            this.system.telecommConfiguration.isEnabled;
        if (hasEsd) {
            esdBoardType = this.system.esdConfiguration.boardType;
            if (!hasTelecomm) {
                telecommBoardType = this.system.esdConfiguration.boardType;
            }
        }

        if (hasTelecomm) {
            telecommBoardType = this.system.telecommConfiguration.boardType;
            if (!hasEsd) {
                esdBoardType = this.system.telecommConfiguration.boardType;
            }
        }

        for (let val of this.currentSystems) {
            if (val.systemLocation == location && val.isActive) {
                let otherEsdBoardType = null;
                let otherTelecommBoardType = null;
                let otherHasEsd = val.esdConfiguration &&
                    val.esdConfiguration.isEnabled;
                let otherHasTelecomm = val.telecommConfiguration &&
                    val.telecommConfiguration.isEnabled;
                if (otherHasEsd) {
                    otherEsdBoardType = val.esdConfiguration.boardType;
                    if (!otherHasTelecomm) {
                        otherTelecommBoardType = val.esdConfiguration.boardType;
                    }
                }

                if (otherHasTelecomm) {
                    otherTelecommBoardType = val.telecommConfiguration.boardType;
                    if (!otherHasEsd) {
                        otherEsdBoardType = val.telecommConfiguration.boardType;
                    }
                }

                if (esdBoardType == otherEsdBoardType &&
                    telecommBoardType == otherTelecommBoardType) {
                    inUse = val;
                    break;
                }
            }
        }

        return inUse;
    }

    public canUse(location: SystemLocationEnum): boolean {
        let esdBoardType = null;
        let telecommBoardType = null;
        let boardInputLocation = null;
        let isSameBoardType: boolean;

        switch (location) {
            case SystemLocationEnum.PORT:
            case SystemLocationEnum.PORT_FORWARD:
            case SystemLocationEnum.PORT_REAR:
                boardInputLocation = BoardInputLocationEnum.PORT;
                break;
            case SystemLocationEnum.STARBOARD:
            case SystemLocationEnum.STARBOARD_FORWARD:
            case SystemLocationEnum.STARBOARD_REAR:
                boardInputLocation = BoardInputLocationEnum.STARBOARD;
                break;
        }

        let hasEsd = this.system &&
            this.system.esdConfiguration &&
            this.system.esdConfiguration.isEnabled;
        let hasTelecomm = this.system &&
            this.system.telecommConfiguration &&
            this.system.telecommConfiguration.isEnabled;

        if (hasEsd) {
            esdBoardType = this.system.esdConfiguration.boardType;
            if (!hasTelecomm) {
                telecommBoardType = this.system.esdConfiguration.boardType;
            }
        }

        if (hasTelecomm) {
            telecommBoardType = this.system.telecommConfiguration.boardType;
            if (!hasEsd) {
                esdBoardType = this.system.telecommConfiguration.boardType;
            }
        }

        isSameBoardType = esdBoardType == telecommBoardType;

        let esdBoardsAvailable = 0;
        let telecommBoardsAvailable = 0;
        for (let board of this.availableBoardLookups) {
            if (!board.isEnabled || !board.isAlive || board.isInUse) {
                continue;
            }

            let isEsdBoardAvailable: boolean = false;
            let isTelecommBoardAvailable: boolean = false;
            if (hasEsd && esdBoardType == board.boardType &&
                (board.boardInputLocation == BoardInputLocationEnum.PORT_STARBOARD ||
                board.boardInputLocation == boardInputLocation)) {
                isEsdBoardAvailable = true;
                esdBoardsAvailable += 1;
            }

            if (hasTelecomm && telecommBoardType == board.boardType &&
                (board.boardInputLocation == BoardInputLocationEnum.PORT_STARBOARD ||
                board.boardInputLocation == boardInputLocation)) {
                telecommBoardsAvailable += 1;
                isTelecommBoardAvailable = true;
            }

            if (esdBoardsAvailable == 0 && isSameBoardType && isTelecommBoardAvailable) {
                esdBoardsAvailable += 1;
            }

            if (telecommBoardsAvailable == 0 && isSameBoardType && isEsdBoardAvailable) {
                telecommBoardsAvailable += 1;
            }
        }

        for (let boardActivationRule of this.boardActivationRules) {
            let checkBoardType: BoardTypeEnum = undefined;
            if (boardActivationRule.boardType == esdBoardType) {
                checkBoardType = esdBoardType;
            }

            if (boardActivationRule.boardType == telecommBoardType) {
                checkBoardType = telecommBoardType;
            }

            if (checkBoardType == undefined) {
                continue;
            }

            let activatedOfType: number = 0;
            for (let existingSystem of this.currentSystems) {
                if (!existingSystem.isActive) {
                    continue;
                }

                let hasExistingEsd = existingSystem.esdConfiguration &&
                    existingSystem.esdConfiguration.isEnabled &&
                    existingSystem.esdConfiguration.boardType == checkBoardType;
                let hasExistingTelecomm = existingSystem.telecommConfiguration &&
                    existingSystem.telecommConfiguration.isEnabled &&
                    existingSystem.telecommConfiguration.boardType == checkBoardType;

                if (hasExistingEsd || hasExistingTelecomm) {
                    activatedOfType++;
                }
            }

            if (activatedOfType + 1 > boardActivationRule.maxActivations) {
                return false;
            }
        }

        return (esdBoardsAvailable > 0 && telecommBoardsAvailable > 0);
    }

    public checkDuplicatedIndexes(systemToCheck: ConnectionSystem): Set<string> {
        let duplicatedIndexes: Set<string> = new Set<string>();

        for (let currentSystem of this.currentSystems) {
            if (!currentSystem.isActive) {
                continue;
            }

            if (systemToCheck.esdConfiguration != undefined &&
                currentSystem.esdConfiguration != undefined) {
                let esdConfigToCheck = systemToCheck.esdConfiguration;
                let esdConfigToMatch = currentSystem.esdConfiguration;

                if (esdConfigToCheck.firstUpIndex == esdConfigToMatch.firstUpIndex) {
                    duplicatedIndexes.add('FIRST_UP_DUPLICATED');
                }

                if (esdConfigToCheck.inhibitIndex == esdConfigToMatch.inhibitIndex) {
                    duplicatedIndexes.add('INHIBIT_DUPLICATED');
                }

                if (esdConfigToCheck.resetIndex == esdConfigToMatch.resetIndex) {
                    duplicatedIndexes.add('RESET_DUPLICATED');
                }

                if (esdConfigToCheck.localInputIndex == esdConfigToMatch.localInputIndex) {
                    duplicatedIndexes.add('LOCAL_INPUT_DUPLICATED');
                }

                if (esdConfigToCheck.localOutputIndex == esdConfigToMatch.localOutputIndex) {
                    duplicatedIndexes.add('LOCAL_OUTPUT_DUPLICATED');
                }
            }

            if (systemToCheck.telecommConfiguration != undefined &&
                currentSystem.telecommConfiguration != undefined) {
                let telecommConfigToCheck = systemToCheck.telecommConfiguration;
                let telecommConfigToMatch = currentSystem.telecommConfiguration;

                if (telecommConfigToCheck.boardType == BoardTypeEnum.ELECTRIC &&
                    telecommConfigToMatch.boardType == BoardTypeEnum.ELECTRIC &&
                    telecommConfigToCheck.continuityLinkIndex == telecommConfigToMatch.continuityLinkIndex) {
                    duplicatedIndexes.add('CONTINUITY_LINK_DUPLICATED');
                }
            }
        }

        return duplicatedIndexes;
    }

    public selectLocation(location: SystemLocationEnum): void {
        if (this.selectedLocation !== location) {
            this.selectedLocation = location;
        } else if (location === this.selectedLocation) {
            this.selectedLocation = undefined;
        }
    }
}
