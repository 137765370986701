import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecordingTypeEnum } from '../enums/recording-type.enum';
import { Observable } from 'rxjs';

@Pipe({
    name: 'recordingType'
})
export class RecordingTypePipe implements PipeTransform {

    constructor(private _translate: TranslateService) {}

    public transform(recordingType: RecordingTypeEnum): Observable<string> {
        switch (recordingType) {
            case RecordingTypeEnum.AUDIO:
                return this._translate.get('SSL.RECORDING_TYPE_AUDIO');
            default:
            case RecordingTypeEnum.IP_CAMERA:
                return this._translate.get('SSL.RECORDING_TYPE_IP_CAMERA');
        }
    }
}
