import { Injectable } from '@angular/core';

import { Cause } from '../../../mod_esds/models/cause.model';
import { Effect } from '../../../mod_esds/models/effect.model';

@Injectable()
export class MockDbService {

    public causes: Cause[] = [];
    public effects: Effect[] = [];

}
