import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'usl-display-v1',
    styleUrls: ['./display-v1.component.css'],
    templateUrl: './display-v1.component.html'
})
export class UslDisplayV1Component {

    @Input() public showSettings: boolean;
}
