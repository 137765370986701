export class CssSync {

    private monitoredAnimations: Set<string> = new Set<string>();
    private animationElements: { [id: string]: Set<any>; } = {};
    private animationEventTimeStamps: { [id: string]: number; } = {};

    constructor() {
        window.addEventListener('animationstart', (event: any) => {
            if (this.monitoredAnimations.has(event.animationName)) {
                this.animationElements[event.animationName].add(event.target);
            }
        }, true);

        window.addEventListener('animationiteration', (event: any) => {
            if (this.monitoredAnimations.has(event.animationName)) {
                requestAnimationFrame((frameTime: number) => {
                    if (frameTime !== this.animationEventTimeStamps[event.animationName]) {
                        this.restartAnimations(this.animationElements[event.animationName]);
                    }

                    this.animationEventTimeStamps[event.animationName] = frameTime;
                });
            }
        }, true);
    }

    public sync(animationNames: string|string[]) {
        if (Array.isArray(animationNames)) {
            animationNames.forEach((animationName) => {
                this.monitoredAnimations.add(animationName);
                this.animationElements[animationName] = new Set();
                this.animationEventTimeStamps[animationName] = 0;
            });
        } else {
            this.monitoredAnimations.add(animationNames);
            this.animationElements[animationNames] = new Set();
            this.animationEventTimeStamps[animationNames] = 0;
        }
    }

    private restartAnimations(elements: Set<any>) {
        elements.forEach((el) => {
            if (window.getComputedStyle(el).animationPlayState !== 'paused') {
                if (this.validate(elements, el)) {
                    el.style.setProperty('animation', 'none');
                }
            }
        });

        requestAnimationFrame(() => {
            elements.forEach((el) => {
                if (window.getComputedStyle(el).animationPlayState !== 'paused') {
                    if (this.validate(elements, el)) {
                        el.style.removeProperty('animation');
                    }
                }
            });
        });
    }

    private validate(elements: Set<any>, el: any): boolean {
        const isValid = document.body.contains(el);
        if (!isValid) {
            elements.delete(el);
        }

        return isValid;
    }
}
