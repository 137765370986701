import { DasData } from './das-data.model';

export class DasOption {

    public hasDas: boolean;

    public dasData: DasData[];

    constructor(data: DasOption) {
        this.hasDas = false;
        if (data.hasDas != null) {
            this.hasDas = data.hasDas;
        }

        this.dasData = [];
        if (data.dasData) {
            for (const newState of data.dasData) {
                this.dasData.push(new DasData(newState));
            }
        }
    }

    public updateState(newState: DasData): void {
        if (this.dasData) {
            for (const oldState of this.dasData) {
                if (oldState.channelId == newState.channelId) {
                    oldState.updateState(newState);
                }
            }
        } else {
            this.dasData = [];
            this.dasData.push(new DasData(newState));
        }
    }
}
