import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PersistenceService } from '../shared/services/system/persistence.service';

@Component({
    selector: 'system-error',
    styleUrls: ['./system-error.component.css'],
    templateUrl: './system-error.component.html'
})
export class SystemErrorComponent implements OnInit, OnDestroy {

    public systemErrorNumber: number = 0;

    private routerSub: Subscription;
    private persistenceSub: Subscription;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _persistence: PersistenceService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.systemErrorNumber = params['id'];

                this.persistenceSub = this._persistence
                    .brainSystemError
                    .subscribe((data: number) => {
                        if (data == -1) {
                            this._router.navigate(['/']);
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            this.persistenceSub.unsubscribe();
        }
    }
}
