export enum ContentAlignmentEnum {
    TOP_LEFT = 1,
    TOP_CCENTER = 2,
    TOP_RIGHT = 4,
    MIDDLE_LEFT = 16,
    MIDDLE_CENTER = 32,
    MIDDLE_RIGHT = 64,
    BOTTOM_LEFT = 256,
    BOTTOM_CENTER = 512,
    BOTTOM_RIGHT = 1024
}
