import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageConfig } from '../../shared/models/system/page-config.model';
import { PageConfigService } from '../../shared/services/system/page-config.service';
import { PageBreadcrumb } from '../../shared/models/system/page-breadcrumb.model';
import { HistoryItem } from '../models/history-item.model';
import { HistoryService } from '../services/history.service';
import { PersistenceService } from '../../shared/services/system/persistence.service';

@Component({
    selector: 'esds-history',
    styleUrls: ['./history.component.css'],
    templateUrl: './history.component.html'
})
export class EsdsHistoryComponent implements OnInit, OnDestroy {

    public historyItems: HistoryItem[] = [];
    public selectedHistoryItem: HistoryItem = undefined;
    public currentTimeZone: string = 'UTC';

    private webSockets: Subscription[] = [];
    private routerSub: Subscription;

    constructor(
        private _route: ActivatedRoute,
        private _persistence: PersistenceService,
        private _configService: PageConfigService,
        private _historyService: HistoryService
    ) {}

    public ngOnInit(): void {
        this.routerSub = this._route
            .params
            .subscribe((params) => {
                this.updateNavigation();
                this.serviceGetHistoryItems();
                this.webSockets.push(this.websocketGetHistoryItem());
                this.webSockets.push(this._persistence
                    .currentTimeZoneIANA
                    .subscribe((data: string) => {
                        this.currentTimeZone = data;
                    }));
            });
    }

    public ngOnDestroy(): void {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
            for (const val of this.webSockets) {
                if (val) {
                    val.unsubscribe();
                }
            }
        }
    }

    // Template Helpers
    public clearHistory(): void {
        this.selectedHistoryItem = undefined;
        this.serviceDeleteClearHistoryItems();
        this.serviceGetHistoryItems();
    }

    public selectHistoryItem(selectedHistoryItem: HistoryItem): void {
        this.selectedHistoryItem = selectedHistoryItem;
    }

    // Helper methods
    private updateNavigation(): void {
        const pageBreadcrumbs: PageBreadcrumb[] = [];
        pageBreadcrumbs.push(new PageBreadcrumb({
            title: 'ESDS',
            path: '/esds'
        }));
        this._configService.setConfig(new PageConfig({
            title: 'HISTORY',
            breadcrumbs: pageBreadcrumbs
        }));
    }

    // Service Calls
    private serviceGetHistoryItems(): Subscription {
        return this._historyService
            .getHistoryItems()
            .subscribe((data: HistoryItem[]) => {
                this.historyItems = data;
            });
    }

    private websocketGetHistoryItem(): Subscription {
        return this._historyService
            .websocketGetNewHistoryItem()
            .subscribe((data: HistoryItem) => {
                this.historyItems.push(data);
            });
    }

    private serviceDeleteClearHistoryItems(): Subscription {
        return this._historyService.deleteClearHistory().subscribe();
    }
}
